import { api } from "../api";

// actions
const BASE_CARREGA_BASE = "BASE_CARREGA_BASE";

// action creators
export const carregaBase = (alunoid, token) => {
  return async (dispatch) => {
    try {
      const res = await fetch(`${api.carrega_base}?alunoid=${alunoid}`, {
        method: "get",
        headers: { token },
      });
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        const base = json[0].data;
        dispatch({ type: BASE_CARREGA_BASE, base });
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      throw new Error(error);
    }
  };
};

export const consultaBase = (alunoid, token) => {
  return async (dispatch) => {
    try {
      const res = await fetch(`${api.consulta_base}?alunoid=${alunoid}`, {
        method: "get",
        headers: { token },
      });
      // if (res.status >= 200 && res.status < 300) {
      //   const json = await res.json();
      // } else {
      if (res.status < 200 || res.status >= 300) {
        throw new Error(res.statusText);
      }
    } catch (error) {
      console.log("consultaBase error", error);
      throw new Error(error);
    }
  };
};

// reducer
export default function reducer(state = {}, action) {
  switch (action.type) {
    case BASE_CARREGA_BASE:
      return action.base;

    default:
      return state;
  }
}
