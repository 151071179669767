import { useContext, useState } from "react";
import {
  Box,
  Button,
  MenuItem,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import { ModalPageContext } from "../../../ui/partials/Modal";
import { useAllCertifications } from "../../certifications/api/getCertifications";
import { useGestorTracks } from "../../tracks/api/getGestorTracks";
import AddCourseToTrack from "../../tracks/components/AddCourseToTrack";
import EditTrackCoursesOrder from "../../tracks/components/EditTrackCoursesOrder";
import { usePagination } from "@mui/lab";
import { useParams } from "react-router-dom";

export default function ManageCourseTracks() {
  const [isFirtSuccess, setIsFirstSuccess] = useState(true);
  const [trackType, setTrackType] = useState("track");
  const [selectedTrackId, setSelectedTrackId] = useState(0);
  const [selectedCertificationId, setSelectedCertificationId] = useState(0);
  const { courseId } = useParams();
  const isOutsourcedCourse = courseId > 900000000;

  // const { data } = useCourse({ courseId });
  const { closeModal } = useContext(ModalPageContext);
  const gestorTracks = useGestorTracks({
    config: {
      onSuccess: (data) => {
        setSelectedTrackId(data[0].trailId);
        if (isFirtSuccess) {
          setIsFirstSuccess(false);
        }
      },
    },
  });
  const allCertifications = useAllCertifications({
    config: {
      onSuccess: (data) => {
        setSelectedCertificationId(data[0].trailId);
        if (isFirtSuccess) {
          setIsFirstSuccess(false);
        }
      },
    },
  });

  const handleChangeTrackType = (event) => {
    setTrackType(event.target.value);
  };
  const handleChangeSelectedTrack = (event) => {
    setSelectedTrackId(event.target.value);
  };
  const handleChangeSelectedCertification = (event) => {
    setSelectedCertificationId(event.target.value);
  };

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <TextField
          sx={{ width: "100%" }}
          label="Tipo de curso"
          select
          onChange={handleChangeTrackType}
          value={trackType}
        >
          <MenuItem value="track">Trilha </MenuItem>
          <MenuItem value="certification" disabled={!isOutsourcedCourse}>
            Certificação
          </MenuItem>
        </TextField>

        {trackType == "track" && (
          <TextField
            sx={{ width: "100%" }}
            value={selectedTrackId}
            onChange={handleChangeSelectedTrack}
            defaultValue={gestorTracks.data?.[0].trailId}
            label="Trilhas"
            select
          >
            {gestorTracks.data?.map((track, index) => {
              return (
                <MenuItem key={`option ${index}`} value={track.trailId}>
                  {track.name}
                </MenuItem>
              );
            })}
          </TextField>
        )}
        {trackType == "certification" && (
          <TextField
            sx={{ width: "100%" }}
            value={selectedCertificationId}
            onChange={handleChangeSelectedCertification}
            defaultValue={allCertifications.data?.[0].trailId}
            label="Certificados"
            select
          >
            {allCertifications.data.map((certification, index) => {
              return (
                <MenuItem key={`option ${index}`} value={certification.trailId}>
                  {certification.title}
                </MenuItem>
              );
            })}
          </TextField>
        )}
        {(!!selectedTrackId || !!selectedCertificationId) && (
          <EditTrackCoursesOrder
            trackId={
              trackType == "track" ? selectedTrackId : selectedCertificationId
            }
          />
        )}

        {(!!selectedTrackId || !!selectedCertificationId) && (
          <AddCourseToTrack
            sx={{
              marginTop: "auto",
              alignSelf: "flex-end",
              marginLeft: "auto",
            }}
            trackId={
              trackType == "track" ? selectedTrackId : selectedCertificationId
            }
            courseId={courseId}
            isCertification={trackType == "certification"}
          />
        )}
      </Box>
    </>
  );
}
