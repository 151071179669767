import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { axios } from "../../../shared/lib/axios";
import { createNotification } from "../../../shared/store/notifications";

export const setCourseOnTrack = async (data) => {
  const res = await axios.cadernoVirtualBase.post(
    "/certification-service/certification/includeCourse",
    data
  );
  return res;
};

export const useSetCourseOnTrack = ({ config, trackId } = {}) => {
  const queryClient = useQueryClient();
  return useMutation({
    ...config,
    mutationKey: ["addCourseToTrack"],
    mutationFn: setCourseOnTrack,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["trackCourses", { trackId }],
      });
      createNotification({
        type: "success",
        message: "Curso adicionado com sucesso",
      });
    },
  });
};
