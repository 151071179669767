import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../shared/lib/axios";

export const uploadFileAnswer = async ({ questionId, attemptId, files }) => {
  const formData = new FormData();
  formData.append(
    "content",
    new Blob([JSON.stringify({ questionId, answerAttemptId: attemptId })], {
      type: "application/json",
    })
  
  );

  files.map((file) => formData.append("files", file));

  const res = await axios.cadernoVirtualBase.post(
    "/survey-service2/research/uploadFileAnswer",
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return res.object;
};

export const useUploadFileAnswer = ({ config, questionId, attemptId } = {}) => {
  return useMutation({
    ...config,

    mutationFn: ({ file }) => uploadFileAnswer({ questionId, attemptId, file }),
  });
};
