import axios from "axios";
import { api, courseApi } from "../api";
import { getClient } from "../config/urlConfig";

// actions
const CHANNEL_POSTS_CANAL = "CHANNEL_POSTS";
const CHANNEL_SET_CURRENT_CHANNEL = "CHANNEL_SET_CURRENT_CHANNEL";
const CHANNELS_CANAL_BY_ID = "CHANNELS_CANAL_BY_ID";
const CHANNEL_STATUS = "CHANNEL_STATUS";
const CHANNEL_REMOVE_POST = "CHANNEL_REMOVE_POST";
const CHANNEL_QTD_FILTRADO = "CHANNEL_QTD_FILTRADO";

const domain = getClient();

// action creators
export const postsCanal = ({ token, alunoid, canalid }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CHANNEL_STATUS, status: "loading" });
      const res = await fetch(
        `${api.posts_canal}?alunoid=${alunoid}&canalid=${canalid}`,
        {
          method: "get",
          headers: { token },
        }
      );
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        const items = json[0].data;
        dispatch({ type: CHANNEL_POSTS_CANAL, items });
        dispatch({ type: CHANNEL_STATUS, status: "success" });
        return items;
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      dispatch({ type: CHANNEL_STATUS, status: "error" });
      throw new Error(error);
    }
  };
};

export const postsCanalPaginado = ({
  token,
  alunoid,
  canalid,
  pageNumber,
  pageSize,
  filter = "*",
}) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CHANNEL_STATUS, status: "loading" });
      const res = await fetch(
        `${
          api.posts_canal_paginado
        }?alunoid=${alunoid}&canalid=${canalid}&pageNumber=${pageNumber}&pageSize=${pageSize}&filtro=${
          filter == "" ? "*" : encodeURI(filter)
        }`,
        {
          method: "get",
          headers: { token },
        }
      );
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        const items = json[0].data;
        dispatch({ type: CHANNEL_POSTS_CANAL, items });
        dispatch({ type: CHANNEL_STATUS, status: "success" });
        return items;
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      dispatch({ type: CHANNEL_STATUS, status: "error" });
      throw new Error(error);
    }
  };
};

export const postsCanalFiltrado = ({
  token,
  alunoid,
  canalid,
  apenas_ativos,
  dias,
}) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CHANNEL_STATUS, status: "loading" });
      const res = await fetch(
        `${api.posts_canal_filtrado}?alunoid=${alunoid}&canalid=${canalid}&apenas_ativos=${apenas_ativos}&dias=${dias}`,
        {
          method: "get",
          headers: { token },
        }
      );
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        const items = json[0].data;
        dispatch({ type: CHANNEL_POSTS_CANAL, items });
        dispatch({ type: CHANNEL_STATUS, status: "success" });
        return items;
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      dispatch({ type: CHANNEL_STATUS, status: "error" });
      throw new Error(error);
    }
  };
};

export const postsCanalFiltradoPaginado = ({
  token,
  alunoid,
  canalid,
  apenas_ativos,
  dias,
  pageNumber,
  pageSize,
  filter = "*",
}) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CHANNEL_STATUS, status: "loading" });
      const res = await fetch(
        `${
          api.posts_canal_filtrado_paginado
        }?alunoid=${alunoid}&canalid=${canalid}&apenas_ativos=${apenas_ativos}&dias=${dias}&pageNumber=${pageNumber}&pageSize=${pageSize}&filtro=${
          filter == "" ? "*" : encodeURI(filter)
        }`,
        {
          method: "get",
          headers: { token },
        }
      );
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        const items = json[0].data;
        dispatch({ type: CHANNEL_POSTS_CANAL, items });
        dispatch({ type: CHANNEL_STATUS, status: "success" });
        return items;
      } else {
        dispatch({ type: CHANNEL_POSTS_CANAL, items: [] });

        throw new Error(res.statusText);
      }
    } catch (error) {
      dispatch({ type: CHANNEL_STATUS, status: "error" });
      throw new Error(error);
    }
  };
};

export const qtdPostsCanalFiltrado = (
  token,
  alunoid,
  canalid,
  apenas_ativos,
  dias
) => {
  return async (dispatch) => {
    try {
      const res = await fetch(
        `${api.qtd_posts_canal_filtrado}?alunoid=${alunoid}&canalid=${canalid}&apenas_ativos=${apenas_ativos}&dias=${dias}`,
        {
          method: "get",
          headers: { token },
        }
      );
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        dispatch({ type: CHANNEL_QTD_FILTRADO, total: json[0].data });
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      dispatch({ type: CHANNEL_QTD_FILTRADO, total: 0 });
      console.log("qtdPostsAluno error", error);
      throw new Error(error);
    }
  };
};

export const dadosCanal = ({ alunoid, token, canalid }) => {
  return async (dispatch) => {
    try {
      const res = await fetch(
        `${api.dados_canal}?alunoid=${alunoid}&canalid=${canalid}`,
        {
          method: "get",
          headers: { token },
        }
      );
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        const canal = json[0].data[0];
        dispatch({ type: CHANNELS_CANAL_BY_ID, canal });
        return canal;
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      throw new Error(error);
    }
  };
};

export const setCurrentChannel = (currentChannel) => {
  console.log("setando currentchannel", currentChannel);
  return { type: CHANNEL_SET_CURRENT_CHANNEL, currentChannel };
};

export const removePost = ({ postId, token }) => {
  return async (dispatch, getState) => {
    try {
      const res = await fetch(
        `${api.excluir_postagem_gestor}?capituloid=${postId}`,
        {
          method: "get",
          headers: { token },
        }
      );

      if (res.status >= 200 && res.status < 300) {
        const alunoid = getState().auth.user.alunoid;
        const channelId = getState().channel?.currentChannel?.CANALID;

        await postsCanal({ alunoid, canalid: channelId, token })(dispatch);

        return res;
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      console.error(error);
    }
  };
};

export const enablePost = (postId, token) => async (dispatch, getState) => {
  try {
    const res = await axios.post(
      courseApi.save_chapter.url,
      {
        chapterId: postId,
        testType: "S",
      },
      {
        headers: { token, domain },
      }
    );

    if (res.status >= 200 && res.status < 300) {
      const alunoid = getState().auth.user.alunoid;
      const channelId = getState().channel?.currentChannel?.CANALID;

      await postsCanal({ alunoid, canalid: channelId, token })(dispatch);

      return res;
    } else {
      throw new Error(res.statusText);
    }
  } catch (error) {
    console.error(error);
  }
};

export const disablePost = (postId, token) => async (dispatch, getState) => {
  try {
    const res = await axios.post(
      courseApi.save_chapter.url,
      {
        chapterId: postId,
        testType: "N",
      },
      {
        headers: { token, domain },
      }
    );

    if (res.status >= 200 && res.status < 300) {
      const alunoid = getState().auth.user.alunoid;
      const channelId = getState().channel?.currentChannel?.CANALID;

      await postsCanal({ alunoid, canalid: channelId, token })(dispatch);

      return res;
    } else {
      throw new Error(res.statusText);
    }
  } catch (error) {
    console.error(error);
  }
};

// reducer
export default function reducer(state = {}, action) {
  switch (action.type) {
    case CHANNEL_POSTS_CANAL:
    case CHANNEL_REMOVE_POST:
      return { ...state, items: action.items };
    case CHANNEL_SET_CURRENT_CHANNEL:
      return { ...state, currentChannel: action.currentChannel };
    case CHANNELS_CANAL_BY_ID:
      return { ...state, currentChannel: action.canal };
    case CHANNEL_QTD_FILTRADO:
      return { ...state, total: action.total.qtd };
    case CHANNEL_STATUS:
      return { ...state, status: action.status };
    default:
      return state;
  }
}
