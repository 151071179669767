import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import "./richEditor.css";
import { Box } from "@mui/material";

const defaultModules = {
  toolbar: false,
};

const defaultFormats = [
  "header",
  "align",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "code-block",

  "color",
  "background",

  "list",
  "indent",

  "bullet",

  "link",
  "image",
];

export default function RichTextContent({
  value,
  onChange,
  modules = defaultModules,
  formats = defaultFormats,
}) {
  return (
    <Box
      sx={{
        "& .ql-editor": {
          height: "unset",
          minHeight: "unset",
          padding: "0",
        },
      }}
    >
      <ReactQuill
        readOnly
        // defaultValue={""}
        value={value}
        theme={"bubble"}
        // modules={{ toolbar: ["bold"] }}
        formats={formats}
        modules={defaultModules}
      />
    </Box>
  );
}
