import { useLocation, useNavigate, useParams } from "react-router-dom";
import { InputBase } from "@mui/material";
import { Container, Typography, useMediaQuery, useTheme } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useState } from "react";
import { alpha, styled } from "@mui/material";
import { connect } from "react-redux";
import SearchBar from "./SearchBar";
import { bindActionCreators } from "redux";
import {
  setBreadCrumbsElement,
  setCurrentTool,
} from "../../shared/store/menu/menu";
import { keysNav } from "../../shared/constants/routes";

function titleCase(string) {
  return string[0].toUpperCase() + string.substr(1).toLowerCase();
}

const SearchBarWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  borderRadius: theme.shape.borderRadius,
  border: "solid 1px var(--secondary_color_light)",
  backgroundColor: "white",
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 1),
  },
  width: "100%",
  alignItems: "center",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const Button = styled("button")(({ theme }) => ({
  height: "100%",
}));

const BreadCrumbs = ({
  root,
  themeRoute,
  postLabel,
  actions,
  aliases,
  breakActions = false,
  searchBar = false,
  onlyshowlastpath,
  currentTool,
  onClickSearch,
  containerProps,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const sanityCheck = keysNav[currentTool?.name]
    ? location.pathname.split("/")[1].toUpperCase() ==
      keysNav[currentTool?.name].toUpperCase()
    : false;

  const firstCrumbTitle = sanityCheck
    ? currentTool?.title
    : titleCase(location.pathname.split("/")[1]);
  const gt600 = useMediaQuery("(min-width:600px)");
  const theme = useTheme();

  const largeDisplay = theme.breakpoints.up("md");

  const [search, setSearch] = useState("");
  const mediumDisplay = theme.breakpoints.up("md");
  const isDisplayMedium = useMediaQuery(mediumDisplay);

  const [currentMenu, setCurrentMenu] = useState("");

  useEffect(() => {
    setCurrentMenu(
      aliases?.find(
        (alias) =>
          alias.ferramenta.toLowerCase() === location.pathname.split("/")[1]
      )
    );
  }, [aliases, location.pathname]);

  return (
    <Container
      sx={{
        "@media screen and (min-width: 1200px)": {
          maxWidth: "unset",
        },
        margin: "10px 0",
      }}
      {...containerProps}
    >
      <Container
        sx={{
          width: "100%",
          padding: "0.8rem",
          ...(breakActions && !isDisplayMedium
            ? {}
            : {
                display: { xs: "unset", sm: "flex" },
                flexDirection: "flex-start",
                alignItems: "center",
              }),
          borderRadius: "5px",
          justifyContent: "space-between",
          borderBottom: "2px solid #DBDBDB",
          "@media screen and (min-width: 600px)": {
            backgroundColor: "#FFFFFF",
          },
          "@media screen and (min-width: 1200px)": {
            maxWidth: "unset",
          },
        }}
      >
        <Container
          disableGutters
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            "@media screen and (min-width: 1200px)": {
              maxWidth: "unset",
            },
            height: "30px",
            ".MuiTypography-root": {
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              color: theme.palette.primary.dark,
              fontWeight: "600",
            },
            ".MuiTypography-root:not(first-child):not(.slash)": {
              marginLeft: "9px",
            },
            // marginTop: { xs: "-1.5rem", sm: "unset" },
          }}
        >
          {!onlyshowlastpath && (
            <Typography
              sx={{
                ...(!!themeRoute && {
                  cursor: "pointer",
                  textDecorationLine: "underline",
                }),
              }}
              onClick={() => {
                navigate(`../${root.route}`, { replace: true });
              }}
            >
              {firstCrumbTitle}
            </Typography>
          )}
          {!!themeRoute && (
            <>
              {!onlyshowlastpath && (
                <Typography
                  
                  sx={{
                    
                    color: "#6E6D7A",
                  }}
                >
                  {">"}
                </Typography>
              )}
              <Typography
                sx={{
                  ...(!!postLabel && {
                    textDecorationLine: "underline",
                    cursor: "pointer",
                  }),
                }}
                onClick={() => {
                  navigate(
                    `../${
                      root.route.includes("/")
                        ? root.route.split("/")[1].split("?")[0]
                        : root.route.split("?")[0]
                    }/${
                      themeRoute.route == null || themeRoute.route == undefined
                        ? location.pathname.split("/")[2]
                        : themeRoute.route
                    }`,
                    {
                      replace: true,
                    }
                  );
                }}
              >
                {themeRoute.label}
                {/* {gt600 ? themeRoute.label : "..."} */}
              </Typography>
            </>
          )}
          {!!postLabel && (
            <>
              <Typography
                
                sx={{
                  color: "#6E6D7A",
                }}
              >
                {">"}
              </Typography>
              <Typography sx={{}}>{postLabel}</Typography>
            </>
          )}
        </Container>

        <Container
          disableGutters
          sx={{
            [mediumDisplay]: {
              display: "flex",
              flexGrow: "1",
              justifyContent: "end",
            },
          }}
        >
          {actions}
        </Container>
        {!!searchBar && (
          <SearchBarWrapper>
            <Button
              onClick={() => {
                onClickSearch(search);
              }}
            >
              <SearchIconWrapper>
                <SearchIcon color="secondary" />
              </SearchIconWrapper>
            </Button>
            <InputBase
              value={search}
              onChange={(event) => setSearch(event.target.value)}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  onClickSearch(search);
                }
              }}
              placeholder="Pesquisar"
              inputProps={{ "aria-label": "search" }}
            />
          </SearchBarWrapper>
        )}
      </Container>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    onlyshowlastpath: state.menu.onlyshowlastpath,
    currentTool: state.menu.currentTool,
  };
};

const mapDispatchToProps = (dispatch) => {
  bindActionCreators(
    {
      setBreadCrumbsElement,
    },
    dispatch
  );
};
export default connect(mapStateToProps)(BreadCrumbs);
