import { Dialog } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import DialogAppBar from "./DialogAppBar";

const styles = (theme) => ({
  root: {
    marginBottom: theme.spacing(4),
  },
  heading: {
    display: "flex",
    alignContent: "center",
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  main: {
    position: "relative",
    height: "100vh",
    marginTop: theme.spacing(8),
    overflowX: "hidden",
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(7),
    },
  },
  "@media print": {
    main: {
      overflow: "visible",
    },
  },
});

const DialogFull = ({ classes, children, open, ...other }) => {
  return (
    <div className={classes.root}>
      <Dialog fullScreen open={open}>
        <DialogAppBar {...other} />
        <div id="dialogFullMain" className={classes.main}>
          {children}
        </div>
      </Dialog>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(DialogFull);
