import { TextField, Box } from "@mui/material";
import  TextInput from "../../../ui/components/TextComponent/TextInput"
export default function Discursive({ value, onChange, inEditing, ...props }) {
  const type = props?.fieldType ? props?.fieldType : 2;
  if(type == 1) {
    return (
    
      <TextField
      placeholder="Resposta"
      disabled={inEditing}
      multiline={false}
      rows={1}
      maxRows={1}
      value={value}
      onChange={onChange}
      sx={{ width: "100%", marginTop: "0.5rem" }}
      />
      
    );
  }
  if(type == 2) {
    return (
    
      <TextField
          placeholder="Resposta"
          disabled={inEditing}
          multiline={true}
          rows={4}
          maxRows={6}
          value={value}
          onChange={onChange}
          sx={{ width: "100%", marginTop: "0.5rem" }}
          />
      
    );
  }
  if(type == 3) {
    return (
    
      <TextInput sx={{ width: "100%", marginTop: "0.5rem" }} value={value} onChange={onChange} />
      
    );
  }
}

/* <TextField
placeholder="Resposta"
disabled={inEditing}
multiline
rows={4}
maxRows={6}
value={value}
onChange={onChange}
sx={{ width: "100%", marginTop: "0.5rem" }}
/> */