import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import TextComponent from "../../../ui/components/TextComponent";
import RichText from "../../../../../components/RichText";

export const Alternatives = (props) => (
  <MultipleChoice isQuality={false} {...props} />
);

export const Quality = (props) => (
  <MultipleChoice isQuality={true} {...props} />
);

function MultipleChoice({
  value,
  onChange,
  listOptions = [],
  onChangeChoices,
  onRemoveChoice,
  onAddChoice,
  canEditText = true,
  inEditing = false,
  showAnswer = false,
  isQuality,
  ...props
}) {
  const editing = !isQuality && inEditing;

  const numberValue = typeof value === "string" ? Number(value) : value;
  return (
    <>
      <RadioGroup
        sx={{
          marginTop: "1rem",
          "& .MuiRadio-root": {
            paddingY: "0.25rem",
          },
        }}
        value={numberValue}
        onChange={(event) => {
          if (inEditing || (!showAnswer && !inEditing)) {
            onChange(event);
          }
        }}
      >
        {listOptions
          .filter(
            (object) => object.remove === undefined || object.remove === false
          )
          .map(
            (choice, index) =>
              !choice?.remove && (
                <Box
                  key={`option-${index}`}
                  sx={{
                    margin: "0.1rem",
                    padding: "0.25rem",
                    borderRadius: "10px",
                    outline:
                      numberValue === index && showAnswer
                        ? "5px solid blue"
                        : "unset",
                    display: "flex",
                    width: "fit-content",
                  }}
                >
                  <ChoiceComponent
                    value={index}
                    label={choice.optionDescription}
                    editLabel={(newValue) => onChangeChoices(index, newValue)}
                    inEditing={editing}
                    canEditText={canEditText}
                    choiseComponent={editing ? false : true}
                  />
                  {editing &&
                    listOptions.filter(
                      (object) =>
                        object.remove === undefined || object.remove === false
                    ).length > 2 && (
                      <IconButton
                        sx={{ borderRadius: "5px" }}
                        onClick={() => onRemoveChoice(index)}
                      >
                        <DeleteOutlinedIcon
                          color="error"
                          sx={{ fontSize: "24px" }}
                        />
                      </IconButton>
                    )}
                </Box>
              )
          )}
      </RadioGroup>
      {editing &&
        listOptions.filter(
          (object) => object.remove === undefined || object.remove === false
        ).length < 5 &&
        canEditText && (
          <Button
            sx={{ marginTop: "0.5rem" }}
            variant="outlined"
            onClick={onAddChoice}
          >
            <AddCircleOutlineRoundedIcon sx={{ fontSize: "24px" }} />
            <Typography sx={{ marginLeft: "0.5rem" }}>
              Adicionar opção
            </Typography>
          </Button>
        )}
    </>
  );
}
export function AutocompleteQuestion() {
  return <></>;
}

export function DropdownList({ ...props }) {
  return props.inEditing ? (
    <MultipleChoice isQuality={false} {...props} />
  ) : (
    <Box
      sx={{
        padding: "1rem",
      }}
    >
      <TextField
        onChange={(event) => {
          console.log(event.target.value);
          props.onChange(event);
        }}
        sx={{
          minWidth: "100px",
        }}
        label="Opções"
        select
      >
        {props.listOptions.map((choice, index) => {
          return (
            <MenuItem placeholder="Teste" value={index}>
              <Typography
                dangerouslySetInnerHTML={{ __html: choice.optionDescription }}
              />
            </MenuItem>
          );
        })}
      </TextField>
    </Box>
  );
}

export function ChoiceComponent({
  value,
  label,
  editLabel,
  inEditing,
  canEditText,
  sx,
  ...props
}) {
  return (
    <Box sx={{ display: "flex", alignItems: "center", ...sx }}>
      <Radio value={value} sx={{ marginBottom: "10px" }} />
      <RichText
        sx={{ marginTop: "5px" }}
        value={label}
        onChange={(newValue) => editLabel({ optionDescription: newValue })}
        isEditing={inEditing && canEditText}
        choiseComponent={props.choiseComponent}
      />
    </Box>
  );
}
