import {
  Box,
  Divider,
  IconButton,
  SvgIcon,
  Typography,
  useTheme,
} from "@mui/material";
import {
  Cancel,
  Delete,
  InsertDriveFileOutlined,
  Link,
} from "@mui/icons-material";

function ExtensionIcon({ extension, iconScale }) {
  return (
    <SvgIcon
      sx={{
        transform: `scale(${iconScale})`,
        margin: "10px",
        fontSize: "200px",
        color: "var(--primary_color_main)",
      }}
    >
      {extension === "URL" ? (
        <Link />
      ) : (
        <>
          <InsertDriveFileOutlined />
          <rect fill="white" width="24" height="9" x="0" y="10"></rect>
          <text
            x="12"
            y="17"
            width="22"
            textAnchor="middle"
            fontSize="8px"
            fontWeight="700"
            color="var(--primary_color_main)"
          >
            {extension.toUpperCase()}
          </text>
        </>
      )}
    </SvgIcon>
  );
}

export default function FileMiniature({
  file,
  src,
  onDelete,
  iconScale = 0.1,
}) {
  const fileExtension = () => {
    if (file) {
      const lastLinkWord = file.name?.split(".")?.pop() || "URL";

      return lastLinkWord.length <= 5 ? lastLinkWord : "URL";
    }
    if (src) {
      const lastLinkWord = src?.split(".")?.pop() || "URL";
      return lastLinkWord.length <= 5 ? lastLinkWord : "URL";
    }
    return "NULL";
  };
  const imgSrc = () => {
    if (src) {
      return src;
    }
    if (file) {
      return URL.createObjectURL(file);
    }
    return "error";
  };
  const imageExtensions = ["jpg", "jpeg", "png", "gif", "webp", "svg"];
  const isImage = () => {
    return imageExtensions.findIndex((foo) => foo == fileExtension()) > -1;
  };
  const theme = useTheme();

  return (
    <Box
      sx={{
        aspectRatio: "16 / 9",
        height: "90px",
        width: "90px",
        padding: 1,
        borderRadius: 1,
        backgroundColor: theme.palette.primary.main,
        position: "relative",
      }}
    >
      <Box
        sx={{
          display: "flex",
          // flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          widtht: "100%",
          backgroundColor: theme.palette.grey[200],
        }}
      >
        {isImage() ? (
          <Box
            component="img"
            sx={{
              objectFit: "contain",
              height: "100%",
              width: "100%",
            }}
            alt=""
            src={imgSrc()}
          />
        ) : (
          <>
            <ExtensionIcon extension={fileExtension()} iconScale={0.25} />
          </>
        )}
      </Box>

      <IconButton
        onClick={onDelete}
        size="large"
        sx={{
          fontSize: "22px !!important",
          position: "absolute",
          top: 0,
          right: 0,
        }}
        // color="white"
      >
        <Cancel />
      </IconButton>
      <Typography
        sx={{
          position: "absolute",
          bottom: 3,
          textShadow: "0 0 1px #000",
          maxWidth: "74px",
          textOverflow: "clip",
          whiteSpace: "break",
          // WebkitTextStroke: "1px black",
          fontSize: "0.8rem",
        }}
        noWrap
        variant="caption"
      >
        {file.name}
      </Typography>
    </Box>
  );
}
