import { getClient } from "../config/urlConfig";

const api = "https://cadernovirtual.inf.br";
const diaryApi = `${api}/diary-service`;
const middlewareApi = "https://middleware.cadernovirtual.com.br";
const middlewareBackendApi = `${middlewareApi}/Backend.asmx`;
const middlewareAlunoApi = `${middlewareApi}/aluno.asmx`;

const SET_STUDENT_SEARCH_TERM = "SET_STUDENT_SEARCH_TERM";
const SET_WILL_FETCH = "SET_WILL_FETCH";
const SET_STUDENT_SITUATION = "SET_STUDENT_SITUATION";
const SET_STUDENT_FINAL_GRADE = "SET_STUDENT_FINAL_GRADE";
const CLEAN_STUDENT_DATA = "CLEAN_STUDENT_DATA";
const GET_DIARY = "GET_DIARY";
const GET_STUDENT_RATING_DATA = "GET_STUDENT_RATING_DATA";
const GET_STUDENT_ACCESS_DATA = "GET_STUDENT_ACCESS_DATA";
const GET_STUDENT_TASKS = "GET_STUDENT_TASKS";
const GET_STUDENT_EVENTS = "GET_STUDENT_EVENTS";
const GET_COURSE_CLASSROOM = "GET_COURSE_CLASSROOM";
const GET_WORKLOAD = "GET_WORKLOAD";
const GET_COURSE_STUDENTS = "GET_COURSE_STUDENTS";
const GET_CLASSES = "GET_CLASSES";
const GET_TEACHER = "GET_TEACHER";
const GET_ENROLLMENT_DATA = "GET_ENROLLMENT_DATA";
const POST_SITUATION = "POST_SITUATION";
const POST_FINAL_GRADE = "POST_FINAL_GRADE";

const domain = getClient();

export const setStudentSearchTerm = (searchTerm) => {
  return (dispatch) => {
    dispatch({
      type: SET_STUDENT_SEARCH_TERM,
      studentSearchTerm: searchTerm,
    });
  };
};

export const setWillFetch = (boolean) => {
  return (dispatch) => {
    dispatch({
      type: SET_WILL_FETCH,
      willFetch: boolean,
    });
  };
};

export const setStudentSituation = (situation) => {
  return (dispatch) => {
    dispatch({
      type: SET_STUDENT_SITUATION,
      currentStudentSituation: situation,
    });
  };
};

export const setStudentFinalGrade = (grade) => {
  return (dispatch) => {
    dispatch({
      type: SET_STUDENT_FINAL_GRADE,
      currentStudentFinalGrade: grade,
    });
  };
};

export const cleanStudentData = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAN_STUDENT_DATA,
    });
  };
};

export const getDiaryData = ({ disciplinaid, token }) => {
  return async (dispatch) => {
    try {
      const res = await fetch(`${diaryApi}/diary?courseId=${disciplinaid}`, {
        method: "get",
        headers: { token, domain },
      });
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        dispatch({
          type: GET_DIARY,
          diaryData: json,
        });
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      console.log("Erro ao buscar dados de diario:", error);
    }
  };
};

export const getRatingStudentData = ({ disciplinaid, alunoid, token }) => {
  return async (dispatch) => {
    try {
      const res = await fetch(
        `${diaryApi}/registration?courseId=${disciplinaid}&studentId=${alunoid}`,
        {
          method: "get",
          headers: { token, domain },
        }
      );
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        const ratingData = json[0];
        dispatch({
          type: GET_STUDENT_RATING_DATA,
          ratingData: ratingData,
          currentStudentSituation: ratingData.situation,
          currentStudentFinalGrade: ratingData.finalGrade,
        });
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      console.log("Erro ao buscar dados da avaliação do aluno:", error);
    }
  };
};

export const getStudentAccessData = ({ disciplinaid, alunoid, token }) => {
  return async (dispatch) => {
    try {
      const res = await fetch(
        `${diaryApi}/course-data-access?courseId=${disciplinaid}&studentId=${alunoid}`,
        {
          method: "get",
          headers: { token, domain },
        }
      );
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        dispatch({
          type: GET_STUDENT_ACCESS_DATA,
          accessData: json,
        });
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      console.log("Erro ao buscar dados de acesso do aluno:", error);
    }
  };
};

export const getTasksData = ({ disciplinaid, alunoid, token }) => {
  return async (dispatch) => {
    try {
      const res = await fetch(
        `${middlewareBackendApi}/BK_TarefasAlunoDisciplina?alunoid=${alunoid}&disciplinaid=${disciplinaid}`,
        {
          method: "get",
          headers: { token },
        }
      );
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        dispatch({
          type: GET_STUDENT_TASKS,
          tasksData: json[0].data,
        });
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      console.log("Erro ao buscar dados de tarefas do aluno:", error);
    }
  };
};

export const getEventsData = ({ alunoid, token }) => {
  return async (dispatch) => {
    try {
      const res = await fetch(
        `${middlewareBackendApi}/BK_AgendaEventosSincronosAluno?alunoid=${alunoid}`,
        {
          method: "get",
          headers: { token },
        }
      );
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        dispatch({
          type: GET_STUDENT_EVENTS,
          eventsData: json[0].data,
        });
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      console.log("Erro ao buscar dados de eventos do aluno:", error);
    }
  };
};

export const getCourseClassroom = ({ disciplinaid, token }) => {
  return async (dispatch) => {
    try {
      const res = await fetch(
        `${diaryApi}/diary/classroom?courseId=${disciplinaid}`,
        {
          method: "get",
          headers: { token },
        }
      );
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        dispatch({
          type: GET_COURSE_CLASSROOM,
          classroomData: json.object,
        });
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      console.log("Erro ao buscar dados de turmas:", error);
    }
  };
};

export const getWorkload = ({ disciplinaid, token }) => {
  return async (dispatch) => {
    const apiUrl =
      disciplinaid > 900000
        ? `${middlewareAlunoApi}/Dados_Disciplina_Convenio?disciplinaid=`
        : `${middlewareAlunoApi}/Dados_Disciplina?disciplinaid=`;
    try {
      const res = await fetch(`${apiUrl}${disciplinaid}`, {
        method: "get",
        headers: { token },
      });
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        dispatch({
          type: GET_WORKLOAD,
          workload: json[0].data[0].carga_horaria,
        });
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      console.log("Erro ao buscar carga horária:", error);
    }
  };
};

export const getCourseStudents = ({ disciplinaid, token }) => {
  return async (dispatch) => {
    try {
      const res = await fetch(
        `${middlewareBackendApi}/BK_Alunos_Curso?disciplinaid=${disciplinaid}`,
        {
          method: "get",
          headers: { token },
        }
      );
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        dispatch({
          type: GET_COURSE_STUDENTS,
          courseStudents: json[0]?.data,
        });
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      console.log("Erro ao buscar dados de alunos:", error);
    }
  };
};

export const getClasses = ({ disciplinaid, token }) => {
  return async (dispatch) => {
    try {
      const res = await fetch(
        `${middlewareBackendApi}/BK_Turmas_Curso?disciplinaid=${disciplinaid}`,
        {
          method: "get",
          headers: { token },
        }
      );
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        dispatch({
          type: GET_CLASSES,
          classes: json[0]?.data,
        });
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      console.log("Erro ao buscar dados de turmas:", error);
    }
  };
};

export const getTeacher = ({ userName, token }) => {
  return async (dispatch) => {
    try {
      const res = await fetch(
        `${api}/professor-service/professor/?username=${userName}`,
        {
          method: "get",
          headers: { token, domain },
        }
      );
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        dispatch({
          type: GET_TEACHER,
          teacher: json[0]?.object[0],
        });
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      console.log("Erro ao buscar dados de professor:", error);
    }
  };
};

export const getEnrollmentData = ({ matriculaid, token }) => {
  return async (dispatch) => {
    try {
      const res = await fetch(
        `${middlewareBackendApi}/BK_Dados_Matricula?matriculaid=${matriculaid}`,
        {
          method: "get",
          headers: { token },
        }
      );
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        dispatch({
          type: GET_ENROLLMENT_DATA,
          enrollmentData: json[0]?.data[0],
        });
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      console.log("Erro ao buscar dados de matricula:", error);
    }
  };
};

export const postStudentSituation = ({ matriculaid, token, situation }) => {
  return async (dispatch) => {
    var data = '[{"matriculaid":"'
      .concat(matriculaid, '","situacao":"')
      .concat(situation, '"}]');
    var body = new FormData();
    body.append("dados", data);

    try {
      const res = await fetch(`${middlewareBackendApi}/BK_Atualiza_Situacao`, {
        method: "post",
        headers: { token },
        body: body,
      });
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        dispatch({
          type: POST_SITUATION,
          postSituationStatus: json[0]?.data.message,
        });
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      console.log("Erro enviar dados de situação:", error);
    }
  };
};

export const postStudentFinalGrade = ({ matriculaid, token, grade }) => {
  return async (dispatch) => {
    var data = '[{"matriculaid":"'
      .concat(matriculaid, '","nota_final":"')
      .concat(grade, '"}]');
    var body = new FormData();
    body.append("dados", data);

    try {
      const res = await fetch(
        `${middlewareBackendApi}/BK_Atualiza_Nota_Final`,
        {
          method: "post",
          headers: { token },
          body: body,
        }
      );
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        dispatch({
          type: POST_FINAL_GRADE,
          postFinalGradeStatus: json[0]?.data.message,
        });
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      console.log("Erro ao enviar dados de nota final:", error);
    }
  };
};

export default function reducer(
  state = {
    studentSearchTerm: "",
    willFetch: true,
    currentStudentSituation: null,
    currentStudentFinalGrade: null,
  },
  action
) {
  switch (action.type) {
    case SET_STUDENT_SEARCH_TERM: {
      return { ...state, studentSearchTerm: action.studentSearchTerm };
    }
    case SET_WILL_FETCH: {
      return { ...state, willFetch: action.willFetch };
    }
    case SET_STUDENT_SITUATION: {
      return {
        ...state,
        currentStudentSituation: action.currentStudentSituation,
      };
    }
    case SET_STUDENT_FINAL_GRADE: {
      return {
        ...state,
        currentStudentFinalGrade: action.currentStudentFinalGrade,
      };
    }
    case CLEAN_STUDENT_DATA: {
      return {
        ...state,
        accessData: null,
        ratingData: null,
        tasksData: null,
        eventsData: null,
      };
    }
    case GET_DIARY: {
      return { ...state, diaryData: action.diaryData };
    }
    case GET_STUDENT_RATING_DATA: {
      return {
        ...state,
        ratingData: action.ratingData,
        currentStudentSituation: action.currentStudentSituation,
        currentStudentFinalGrade: action.currentStudentFinalGrade,
      };
    }
    case GET_STUDENT_ACCESS_DATA: {
      return { ...state, accessData: action.accessData };
    }
    case GET_STUDENT_TASKS: {
      return { ...state, tasksData: action.tasksData };
    }
    case GET_STUDENT_EVENTS: {
      return { ...state, eventsData: action.eventsData };
    }
    case GET_COURSE_CLASSROOM: {
      return { ...state, classroomData: action.classroomData };
    }
    case GET_WORKLOAD: {
      return { ...state, workload: action.workload };
    }
    case GET_COURSE_STUDENTS: {
      return { ...state, courseStudents: action.courseStudents };
    }
    case GET_CLASSES: {
      return { ...state, classes: action.classes };
    }
    case GET_TEACHER: {
      return { ...state, teacher: action.teacher };
    }
    case GET_ENROLLMENT_DATA: {
      return { ...state, enrollmentData: action.enrollmentData };
    }
    case POST_SITUATION: {
      return { ...state, postSituationStatus: action.postSituationStatus };
    }
    case POST_FINAL_GRADE: {
      return { ...state, postFinalGradeStatus: action.postFinalGradeStatus };
    }
    default: {
      return state;
    }
  }
}
