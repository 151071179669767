import { useQuery } from "@tanstack/react-query";
import { axios } from "../../../shared/lib/axios";

export const getCourse = async (courseId) => {
  const isOutsourcedCourse = courseId > 900000000;
  const res = await axios.cadernoVirtualBase(
    "/catalog-service/shelf/getByCourseId",
    {
      params: { courseId },
    }
  );

  return res.object;
};

export const useCourse = ({ config, courseId } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["course", { courseId }],
    queryFn: () => getCourse(courseId),
  });
};
