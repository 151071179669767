import React from "react";
import clsx from "clsx";
import withStyles from '@mui/styles/withStyles';

const styles = theme => ({
  divider: {
    height: 1,
    margin: 0,
    border: "none",
    flexShrink: 0,
    backgroundColor: "rgba(0, 0, 0, 0.12)",

    "&.wide": {
      width: `calc(100% + ${theme.spacing(8)})`,
      marginLeft: -theme.spacing(4),
      [theme.breakpoints.up("lg")]: {
        width: `calc(100% + ${theme.spacing(16)})`,
        marginLeft: -theme.spacing(8)
      },
      [theme.breakpoints.down('sm')]: {
        width: `calc(100% + ${theme.spacing(4)})`,
        marginLeft: -theme.spacing(2)
      }
    }
  }
});

function Hr({ classes, wide, mt, mb, mv }) {
  const getMargin = () => {
    if (mv) return { marginTop: mv * 8, marginBottom: mv * 8 };

    if (mt && mb) return { marginTop: mt * 8, marginBottom: mb * 8 };

    if (mt) return { marginTop: mt * 8 };

    if (mb) return { marginBottom: mb * 8 };

    return null;
  };

  return (
    <hr
      className={clsx(classes.divider, wide ? "wide" : null)}
      style={getMargin()}
    />
  );
}

export default withStyles(styles, { withTheme: true })(Hr);
