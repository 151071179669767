// <![CDATA[
(function (w, d, u) {
  var s = d.createElement("script");
  s.async = true;
  s.src = u + "?" + ((Date.now() / 60000) | 0);
  var h = d.getElementsByTagName("script")[0];
  h.parentNode.insertBefore(s, h);
})(
  window,
  document,
  "https://cdn.bitrix24.com.br/b29099631/crm/site_button/loader_6_65d2m9.js"
);
//]]>
