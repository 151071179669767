import { useQuery } from "@tanstack/react-query";
import { axios } from "../../../shared/lib/axios";
import _cookies from "../../../shared/utils/_cookies";

export const getGestorTracks = async () => {
  const res = await axios.cadernoVirtualBase(
    "/certification-service/certification/listTracksByManager",
    {
      params: {
        professorId: _cookies.getItem("auth").user?.profile?.isProfessor?.ID,
        managerId: _cookies.getItem("auth").user?.profile?.isGestor?.ID,

      },
    }
  );
  return res.object;
};

export const useGestorTracks = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["allTracks"],
    queryFn: getGestorTracks,
  });
};
