// import { api } from "../../config";
import moment from "moment";
import "moment/locale/pt-br";
import { api } from "../../api";

moment.locale("pt-br");

// actions
const IDEAS_LIST_ALUNO = "IDEAS_LIST_ALUNO";
const IDEA_SET_CURRENT_THEME = "IDEA_SET_CURRENT_THEME";
const IDEA_SET_LIST_PROPOSE = "IDEA_SET_LIST_PROPOSE";
const CURRENT_THEME = "CURRENT_THEME";
const IDEA_SET_LIST_FIELDS = "IDEA_SET_LIST_FIELDS";
const IDEA_LIST_ANEXOS_TEMA = "IDEA_LIST_ANEXOS_TEMA";
const IDEA_LIST_ANEXOS_PROPOSTA = "IDEA_LIST_ANEXOS_PROPOSTA";
const IDEA_SET_CURRENT_PROPOSE = "IDEA_SET_CURRENT_PROPOSE";
const CREATE_NEW_IDEA = "CREATE_NEW_IDEA";
const CLEAN_DATA = "CLEAN_DATA";
const ALTER_CURRENT_IDEA = "ALTER_CURRENT_IDEA";
const INSERIR_ANEXO_IDEA = "INSERIR_ANEXO_IDEA";

export const setCurrentTheme = (currentTheme) => {
  return async (dispatch) => {
    try {
      dispatch({ type: IDEA_SET_CURRENT_THEME, currentTheme });
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  };
};
export const cleanData = () => {
  return async (dispatch) => {
    const currentPropose = null;
    const anexosPropostas = null;
    const listAnexos = null;
    const listFields = null;
    dispatch({
      type: CLEAN_DATA,
      currentPropose,
      anexosPropostas,
      listAnexos,
      listFields,
    });
  };
};
export const getCurrentPropose = (token, proposeid) => {
  return async (dispatch) => {
    try {
      const res = await fetch(`${api.dados_proposta}?propostaid=${proposeid}`, {
        method: "get",
        headers: { token },
      });
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        const currentPropose = json[0].data[0];
        dispatch({ type: IDEA_SET_CURRENT_PROPOSE, currentPropose });
        return currentPropose;
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      console.error("feedAluno error", error);
      throw new Error(error);
    }
  };
};
export const getListPropose = (themeid, token) => {
  return async (dispatch) => {
    try {
      const res = await fetch(`${api.lista_propostas}?temaid=${themeid}`, {
        method: "get",
        headers: { token },
      });
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        const currentListPropose = json[0].data;
        dispatch({ type: IDEA_SET_LIST_PROPOSE, currentListPropose });
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      console.error("feedAluno error", error);
      throw new Error(error);
    }
  };
};

export const getCurrentFields = (themeid, token, proposta) => {
  return async (dispatch) => {
    try {
      const res = await fetch(
        `${api.lista_campos}?propostaid=${proposta}&temaid=${themeid}`,
        {
          method: "get",
          headers: { token },
        }
      );
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        const listFields = json[0].data;
        dispatch({ type: IDEA_SET_LIST_FIELDS, listFields });
        return listFields;
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      console.error("feedAluno error", error);
      throw new Error(error);
    }
  };
};

export const inserirIdeia = (token, dados) => {
  return async (dispatch) => {
    const body = new FormData();
    body.append("dados", dados);
    try {
      const res = await fetch(`${api.inserir_ideia}`, {
        method: "post",
        headers: { token },
        body,
      });
      const json = await res.json();
      if (res.status >= 200 && res.status < 300) {
        const status = await json[0].status;
        const newPropostaId = await json[0].data;
        if (status >= 200 && status < 300) {
          dispatch({
            type: CREATE_NEW_IDEA,
            newPropostaId: newPropostaId.PROPOSTAID,
          });
          return newPropostaId;
        } else {
          throw new Error(json[0].message);
        }
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      dispatch({ type: CREATE_NEW_IDEA });
      throw new Error(error);
    }
  };
};

export const insereAnexoIdeia = (dados, file, token) => {
  return async (dispatch) => {
    const body = new FormData();
    body.append("dados", `${JSON.stringify(dados)}`);
    body.append("file", file);

    const endpoint = api.inserir_anexo_ideia;
    try {
      const res = await fetch(endpoint, {
        method: "post",
        headers: { token },
        body,
      });
      const json = await res.json();
      if (res.status >= 200 && res.status < 300) {
        const status = await json[0].status;
        if (status >= 200 && status < 300) {
          dispatch({
            type: INSERIR_ANEXO_IDEA,
            status: 200,
          });
        } else {
          throw new Error(json[0].message);
        }
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      dispatch({ type: INSERIR_ANEXO_IDEA });
      throw new Error(error);
    }
  };
};

export const alterarIdeia = (token, dados) => {
  return async (dispatch) => {
    const body = new FormData();
    body.append("dados", dados);

    try {
      const res = await fetch(`${api.inserir_alterar}`, {
        method: "post",
        headers: { token },
        body,
      });
      const json = await res.json();
      let status;
      if (res.status >= 200 && res.status < 300) {
        status = await json[0].status;
        if (status >= 200 && status < 300) {
          await dispatch({
            type: ALTER_CURRENT_IDEA,
            status: status,
          });
        } else {
          throw new Error(json[0].message);
        }
      } else {
        throw new Error(res.statusText);
      }
      return status;
    } catch (error) {
      await dispatch({ type: ALTER_CURRENT_IDEA, status: error });
      throw new Error(error);
    }
  };
};

// action creators
export const listThemes = (alunoid, token) => {
  return async (dispatch) => {
    try {
      const res = await fetch(`${api.lista_ideias}?alunoid=${alunoid}`, {
        method: "get",
        headers: { token },
      });
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        const items = json[0].data;
        dispatch({ type: IDEAS_LIST_ALUNO, items });
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      console.error("feedAluno error", error);
      throw new Error(error);
    }
  };
};

export const getThemeAttachments = (themeid, token) => {
  return async (dispatch) => {
    try {
      const res = await fetch(`${api.lista_anexos_tema}?temaid=${themeid}`, {
        method: "get",
        headers: { token },
      });
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        const listAnexos = json[0].data;
        dispatch({ type: IDEA_LIST_ANEXOS_TEMA, listAnexos });
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      console.error("feedAluno error", error);
      throw new Error(error);
    }
  };
};
export const getProposeAttachments = (themeid, propostaid, token) => {
  return async (dispatch) => {
    try {
      const res = await fetch(
        `${api.lista_anexos_proposta}?temaid=${themeid}&propostaid=${propostaid}`,
        {
          method: "get",
          headers: { token },
        }
      );
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        const listAnexos = json[0].data;
        dispatch({ type: IDEA_LIST_ANEXOS_PROPOSTA, listAnexos });
        console.log(
          "🚀 ~ file: ideas.js:270 ~ return ~ listAnexos",
          listAnexos
        );
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      console.error("feedAluno error", error);
      throw new Error(error);
    }
  };
};

export const excluirAnexo = (propostaid, token) => {
  return async (dispatch) => {
    try {
      const res = await fetch(
        `${api.excluir_anexo_ideia}?ideia_anexoid=${propostaid}`,
        {
          method: "get",
          headers: { token },
        }
      );
      if (res.status >= 300) {
        throw new Error(res.statusText);
      }
    } catch (error) {
      console.error("erro ao excluir Anexo :", error);
    }
  };
};

export const excluirProposta = (propostaid, token) => {
  return async (dispatch) => {
    try {
      const res = await fetch(`${api.excluir_ideia}?propostaid=${propostaid}`, {
        method: "get",
        headers: { token },
      });
      if (res.status >= 300) {
        throw new Error(res.statusText);
      }
    } catch (error) {
      console.error("erro ao excluir Anexo :", error);
    }
  };
};
// reducer
export default function reducer(state = {}, action) {
  switch (action.type) {
    case IDEAS_LIST_ALUNO:
      return { ...state, items: action.items };
    case IDEA_SET_CURRENT_THEME:
      return { ...state, currentTheme: action.currentTheme };
    case IDEA_SET_LIST_PROPOSE:
      return { ...state, currentListPropose: action.currentListPropose };
    case CURRENT_THEME:
      return { ...state, currentTheme: action.currentTheme };
    case IDEA_SET_LIST_FIELDS:
      return { ...state, listFields: action.listFields };
    case IDEA_LIST_ANEXOS_TEMA:
      return { ...state, anexosTemas: action.listAnexos };
    case IDEA_LIST_ANEXOS_PROPOSTA:
      return { ...state, anexosPropostas: action.listAnexos };
    case IDEA_SET_CURRENT_PROPOSE:
      return { ...state, currentPropose: action.currentPropose };
    case CREATE_NEW_IDEA:
      return { ...state, newPropostaId: action.newPropostaId };
    case ALTER_CURRENT_IDEA:
      return { ...state, status: action.status };
    case CLEAN_DATA:
      return {
        ...state,
        currentPropose: action.currentPropose,
        anexosPropostas: action.listAnexos,
        listFields: action.listFields,
      };
    default:
      return state;
  }
}
