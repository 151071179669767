import Box from "@mui/material/Box";
import PreviewMarker from "../../partials/PreviewMarker";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { markView } from "../../../common/redux/CourseActionCreators";
import { useParams } from "react-router-dom";
import { Typography, Button } from "@mui/material";
import ReactQuill from "react-quill";

function TextContent({
  contentId,
  mandatory,
  sx,
  htmlText,
  text,
  children,
  auth,
  enrollment,
  chapters,
  markView,
}) {
  const { currentChapter } = useParams();

  const viewedList =
    chapters?.find((chapter) => chapter?.capituloid === Number(currentChapter))
      ?.views || [];

  const isContentViewed = () =>
    viewedList?.find((viewedObj) => viewedObj.QUADRANTEID === contentId);

  const handleClickView = () => {
    if (!isContentViewed()) {
      if (
        !!auth?.user?.alunoid &&
        !!auth?.token &&
        !!enrollment?.MATRICULAID &&
        !!currentChapter &&
        !!contentId
      ) {
        markView(
          auth.user.alunoid,
          auth.token,
          enrollment.MATRICULAID,
          currentChapter,
          contentId
        );
      }
    }
  };

  const modules = {
    toolbar: false,
  }
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        position: "relative",
        padding: "0 0 0 0",
      }}
    >
      {htmlText ? (
        <div
          style={{
            marginTop: "0px",
            height: "inherit",
            width: "inherit",
            ...sx,
          }}
        >
          <ReactQuill
            modules={modules}
            value={htmlText}
            readOnly
          />
        </div>
      ) : (
        <Box sx={sx} height="inherit" width="inherit">
          {text || children}
        </Box>
      )}
      {mandatory === "S" && (
        <Button
          sx={{
            display:"flex",
            flexDirection:"row",
            position: "absolute",
            top: "0",
            cursor: "pointer",
          }}
          onClick={handleClickView}
        > {isContentViewed() ? "Leitura Confirmada" : "Confirmar Leitura"}
          <PreviewMarker viewed={isContentViewed()} />
        </Button>
      )}
    </Box>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  enrollment: state.course?.enrollment,
  chapters: state.course?.chapters,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ markView }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TextContent);
