import Axios from "axios";
import _cookies from "../utils/_cookies";
import { createNotification } from "../store/notifications";
import { getClient } from "../config/urlConfig";

const MIDDLEWARE_API_URL = "https://middleware.cadernovirtual.com.br";
const middleware = Axios.create({ baseURL: MIDDLEWARE_API_URL });
const domain = getClient();

function middlewareRequestInterceptor(config) {
  try {
    const auth = _cookies.getItem("auth");

    if (auth?.token) {
      config.headers.token = `${auth.token}`;
    }
  } catch (error) {
    console.log(error);
  } finally {
    config.headers.Accept = "application/json";
    return config;
  }
}
function middlewareErrorHandle(error) {
  const message =
    error.data[0].message ||
    error.message ||
    error.response?.data?.Message ||
    error.data?.Message;
  if (!(error?.config?.method == "get")) {
    createNotification({ type: "error", message });
  }
  return Promise.reject(error);
}
function middlewareResponseHandle(response) {
  if (response?.data?.Status >= 400 || response?.data[0]?.status >= 400) {
    return middlewareErrorHandle(response);
  }
  return response.data;
}

middleware.interceptors.request.use(middlewareRequestInterceptor);
middleware.interceptors.response.use(
  middlewareResponseHandle,
  middlewareErrorHandle
);

const CADERNO_VIRTUAL_API_URL = "https://cadernovirtual.inf.br";
const cadernoVirtualBase = Axios.create({ baseURL: CADERNO_VIRTUAL_API_URL });

const rocketChatApi = Axios.create({
  baseURL: "https://api.cadernovirtual.com.br/",
});

function cadernoVirtualBaseRequestInterceptor(config) {
  try {
    const auth = _cookies.getItem("auth");
    if (auth?.token) {
      config.headers.token = `${auth.token}`;
    }
  } catch (error) {
    console.log(error);
  } finally {
    config.headers.domain = domain;
    config.headers.Accept = "application/json";
    return config;
  }
}
const cadernoVirtualBaseErrorHandle = (error) => {
  console.log({ error });
  const message =
    error.message ||
    error.response?.data?.Message ||
    error.data?.Message ||
    error.response?.data?.message ||
    error.data?.message;
  if (!error?.config?.suppressNotification) {
    createNotification({ type: "error", message });
  }

  return Promise.reject(error);
};
const cadernoVirtualBaseResponseHandle = (response) => {
  if (response?.data?.Status >= 400 || response?.data?.status >= 400) {
    return cadernoVirtualBaseErrorHandle(response);
  }
  return response.data;
};

cadernoVirtualBase.interceptors.request.use(
  cadernoVirtualBaseRequestInterceptor
);
cadernoVirtualBase.interceptors.response.use(
  cadernoVirtualBaseResponseHandle,
  cadernoVirtualBaseErrorHandle
);

function rocketChatRequestInterceptor(config) {
  try {
    const rocketChatToken = _cookies.getItem("rocketChatToken");
    const rocketChatUserId = _cookies.getItem("rocketChatUserId");

    if (rocketChatToken && rocketChatUserId) {
      config.headers["X-Auth-Token"] = `${rocketChatToken}`;
      config.headers["X-User-Id"] = `${rocketChatUserId}`;
    }
  } catch (error) {
    console.log(error);
  } finally {
    config.headers.Accept = "application/json";
    return config;
  }
}
// const rocketChatErrorHandle = (error) => {
//   console.log({ error });
//   const message =
//     error.message ||
//     error.response?.data?.Message ||
//     error.data?.Message ||
//     error.response?.data?.message ||
//     error.data?.message;
//   if (!error?.config?.suppressNotification) {
//     createNotification({ type: "error", message });
//   }

//   return Promise.reject(error);
// };
// const rocketChatResponseHandle = (response) => {
//   if (response?.data?.Status >= 400 || response?.data?.status >= 400) {
//     return cadernoVirtualBaseErrorHandle(response);
//   }
//   return response.data;
// };

rocketChatApi.interceptors.request.use(rocketChatRequestInterceptor);

export const axios = { cadernoVirtualBase, middleware, rocketChatApi };
