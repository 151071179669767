import { api } from "../api";

// actions
const DATA_HEADER_DASHBOARD = "DATA_HEADER_DASHBOARD";
const DATA_PANEL_DASHBOARD = "DATA_PANEL_DASHBOARD";
const DATA_DASHBOARD1 = "DATA_DASHBOARD1";
const DATA_DASHBOARD2 = "DATA_DASHBOARD2";
const DATA_DASHBOARD3 = "DATA_DASHBOARD3";
const DATA_DASHBOARD4 = "DATA_DASHBOARD4";
const DATA_TABLE1 = "DATA_TABLE1";
const DATA_TABLE2 = "DATA_TABLE2";
const DATA_TABLE3 = "DATA_TABLE3";
const DATA_TABLE4 = "DATA_TABLE4";
const HAS_LIVE = "HAS_LIVE";
const SHOW_DASHBOARD = "SHOW_DASHBOARD";
const SYNC_EVENTS = "SYNC_EVENTS";
const PROFESSOR_SYNC_EVENTS = "PROFESSOR_SYNC_EVENTS";
const ASYNC_EVENTS = "ASYNC_EVENTS";
const BDAY_LIST = "BDAY_LIST";
// action creators
export const userData = (alunoid, token) => {
  return async (dispatch) => {
    try {
      const res = await fetch(`${api.cabecalho}?alunoid=${alunoid}`, {
        method: "get",
        headers: { token },
      });
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        const dashboardDataHeader = json[0].data[0];
        dispatch({ type: DATA_HEADER_DASHBOARD, dashboardDataHeader });
      } else {
        return null;
        //throw new Error(res.statusText);
      }
    } catch (error) {
      return null;
      //throw new Error(error);
    }
  };
};
export const hasLive = (alunoid, token, datetime) => {
  return async (dispatch) => {
    try {
      const res = await fetch(
        `${api.aovivo}?alunoid=${alunoid}&datetime=${datetime}`,
        {
          method: "get",
          headers: { token },
        }
      );
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        const liveOnline = json[0].data;
        dispatch({ type: HAS_LIVE, liveOnline });
        return liveOnline;
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      throw new Error(error);
    }
  };
};
export const dashboardPanel = (alunoid, token) => {
  return async (dispatch) => {
    try {
      const res = await fetch(`${api.painel}?alunoid=${alunoid}`, {
        method: "get",
        headers: { token },
      });
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        const dashboardPanel = json[0].data;
        const result = dashboardPanel ? true : false;
        dispatch({ type: DATA_PANEL_DASHBOARD, dashboardPanel, result });
        return dashboardPanel;
      } else {
        return null;
        //throw new Error(res.statusText);
      }
    } catch (error) {
      return null;
      //throw new Error(error);
    }
  };
};
export const showDashboard = () => {
  return async (dispatch) => {
    const result = true;
    dispatch({ type: SHOW_DASHBOARD, result });
  };
};
export const dashboardData = (nrografico, alunoid, token) => {
  return async (dispatch) => {
    try {
      const res = await fetch(
        `${api.grafico}${nrografico}?alunoid=${alunoid}`,
        {
          method: "get",
          headers: { token },
        }
      );
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        const dashboardData = json[0].data;
        dispatch({ type: `DATA_DASHBOARD${nrografico}`, dashboardData });
      } else {
        return null;
        //throw new Error(res.statusText);
      }
    } catch (error) {
      return null;
      //throw new Error(error);
    }
  };
};

export const tableData = (nrografico, alunoid, token) => {
  return async (dispatch) => {
    try {
      const res = await fetch(`${api.table}${nrografico}?alunoid=${alunoid}`, {
        method: "get",
        headers: { token },
      });
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        const tableData = json[0].data;
        dispatch({ type: `DATA_TABLE${nrografico}`, tableData });
      } else {
        return null;
        //throw new Error(res.statusText);
      }
    } catch (error) {
      return null;
      //throw new Error(error);
    }
  };
};

export const fetchStudentSyncEvents = (alunoId, token) => {
  return async (dispatch) => {
    const requestUrlAluno = api.eventos_aluno.replace("{alunoid}", alunoId);
    try {
      const responseAluno = await fetch(requestUrlAluno, {
        method: "get",
        headers: { token },
      });
      if (responseAluno.status >= 200 && responseAluno.status < 300) {
        const json = await responseAluno.json();

        const eventsAsync = json[0].data;

        if (!eventsAsync) {
          dispatch({ type: SYNC_EVENTS, events: null });
        } else {
          console.log("dispatch");
          dispatch({ type: SYNC_EVENTS, events: eventsAsync });
        }

        return eventsAsync;
      } else {
        dispatch({ type: SYNC_EVENTS, events: null });
        throw new Error(responseAluno.statusText);
      }
    } catch (error) {
      console.error("fetchAsyncEvents error", error);
      return null;
    }
  };
};
export const fetchSyncEvents = (alunoId, token) => {
  return async (dispatch) => {
    const requestUrl = api.eventos_sincronos.replace("{alunoid}", alunoId);
    try {
      const response = await fetch(requestUrl, {
        method: "get",
        headers: { token },
      });

      if (response.status >= 200 && response.status < 300) {
        const json = await response.json();

        const events = json[0].data;

        if (!events) {
          dispatch({ type: SYNC_EVENTS, events: null });
        } else {
          dispatch({ type: SYNC_EVENTS, events });
        }

        return events;
      } else {
        dispatch({ type: SYNC_EVENTS, events: null });
        throw new Error(response.statusText);
      }
    } catch (error) {
      console.error("fetchSyncEvents error", error);
      return null;
    }
  };
};
export const fetchProfessorSyncEvents = (professorId, token) => {
  return async (dispatch) => {
    const requestUrl = api.eventos_sincronos_professor.replace(
      "{professorid}",
      professorId
    );
    try {
      const response = await fetch(requestUrl, {
        method: "get",
        headers: { token },
      });

      if (response.status >= 200 && response.status < 300) {
        const json = await response.json();

        const events = json[0].data;

        if (!events) {
          dispatch({ type: PROFESSOR_SYNC_EVENTS, events: null });
        } else {
          dispatch({ type: PROFESSOR_SYNC_EVENTS, events });
        }

        return events;
      } else {
        dispatch({ type: PROFESSOR_SYNC_EVENTS, events: null });
        throw new Error(response.statusText);
      }
    } catch (error) {
      console.error("fetchSyncEvents error", error);
      return null;
    }
  };
};
export const fetchBirthdayList = (token, alunoid) => {
  return async (dispatch) => {
    try {
      const response = await fetch(api.aniversariante, {
        method: "get",
        headers: { token },
      });
      const json = await response.json();
      if (json[0].status >= 200 && json[0].status < 300) {
        const birthdayList = json[0].data;
        dispatch({ type: BDAY_LIST, birthdayList });

        return birthdayList;
      }
      if (json[0].status == 400) {
        dispatch({ type: BDAY_LIST, birthdayList: [] });
        return "abelinha";
      }
    } catch (error) {
      throw new Error(error);
    }
  };
};
// reducer
export default function reducer(state = { syncEvents: [] }, action) {
  switch (action.type) {
    case DATA_HEADER_DASHBOARD:
      return { ...state, dashboardDataHeader: action.dashboardDataHeader };
    case DATA_PANEL_DASHBOARD:
      return {
        ...state,
        dashboardPanelData: action.dashboardPanel,
        showDash: action.result,
      };
    case DATA_DASHBOARD1:
      return { ...state, dashboardData1: action.dashboardData };
    case DATA_DASHBOARD2:
      return { ...state, dashboardData2: action.dashboardData };
    case DATA_DASHBOARD3:
      return { ...state, dashboardData3: action.dashboardData };
    case DATA_DASHBOARD4:
      return { ...state, dashboardData4: action.dashboardData };
    case DATA_TABLE1:
      return { ...state, tableData1: action.tableData };
    case DATA_TABLE2:
      return { ...state, tableData2: action.tableData };
    case DATA_TABLE3:
      return { ...state, tableData3: action.tableData };
    case DATA_TABLE4:
      return { ...state, tableData4: action.tableData };
    case HAS_LIVE:
      return { ...state, hasLive: action.liveOnline };
    case SHOW_DASHBOARD:
      return { ...state, showDash: action.result };
    case SYNC_EVENTS:
      return { ...state, syncEvents: [...state.syncEvents, ...action.events] };
    case PROFESSOR_SYNC_EVENTS:
      return { ...state, syncEvents: [...state.syncEvents, ...action.events] };
    case ASYNC_EVENTS:
      return { ...state, asyncEvents: action.events };
    case BDAY_LIST:
      return { ...state, birthdayList: action.birthdayList };
    default:
      return state;
  }
}
