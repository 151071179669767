export const unflattenObjArr = (arr = [{}], idKey = "", fatherKey = "") => {
  let treeArr = [],
    fatherObj = {};
  arr.forEach((object) => {
    if (object.hasOwnProperty(fatherKey)) {
      fatherObj = arr.find((father) => father[idKey] === object[fatherKey]);
      if (fatherObj) {
        if (!fatherObj["children"]) {
          fatherObj["children"] = [];
        }
        fatherObj["children"].push(object);
      }
    } else {
      treeArr.push(object);
    }
  });

  return treeArr;
};
