import { api } from "../api";

// actions
export const TUTORIALS_LISTAR_CATEGORIAS_TUTORIAIS =
  "TUTORIALS_LISTAR_CATEGORIAS_TUTORIAIS";
export const TUTORIALS_LISTAR_TUTORIAIS = "TUTORIALS_LISTAR_TUTORIAIS";
export const TUTORIALS_LISTAR_TUTORIAIS_CATEGORIA =
  "TUTORIALS_LISTAR_TUTORIAIS_CATEGORIA";
export const TUTORIALS_LISTA_CAPITULOS_TUTORIAL =
  "TUTORIALS_LISTA_CAPITULOS_TUTORIAL";
const TUTORIALS_SET_CURRENT_CATEGORY = "TUTORIALS_SET_CURRENT_CATEGORY";
const TUTORIALS_SET_CURRENT_TUTORIAL = "TUTORIALS_SET_CURRENT_TUTORIAL";
const TUTORIALS_LISTAR_BOTOES_CAPITULO = "TUTORIALS_LISTAR_BOTOES_CAPITULO";
const TUTORIALS_VISITED_ADD = "TUTORIALS_VISITED_ADD";
const TUTORIALS_VISITED_REMOVE = "TUTORIALS_VISITED_REMOVE";
const TUTORIALS_VISITED_RESET = "TUTORIALS_VISITED_RESET";
const TUTORIALS_STATUS = "TUTORIALS_STATUS";

// action creators
export const listarCategoriasTutoriais = (alunoid, token) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: TUTORIALS_STATUS,
        key: TUTORIALS_LISTAR_CATEGORIAS_TUTORIAIS,
        status: "loading",
      });
      const res = await fetch(
        `${api.listar_categorias_tutoriais}?alunoid=${alunoid}`,
        {
          method: "get",
          headers: { token },
        }
      );
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        const categories = json[0].data;
        dispatch({ type: TUTORIALS_LISTAR_CATEGORIAS_TUTORIAIS, categories });
        dispatch({
          type: TUTORIALS_STATUS,
          key: TUTORIALS_LISTAR_CATEGORIAS_TUTORIAIS,
          status: "success",
        });
        return categories;
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      dispatch({
        type: TUTORIALS_STATUS,
        key: TUTORIALS_LISTAR_CATEGORIAS_TUTORIAIS,
        status: "error",
      });
      throw new Error(error);
    }
  };
};

export const listarTutoriais = (alunoid, token) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: TUTORIALS_STATUS,
        key: TUTORIALS_LISTAR_TUTORIAIS,
        status: "loading",
      });
      const res = await fetch(`${api.listar_tutoriais}?alunoid=${alunoid}`, {
        method: "get",
        headers: { token },
      });
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        const tutorials = json[0].data;
        dispatch({ type: TUTORIALS_LISTAR_TUTORIAIS, tutorials });
        dispatch({
          type: TUTORIALS_STATUS,
          key: TUTORIALS_LISTAR_TUTORIAIS,
          status: "success",
        });
        return tutorials;
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      dispatch({
        type: TUTORIALS_STATUS,
        key: TUTORIALS_LISTAR_TUTORIAIS,
        status: "error",
      });
      throw new Error(error);
    }
  };
};

export const listarTutoriaisCategoria = (alunoid, cursoid, token) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: TUTORIALS_STATUS,
        key: TUTORIALS_LISTAR_TUTORIAIS_CATEGORIA,
        status: "loading",
      });
      const res = await fetch(
        `${api.listar_tutoriais_categoria}?alunoid=${alunoid}&cursoid=${cursoid}`,
        {
          method: "get",
          headers: { token },
        }
      );
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        const tutorials = json[0].data;
        dispatch({ type: TUTORIALS_LISTAR_TUTORIAIS_CATEGORIA, tutorials });
        dispatch({
          type: TUTORIALS_STATUS,
          key: TUTORIALS_LISTAR_TUTORIAIS_CATEGORIA,
          status: "success",
        });
        return tutorials;
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      dispatch({
        type: TUTORIALS_STATUS,
        key: TUTORIALS_LISTAR_TUTORIAIS_CATEGORIA,
        status: "error",
      });
      throw new Error(error);
    }
  };
};

export const listaCapitulosTutorial = (disciplinaid, token) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: TUTORIALS_STATUS,
        key: TUTORIALS_LISTA_CAPITULOS_TUTORIAL,
        status: "loading",
      });
      const res = await fetch(
        `${api.lista_capitulos_tutorial}?disciplinaid=${disciplinaid}`,
        {
          method: "get",
          headers: { token },
        }
      );
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        const chapters = json && json.length > 0 ? json[0].data : null;
        dispatch({
          type: TUTORIALS_LISTA_CAPITULOS_TUTORIAL,
          chapters,
        });
        dispatch({
          type: TUTORIALS_STATUS,
          key: TUTORIALS_LISTA_CAPITULOS_TUTORIAL,
          status: "success",
        });
      } else {
        dispatch({ type: TUTORIALS_LISTA_CAPITULOS_TUTORIAL, chapters: null });
        throw new Error(res.statusText);
      }
    } catch (error) {
      dispatch({
        type: TUTORIALS_STATUS,
        key: TUTORIALS_LISTA_CAPITULOS_TUTORIAL,
        status: "error",
      });
      throw new Error(error);
    }
  };
};

export const setCurrentCategory = (category) => {
  return { type: TUTORIALS_SET_CURRENT_CATEGORY, category };
};

export const setCurrentTutorial = (tutorial) => {
  return { type: TUTORIALS_SET_CURRENT_TUTORIAL, tutorial };
};

export const listarBotoesCapitulo = (capituloid, token) => {
  return async (dispatch) => {
    try {
      const res = await fetch(
        `${api.listar_botoes_capitulo}?capituloid=${capituloid}`,
        {
          method: "get",
          headers: { token },
        }
      );
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        const data = json[0].data;
        dispatch({
          type: TUTORIALS_LISTAR_BOTOES_CAPITULO,
          nextChapters: data,
        });
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      dispatch({ type: TUTORIALS_LISTAR_BOTOES_CAPITULO, nextChapters: null });
      console.log("listarBotoesCapitulo error", error);
      throw new Error(error);
    }
  };
};

export const visitedAdd = (chapterId) => {
  return { type: TUTORIALS_VISITED_ADD, chapterId };
};

export const visitedRemove = () => {
  return { type: TUTORIALS_VISITED_REMOVE };
};

export const visitedReset = () => {
  return { type: TUTORIALS_VISITED_RESET };
};

// reducer
export default function reducer(state = {}, action) {
  switch (action.type) {
    case TUTORIALS_LISTAR_CATEGORIAS_TUTORIAIS:
      return { ...state, categories: action.categories };

    case TUTORIALS_LISTAR_TUTORIAIS:
      return { ...state, items: action.tutorials };

    case TUTORIALS_LISTAR_TUTORIAIS_CATEGORIA:
      return { ...state, tutorials: action.tutorials };

    case TUTORIALS_LISTA_CAPITULOS_TUTORIAL:
      return { ...state, chapters: action.chapters };

    case TUTORIALS_SET_CURRENT_CATEGORY:
      return { ...state, currentCategory: action.category };

    case TUTORIALS_SET_CURRENT_TUTORIAL:
      return { ...state, currentTutorial: action.tutorial };

    case TUTORIALS_LISTAR_BOTOES_CAPITULO:
      return { ...state, nextChapters: action.nextChapters };

    case TUTORIALS_VISITED_ADD:
      const visited = state.visited
        ? [...state.visited, action.chapterId]
        : [action.chapterId];
      return { ...state, visited };

    case TUTORIALS_VISITED_REMOVE:
      const currentVisited = state.visited || [];
      if (currentVisited.length > 0) currentVisited.pop();
      return { ...state, visited: currentVisited };

    case TUTORIALS_VISITED_RESET:
      return { ...state, visited: [] };

    case TUTORIALS_STATUS:
      return {
        ...state,
        status: {
          ...state.status,
          [action.key]: action.status,
        },
      };

    default:
      return state;
  }
}
