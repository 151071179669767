import { useContext } from "react";
import { ModalPageContext } from "../../../ui/partials/Modal";
import {
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Tab,
  Tabs,
} from "@mui/material";
import { Outlet, useParams } from "react-router-dom";
import { Close } from "@mui/icons-material";

export default function ManageCourseModal() {
  const { closeModal } = useContext(ModalPageContext);
  const { tab } = useParams();

  return (
    <Paper
      sx={{
        position: "relative",
        width: { xs: "90vw", md: "75vw", xl: "50vw" },
        // height: { xs: "80vh", xl: "60vh" },
      }}
    >
      <DialogTitle>Gerenciar curso</DialogTitle>
      <IconButton
        onClick={closeModal}
        sx={{ position: "absolute", top: 3, right: 3 }}
      >
        <Close />
      </IconButton>
      <DialogContent
        sx={{
          display: "flex",
          height: "100%",
          overflow: "auto",
          flexDirection: "column",
        }}
      >
        <Outlet />
      </DialogContent>
    </Paper>
  );
}
