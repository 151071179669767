import { api } from "../api";

// actions
const LIVES_LISTAR_LIVES = "LIVES_LISTAR_LIVES";
const LIVES_MARCAR_PRESENCA = "LIVES_MARCAR_PRESENCA";
const LIVES_MARCAR_PRESENCA_CHAT = "LIVES_MARCAR_PRESENCA_CHAT";
const LIVES_MARCAR_PRESENCA_LIVE = "LIVES_MARCAR_PRESENCA_LIVE";
const LIVES_MARCAR_SAIDA_LIVE = "LIVES_MARCAR_SAIDA_LIVE";
const VERIFICA_MATRICULA = "VERIFICA_MATRICULA";
const SET_STATUS = "SET_STATUS";
// action creators
export const listarLives = (alunoid, datetime, token) => {
  return async (dispatch) => {
    try {
      // const lives = null;
      const res = await fetch(
        `${api.listar_lives}?alunoid=${alunoid}&datetime=${datetime}`,
        {
          method: "get",
          headers: { token },
        }
      );
      const res2 = await fetch(
        `${api.listar_meetings}?alunoid=${alunoid}&datetime=${datetime}`,
        {
          method: "get",
          headers: { token },
        }
      );
      if (
        res2.status >= 200 &&
        res2.status < 300 &&
        res.status >= 200 &&
        res.status < 300
      ) {
        const json = await res.json();
        const lives = json[0].data;
        const json2 = await res2.json();
        const meetings = json2[0].data;
        if (meetings !== null && lives !== null) {
          const result = lives.concat(meetings);
          dispatch({ type: LIVES_LISTAR_LIVES, result });
        } else if (lives !== null && meetings == null) {
          const result = lives;
          dispatch({ type: LIVES_LISTAR_LIVES, result });
        } else {
          const result = meetings;
          dispatch({ type: LIVES_LISTAR_LIVES, result });
        }

        //dispatch({ type: LIVES_LISTAR_LIVES, result });
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      throw new Error(error);
    }
  };
};

export const verificaMatricula = (alunoid, token, disciplinaId) => {
  return async (dispatch) => {
    try {
      var dados = `[{"aulaid":${disciplinaId},"alunoid":${alunoid}}]`;
      var body = new FormData();
      body.append("dados", dados);
      const res = await fetch(`${api.verifica_matricula_aula}`, {
        method: "post",
        headers: { token },
        body: body,
      });
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        const data = json[0].data;
        if (data["turmaid:"]) {
          data.turmaid = data["turmaid:"];
          delete data["turmaid:"];
        }
        dispatch({ type: VERIFICA_MATRICULA, payload: data });
      }
    } catch (error) {
      throw new Error(error);
    }
  };
};

export const marcarPresenca = (token, alunoid, live) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: SET_STATUS,
        payload: { key: LIVES_MARCAR_PRESENCA, value: "loading" },
      });
      if (live.liveid == null) {
        const dados = '[{"aulaid":"'
          .concat(live.aulaid, '","alunoid":"')
          .concat(alunoid, '", "matriculaid":"')
          .concat(live.matriculaid, '", "turmaid":"')
          .concat(live.turmaid, '"}]');
        const body = new FormData();
        body.append("dados", dados);

        const res = await fetch(
          `${api.marcar_presenca}?alunoid=${alunoid}&aulaid=${live.aulaid}&turmaid=${live.turmaid}&matriculaid=${live.matriculaid}`,
          { method: "post", headers: { token }, body: body }
        );
        if (res.status >= 200 && res.status < 300) {
          const json = await res.json();
          const result = json[0].data;

          dispatch({ type: LIVES_MARCAR_PRESENCA, result });
          dispatch({
            type: SET_STATUS,
            payload: { key: LIVES_MARCAR_PRESENCA, value: "success" },
          });
        }
      } else {
        const dados = '[{"liveid":"'
          .concat(live.liveid, '","alunoid":"')
          .concat(alunoid, '"}]');
        const body = new FormData();
        body.append("dados", dados);

        const res = await fetch(
          `${api.marcar_presenca_live}?liveid=${live.liveid}&alunoid=${alunoid}`,
          { method: "post", headers: { token }, body: body }
        );
        if (res.status >= 200 && res.status < 300) {
          const json = await res.json();
          const result = json[0].data;

          dispatch({ type: LIVES_MARCAR_PRESENCA, result });
          dispatch({
            type: SET_STATUS,
            payload: { key: LIVES_MARCAR_PRESENCA, value: "success" },
          });
        }
      }
    } catch (error) {
      dispatch({
        type: SET_STATUS,
        payload: { key: LIVES_MARCAR_PRESENCA, value: "error" },
      });
      throw new Error(error);
    } finally {
      dispatch({
        type: SET_STATUS,
        payload: { key: LIVES_MARCAR_PRESENCA, value: "idle" },
      });
    }
  };
};

export const registerCoursePresence = (courseId) => {
  return async (dispatch, getState) => {
    try {
      const { auth } = getState();
      await verificaMatricula(
        auth.user.alunoid,
        auth.token,
        courseId
      )(dispatch);
      const { lives } = getState();
      console.log(lives);
      lives.matricula &&
        !lives.matricula.erro &&
        (await marcarPresenca(
          auth.user.alunoid,
          courseId,
          lives.matricula?.turmaid,
          lives.matricula?.matriculaid,
          auth.token,
          null
        )(dispatch));
    } catch (error) {
      throw error;
    }
  };
};

export const marcarPresencaChat = (alunoid, aulaid, token) => {
  return async (dispatch) => {
    try {
      var dados = '[{"aulaid":"'
        .concat(aulaid, '","alunoid":"')
        .concat(alunoid, '"}]');
      var body = new FormData();
      body.append("dados", dados);

      const res = await fetch(
        `${api.marcar_presenca_chat}?alunoid=${alunoid}&aulaid=${aulaid}`,
        { method: "post", headers: { token }, body: body }
      );

      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        const result = json[0].data;

        dispatch({ type: LIVES_MARCAR_PRESENCA_CHAT, result });
      }
    } catch (error) {
      throw new Error(error);
    }
  };
};
export const marcarSaidaLive = (alunoid, liveid, token) => {
  return async (dispatch) => {
    try {
      var dados = '[{"liveid":"'
        .concat(liveid, '","alunoid":"')
        .concat(alunoid, '"}]');
      var body = new FormData();
      body.append("dados", dados);

      const res = await fetch(
        `${api.marcar_saida_live}?liveid=${liveid}&alunoid=${alunoid}`,
        { method: "post", headers: { token }, body: body }
      );

      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        const result = json[0].data;

        dispatch({ type: LIVES_MARCAR_SAIDA_LIVE, result });
      }
    } catch (error) {
      throw new Error(error);
    }
  };
};
// reducer
export default function reducer(state = { status: {} }, action) {
  switch (action.type) {
    case LIVES_LISTAR_LIVES:
      return action.result;
    case LIVES_MARCAR_PRESENCA:
      return state;
    case LIVES_MARCAR_PRESENCA_CHAT:
      return state;
    case LIVES_MARCAR_PRESENCA_LIVE:
      return state;
    case LIVES_MARCAR_SAIDA_LIVE:
      return state;
    case VERIFICA_MATRICULA:
      return { ...state, matricula: action.payload };
    case SET_STATUS:
      const newStatus = { ...state.status };
      const { key, value } = action.payload;

      newStatus[key] = value;

      return { ...state, status: newStatus };
    default:
      return state;
  }
}
