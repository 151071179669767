const contentBaseApi = "https://cadernovirtual.inf.br";
export const courseApi = {
  put_course: {
    url: `${contentBaseApi}/courses-service/course`,
    method: "POST",
  },
  course_by_id: {
    url: `${contentBaseApi}/courses-service/course`,
    queryParams: "courseId",
  },
  chapter_by_id: {
    url: `${contentBaseApi}/courses-service/chapter`,
    queryParams: "chapterId",
  },
  course_list_categories: {
    url: `${contentBaseApi}/courses-service/category`,
    queryParams: "categoryId",
  },
  save_chapter: {
    url: `${contentBaseApi}/courses-service/chapter`,
    method: "POST",
  },
  save_chapter_v2: {
    url: `${contentBaseApi}/courses-service/chapter/v2/save`,
    method: "POST",
  },
  chapter_all_content_types: {
    url: `${contentBaseApi}/courses-service/content/type`,
    queryParams: "chapterId",
  },
  chapter_all_content: {
    url: `${contentBaseApi}/courses-service/content`,
    queryParams: "chapterId",
  },
  chapter_content: {
    url: `${contentBaseApi}/courses-service/content`,
    queryParams: "chapterContentId",
  },
  save_chapter_content: {
    url: `${contentBaseApi}/courses-service/content/`,
    method: "POST",
  },
  delete_chapter_content: {
    url: `${contentBaseApi}/courses-service/content?`,
    method: "DELETE",
  },
  authenticate: {
    url: `${contentBaseApi}/autenticacao-service/authenticate`,
    method: "POST",
  },
  get_scorm_packages: {
    url: `${contentBaseApi}/courses-service/scorm`,
    method: "GET",
  },
  search: {
    url: `${contentBaseApi}/courses-service/search`,
    method: "GET",
  },
};
