import { api } from "../api";

// actions
const COMMENTS_COMENTARIOS_POST = "COMMENTS_COMENTARIOS_POST";
const COMMENTS_INSERIR_COMENTARIO = "COMMENTS_INSERIR_COMENTARIO";
const COMMENTS_ALTERAR_COMENTARIO = "COMMENTS_ALTERAR_COMENTARIO";
const COMMENTS_EXCLUIR_COMENTARIO = "COMMENTS_EXCLUIR_COMENTARIO";

// action creators
export const comentariosPost = (capituloid, token, postagemid, alunoid) => {
  return async (dispatch) => {
    const apiUrl =
      capituloid != null ? api.comentarios_post : api.comentarios_post_aluno;
    try {
      const res =
        capituloid != null
          ? await fetch(
              `${apiUrl}?capituloid=${capituloid}&alunoid=${alunoid}`,
              {
                method: "get",
                headers: { token },
              }
            )
          : await fetch(
              `${apiUrl}?postagemid=${postagemid}&alunoid=${alunoid}`,
              {
                method: "get",
                headers: { token },
              }
            );
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        dispatch({ type: COMMENTS_COMENTARIOS_POST, items: json[0].data });
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      dispatch({ type: COMMENTS_COMENTARIOS_POST, items: null });
      throw new Error(error);
    }
  };
};

export const inserirComentario = (body, token) => {
  return async (dispatch) => {
    try {
      const res = await fetch(api.inserir_comentario, {
        method: "post",
        headers: { token },
        body,
      });

      if (res.status >= 200 && res.status < 300) {
        // const json = await res.json();
        dispatch({ type: COMMENTS_INSERIR_COMENTARIO });
        return "success";
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      dispatch({ type: COMMENTS_INSERIR_COMENTARIO });
      return "error";
    }
  };
};
export const inserirComentarioPostAluno = (body, token) => {
  return async (dispatch) => {
    try {
      const res = await fetch(api.inserir_comentario_postagem, {
        method: "post",
        headers: { token },
        body,
      });

      if (res.status >= 200 && res.status < 300) {
        // const json = await res.json();
        dispatch({ type: COMMENTS_INSERIR_COMENTARIO });
        return "success";
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      dispatch({ type: COMMENTS_INSERIR_COMENTARIO });
      return "error";
    }
  };
};
export const alterarComentario = (body, token) => {
  return async (dispatch) => {
    try {
      const res = await fetch(api.alterar_comentario, {
        method: "post",
        headers: { token },
        body,
      });
      if (res.status >= 200 && res.status < 300) {
        // const json = await res.json();
        dispatch({ type: COMMENTS_COMENTARIOS_POST });
        return "success";
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      return "error";
    }
  };
};

export const editComentarioPostAluno = (body, token) => {
  return async (dispatch) => {
    try {
      const res = await fetch(api.editar_comentario_postagem, {
        method: "post",
        headers: { token },
        body,
      });
      if (res.status >= 200 && res.status < 300) {
        // const json = await res.json();
        dispatch({ type: COMMENTS_COMENTARIOS_POST });
        return "success";
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      return "error";
    }
  };
};
export const excluirComentario = (body, token) => {
  return async (dispatch) => {
    try {
      const res = await fetch(api.excluir_comentario, {
        method: "post",
        headers: { token },
        body,
      });
      if (res.status >= 200 && res.status < 300) {
        // const json = await res.json();
        dispatch({ type: COMMENTS_EXCLUIR_COMENTARIO });
        return "success";
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      dispatch({ type: COMMENTS_EXCLUIR_COMENTARIO });
      return "error";
    }
  };
};

export const excluirComentarioPostAluno = (body, token) => {
  return async (dispatch) => {
    try {
      const res = await fetch(api.excluir_comentario_postagem, {
        method: "post",
        headers: { token },
        body,
      });
      if (res.status >= 200 && res.status < 300) {
        // const json = await res.json();
        dispatch({ type: COMMENTS_EXCLUIR_COMENTARIO });
        return "success";
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      dispatch({ type: COMMENTS_EXCLUIR_COMENTARIO });
      return "error";
    }
  };
};
// reducer
export default function reducer(state = {}, action) {
  switch (action.type) {
    case COMMENTS_COMENTARIOS_POST:
      return { ...state, items: action.items };
    case COMMENTS_ALTERAR_COMENTARIO:
      return state;
    default:
      return state;
  }
}
