import moment from "moment";

const secTiMiliSec = 1000;
const minToSec = 60;
const dayToHour = 24;
const monthToDay = 30;

export const calcPercentage = (current, total) => {
  return Math.ceil((current * 100) / total);
};

export const getTimeDif = (inicio, conclusao) => {
  const begin = new Date(inicio);
  const end = new Date(conclusao);
  const part = end.valueOf() - begin.valueOf();
  return part;
};

export const getMonthDif = (inicio, conclusao) => {
  const timeDif = getTimeDif(inicio, conclusao);
  return Math.ceil((((((timeDif/secTiMiliSec)/minToSec)/minToSec)/dayToHour)/monthToDay));
}

export const getTimeUsed = (inicio, conclusao) => {
  if (inicio && conclusao) {
    const today = moment();
    const begin = moment(inicio);
    const end = moment(conclusao);
    const part = today.unix() - begin.unix();
    const total = end.unix() - begin.unix();
    let result = Math.ceil((part * 100) / total);
    return result > 100 ? 100 : result;;
  }
  return undefined;
};