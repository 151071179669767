import { Box } from "@mui/material";
import ReactQuill from "react-quill";
// import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.snow.css";
import "./richEditor.css";
import { CSS_COLOR_NAMES as colorsList } from "./CSSColors";

export default function Editor({ value, onChange }) {
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote", "code-block"],
      [{ color: [] }, { background: [] }],
      [{ align: [] }],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
    ],
  };

  const formats = [
    "header",
    "align",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "code-block",

    "color",
    "background",

    "list",
    "indent",

    "bullet",

    "link",
    "image",
  ];
  return (
    <Box sx={{ backgroundColor: "#F3F3F4", maxWidth:"initial" }}>
      <ReactQuill
        style={{ width: "inherit", background: "white" }}
        defaultValue={""}
        value={value}
        onChange={onChange}
        formats={formats}
        modules={modules}
      />
    </Box>
  );
}
