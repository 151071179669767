import { Container } from "@mui/material";
import { baseUrl, certificateDefault } from "../../shared/config";
import { getClient } from "../../shared/config";
import { useRef } from "react";
import { display } from "@mui/system";
import RichText from "./RichText";

let client = getClient();
export const Certificate1sPart = ({ id, certificate }) => {
  return (
    <div className="relative w-[900px] h-[675px] mx-auto" id={id}>
      <img
        className="relative w-full h-full mx-auto"
        alt=""
        src={certificate?.certificateUrl?.replace(
          "{disciplinaid}",
          certificate?.disciplinaid
        )}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = certificateDefault;
        }}
      />
      <Container
        sx={{
          position: "absolute",
          top: "17%",
          width: "80%",
          left: "10%",
          bottom: "17%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
        maxWidth="false"
        disableGutters
      >
        <RichText value={certificate.texto} />
        {/* <div
          className="unreset"
          dangerouslySetInnerHTML={{ __html: certificate.texto }}
        /> */}
      </Container>
    </div>
  );
};

export const Certificate2ndPart = ({ id, certificate }) => {
  const ref = useRef();

  if (client == "MOSSMANN") {
    return (
      <div className="w-[900px] mx-auto" id={id}>
        <div className="w-full  bg-white flex flex-col justify-between">
          <div>
            <img
              alt=""
              src={`${baseUrl}/img/logo_login.png`}
              width="170px"
              className="ml-6"
            />
          </div>
          <Container sx={{ display: "flex", flexDirection: "column" }}>
            <div className="ml-6">
              <div className=" flex flex-col gap-1 mt-1">
                <p>
                  <b>Curso/Treinamento:</b>
                  {certificate.nome_curso}
                </p>
                <p>
                  <b>Carga Horária Total:</b>
                  {certificate.Carga_Horaria}
                </p>
                <p>
                  <b>Data da Conclusão: </b>
                  {new Intl.DateTimeFormat("pt-BR", {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                  }).format(new Date(certificate.dtconclusao))}
                </p>
              </div>

              <p className="font-bold">Conteúdo programático:</p>
              <Container
                ref={ref}
                disableGutters
                sx={{
                  "& > pre": { whiteSpace: "break-spaces" },
                  "& table > tbody > tr > td": {
                    verticalAlign: "top",
                  },
                  // "& table > tbody > tr:last-child > td:last-child": {
                  //   backgroundImage: `url(${`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${
                  //     certificate.disciplinaid > 900000
                  //       ? baseUrl +
                  //         "/VERIFICA_CertificadoConvenio.aspx?matriculaid=" +
                  //         certificate.matriculaid
                  //       : baseUrl +
                  //         "/VERIFICA_Certificado.aspx?matriculaid=" +
                  //         certificate.matriculaid
                  //   }`})`,
                  //   backgroundRepeat: `no-repeat`,
                  //   backgroundPosition: "center",
                  // },
                }}
                dangerouslySetInnerHTML={{ __html: certificate.CONTEUDO }}
              />
              <div className="m-6"></div>
            </div>
          </Container>
        </div>
      </div>
    );
  } else {
    return (
      <div className="w-[900px] mx-auto" id={id}>
        <div className="w-full  bg-white flex flex-col justify-between">
          <div>
            <img
              alt=""
              src={`${baseUrl}/img/logo_login.png`}
              width="100px"
              className="ml-[3rem]"
            />
          </div>
          <Container sx={{ display: "flex", flexDirection: "row" }}>
            <div className="ml-6">
              <p>
                <b>Dados deste certificado:</b>
              </p>
              <div className=" flex flex-col gap-1 mt-1">
                <p>
                  <b>Nome do aluno:</b>
                </p>
                <p>{certificate.nome}</p>
                <p>
                  <b>Nome do Curso/Treinamento:</b>
                </p>
                <p>{certificate.nome_curso}</p>
                <p>
                  <b>Carga Horária:</b>
                </p>
                <p>{certificate.Carga_Horaria}</p>
                <p>
                  <b>Data da Conclusão:</b>
                </p>
                <p>
                  {new Intl.DateTimeFormat("en-GB", {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                  }).format(new Date(Date.parse(certificate.dtconclusao)))}
                </p>
              </div>
              <div className="m-6">
                <img
                  alt=""
                  src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${
                    certificate.disciplinaid > 900000
                      ? baseUrl +
                        "/VERIFICA_CertificadoConvenio.aspx?matriculaid=" +
                        certificate.matriculaid
                      : baseUrl +
                        "/VERIFICA_Certificado.aspx?matriculaid=" +
                        certificate.matriculaid
                  }`}
                />
              </div>
            </div>
            <div className="ml-6">
              <p className="font-bold">Conteúdo programático:</p>
              <Container
                sx={{
                  paddingLeft: "0 !important",
                  "& > pre": { whiteSpace: "break-spaces" },
                }}
                dangerouslySetInnerHTML={{ __html: certificate.CONTEUDO }}
              ></Container>
            </div>
          </Container>
        </div>
      </div>
    );
  }
};
