import { configVimeo } from "../../config";
import { getClient } from "../../config/urlConfig";

const INITIAL_VALUE = {
  videoList: [],
};

const Check_All_Videos = "Check_All_Videos";
const LIST_VIDEOS_ALBUM = "LIST_VIDEOS_ALBUM";
const CHECK_USED_VIDEO = "CHECK_USED_VIDEO";
const REMOVE_VIDEO = "REMOVE_VIDEO";

const domain = getClient();

export const checkAllVideos = () => {
  return async (dispatch) => {
    try {
      const res = await fetch(
        `${configVimeo.apiVimeo}/check-all?cvApiBaseUrl=${configVimeo.cvApiBaseUrl}`,
        {
          method: "get",
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            domain,
          },
        }
      );
      if (res.status >= 200 && res.status < 300) {
        // const json = await res.json();
        dispatch({ type: Check_All_Videos });
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  };
};

export const listAll = (page, albumId, user, myVideosFilter, filter) => {
  return async (dispatch) => {
    try {
      const res = await fetch(
        `${
          configVimeo.apiVimeo
        }/list?album_id=${albumId}&per_page=10&page=${page}&my_videos=${myVideosFilter}&user=${
          user.id
        }&type=${user.type}${
          filter ? "&filter=" + encodeURIComponent(filter) : ""
        }`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            domain,
          },
        }
      );
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        dispatch({ type: LIST_VIDEOS_ALBUM, payload: json.data });
        return json;
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: LIST_VIDEOS_ALBUM, payload: [] });
      return null;
    }
  };
};

export const deleteVideo = (videoId) => {
  return async (dispatch, getState) => {
    try {
      const res = await fetch(`${configVimeo.apiVimeo}/delete/${videoId}`, {
        method: "delete",
        headers: { "Content-Type": "application/json; charset=utf-8", domain },
      });

      if (res.status >= 200 && res.status < 300) {
        const newList = getState().video.videoList.filter(
          (video) => video.id !== videoId
        );

        dispatch({ type: REMOVE_VIDEO, payload: newList });
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  };
};

export const processUpload = (identifier, fileName, vimeoName, user) => {
  return async (dispatch) => {
    try {
      fetch(
        `${
          configVimeo.apiVimeo
        }/process-upload?identifier=${identifier}&fileName=${fileName}&vimeoName=${vimeoName}&userId=${
          user.id
        }&userType=${user.type}&userAlbumId=${
          user.albumId
        }&origin=${domain?.toLowerCase()}`,
        { headers: { domain } }
      );
      return null;
    } catch (error) {
      console.log("error", error);
      return null;
    }
  };
};

export const upload = (formData, callback) => {
  return async (dispatch) => {
    try {
      // const res =
      await fetch(`${configVimeo.apiVimeo}/upload`, {
        method: "POST",
        formData,
        headers: { domain },
      });
    } catch (error) {
      console.log(error);
      return null;
    }
  };
};

export const toggleStatus = (formData) => {
  return async (dispatch) => {
    try {
      // const res =
      await fetch(`${configVimeo.apiVimeo}/toggle-status`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          domain,
        },

        body: JSON.stringify({ video_id: "" + formData }),
      });
    } catch (error) {
      console.log(error);
      return null;
    }
  };
};

export const checkIfBeingUsed = (videoid, token) => {
  return async (dispatch) => {
    try {
      const res = await fetch(
        //        'https://cadernovirtual.inf.br/server/api/vimeo/check-video?cvApiBaseUrl=https://cvcorporate.cadernovirtual.com.br/api/vimeo/vimeo.asmx&vimeoid='+vimeoid,
        `${configVimeo.apiUrl}/aluno.asmx/Verifica_video?vimeoid=${videoid}`,
        {
          method: "GET",
          headers: {
            token,
          },
        }
      );

      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();

        dispatch({ type: CHECK_USED_VIDEO, payload: json[0].data });
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  };
};

export default function reducer(state = INITIAL_VALUE, action) {
  switch (action.type) {
    case Check_All_Videos:
      return { ...state };
    case LIST_VIDEOS_ALBUM:
    case REMOVE_VIDEO:
      return { ...state, videoList: action.payload };
    case CHECK_USED_VIDEO:
      return { ...state, isUsed: action.payload };
    default:
      return state;
  }
}
