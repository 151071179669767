import Form from "../../../ui/components/Form";
import * as yup from "yup";
import CustomTextField from "../../../ui/components/Form/TextField";
import { Box, Button, CircularProgress, styled } from "@mui/material";
import { useRocketChatLogin } from "./rocketChatLogin";

const StyledForm = styled("form")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  width: "100%",
  justifyContent: "center",
  gap: theme.spacing(5),
  padding: theme.spacing(5),
  "& > span": {
    display: "flex",
    gap: theme.spacing(2),
    flexDirection: "column",
  },
}));

const schema = yup.object().shape({
  user: yup.string().required(),
  password: yup.string().required(),
});

const defaultValues = {
  user: "",
  password: "",
};

export const RocketChatLoginForm = ({ onSuccess }) => {
  const { mutate, isLoading } = useRocketChatLogin();
  const handleSubmit = (values) => {
    mutate(values, { onSuccess });
  };
  return (
    <>
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Form
          schema={schema}
          defaultValues={defaultValues}
          onSubmit={handleSubmit}
          Component={StyledForm}
        >
          {(formMethods) => {
            return (
              <>
                <span>
                  <CustomTextField
                    labelText={"Nome de usuário"}
                    formMethods={formMethods}
                    fieldName={"user"}
                  />
                  <CustomTextField
                    labelText={"Senha"}
                    formMethods={formMethods}
                    fieldName={"password"}
                    type="password"
                  />
                </span>
                <Button type="submit">Logar</Button>
              </>
            );
          }}
        </Form>
      )}
    </>
  );
};
