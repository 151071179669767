export const setOneSignalOptedIn = (value) => {
  return async (dispatch) => {
    dispatch({ type: "SET_ONESIGNAL_OPTED_IN", value });
  };
};

export default function reducer(state = {}, action) {
  switch (action.type) {
    case "SET_ONESIGNAL_OPTED_IN":
      return { ...state, optedIn: action.value };
    default:
      return state;
  }
}
