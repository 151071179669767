import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../shared/lib/axios";
import { createNotification } from "../../../shared/store/notifications";

export const insertGroupsToSurvey = async ({ data }) => {
  const res = await axios.cadernoVirtualBase.post(
    "/survey-service2/research/insertGroups",
    data
  );
  return res;
};

export const useInsertGroupsToSurvey = ({ config, surveyId }) => {
  return useMutation({
    ...config,
    mutationKey: ["insertGroupsToSurvey"],
    onSuccess: () => {
      createNotification({
        type: "success",
        message: "Audiência da pesquisa atualizada com sucesso",
      });
    },
    mutationFn: (data) =>
      insertGroupsToSurvey({
        data: { researchId: surveyId, ...data },
      }),
  });
};
