import { useMutation } from "@tanstack/react-query";
import { useContext, useEffect, useRef } from "react";
import StatefulFileInput from "../../../ui/components/DragAndDropFileInput/StatefulFileInput";
import { QuestionContext } from "./Question";
import { Button } from "@mui/material";
export default function FileUploadQuestion({
  uploadMutationFn,
  attemptId,
  ...rest
}) {
  const {
    isEditing,
    question,
    submittedAnswer,
    setSubmittedAnswer,
    ...restContext
  } = useContext(QuestionContext);

  const buttonRef = useRef();
  const { mutate, isLoading } = useMutation({
    mutationFn: (files) =>
      uploadMutationFn({
        questionId: question.questionId,
        attemptId: attemptId,
        files,
      }),
  });

  if (!attemptId) {
    return <></>;
  }
  if (!!submittedAnswer) {
    return (
      <Button
        onClick={() => {
          setSubmittedAnswer(null);
        }}
      >
        Alterar resposta
      </Button>
    );
  }
  return (
    <>
      <StatefulFileInput
        loading={isLoading}
        multiple
        onSubmit={(files) =>
          mutate(files, {
            onSuccess: (data) => {
              setSubmittedAnswer(data);
            },
          })
        }
      />
    </>
  );
}
