import {
  Tooltip,
  Icon,
  TextField as MuiTextField,
  FormControl,
  InputLabel,
} from "@mui/material";
import { Info } from "@mui/icons-material";
export default function CustomTextField({
  children,
  labelText,
  helperText,
  tipText,
  formMethods,
  fieldName,
  ...rest
}) {
  const { register, formState } = formMethods;
  return (
    <MuiTextField
      {...register(fieldName)}
      error={!!formState?.errors?.[fieldName]}
      helperText={helperText || formState.errors?.[fieldName]?.message}
      label={
        <>
          {labelText}
          {!!tipText && (
            <Tooltip sx={{ marginBottom: "-5px" }} title={tipText}>
              <Icon color="info">
                <Info />
              </Icon>
            </Tooltip>
          )}
        </>
      }
      {...rest}
    >
      {children}
    </MuiTextField>
  );
}
