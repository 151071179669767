import { api } from "../api";
// import _cookies from "../utils/_cookies";
import _cookiesChunked from "../utils/_cookiesChunked";

// actions
const APP_SET_PARAMS = "APP_SET_PARAMS";
const APP_INSTRUCOES_APP = "APP_INSTRUCOES_APP";
const APP_DASHBOARD = "APP_DASHBOARD";
const APP_TERMOS_DE_USO = "APP_TERMOS_DE_USO";
const APP_ACEITE_TERMOS_DE_USO = "APP_ACEITE_TERMOS_DE_USO";
const APP_INSTALL_EVENT = "APP_INSTALL_EVENT";
const GET_IP_ADDRESS = "GET_IP_ADDRESS";
// action creators
export const setParams = (token, alunoid) => {
  return async (dispatch) => {
    try {
      const res = await fetch(`${api.parametros_gerais}?alunoid=${alunoid}`, {
        method: "get",
        headers: { token },
      });
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        const params = json[0].data[0];

        // removing heavy params to fit in the cookies
        delete params.TEMPLATE_MATRICULA;
        delete params.TEMPLATE_POSTAGEM;
        delete params.TEMPLATE_AVISO_PRAZO_CURSO;
        delete params.TEMPLATE_FIM_PRAZO_CURSO;
        delete params.TEMPLATE_AVISAR_ALUNOS;
        delete params.HTML_DASHBOARD;
        delete params.HTML_INSTRUCOES_APP;

        _cookiesChunked.setItem("params", params);
        dispatch({ type: APP_SET_PARAMS, params });
        return true;
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      dispatch({ type: APP_SET_PARAMS, params: null });
      console.error("setParams error", error);
      return false;
    }
  };
};

export const instrucoesApp = (token) => {
  return async (dispatch) => {
    try {
      const res = await fetch(api.instrucoes_app, {
        method: "get",
        headers: { token },
      });
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        const html = json[0].data[0].HTML_INSTRUCOES_APP;
        dispatch({ type: APP_INSTRUCOES_APP, installApp: html });
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      dispatch({ type: APP_INSTRUCOES_APP, installApp: null });
      console.error("instrucoesApp error", error);
      throw new Error(error);
    }
  };
};

export const dashboard = (token) => {
  return async (dispatch) => {
    try {
      const res = await fetch(api.dashboard, {
        method: "get",
        headers: { token },
      });
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        const html = json[0].data[0].HTML_DASHBOARD;
        dispatch({ type: APP_DASHBOARD, dashboard: html });
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      dispatch({ type: APP_DASHBOARD, dashboard: null });
      console.error("dashboard error", error);
      throw new Error(error);
    }
  };
};

export const termosDeUso = (alunoid, token) => {
  return async (dispatch) => {
    try {
      const res = await fetch(`${api.termos_de_uso}?alunoid=${alunoid}`, {
        method: "get",
        headers: { token },
      });
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        const terms = json[0].data;
        dispatch({ type: APP_TERMOS_DE_USO, termsOfUse: terms || [] });
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      dispatch({ type: APP_TERMOS_DE_USO, termsOfUse: [] });
      console.error("termsOfUse error", error);
      throw new Error(error);
    }
  };
};

export const getIpAddress = () => {
  return async (dispatch) => {
    try {
      const res = await fetch("https://api.ipify.org/?format=json", {
        method: "GET",
      });
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        const ip = json.ip;
        dispatch({ type: GET_IP_ADDRESS, ipData: ip });
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      dispatch({ type: GET_IP_ADDRESS, ipData: null });
      console.error("AccepttermsOfUse error", error);
      throw new Error(error);
    }
  };
};

export const acceptTerms = (data, token) => {
  return async (dispatch) => {
    const body = new FormData();
    body.append("dados", data);
    try {
      const res = await fetch(`${api.registra_aceite}`, {
        method: "POST",
        headers: { token },
        body: body,
      });
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        //const terms = json[0].data;
        dispatch({
          type: APP_ACEITE_TERMOS_DE_USO,
          AccepttermsOfUse: json || [],
        });
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      dispatch({ type: APP_ACEITE_TERMOS_DE_USO, AccepttermsOfUse: [] });
      console.error("AccepttermsOfUse error", error);
      throw new Error(error);
    }
  };
};

export const saveInstallPrompt = (event) => {
  return async (dispatch) => {
    dispatch({ type: APP_INSTALL_EVENT, event: event });
  };
};

export const setColors = (params) => {
  const reg = new RegExp(/(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i);
  const isColor = (string) => {
    return reg.test(string);
  };
  const default_colors = {
    primary_color_light: "#F79454",
    primary_color_main: "#FF6600",
    primary_color_dark: "#df5900",
    secondary_color_light: "#6c6c6c",
    secondary_color_main: "#4F4F4F",
    secondary_color_dark: "#393939",
  };
  return () => {
    Object.keys(default_colors).map((key) => {
      document.documentElement.style.setProperty(
        `--${key}`,
        isColor(params[`NEW_${key}`]) ? params[`NEW_${key}`] : "#f79454"
      );
    });
  };
};
// reducer
const initialState = { currentRoute: null };
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case APP_SET_PARAMS:
      return { ...state, params: action.params };

    case APP_INSTRUCOES_APP:
      return { ...state, installApp: action.installApp };

    case APP_DASHBOARD:
      return { ...state, dashboard: action.dashboard };

    case APP_TERMOS_DE_USO:
      return { ...state, termsOfUse: action.termsOfUse };
    case APP_ACEITE_TERMOS_DE_USO:
      return { ...state, AccepttermsOfUse: action.AccepttermsOfUse };
    case GET_IP_ADDRESS:
      return { ...state, ipData: action.ipData };
    case APP_INSTALL_EVENT:
      return { ...state, installEvent: action.event };
    default:
      return state;
  }
}
