import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import ArrowBack from "@mui/icons-material/ArrowBack";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

function NotFound({ ...props }) {
  const handleClick = () => {
    window.history.go(-1);
  };

  return (
    <Paper>
      <Box margin="auto">
        <IconButton onClick={handleClick}>
          <ArrowBack />
        </IconButton>
        Voltar
      </Box>
      <Typography fontWeight={500} textAlign={"center"}>
        404
      </Typography>
      <Typography textAlign={"center"}>Página não encontrada</Typography>
    </Paper>
  );
}

export default NotFound;
