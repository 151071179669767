import { Outlet } from "react-router-dom";

import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";
import AppBar from "../partials/AppBar";
import ModuleSelect from "./ModuleSelect";
import Chat from "../../shared/lib/rocketChat/Chat";

function ProtectedRoutesLayout({ children, AppBarProps, ...props }) {
  return (
    <>
      <AppBar {...AppBarProps} />

      <Box sx={{ display: "flex", flexDirection: "row", minHeight: "100%" }}>
        {/* <Chat /> */}
        <Outlet />
      </Box>
    </>
  );
}

export default ProtectedRoutesLayout;
