import { useQuery } from "@tanstack/react-query";
import { axios } from "../../../shared/lib/axios";
import _cookies from "../../../shared/utils/_cookies";

export const getAllCertifications = async () => {
  const res = await axios.cadernoVirtualBase(
    "/certification-service/certification",
    {}
  );
  return res.object;
};

export const useAllCertifications = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["allCertifications"],
    queryFn: getAllCertifications,
  });
};
