import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../shared/lib/axios";

export const startSurveyAttempt = async (data) => {
  const res = await axios.cadernoVirtualBase.post(
    "/survey-service/research/startResearch",
    data
  );

  return res.object;
};

export const useStartSurveyAttempt = ({ config } = {}) => {
  return useMutation({ ...config, mutationFn: startSurveyAttempt });
};
