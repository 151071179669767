const LAST_MENU_PATH = "LAST_MENU_PATH";
const CURRENT_TOOL = "CURRENT_TOOL";
const BREADCRUMBS_PATH = "BREADCRUMBS_PATH";
const ONLY_SHOW_LAST_PATH = "ONLY_SHOW_LAST_PATH";

export const setLastMenuPath = (path) => {
  return async (dispatch) => {
    dispatch({ type: LAST_MENU_PATH, path: path });
  };
};

export const setCurrentTool = (title,name) => {
  return async (dispatch) => {
    dispatch({type: CURRENT_TOOL, tool: {title, name}})
  }
}

export const setBreadCrumbsElement = (
  path,
  index,
  onlyshowlastpath = false
) => {
  return async (dispatch) => {
    dispatch({ type: BREADCRUMBS_PATH, path, index });
    dispatch({ type: ONLY_SHOW_LAST_PATH, onlyshowlastpath });
  };
};

export default function reducer(
  state = {
    lastmenu: "../",
    breadcrumbs: ["", "", ""],
    onlyshowlastpath: false,
  },
  action
) {
  switch (action.type) {
    case LAST_MENU_PATH:
      return { ...state, lastmenu: action.path };
    case CURRENT_TOOL:
      return { ...state, currentTool: action.tool};
    case ONLY_SHOW_LAST_PATH:
      return { ...state, onlyshowlastpath: action.onlyshowlastpath };
    case BREADCRUMBS_PATH:
      const temp = state.breadcrumbs;
      temp.splice(action.index, 1, action.path);
      return {
        ...state,
        breadcrumbs: temp,
      };
    default:
      return state;
  }
}
