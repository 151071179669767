import RouterBuilder from "./RouterBuilder";
import { lazy } from "react";
// import TrackListPage from "../../ui/pages/TrackListPage";
// import TrackPage from "../../ui/pages/TrackPage";

const TrackListPage = lazy(() => import("../../ui/pages/TrackListPage"));
const TrackPage = lazy(() => import("../../ui/pages/TrackPage"));


const routeFactory = RouterBuilder.routeFactory;

const routes = [
  routeFactory("tracks", "trilhas", TrackListPage),
  routeFactory("track", ":trackid", TrackPage, "tracks"),
  routeFactory("track", "filtro", TrackPage, "tracks"),
];

const TrackRoutes = new RouterBuilder(routes).nestedRoutes;

export default TrackRoutes;
