import { api } from "../api";

export const SOCIAL_SET_THEMES = "SOCIAL_SET_THEMES";
const SOCIAL_SET_CURRENT_THEME = "SOCIAL_SET_CURRENT_THEME";
const SOCIAL_GET_THEME_DATA = "SOCIAL_GET_THEME_DATA";
export const SOCIAL_GET_POSTS = "SOCIAL_GET_POSTS";
const SOCIAL_STATUS = "SOCIAL_STATUS";

export const getThemes = (alunoid, token) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: SOCIAL_STATUS,
        key: SOCIAL_SET_THEMES,
        status: "loading",
      });
      const res = await fetch(`${api.temas}?alunoid=${alunoid}`, {
        method: "get",
        headers: { token },
      });
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        dispatch({
          type: SOCIAL_SET_THEMES,
          postThemes: json[0].data,
        });
        dispatch({
          type: SOCIAL_STATUS,
          key: SOCIAL_SET_THEMES,
          status: "success",
        });
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      console.log("Erro ao buscar dados de temas:", error);
      dispatch({
        type: SOCIAL_STATUS,
        key: SOCIAL_SET_THEMES,
        status: "error",
      });
    }
  };
};

export const getThemeData = (themeid, alunoid, token) => {
  return async (dispatch) => {
    try {
      const res = await fetch(
        `${api.dados_tema}?temaid=${themeid}&alunoid=${alunoid}`,
        {
          method: "get",
          headers: {  token },
        }
      );
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        dispatch({
          type: SOCIAL_GET_THEME_DATA,
          currentThemeData: json[0].data[0],
        });
        return json[0].data;
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      console.log("Erro ao buscar dados de temas:", error);
    }
  };
};

export const setCurrentTheme = (currentTheme) => {
  // console.log('setando currenttheme',currentTheme);
  return (dispatch) => {
    dispatch({ type: SOCIAL_SET_CURRENT_THEME, currentTheme });
  };
};

export const getPostsByStudentAndTheme = ({ themeId, studentId, token }) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: SOCIAL_STATUS,
        key: SOCIAL_GET_POSTS,
        status: "loading",
      });
      const res = await fetch(
        `${api.posts_aluno}?alunoid=${studentId}&temaid=${themeId}`,
        {
          method: "get",
          headers: { token },
        }
      );
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        dispatch({
          type: SOCIAL_GET_POSTS,
          themePosts: json[0].data,
        });
        dispatch({
          type: SOCIAL_STATUS,
          key: SOCIAL_GET_POSTS,
          status: "success",
        });
        return json[0].data;
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      console.log("Erro ao buscar dados de postagens:", error);
      dispatch({
        type: SOCIAL_STATUS,
        key: SOCIAL_GET_POSTS,
        status: "error",
      });
    }
  };
};

export default function reducer(state = { status: {} }, action) {
  switch (action.type) {
    case SOCIAL_SET_CURRENT_THEME: {
      return { ...state, currentTheme: action.currentTheme };
    }
    case SOCIAL_SET_THEMES: {
      return { ...state, postThemes: action.postThemes };
    }
    case SOCIAL_GET_THEME_DATA: {
      return { ...state, currentThemeData: action.currentThemeData };
    }
    case SOCIAL_GET_POSTS: {
      return { ...state, themePosts: action.themePosts };
    }
    case SOCIAL_STATUS:
      return {
        ...state,
        status: { ...state.status, [action.key]: action.status },
      };
    default: {
      return state;
    }
  }
}
