import _cookies from "../../../../shared/utils/_cookies";
import { axios } from "../../../../shared/lib/axios";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { ManagerCatalogueContext } from "../common/context";
export const getCourseGroups = async (params) => {
  
  const res = await axios.cadernoVirtualBase.get(
    "/catalog-service/shelf/groups?" +
      new URLSearchParams({
        ...params,
        gestorId: _cookies.getItem("auth").user.gestorid,
        token: _cookies.getItem("auth").token,
      }).toString()
  );
  return res.object;
};

export const useGetCourseGroups = ({ config, courseId } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["shelfCourseGroups", { courseId }],
    queryFn: () => getCourseGroups({ disciplinaId: courseId }),
    enabled: !!courseId,
  });
};
