import { Box, Button, CircularProgress, useTheme } from "@mui/material";
import { Question } from "../../questions/components/Question";
import { useState } from "react";
import { Add } from "@mui/icons-material";
import { useSaveQuestion } from "../api/saveQuestion";
import { defaultSurveyQuestionTemplates } from "../../questions/utils/defaultQuestionTemplates";
import { useSurvey } from "../api/getSurvey";
import { surveyQuestionTemplates } from "../utils/surveyQuestionTemplates";
import _ from "lodash";
export default function ({ surveyId, onDirty }) {
  const { data, isFetching, isError } = useSurvey({ surveyId });
  const [newQuestionAdded, setNewQuestionAdded] = useState(false);

  const [dirtyQuestionIndex, setDirtyQuestionIndex] = useState(null);
  const saveQuestionMutation = useSaveQuestion({ surveyId });
  const isNPSSurvey = data?.npsType == "S";
  const onlyRatingsEnabled = Object.fromEntries(
    Object.entries(surveyQuestionTemplates).map((entry) => [
      entry[0],
      { ...entry[1], disabled: !(entry[0] == "0-10" || entry[0] == "0-5") },
    ])
  );

  const onlyDiscursiveEnabled = Object.fromEntries(
    Object.entries(surveyQuestionTemplates).map((entry) => [
      entry[0],
      { ...entry[1], disabled: !(entry[0] == "ABERTA") },
    ])
  );

  const theme = useTheme();
  const handleDeleteQuestion = (question) => {
    saveQuestionMutation.mutate({
      ...question,
      remove: true,
      listOptions: question.listOptions?.map((option) => ({
        ...option,
        remove: true,
      })),
    });
  };

  const handleSaveQuestion = (newValue, oldValue = undefined) => {
    // Only send options if they are to be removed, changed or added

    const filteredOptions = newValue.listOptions?.filter((option) => {
      return option.remove || option.isDirty || option.id == undefined;
    });

    return saveQuestionMutation.mutate(
      { ...newValue, listOptions: filteredOptions },
      {
        onSuccess: () => {
          setDirtyQuestionIndex(null);
          onDirty(false);
          setNewQuestionAdded(false);
        },
      }
    );
  };
  const keys = Object.keys(surveyQuestionTemplates);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        rowGap: { xs: 1, md: 2 },
      }}
    >
      {saveQuestionMutation.isLoading || isFetching ? (
        <CircularProgress />
      ) : (
        <>
          {data.questions?.map((question, index) => {
            return (
              <Question
                key={index}
                isEditionDirty={dirtyQuestionIndex === index}
                onDirty={(isDirty) => {
                  setDirtyQuestionIndex(isDirty ? index : null);
                  onDirty(isDirty);
                }}
                label={`Questão ${index + 1} de ${data.questions.length}`}
                editable={
                  dirtyQuestionIndex === index || dirtyQuestionIndex == null
                }
                deletable={(isNPSSurvey && index == 1) || !isNPSSurvey}
                initialQuestionData={question}
                questionTemplates={
                  isNPSSurvey
                    ? index == 0
                      ? onlyRatingsEnabled
                      : onlyDiscursiveEnabled
                    : surveyQuestionTemplates
                }
                onDeleteQuestion={handleDeleteQuestion}
                onSaveQuestion={handleSaveQuestion}
                disabledAnswer={true}
              />
            );
          })}
          <Box
            sx={{
              width: "100%",
            }}
          >
            {newQuestionAdded ? (
              <Question
                mandatoryFieldEditable={!isNPSSurvey}
                key={"newQuestion"}
                initiallyEditing
                editable={
                  dirtyQuestionIndex === data.questions?.length + 1 ||
                  dirtyQuestionIndex == null
                }
                isEditionDirty={
                  dirtyQuestionIndex === data.questions?.length + 1
                }
                onDirty={(isDirty) => {
                  onDirty(isDirty);
                  setDirtyQuestionIndex(
                    isDirty ? data.questions?.length + 1 : null
                  );
                }}
                label={"Nova questão"}
                initialQuestionData={{
                  newQuestionType: keys[8],
                  statement: "Nova questão",
                  ...surveyQuestionTemplates[keys[8]].defaultValues,
                }}
                questionTemplates={
                  isNPSSurvey ? onlyDiscursiveEnabled : surveyQuestionTemplates
                }
                onSaveQuestion={(newValue) =>
                  handleSaveQuestion(newValue, undefined)
                }
                onDeleteQuestion={(question) => {
                  setNewQuestionAdded(false);
                }}
              />
            ) : (
              <Box
                sx={{
                  padding: "2rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <Button
                  disabled={
                    dirtyQuestionIndex != null ||
                    (isNPSSurvey && data.questions.length == 2)
                  }
                  variant="contained"
                  onClick={() => {
                    setNewQuestionAdded(true);
                  }}
                  startIcon={<Add />}
                >
                  Adicionar nova questão
                </Button>
              </Box>
            )}
          </Box>
        </>
      )}
    </Box>
  );
}
