import { Box } from "@mui/material";
import Editor from "./Editor";

function TextInput({ sx, value, onChange }) {
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        padding: "10px",
      }}
    >
      <Editor {...{ value, onChange }} />
    </Box>
  );
}

export default TextInput;
