import SearchIcon from "@mui/icons-material/Search";
import { alpha, styled } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { searchQuery } from "../../../src/shared/store/search/search";

const Wrapper = styled("div")(({ theme }) => ({
  display: "flex",
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.85),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 1),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "50%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: "1em",
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const Button = styled("button")(({ theme }) => ({
  height: "100%",
}));

function SearchBar({ searchQuery, auth, ...props }) {
  const [search, setSearch] = useState("");
  const navigate = useNavigate();

  const searchRequest = async (event) => {
    event.preventDefault();
    if (!auth.user?.alunoid) return;
    await searchQuery(auth.token, auth.user.alunoid, search);
  };

  return (
    <Wrapper {...props}>
      <form
        className="flex"
        onSubmit={(event) => {
          searchRequest(event);
        }}
      >
        <Button
          onClick={() => {
            navigate("search");
          }}
          type="submit"
        >
          <SearchIconWrapper>
            <SearchIcon color="secondary" />
          </SearchIconWrapper>
        </Button>

        <InputBase
          value={search}
          onChange={(event) => setSearch(event.target.value)}
          placeholder="Pesquisar"
          inputProps={{ "aria-label": "search" }}
        />
      </form>
    </Wrapper>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      searchQuery,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
