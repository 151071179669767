import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
} from "@mui/material";
import { pickModule } from "../../../shared/store/auth";
import { useLocation } from "react-router-dom";

function ModuleSelect({ auth, ...props }) {
  const { pickModule } = props;
  const { token } = auth;

  const location = useLocation();

  const isHomePage = location?.pathname === "/";

  return (
    <>
      <Dialog
        open={
          !!isHomePage && !!auth.notSelected && !!auth?.user?.profile?.isGestorAluno
        }
      >
        <DialogTitle>
          <Typography sx={{color:"#3667FC",fontWeight: "bold", textAlign:"justify"}} >
            Você também possui perfil de Gestor. Deseja acessar o módulo de
            gestão agora?
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ display: "grid", gridAutoFlow: "row", gridRowGap: "10px"}}>
          <Button
          sx={{
            background: "linear-gradient(to right bottom, var(--primary_color_dark), var(--primary_color_light))",
            color: "white"
          }}
            onClick={() => {
              pickModule();
              window.location.href = `${window.location.origin}/REDIRECT_GESTOR.ASPX?token=${token}`;
            }}
          >
            Acessar módulo de gestão
          </Button>

          <Button sx={{
            background: "linear-gradient(to right bottom, var(--primary_color_dark), var(--primary_color_light))",
            color: "white"
          }} onClick={() => pickModule()}>
            continuar no módulo de Aluno
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      pickModule,
    },
    dispatch
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(ModuleSelect);
