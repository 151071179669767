import { useMutation, useQueryClient } from "@tanstack/react-query";
import { axios } from "../../../shared/lib/axios";
import _cookies from "../../../shared/utils/_cookies";

export const answerMoodFeedback = async ({ mood, reason }) => {
  return axios.cadernoVirtualBase.post(
    "/mood-service/mood/save",
    {
      mood,
      reason,
      studentId: _cookies.getItem("auth").user.alunoid,
    },
    { headers: { "Access-Control-Allow-Origin": "*" } }
  );
};

export const useAnswerMoodFeedback = ({ config } = {}) => {
  const queryClient = useQueryClient();
  return useMutation({
    ...config,
    mutationFn: answerMoodFeedback,
    onSuccess: (data, variables, context) => {
      config.onSuccess(data, variables, context);
      queryClient.invalidateQueries({ queryKey: ["studentMood"] });
    },
  });
};
