import { styled } from "@mui/material/styles";
import MuiPaper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { Box, Divider, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material";
import ToggleButton from "./ToggleButton";
import isMobile from "../../../shared/utils/isMobile";
import { logoUrl, logoAdicionalUrl, baseUrl } from "../../../shared/config";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef } from "react";
import { KeyboardReturn } from "mdi-material-ui";
import { useTenantParameters } from "../../../features/misc/api/getTenantParameters";

const drawerWidth = 300;

const openedMixin = (theme, width) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  position: "absolute",
  // top: "0",
  display: "block",
  width: width || drawerWidth,
  [theme.breakpoints.up("sm")]: {
    top: "unset",
  },
  [theme.breakpoints.up("lg")]: {
    position: "static",
  },
  overflowX: "hidden",
});

const closedMixin = (theme, width) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  display: "none",
  width: width || `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    display: "block",
    width: width || `calc(${theme.spacing(8)} + 1px)`,
  },
  [theme.breakpoints.up("md")]: {
    position: "static",
  },
});

const Wrapper = styled(MuiPaper, {
  shouldForwardProp: (prop) => prop !== "open" || prop !== "anchor",
})(({ theme, open, isCourseNav, anchor, closedwidth, width }) => ({
  height: "100vh",
  ...(isCourseNav && {
    height: "calc(100vh - 80px)",
  }),
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  overflowY: "auto",
  [anchor]: 0,
  ...(open && {
    ...openedMixin(theme, width),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme, closedwidth),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const MobileNavToggleButton = styled(IconButton)(({ theme, open }) => ({
  position: "absolute",
  top: "21px",
  left: "15px",
  fontSize: "1000px",
  
  transform: open ? "rotate(90deg)" : "",
  transition: theme.transitions.create("transform", {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.short,
  }),
  [theme.breakpoints.up(theme.breakpoints.values["sm"])]: {
    display: "none",
    height: "0",
    width: "0",
  },
}));

function useOutsideAlerter(ref, callback, open) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        open &&
        callback
      ) {
        callback();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, callback, open]);
}

function Drawer({
  sx,
  anchor = "left",
  width,
  open,
  onClick,
  onClickOutside,
  logoAdicional,
  closedWidth,
  children,
  button,
  disableButton,
  hideMobileButton,
  ButtonProps,
  title = <div></div>,
  iscoursenav = false,
  hideTopBar = false,
  ...props
}) {
  const navigate = useNavigate();

  const Button = button || ToggleButton;
  const theme = useTheme();
  const largeDisplay = theme.breakpoints.up("md");
  const drawerRef = useRef(null);
  const tenantParams = useTenantParameters();
  useOutsideAlerter(drawerRef, onClickOutside, open);

  return (
    <>
      {!hideMobileButton && (
        <MobileNavToggleButton
          size="large"
          sx={{
            zIndex: 4,
            color: "menu.icon",
            display: "flex",
            marginTop: "-12px",
          }}
          open={open}
          onClick={onClick}
        >
          <MenuIcon sx={{}} />
        </MobileNavToggleButton>
      )}
      <Wrapper
        sx={{
          zIndex: 3,
          borderRadius: "0",
          overflow: "hidden",
          ...sx,
        }}
        ref={drawerRef}
        open={open}
        anchor={anchor}
        width={width}
        closedwidth={closedWidth}
        {...props}
      >
        {!disableButton && (
          <Box
            className="hidden mq-600:flex"
            justifyContent={open ? "space-between" : "center"}
            paddingY="6px"
            paddingX="6px"
            height="80px"
          >
            {open && title}
            <Button
              open={open}
              onClick={onClick}
              sx={{ marginY: "auto" }}
              {...ButtonProps}
            />
          </Box>
        )}
        <Box
          sx={{
            height: "60px",
            display: hideTopBar ? "none" : { xs: "flex", sm: "none" },
          }}
        >
          <Box
            sx={{
              objectFit: "contain",
              width: "50%",
              height: 40,
              marginTop: "10px",
              marginLeft: "10px",
              marginRight: "15px",
              [largeDisplay]: {
                width: 160,
                height: 50,
                margin: "auto",
              },
            }}
            component="img"
            src={`${baseUrl}/img/${tenantParams?.data?.LOGOMARCA}`}
            alt="Logo da plataforma"
            onClick={onClick}
          />
          {logoAdicional ? (
            <Box
              sx={{
                width: "80%",
                height: 40,
                marginTop: "10px",
                marginLeft: "0px",
                marginRight: "15px",
                objectFit: "contain",

                "&:hover": { cursor: "pointer" },
                [largeDisplay]: {
                  width: 160,
                  height: 50,
                  margin: "auto",
                },
              }}
              component="img"
              src={`${logoAdicionalUrl}/${logoAdicional}`}
              alt="Logo da plataforma"
              onClick={() => {
                navigate("/");
              }}
            />
          ) : null}
        </Box>
        {!disableButton && (
          <Divider
            sx={{
              backgroundColor: "menu.background",
              borderBottomWidth: "5px",
            }}
          />
        )}
        <Box
          sx={{
            overflowY: "auto",
            overflowX: "hidden",
            height: disableButton
              ? hideTopBar
                ? "calc(100vh - 80px)"
                : "calc(100vh - 80px)"
              : "calc(100vh - 160px)",
          }}
        >
          {children}
        </Box>
      </Wrapper>
    </>
  );
}

export default Drawer;
