import { getClient } from "../../config/urlConfig";

const baseUrlMiddleware = "https://middleware.cadernovirtual.com.br";
const baseUrlInf = "https://cadernovirtual.inf.br";

const domain = getClient();

const api = {
  getProfessorId: (userName) =>
    `${baseUrlInf}/professor-service/professor/?username=${userName}`,
  professorAttachments: (professorId) =>
    `${baseUrlInf}/courses-service/resource?professorId=${professorId}`,
  //professorAttachments: (professorId) =>
  //    `${baseUrlMiddleware}/Backend.asmx/BK_Lista_Recursos_Professor?professorid=${professorId}`,
  inserirRecurso: `${baseUrlInf}/courses-service/resource`,
  removerRecurso: (recursoId) =>
    `${baseUrlMiddleware}/Backend.asmx/BK_Excluir_Recurso?recursoid=${recursoId}`,
};

const INITIAL_VALUE = {
  attachmentList: [],
  status: {},
};

export const types = {
  LIST_ALL: "LIST_ALL",
  SET: "SET",
  REMOVE: "REMOVE",
  ADD: "ADD",
  STATUS: "ATTACHMENT_STATUS",
};

const getProfessorId = async (userName, token) => {
  try {
    const response = await fetch(api.getProfessorId(userName), {
      method: "GET",
      headers: { "Access-Control-Allow-Origin": "*", token, domain },
    });

    const json = await response.json();

    return json.object[0].id;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const listAll = (userName, token, type = null) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.STATUS,
        payload: { key: types.LIST_ALL, value: "loading" },
      });

      const professorId = await getProfessorId(userName, token);

      if (professorId === null) {
        throw "Id de professor não encontrado";
      }

      const response = await fetch(
        api.professorAttachments(professorId) + (!!type ? `&type=${type}` : ""),
        {
          method: "GET",
          headers: { "Access-Control-Allow-Origin": "*", token, domain },
        }
      );

      const json = await response.json();
      dispatch({
        type: types.LIST_ALL,
        payload: json.object,
      });
      dispatch({
        type: types.STATUS,
        payload: { key: types.LIST_ALL, value: "success" },
      });
    } catch (error) {
      console.log("error", error);
      dispatch({
        type: types.STATUS,
        payload: { key: types.LIST_ALL, value: "error" },
      });
    }
  };
};

export const setAttachment = (callBack = (prev) => prev) => {
  return (dispatch, getState) => {
    const newPayload = callBack([...getState().attachment.attachmentList]);
    dispatch({ type: types.SET, payload: newPayload });
  };
};

export const removeAttachment = (attachmentId, token) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: types.STATUS,
        payload: { key: types.REMOVE, value: "loading" },
      });
      const response = await fetch(api.removerRecurso(attachmentId), {
        method: "GET",
        headers: { token },
      });

      const json = await response.json();
      if (json[0].status === 200) {
        dispatch({
          type: types.LIST_ALL,
          payload: getState().attachment.attachmentList.filter((attachment) => {
            return attachment.resourceId != attachmentId;
          }),
        });
      }
      dispatch({
        type: types.STATUS,
        payload: { key: types.REMOVE, value: "success" },
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: types.STATUS,
        payload: { key: types.REMOVE, value: "error" },
      });
    }
  };
};

export const addAttachment = (
  userName,
  name,
  tags,
  file,
  token,
  professorId
) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.STATUS,
        payload: { key: types.ADD, value: "loading" },
      });

      const lastLinkWord = file.name.split(".").pop();
      const attachmentExtension =
        lastLinkWord.length <= 4 ? lastLinkWord : "url";

      const payload = {
        chapterId: 0,
        name,
        extension: attachmentExtension,
        teacherId: professorId,
        tags: tags.join(";"),
      };

      const contentBlob = new Blob([`${JSON.stringify(payload)}`], {
        type: "application/json",
      });

      const fileBlob = new Blob([file], { type: file?.type });

      const body = new FormData();
      body.append("resource", contentBlob);
      body.append("file", fileBlob, file?.name);

      const response = await fetch(api.inserirRecurso, {
        method: "POST",
        headers: { "Access-Control-Allow-Origin": "*", token, domain },
        body: body,
      });

      const json = await response.json();

      if (json.status >= 200 && json.status < 300) {
        const addedResource = json.object;

        const newResource = {
          ...addedResource,
          RECURSOID: addedResource.resourceId,
          CAPITULOID: addedResource.chapterId,
          NOME: addedResource.name,
          LINK: addedResource.link,
          EXTENSAO: addedResource.extension,
          recursoid_original: addedResource.originalResourceId,
          TAGS: addedResource.tags,
          PROFESSORID: addedResource.teacherId,
          nome_professor: userName,
        };

        dispatch({ type: types.ADD, payload: newResource });
        dispatch({
          type: types.STATUS,
          payload: { key: types.ADD, value: "success" },
        });
        return addedResource;
      } else {
        throw "Erro na requisição";
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: types.STATUS,
        payload: { key: types.ADD, value: "error" },
      });
    }
  };
};

export default function reducer(state = INITIAL_VALUE, action) {
  switch (action.type) {
    case types.LIST_ALL:
    case types.SET:
    case types.REMOVE:
      return { ...state, attachmentList: action.payload };
    case types.ADD:
      return {
        ...state,
        attachmentList: [...state.attachmentList, action.payload],
      };
    case types.STATUS:
      const newStatus = { ...state.status };
      const { key, value } = action.payload;

      newStatus[key] = value;

      return { ...state, status: newStatus };
    default:
      return state;
  }
}
