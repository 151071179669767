import { TextField, MenuItem, Button, Box } from "@mui/material";
import Form from "../../components/Form";
import * as yup from "yup";
let certificatesListSchema = yup.object({
  ids: yup
    .array()
    .transform((str) => str.toString().split(",").map((value) => parseInt(value)))
    .of(yup.number()),
  courseType: yup.string().required().oneOf(["proprio", "convenio"]),
});

export default function CertificatesListForm({ onSubmit, defaultValues }) {
  return (
    <Form
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      schema={certificatesListSchema}
      children={({ register, formState, values }) => {
        return (
          <Box
            sx={{ display: "flex", flexDirection: "column", rowGap: "12px" }}
          >
            <TextField
              {...register("ids")}
              label="Matrículas"
              helperText="As matrículas devem ser separadas por vírgula e sem espaço"
            />
            <TextField
              inputProps={{ ...register("courseType") }}
              label="Tipo de curso"
              defaultValue={defaultValues.courseType}
              select
            >
              <MenuItem value={"proprio"}>Curso próprio</MenuItem>
              <MenuItem value={"convenio"}>Convênio</MenuItem>
            </TextField>
            <Button disabled={!formState.isValid} type="submit">
              Procurar certificados
            </Button>
          </Box>
        );
      }}
    />
  );
}
