import { Autocomplete, TextField } from "@mui/material";
import { cleanHTML } from "../../../../../../shared/utils/cleanHTML";

const QuestionComponent = ({ onChange, listOptions, ...props }) => {
  const options = listOptions[0].optionDescription.split(",");

  return (
    <Autocomplete
      sx={{ marginTop: "10px", minWidth: "200px" }}
      options={options}
      renderInput={(params) => <TextField {...params} label="Respostas" />}
      onChange={(event) => {
        onChange(event.target.innerText);
      }}
    />
  );
};

const EditionComponent = ({
  listOptions,
  canEditText,
  onAddChoice,
  label,
  onChangeChoices,
}) => {
  return (
    <>
      <TextField
        multiline
        maxRows={4}
        sx={{ marginTop: "5px" }}
        value={cleanHTML(listOptions[0]?.optionDescription)}
        onChange={(event) =>
          onChangeChoices(0, { optionDescription: event.target.value })
        }
        helperText="* As opções são separdas por vírgula "
      ></TextField>
    </>
  );
};

export const AutocompleteList = ({ inEditing, ...rest }) =>
  inEditing ? <EditionComponent {...rest} /> : <QuestionComponent {...rest} />;
