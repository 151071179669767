import ReactQuill from "react-quill";

export default function RichTextInput({
  value,
  onChange,
  modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote", "code-block"],
      [{ color: [] }, { background: [] }],
      [{ align: [] }],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
    ],
  },
  formats = [
    "header",
    "align",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "code-block",

    "color",
    "background",

    "list",
    "indent",

    "bullet",

    "link",
    "image",
  ],
  ...rest
}) {
  return (
    <ReactQuill
      defaultValue={""}
      value={value}
      onChange={onChange}
      formats={formats}
      modules={modules}
      {...rest}
    />
  );
}
