import { useRef, useState, useCallback } from "react";
import { useDrag, useDrop } from "react-dnd";

export default function DndItemProvider({
  id,
  index,
  children,
  setItems = () => {},
  onDropItem = () => {},
}) {
  const ref = useRef(null);
  const moveCard = useCallback((newIndex, oldIndex) => {
    setItems((prevCards) => {
      const newCards = structuredClone(prevCards);
      const element = newCards[oldIndex];
      newCards.splice(oldIndex, 1);
      newCards.splice(newIndex, 0, element);
      return newCards;
    });
  });

  const [collected, drag, preview] = useDrag(() => ({
    type: "card",
  }));
  const [{ handlerId }, drop] = useDrop({
    accept: "card",
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = structuredClone(index);

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.originalIndex =
        item.originalIndex == undefined ? index : item.originalIndex;
      item.index = hoverIndex;
      item.id = id;
    },
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop(item, monitor) {
      moveCard(item.index, item.originalIndex);
      onDropItem(item.index, item.originalIndex, item);
    },
  });

  return <>{children({ preview, drag, drop, ref })}</>;
}
