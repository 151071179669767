import MuiMenu from "@mui/material/Menu";

function Menu({ children, ...props }) {
  return (
    <MuiMenu
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    >
      {children}
    </MuiMenu>
  );
}

export default Menu;