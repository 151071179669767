import { api } from "../api";

// actions
const EXTRAS_DISCIPLINAS_EXTRAS = "EXTRAS_DISCIPLINAS_EXTRAS";
const STATUS = "STATUS";

// action creators
export const disciplinasExtras = (alunoid, token) => {
  return async (dispatch) => {
    try {
      dispatch({ type: STATUS, status: "loading" });

      const res = await fetch(`${api.disciplinas_extras}?alunoid=${alunoid}`, {
        method: "get",
        headers: { token },
      });

      const resConvenio = await fetch(
        `${api.disciplinas_extras_convenio}?alunoid=${alunoid}`,
        {
          method: "get",
          headers: { token },
        }
      );

      // const resLoja = await fetch(
      //   `${api.disciplinas_loja}?alunoid=${alunoid}`,
      //   {
      //     method: "get",
      //     headers: { token },
      //   }
      // );

      if (
        res.status < 200 ||
        res.status >= 300 ||
        resConvenio.status < 200 ||
        resConvenio.status >= 300
      ) {
        throw new Error(res.statusText);
      }

      const json = await res.json();
      const jsonConvenio = await resConvenio.json();
    //  const jsonLoja = await resLoja.json();

      const extras = json[0].data || [];
      const extrasConvenio =
        jsonConvenio[0].data?.map((item) => {
          item["convenio"] = true;
          return item;
        }) || [];
      // const extrasLoja =
      //   jsonLoja[0].data?.map((item) => {
      //     item["loja"] = true;
      //     return item;
      //   }) || [];

      dispatch({
        type: EXTRAS_DISCIPLINAS_EXTRAS,
        extras: [...extras, ...extrasConvenio, /* ...extrasLoja */],
      });

      dispatch({ type: STATUS, status: "success" });
    } catch (error) {
      dispatch({ type: STATUS, status: "error" });
      throw new Error(error);
    }
  };
};

// reducer
export default function reducer(state = {}, action) {
  switch (action.type) {
    case EXTRAS_DISCIPLINAS_EXTRAS:
      return { ...state, items: action.extras };
    case STATUS:
      return { ...state, status: action.status };
    default:
      return state;
  }
}
