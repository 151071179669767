import { useMutation } from "@tanstack/react-query";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import _cookies from "../../utils/_cookies";
import OneSignal from "react-onesignal";
import { useTenantParameters } from "../../../features/misc/api/getTenantParameters";
import { setOneSignalOptedIn } from "../../store/OneSignalStore";
import { createNotification } from "../../store/notifications";

export const useOneSignalInit = ({ config } = {}) => {
  return useMutation({
    ...config,
    mutationFn: OneSignal.init,
    mutationKey: ["OneSignalInit"],
  });
};

export const useOneSignalLogin = ({ config } = {}) => {
  const dispatch = useDispatch();
  return useMutation({
    ...config,
    mutationFn: () =>
      OneSignal.login(btoa(_cookies.getItem("auth")?.user?.alunoid)),
    mutationKey: ["OneSignalLogin"],
    onSuccess: () => {
      dispatch(setOneSignalOptedIn(OneSignal.User.PushSubscription.optedIn));
    },
  });
};

export const useOneSignalOptIn = ({ config } = {}) => {
  const dispatch = useDispatch();
  return useMutation({
    ...config,
    mutationFn: OneSignal.User.PushSubscription.optIn,
    mutationKey: ["OneSignalOptIn"],
    onSuccess: () => {
      dispatch(setOneSignalOptedIn(true));
      createNotification({ type: "info", message: "Notificações ativadas" });
    },
  });
};

export const useOneSignalOptOut = ({ config } = {}) => {
  const dispatch = useDispatch();
  return useMutation({
    ...config,
    mutationFn: OneSignal.User.PushSubscription.optOut,
    mutationKey: ["OneSignalOptOut"],
    onSuccess: () => {
      dispatch(setOneSignalOptedIn(false));
      createNotification({ type: "info", message: "Notificações desativadas" });
    },
  });
};

export const useOneSignalAutoLogin = () => {
  const auth = useSelector((state) => state?.auth);
  const dispatch = useDispatch();
  const tenantParams = useTenantParameters();
  const OneSignalInit = useOneSignalInit();
  const OneSignalLogin = useOneSignalLogin();

  OneSignal.User.PushSubscription.addEventListener("change", (change) => {
    console.log("One Signal Push Subscription change", change);
    dispatch(setOneSignalOptedIn(change.current.optedIn));
  });

  const isUserAuthenticated = useMemo(() => {
    return !!auth?.token;
  }, [auth?.token]);

  return useEffect(async () => {
    if (
      typeof window !== "undefined" &&
      isUserAuthenticated &&
      !!tenantParams.data?.Onesignal_AppID
    ) {
      OneSignalInit.mutate(
        {
          appId: tenantParams.data.Onesignal_AppID,
        },
        {
          onSettled: () => {
            OneSignalLogin.mutate(null, {
              onSuccess: () => {
                if (!OneSignal.User.PushSubscription.optedIn) {
                  OneSignal.Slidedown.promptPush();
                }
              },
            });
          },
        }
      );
    }
  }, [isUserAuthenticated, tenantParams.isSuccess]);
};

export const OneSignalProvider = ({ children }) => {
  useOneSignalAutoLogin();
  return <>{children}</>;
};
