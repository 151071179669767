import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import {
  Box,
  Stack,
  Stepper,
  Step,
  StepLabel,
  Button,
  Divider,
  Typography,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogActions,
  IconButton,
  DialogContent,
} from "@mui/material";
import {
  Settings,
  GroupAdd,
  VideoLabel,
  ArrowBackIos,
  DoneOutline,
  ArrowForwardIos,
  DoDisturb,
  ReportProblem,
  Close,
} from "@mui/icons-material";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { CurrencyMnt } from "mdi-material-ui";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: `linear-gradient( 95deg,${theme.palette.primary.dark} 0%,${theme.palette.primary.main} 50%,${theme.palette.primary.light} 100%)`,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: `linear-gradient( 95deg,${theme.palette.primary.dark} 0%,${theme.palette.primary.main} 50%,${theme.palette.primary.light} 100%)`,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage: `linear-gradient( 136deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 50%, ${theme.palette.primary.dark} 100%)`,
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage: `linear-gradient( 136deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 50%, ${theme.palette.primary.dark} 100%)`,
  }),
}));

function ColorlibStepIcon(props, icon) {
  const { active, completed, className } = props;

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icon}
    </ColorlibStepIconRoot>
  );
}

export default function Wizard({ steps = [], currentStepIndex = 0, children }) {
  const currentStep = steps[currentStepIndex];
  const [dirtyStepWarning, setDirtyStepWarning] = useState({
    open: false,
    direction: "none",
  });
  const handleNextStepButtonClick = () => {
    if (currentStep.isDirty && currentStep.openWarningOnNextStep) {
      setDirtyStepWarning({ open: true, direction: "next" });
    } else {
      currentStep.onNextStep();
    }
  };
  const handlePreviousStepButtonClick = () => {
    if (currentStep.isDirty && currentStep.openWarningOnPreviousStep) {
      setDirtyStepWarning({ open: true, direction: "previous" });
    } else {
      currentStep.onPreviousStep();
    }
  };
  const isLastStep = currentStepIndex + 1 == steps.length;
  const isFirstStep = currentStepIndex == 0;
  return (
    <>
      <Dialog
        onClose={() => {
          setDirtyStepWarning({
            open: false,
            direction: "none",
          });
        }}
        open={dirtyStepWarning.open}
      >
        <IconButton
          sx={{ position: "absolute", top: 3, right: 3 }}
          onClick={() => {
            setDirtyStepWarning({
              open: false,
              direction: "none",
            });
          }}
        >
          <Close />
        </IconButton>
        <DialogTitle>Aviso</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Você possui alterações não salvas. Deseja continuar mesmo assim?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() =>
              setDirtyStepWarning({
                open: false,
                direction: "none",
              })
            }
          >
            Cancelar
          </Button>
          <Button
            onClick={() => {
              if (dirtyStepWarning.direction == "next") {
                currentStep.onNextStep();
              }
              if (dirtyStepWarning.direction == "previous") {
                currentStep.onPreviousStep();
              }
              currentStep?.setIsDirty(false);
              setDirtyStepWarning({
                open: false,
                direction: "none",
              });
            }}
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
      <Stack
        sx={{ width: "100%", height: "100%", overflow: "auto" }}
        spacing={1}
      >
        <Stepper
          alternativeLabel
          activeStep={currentStepIndex}
          connector={<ColorlibConnector />}
        >
          {steps.map(({ isDirty, label, icon }, index) => (
            <Step key={label}>
              <StepLabel
                StepIconComponent={(props) =>
                  ColorlibStepIcon(props, isDirty ? <ReportProblem /> : icon)
                }
              >
                {label}
                {isDirty && (
                  <Typography fontSize={"9px"} color="error">
                    Alterações não salvas
                  </Typography>
                )}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box sx={{ width: "100%", display: "flex" }}>
          {!currentStep.hidePreviousStepButton && (
            <Button
              disabled={
                currentStep.previousStepDisabled ||
                currentStep.nextStepIsLoading
              }
              onClick={handlePreviousStepButtonClick}
              startIcon={isFirstStep ? <DoDisturb /> : <ArrowBackIos />}
            >
              {currentStep.previousStepButtonLabel || "Voltar"}
            </Button>
          )}
          {!currentStep.hideNextStepButton && (
            <LoadingButton
              loading={currentStep.nextStepIsLoading}
              disabled={currentStep.nextStepDisabled}
              onClick={handleNextStepButtonClick}
              sx={{ marginLeft: "auto" }}
              endIcon={isLastStep ? <DoneOutline /> : <ArrowForwardIos />}
            >
              {currentStep.nextStepButtonLabel || isLastStep
                ? "Concluir"
                : "Próximo"}
            </LoadingButton>
          )}
        </Box>
        <Divider />
        <Stack sx={{ height: "100%" }} spacing={2}>
          {children}
        </Stack>
      </Stack>
    </>
  );
}
