import { Outlet, useNavigate, useLocation } from "react-router-dom";
import MuiModal from "@mui/material/Modal";
import { createContext } from "react";

export const ModalPageContext = createContext();

function ModalPageContextProvider({ children, ...props }) {
  return <ModalPageContext.Provider value={{...props}}>{children}</ModalPageContext.Provider>;
}

function Modal({ ...props }) {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClose = () => {
    navigate(location.state?.backgroundLocation, {
      replace: true,
    });
  };

  return (
    <MuiModal
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      open={true}
      onClose={handleClose}
    >
      <ModalPageContextProvider closeModal={handleClose}>
        <Outlet />
      </ModalPageContextProvider>
    </MuiModal>
  );
}

export default Modal;
