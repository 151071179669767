import axios from "axios";
import { courseApi } from "../../api/courseApi";
import { getClient } from "../../config/urlConfig";
const SEARCH_RESULTS = "SEARCH_RESULTS";
const SEARCHED_TERM = "SEARCHED_TERM";
const SET_LOADING = "setLoading";
const CLEAR_LOADING = "clearLoading";
const domain = getClient();

export const searchQuery = (token, alunoid, string) => {
  if (!string) return;
  return async (dispatch) => {
    dispatch({ type: SEARCHED_TERM, string });
    dispatch({ type: SET_LOADING});
    var apiUrl = `${courseApi.search.url}?studentId=${alunoid}&text=${encodeURI(
      string
    )}`;
    try {
      const { data, status, statusText } = await axios.get(apiUrl, {
        headers: { token, "Access-Control-Allow-Origin": "*", domain },
      });
      if (status >= 200 && status < 300) {
        const searchResults = data.object;
        dispatch({ type: SEARCH_RESULTS, searchResults });
        dispatch({ type: CLEAR_LOADING});
        return searchResults;
      } else {
        dispatch({ type: CLEAR_LOADING});
        throw new Error(statusText);
      }
    } catch (error) {
      dispatch({ type: CLEAR_LOADING});
      console.error(error);
      throw new Error(error);
    }
  };
};

export default function reducer(state = {}, action) {
  switch (action.type) {
    case SEARCH_RESULTS:
      return { ...state, searchResults: action.searchResults };
    case SEARCHED_TERM:
      return { ...state, searchedTerm: action.string };
      case SET_LOADING:
        return {
          ...state,
          isLoading: true,
        };
      case CLEAR_LOADING:
        return {
          ...state,
          isLoading: false,
        };
    default:
      return state;
  }
}
