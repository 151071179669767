import { Rating } from "@mui/material";
import { useContext } from "react";
import { QuestionContext } from "./Question";

export function TenStarsRating() {
  const { disabledAnswer } = useContext(QuestionContext);
  return (
    <Rating
      disabled={disabledAnswer}
      sx={{
        padding: "0.25rem",
        borderRadius: "10px",
        backgroundColor: "gray",
        "& .MuiRating-iconEmpty": {
          color: "white",
        },
      }}
      size="large"
      max={10}
    />
  );
}

export function FiveStarsRating() {
  const { disabledAnswer } = useContext(QuestionContext);
  return (
    <Rating
      disabled={disabledAnswer}
      sx={{
        padding: "0.25rem",
        borderRadius: "10px",
        backgroundColor: "gray",
        "& .MuiRating-iconEmpty": {
          color: "white",
        },
      }}
      size="large"
    />
  );
}
