import { useNavigate, useParams } from "react-router-dom";
import Survey from "../../../ui/pages/SurveyPage/Survey";
import { Container, Box, Paper, useTheme } from "@mui/material";

export default function PublicSurveyPage({}) {
  const params = useParams();
  const theme = useTheme();
  const navigate = useNavigate("./login");
  const handleFinish = () => {
    navigate("/login");
  };
  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",

        backgroundColor: theme.palette.grey["200"],
      }}
    >
      <Container sx={{ height: "100%", paddingY: 2 }}>
        <Paper sx={{ height: "100%", overflow: "auto" }}>
          <Survey surveyId={params.id} onFinishAttemptSuccess={handleFinish} />
        </Paper>
      </Container>
    </Box>
  );
}
