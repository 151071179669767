import { useContext } from "react";
import { QuestionContext } from "./Question";
import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import { cleanHTML } from "../../../shared/utils/cleanHTML";

export default function AutoCompleteList() {
  const { isEditing, question, setQuestion, disabledAnswer } =
    useContext(QuestionContext);
  const options = question?.listOptions[0]?.optionDescription?.split(",");
  return (
    <>
      {isEditing ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography fontSize={"9px"} variant="caption">
            Possíveis respostas separadas vírgula
          </Typography>
          <TextField
            multiline
            rows={3}
            value={cleanHTML(question?.listOptions[0]?.optionDescription)}
            onChange={(event) =>
              setQuestion((prev) => {
                var foo = prev.listOptions;
                foo[0] = {
                  ...prev.listOptions[0],
                  optionDescription: event.target.value,
                };
                return { ...prev, foo };
              })
            }
          />
        </Box>
      ) : (
        <Autocomplete
          disabled={disabledAnswer}
          sx={{ marginTop: "10px", minWidth: "200px" }}
          options={options}
          renderInput={(params) => <TextField {...params} label="Respostas" />}
        />
      )}
    </>
  );
}
