import {
  Box,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Divider,
  TextField,
  MenuItem,
  CircularProgress,
  useTheme,
  useMediaQuery,
  ButtonGroup,
  Button,
  FormControl,
  InputBase,
  InputLabel,
} from "@mui/material";
import { useState } from "react";
import { ReactComponent as HandleIcon } from "../../../shared/assets/svg/icons/handle-icon.svg";
import DndItemProvider from "../../../ui/components/DndItemProvider";
import { useTrackCourses } from "../api/getTrackCourses";
import { useSetCourseOnTrack } from "../api/setCourseOnTrack";
import { ArrowDown, ArrowUp } from "mdi-material-ui";

export default function EditTrackCoursesOrder({ trackId, onSuccess }) {
  const [courses, setCourses] = useState([]);
  const trackCourses = useTrackCourses({
    trackId,
    config: {
      onSuccess: (data) => {
        setCourses(data);
      },
    },
  });
  const setCourseOnTrack = useSetCourseOnTrack({ trackId });


  const handleDropItem = (course) => (newIndex, originalIndex, item) => {

    setCourseOnTrack.mutate({
      id: courses[originalIndex].id,
      courseId: courses[originalIndex].courseId,
      trackId,
      orderID: newIndex,
    });
  };

  const handleMoveUpItem = (course) => (newIndex, originalIndex, item) => {
    
    setCourseOnTrack.mutate({
      id: courses[originalIndex].id,
      courseId: courses[originalIndex].courseId,
      trackId,
      orderID: courses[originalIndex].orderID - 1,
    });
  };
  const handleMoveDownItem = (course) => {
    
    setCourseOnTrack.mutate({
      id: course.id,
      courseId: course.courseId,
      trackId,
      orderID: course.orderID + 1,
    });
  };

  const theme = useTheme();
  const isDisplaySmall = useMediaQuery(theme.breakpoints.down("md"));

  return trackCourses.isLoading || setCourseOnTrack.isLoading || false ? (
    <CircularProgress />
  ) : (
    <>
      {trackCourses.isFetching ? (
        <Box
          sx={{
            p: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <FormControl
          sx={{
            border: `1px solid #e5e7eb`,
            borderRadius: 1,
            marginTop: "5px",
            "&:hover": { border: "1px solid black" },
          }}
        >
          <InputLabel
             sx={{ transform: "translate(10px, -14px) scale(0.75)" }}
            shrink
            htmlFor="dndInput"
          >
            Cursos em ordem
          </InputLabel>
          <List id="dndInput" sx={{ maxHeight: "300px", overflow: "auto" }}>
            {courses.map((_course, index) => {
              return (
                <>
                  <DndItemProvider
                    key={_course.cursoId}
                    index={index}
                    id={_course.cursoId}
                    onDropItem={handleDropItem(_course)}
                    // setItems={setCourses}
                  >
                    {(dnd) => {
                      dnd.drop(dnd.ref);
                      dnd.preview(dnd.ref);
                      return (
                        <ListItem
                          sx={{ paddingY: 2 }}
                          ref={dnd.ref}
                          secondaryAction={
                            !isDisplaySmall ? (
                              <IconButton ref={dnd.drag}>
                                <HandleIcon />
                              </IconButton>
                            ) : (
                              <ButtonGroup size="small" orientation="vertical">
                                <Button onClick={handleMoveUpItem}>
                                  <ArrowUp />
                                </Button>
                                <Button onClick={handleMoveDownItem}>
                                  <ArrowDown />
                                </Button>
                              </ButtonGroup>
                            )
                          }
                        >
                          <ListItemText
                            primary={_course.courseName || _course.nome}
                          />
                        </ListItem>
                      );
                    }}
                  </DndItemProvider>
                  <Divider />
                </>
              );
            })}
          </List>
        </FormControl>
      )}
    </>
  );
}
