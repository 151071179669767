import { getClient } from "../config";

const CLIENTS_WITH_INTEGRATED_LOGIN = [
  {
    clientName: "UNIVERSIDADESETE",
    link: "https://cadernovirtual.inf.br/auth-integrado-sete/dologin.jsp?perfil=Aluno",
  },
  {
    clientName: "TEMPLATE",
    link: "https://cadernovirtual.inf.br/auth-piracanjuba/dologin.jsp?perfil=Aluno",
  },
  {
    clientName: "PIRACANJUBA",
    link: "https://cadernovirtual.inf.br/auth-piracanjuba/dologin.jsp?perfil=Aluno",
  },
];

export const CLIENT_HAS_INTEGRATED_LOGIN = () =>
  CLIENTS_WITH_INTEGRATED_LOGIN.some((obj) => {
    return obj.clientName == getClient().toUpperCase();
  });

export const CLIENT_INTEGRATED_LOGIN_LINK = () =>
  CLIENTS_WITH_INTEGRATED_LOGIN.find(
    (obj) => obj.clientName == getClient().toUpperCase()
  ).link;
