import { api } from "../../api";

// actions
const COMMENTS_COMENTARIOS_IDEIAS = "COMMENTS_COMENTARIOS_IDEIAS";
const COMMENTS_INSERIR_COMENTARIO = "COMMENTS_INSERIR_COMENTARIO";
const COMMENTS_ALTERAR_COMENTARIO = "COMMENTS_ALTERAR_COMENTARIO";
const CLEAN_COMMENTS = "CLEAN_COMMENTS";

export const cleanComments = () => {
  return async (dispatch) => {
    dispatch({ type: COMMENTS_COMENTARIOS_IDEIAS, items: null });
  };
};

// action creators
export const comentariosIdeias = (propostaid, token) => {
  return async (dispatch) => {
    const apiUrl = api.lista_comentarios_ideia;
    try {
      const res = await fetch(`${apiUrl}?propostaid=${propostaid}`, {
        method: "get",
        headers: { token },
      });
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        dispatch({ type: COMMENTS_COMENTARIOS_IDEIAS, items: json[0].data });
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      dispatch({ type: COMMENTS_COMENTARIOS_IDEIAS, items: null });
      throw new Error(error);
    }
  };
};

export const inserirComentario = (body, token) => {
  return async (dispatch) => {
    try {
      const res = await fetch(api.inseri_comentario_ideia, {
        method: "post",
        headers: { token },
        body,
      });

      if (res.status >= 200 && res.status < 300) {
        // const json = await res.json();
        dispatch({ type: COMMENTS_INSERIR_COMENTARIO });
        return "success";
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      dispatch({ type: COMMENTS_INSERIR_COMENTARIO });
      return "error";
    }
  };
};
export const alterarComentario = (body, token) => {
  return async (dispatch) => {
    try {
      const res = await fetch(api.altera_comentario_ideia, {
        method: "post",
        headers: { token },
        body,
      });
      if (res.status >= 200 && res.status < 300) {
        // const json = await res.json();
        dispatch({ type: COMMENTS_ALTERAR_COMENTARIO });
        return "success";
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      return "error";
    }
  };
};

// reducer
export default function reducer(state = {}, action) {
  switch (action.type) {
    case COMMENTS_COMENTARIOS_IDEIAS:
      return { ...state, items: action.items };
    case CLEAN_COMMENTS:
      return { ...state, items: action.items };
    default:
      return state;
  }
}
