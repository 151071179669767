import { createContext, useEffect, useReducer } from "react";
import { useSearchParams } from "react-router-dom";

export const ManagerCatalogueContext = createContext();

const serialize = (state) => {
  const params = new URLSearchParams();
  console.log(state.groupFilter);
  params.set("grupo", "AAAAA"); // Comma-separated list
  params.set("situacao", state.statusFilter); // Individual values
  params.set("nome", state.nameFilter);
  params.set("avaliacao", state.ratingFilter);
  params.set("pagina", state.currentPage);
  params.set("tamanhoPagina", state.itemsPerPage);

  return params;
};

const parse = (params) => {
  const obj = {
    groupFilter: params.get("groupFilter"),
    statusFilter: params.get("statusFilter"),
    nameFilter: params.get("nameFilter"),
    ratingFilter: Number(params.get("ratingFilter")),
    currentPage: Number(params.get("currentPage")) || 0,
    itemsPerPage: Number(params.get("itemsPerPage")) || 20,
  };
  for (let key in obj) {
    if (obj[key] == undefined) {
      delete obj[key];
    }
  }
  return obj;
};

function ManagerCatalogueProvider({ children, ...props }) {
  const [searchState, setSearchParams] = useSearchParams(
    {
      groupFilter: undefined,
      statusFilter: undefined,
      nameFilter: undefined,
      ratingFilter: undefined,
      currentPage: 0,
      itemsPerPage: 20,
    },
    { serialize, parse }
  );

  function reducer(state, action) {
    switch (action.type) {
      case "openDialog": {
        return { ...state, dialogOpen: true };
      }
      case "closeDialog": {
        return { ...state, selectedGroups: [], dialogOpen: false };
      }
      case "selectedItemData": {
        return {
          ...state,
          dialogOpen: true,
          selectedItemData: action.payload,
          dialogComponent: action.component,
        };
      }
      case "setRatingFilter": {
        setSearchParams((prev) => ({
          ...parse(prev),
          ratingFilter: action.payload,
        }));
        return state;
      }
      case "setGroupFilter": {
        setSearchParams((prev) => {
          return {
            ...parse(prev),
            groupFilter: action.payload,
          };
        });
        return state;
      }
      case "setStatusFilter": {
        setSearchParams((prev) => {
          return { ...parse(prev), statusFilter: action.payload };
        });
        return state;
      }
      case "setNameFilter": {
        setSearchParams((prev) => ({ ...parse(prev), nameFilter: action.payload }));
        return state;
      }

      case "setCurrentPage": {
        setSearchParams((prev) => ({
          ...parse(prev),
          currentPage: action.payload,
        }));
        return state;
      }
      case "setItemsPerPage": {
        setSearchParams((prev) => ({
          ...parse(prev),
          itemsPerPage: action.payload,
        }));
        return state;
      }
      case "setMobileActionsDrawerOpen": {
        return {
          ...state,
          mobileActionsDrawerOpen: action.payload,
        };
      }

      case "setSupliers": {
        const temp = action.payload
          .map((obj) => {
            return obj.descricao_portal;
          })
          .filter((value, index, arr) => {
            return arr.indexOf(value) === index;
          });
        return { ...state, supliers: [...temp] };
      }

      default: {
        return {
          ...state,
        };
      }
    }
  }
  const [state, dispatch] = useReducer(reducer, {
    dialogOpen: false,
    mobileActionsDrawerOpen: false,
    supliers: [],
    registrationType: undefined,
  });

  return (
    <ManagerCatalogueContext.Provider
      value={[state, dispatch, parse(searchState)]}
      {...props}
    >
      {children}
    </ManagerCatalogueContext.Provider>
  );
}

export default ManagerCatalogueProvider;
