import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export function createPage() {
  return new Promise((resolve, reject) => {});
}

export async function generatePDF(
  { config, title, pages, onProgress = () => {} } = {
    config: {},
    pages: [],
    onProgress: () => {},
  }
) {
  return new Promise((resolve, reject) => {
    const length = pages.length;
    const pdf = new jsPDF({
      orientation: "landscape",
      unit: "px",
      format: [900, 675],
    });
    (async () => {
      try {
        for await (const [index, page] of pages.entries()) {
          var canvas = await html2canvas(page, {
            useCORS: true,
            scale: 2,
          });
          const image = canvas.toDataURL("image/png");
          pdf.addPage();
          pdf.addImage(image, "png", 0, 0, 900, 675, "", "FAST");
          onProgress((index * 100) / length);
        }
        pdf.deletePage(1);
        pdf.save(title + ".pdf");

        resolve(pdf);
      } catch (error) {
        reject(error);
      }
    })();
  });
}

export function printPDF(pages) {
  return new Promise((resolve, reject) => {
    try {
      // let index = 0;
      // for await  (let page of pages){
      //     await html2canvas()
      // }
    } catch (errror) {}
  });
}
