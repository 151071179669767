import { api } from "../api";

// actions
const CHANNELS_CANAIS = "CHANNELS_CANAIS";
const STATUS = "STATUS";

// action creators
export const fetchCanais = (alunoid, token) => {
  return async (dispatch) => {
    try {
      dispatch({ type: STATUS, status: "loading" });
      const res = await fetch(`${api.canais}?alunoid=${alunoid}`, {
        method: "get",
        headers: { token },
      });
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        const canais = json[0].data;
        dispatch({ type: CHANNELS_CANAIS, canais });
        dispatch({ type: STATUS, status: "success" });
        return canais;
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      dispatch({ type: STATUS, status: "error" });
      throw new Error(error);
    }
  };
};
// reducer
export default function reducer(state = {}, action) {
  switch (action.type) {
    case CHANNELS_CANAIS:
      return { ...state, channelListData: action.canais };
    case STATUS:
      return { ...state, status: action.status };
    default:
      return state;
  }
}
