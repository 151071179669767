const INITIAL_VALUE = {
  list: [],
  status: "idle",
};

// actions
export const TRACKS_TRILHAS = "TRACKS_TRILHAS";
export const TRACKS_STATUS = "TRACKS_STATUS";

// reducer
export default function reducer(state = INITIAL_VALUE, action) {
  switch (action.type) {
    case TRACKS_TRILHAS:
      return { ...state, list: action.payload };
    case TRACKS_STATUS:
      return { ...state, status: action.payload };
    default:
      return state;
  }
}
