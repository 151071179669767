import {
  NavigationType,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  Box,
  CircularProgress,
  Container,
  Paper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useSurvey } from "../api/getSurvey";
import { SaveSurveyForm } from "../components/SaveSurveyForm";
import NavigationPage from "../../../ui/templates/NavigationPage";
import moment from "moment";
import InsertGroupsToSurvey from "../components/InsertGroupsToSurvey";
import EditSurveyQuestions from "../components/EditSurveyQuestions";
import { Settings, GroupAdd, VideoLabel } from "@mui/icons-material";
import { useTheme } from "@emotion/react";
import Wizard from "../../../ui/components/Wizard";
import { useEffect, useRef, useState } from "react";
import { useSaveSurvey } from "../api/saveSurvey";
import { useIsMutating } from "@tanstack/react-query";

export const EditSurvey = () => {
  const { surveyId } = useParams();
  const { data, isLoading, isError } = useSurvey({ surveyId });
  const isSurveyMutating = useIsMutating({
    mutationKey: ["saveSurvey"],
  });
  const isInsertGroupsMutating = useIsMutating({
    mutationKey: ["insertGroupsToSurvey"],
  });
  const [URLSearchParams, setSearchParams] = useSearchParams();
  const [currentStep, setCurrentStep] = useState(
    Number(URLSearchParams.get("initialStep")) || 0
  );
  const currentStepSubmitButtonRef = useRef();

  const [isCurrentStepDirty, setIsCurrentStepDirty] = useState({
    0: false,
    1: false,
    2: false,
  });

  const theme = useTheme();
  const isMobileDisplay = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  return (
    <NavigationPage>
      {isLoading || isError ? (
        <Box
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Container
          disableGutters={isMobileDisplay}
          sx={{ height: "100%" }}
          maxWidth={false}
        >
          <Paper sx={{ minHeight: "100%", paddingY: 4, paddingX: 1 }}>
            <Wizard
              currentStepIndex={currentStep}
              steps={[
                {
                  label: "Editar pesquisa",
                  icon: <Settings />,
                  onNextStep: () => {
                    isCurrentStepDirty[0]
                      ? currentStepSubmitButtonRef.current.click()
                      : setCurrentStep((prev) => prev + 1);
                  },
                  onPreviousStep: () => {
                    navigate("/pesquisa");
                  },
                  openWarningOnPreviousStep: true,
                  isDirty: isCurrentStepDirty["0"],
                  setIsDirty: (value) => {
                    setIsCurrentStepDirty((prev) => ({ ...prev, 0: value }));
                  },
                  nextStepIsLoading: isSurveyMutating > 0,
                },
                {
                  label: "Adicionar questões",
                  icon: <GroupAdd />,
                  onNextStep: () => {
                    setCurrentStep((prev) => prev + 1);
                    setIsCurrentStepDirty((prev) => ({ ...prev, 1: false }));
                  },
                  onPreviousStep: () => {
                    setCurrentStep((prev) => prev + -1);
                    setIsCurrentStepDirty((prev) => ({ ...prev, 1: false }));
                  },
                  openWarningOnNextStep: true,
                  openWarningOnPreviousStep: true,
                  isDirty: isCurrentStepDirty["1"],
                  setIsDirty: (value) => {
                    setIsCurrentStepDirty((prev) => ({ ...prev, 1: value }));
                  },
                },
                {
                  label: "Seleção de grupos",
                  icon: <VideoLabel />,
                  onNextStep: () => {
                    isCurrentStepDirty["2"]
                      ? currentStepSubmitButtonRef.current.click()
                      : navigate("/pesquisa");
                  },
                  onPreviousStep: () => {
                    setCurrentStep((prev) => prev + -1);
                  },
                  openWarningOnPreviousStep: true,
                  isDirty: isCurrentStepDirty["2"],
                  setIsDirty: (value) => {
                    setIsCurrentStepDirty((prev) => ({ ...prev, 2: value }));
                  },
                  nextStepIsLoading: isInsertGroupsMutating > 0,
                },
              ]}
            >
              {currentStep == 0 && (
                <SaveSurveyForm
                  defaultValues={{
                    ...data,
                    startDate: moment(data.startDate, "DD/MM/YYYY HH:mm:ss"),
                    endDate: moment(data.endDate, "DD/MM/YYYY HH:mm:ss"),
                    orientations: data.orientations,
                  }}
                  onDirty={(value) => {
                    setIsCurrentStepDirty((prev) => ({ ...prev, 0: value }));
                  }}
                  onSuccess={() => {
                    setCurrentStep((prev) => prev + 1);
                  }}
                  submitButtonRef={
                    currentStep == 0 ? currentStepSubmitButtonRef : null
                  }
                />
              )}
              {currentStep == 1 && (
                <EditSurveyQuestions
                  isDirty={isCurrentStepDirty["1"]}
                  onDirty={(value) => {
                    setIsCurrentStepDirty((prev) => ({ ...prev, 1: value }));
                  }}
                  surveyId={surveyId}
                />
              )}
              {currentStep == 2 && (
                <InsertGroupsToSurvey
                  submitButtonRef={
                    currentStep == 2 ? currentStepSubmitButtonRef : null
                  }
                  isDirty={isCurrentStepDirty["2"]}
                  onDirty={(value) => {
                    setIsCurrentStepDirty((prev) => ({ ...prev, 2: value }));
                  }}
                  surveyId={surveyId}
                  onSuccess={() => {
                    navigate("/pesquisa");
                  }}
                />
              )}
            </Wizard>
          </Paper>
        </Container>
      )}
    </NavigationPage>
  );
};
