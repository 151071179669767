import { styled } from "@mui/material/styles";

import MuiButton from "@mui/material/Button";

const Button = styled(MuiButton, {
	shouldForwardProp: (prop) => prop !== "color" || prop !== "variant",
})(({ theme, variant, color }) => ({
	fontWeight: 600,
	width: "100%",
	[theme.breakpoints.up(theme.breakpoints.values.sm)]: {
		width: "initial",
	},
	"&.MuiButton-root": {
		color: theme.palette.getContrastText(
			!variant || variant === "outlined"
				? theme.palette.common.white
				: theme.palette[color || "primary"].dark
		),
	},
}));

export default Button;
