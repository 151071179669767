import { useContext } from "react";
import { QuestionContext } from "./Question";
import { Delete, AddBox, AddCircleOutlineOutlined } from "@mui/icons-material";
import { Box, Button, IconButton, Radio, RadioGroup } from "@mui/material";
import RichText from "../../../ui/components/RichText";

export function Choice({ isEditing, text, value, onChangeText, onDelete }) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Radio value={value} />
      <RichText onChange={onChangeText} isEditing={isEditing} value={text} />
    </Box>
  );
}

export default function MultipleChoice({ canEditOptions }) {
  const { isEditing, question, setQuestion, disabledAnswer } =
    useContext(QuestionContext);
  const { listOptions } = question;

  return (
    <Box sx={{ display: "flex", flexDirection: "column", rowGap: 2 }}>
      <RadioGroup>
        {listOptions.map((option, index) => {
          return (
            !option.remove && (
              <Box key={index} sx={{ display: "flex", alignItems: "center" }}>
                <Radio
                  disabled={disabledAnswer}
                  sx={{ marginBottom: "10px" }}
                  value={index}
                />
                <RichText
                  isEditing={canEditOptions && isEditing}
                  value={option.optionDescription}
                  onChange={(newOptionDescriptionValue) => {
                    setQuestion((prev) => {
                      const newListOptions = prev.listOptions;
                      newListOptions[index].optionDescription =
                        newOptionDescriptionValue;
                      newListOptions[index].isDirty = true;
                      return { ...prev, listOptions: [...newListOptions] };
                    });
                  }}
                />
                {canEditOptions &&
                  isEditing &&
                  listOptions?.filter(
                    (object) =>
                      object.remove === undefined || object.remove === false
                  ).length > 2 && (
                    <IconButton
                      onClick={() => {
                        setQuestion((prev) => {
                          const newListOptions = prev.listOptions;
                          if (newListOptions[index].id != undefined) {
                            newListOptions[index].remove = true;
                          } else {
                            newListOptions.splice(index, 1);
                          }
                          return { ...prev, listOptions: [...newListOptions] };
                        });
                      }}
                    >
                      <Delete />
                    </IconButton>
                  )}
              </Box>
            )
          );
        })}
      </RadioGroup>
      {isEditing && canEditOptions && (
        <Button
          sx={{ width: "fit-content" }}
          variant="outlined"
          startIcon={<AddCircleOutlineOutlined />}
          onClick={() => {
            setQuestion((prev) => {
              const newListOptions = prev.listOptions;
              newListOptions.push({
                optionDescription: "<p>Nova opção</p>",
              });
              return { ...prev, listOptions: [...newListOptions] };
            });
          }}
        >
          {" "}
          Adicionar opção
        </Button>
      )}
    </Box>
  );
}
