import {
  Box,
  Button,
  CircularProgress,
  Typography,
  Tab,
  Tabs,
} from "@mui/material";
import { ManagerCatalogueContext } from "../../../common/context";
import { useContext, useState } from "react";
import { useGetCourseGroups } from "../../../api/getCourseGroups";
import { useSaveGroupSelection } from "../../../api/saveGroupSelection";
import TreeSelector from "../../../../../components/TreeSelector";
import { useCourse } from "../../../../../../features/courses/api/getCourse";

function CustomTabPanel(props) {
  const { children, currentTab, index, ...other } = props;

  return (
    <div
      className="grow relative pt-4"
      role="tabpanel"
      hidden={currentTab !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {currentTab === index && (
        <Box sx={{}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function SelectGroupsForm({ courseId }) {
  const [groups, setGroups] = useState([]);

  const { isLoading, isError, data } = useGetCourseGroups({
    courseId,
    config: {
      onSuccess: (data) => {
        setGroups([
          { label: "Todos", id: 0 },
          ...data.listGestor.map((item) => ({
            selected: !!data.listPrateleira.find(
              (_item) => _item.grupoid == item.grupoid
            ),
            label: item.nome,
            id: item.grupoid,
            parentId: item.filhode,
          })),
        ]);
      },
    },
  });
  const course = useCourse({ courseId });
  const saveGroupSelection = useSaveGroupSelection({
    courseId,
  });
  const handleSave = () => {
    saveGroupSelection.mutate({
      data: {
        disciplinaid: courseId,
        grupos: groups
          .filter((group) => group.selected)
          .map((selectedGroups) => selectedGroups.id)
          .join(","),
        registrationType: course.data.forma_inscricao,
      },
    });
  };
  return (
    <Box>
      {isLoading || course.isLoading || isError ? (
        <>
          <CircularProgress />
        </>
      ) : (
        <>
          <TreeSelector
            sx={{ maxHeight: "300px" }}
            list={groups}
            setList={setGroups}
            defaultExpandedItems={[0]}
          />
          <Button
            onClick={() => {
              handleSave();
            }}
          >
            Confirmar
          </Button>
        </>
      )}
    </Box>
  );
}
export default SelectGroupsForm;
