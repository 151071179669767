import { useMutation, useQueryClient } from "@tanstack/react-query";
import { axios } from "../../../shared/lib/axios";
import { createNotification } from "../../../shared/store/notifications";

export const saveQuestion = async ({ surveyId, data }) => {
  const res = await axios.cadernoVirtualBase.post(
    "/survey-service2/researchQuestions",
    {
      researchId: surveyId,
      ...data,
    }
  );
  return res;
};

export const useSaveQuestion = ({ config, surveyId } = {}) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data) => saveQuestion({ surveyId, data }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["survey", { surveyId }] });
      createNotification({
        type: "success",
        message: "Questão salva com sucesso",
      });
    },
  });
};
