import { useQuery } from "@tanstack/react-query";
import { axios } from "../../../shared/lib/axios";
import _cookies from "../../../shared/utils/_cookies";

export const getTenantParameters = async ({ studentId }) => {
  const res = await axios.middleware(
    "/aluno.asmx/parametros_gerais_personalizado",
    {
      params: {
        alunoid: studentId || _cookies.getItem("auth")?.user?.alunoid,
      },
    }
  );

  return res[0].data[0];
};

export const useTenantParameters = ({ config, studentId } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["tenantParameters"],
    queryFn: () => getTenantParameters({ studentId }),
    staleTime: "Infinity",
  });
};
