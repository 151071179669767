import {
  Box,
  IconButton,
  styled,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { axios } from "../axios";
import { useQuery } from "@tanstack/react-query";
import _cookies from "../../utils/_cookies";
import { useState } from "react";
import { RocketChatLoginForm } from "./RocketChatLoginForm";
import { useRocketChatUser } from "./getRocketChatUser";

import ChatRoomsList from "./ChatRoomsList";

import {
  ChatBubbleOutline,
  ChevronLeft,
  ChevronRight,
  Close,
} from "@mui/icons-material";
import { useTheme } from "@emotion/react";

const getRoomsInfo = async () => {
  const res = await axios.cadernoVirtualBase(
    "https://api.cadernovirtual.com.br/api/v1/rooms.get",
    {
      headers: {
        "X-Auth-token": "x1oU8HnGP-Zi-s-VuJUZpeCv6pJEMfYck_fFfaEueH_",
        "X-User-Id": "ss4dEsw8YfKS7we9R",
      },
    }
  );
  return res;
};

const handleChatLogin = (token) => {
  console.log(
    "login",
    document.getElementById("rocketChatIframe").contentWindow
  );
  document.getElementById("rocketChatIframe").contentWindow.postMessage(
    {
      externalCommand: "login-with-token",
      token,
    },
    "*"
  );
};

const handleChatNavigate = (path) => {
  document.getElementById("rocketChatIframe").contentWindow.postMessage(
    {
      externalCommand: "go",
      path,
    },
    "*"
  );
};

const handleChatLogout = () => {
  document.getElementById("rocketChatIframe").contentWindow.postMessage(
    {
      externalCommand: "logout",
    },
    "*"
  );
};

export const useRoomsInfo = () => {
  return useQuery({ queryKey: ["rocketChatRooms"], queryFn: getRoomsInfo });
};

const getChatUsers = async () => {
  const res = await axios.rocketChatApi("/api/v1/users.list", {
    params: { userId: _cookies.getItem("rocketChatUserId") },
  });
  return res;
};

export const useChatUsers = ({ config } = {}) => {
  return useQuery({ queryKey: ["rocketChatUsers"], queryFn: getChatUsers });
};

const ChatIcon = styled(IconButton)(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  boxShadow: "5px 3px 3px grey",
  zIndex: "9999",
  backgroundColor: "white",
  width: "50px",
  height: "50px",
}));

const ChatWrapper = styled("span")(({ theme }) => ({
  position: "fixed",
  bottom: 7,
  right: 7,
  [theme.breakpoints.down("md")]: {
    bottom: 0,
    right: 0,
  },
}));

const ChatHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: theme.spacing(0, 1),
  height: "64px",
  backgroundColor: theme.palette.grey["600"],
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const ChatBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  p: 2,
  backgroundColor: theme.palette.grey["300"],
  boxShadow: "5px 3px 3px grey",
  width: "600px",
  [theme.breakpoints.down("md")]: {
    width: "100dvw",
  },
  height: open ? "600px" : "0px",
  position: "absolute",
  transform: "translate(0,-100%)",
  top: "0px",
  right: "0px",
  display: open ? "block" : "none",
}));

const ChatIframe = styled("iframe", {
  shouldForwardProp: (prop) => prop !== "listExpanded",
})(({ theme, listExpanded }) => ({
  width: "100%",
  [theme.breakpoints.down("md")]: {
    width: listExpanded ? "0px" : "100%",
  },
  height: "100%",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.shortest,
  }),
}));

const HideableWrapper = styled("div", {
  shouldForwardProp: (prop) => prop !== "hidden" || prop !== "listExpanded",
})(({ theme, hidden, listExpanded }) =>
  hidden
    ? {
        position: "absolute",
        width: 0,
        height: 0,
        border: 0,
      }
    : {
        height: "100%",
        "& .chatContent": {
          flexGrow: 1,
          height: "calc(100% - 64px)",
          display: "flex",
          flexDirection: "row",
        },
        "& ul": {
          transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.shortest,
          }),

          width: listExpanded ? "100%" : "0%",
          overflow: "hidden",
        },
      }
);

const Chat = ({ children }) => {
  const [chatOpen, setChatOpen] = useState(false);
  const theme = useTheme();
  const isDisplaySmall = useMediaQuery(theme.breakpoints.down("md"));
  const [listExpanded, setListExpanded] = useState(!isDisplaySmall);
  const [selectedRoom, setSelectedRoom] = useState(null);

  const handleOpen = () => {
    setChatOpen(true);
  };

  const handleClose = () => {
    setChatOpen(false);
  };

  const handleExpandList = () => {
    setListExpanded(true);
  };

  const handleMinimizeList = () => {
    setListExpanded(false);
  };

  const handleToggleExpansion = () => {
    listExpanded ? handleMinimizeList() : handleExpandList();
  };

  const handleClickChatBubble = () => {
    if (chatOpen) {
      handleClose();
    } else {
      handleOpen();
    }
  };

  const rocketChatUser = useRocketChatUser({
    config: {
      onSuccess: (data) => {
        console.log({ data });
        handleChatLogin(_cookies.getItem("rocketChatToken"));
      },
    },
  });

  const handleSelectRoom = (room) => {
    setSelectedRoom(room);
    if (room.name) {
      handleChatNavigate(`channel/${room._id}`);
    } else {
      handleChatNavigate(`direct/${room._id}`);
    }
    handleMinimizeList();
  };

  return (
    <ChatWrapper>
      <ChatBox open={chatOpen}>
        {!rocketChatUser.isSuccess && (
          <RocketChatLoginForm
            onSuccess={(data) => {
              handleChatLogin(data.authToken);
              handleChatNavigate("/channel/general");
            }}
          />
        )}

        <HideableWrapper
          hidden={!rocketChatUser.isSuccess}
          listExpanded={listExpanded}
        >
          <ChatHeader>
            <IconButton onClick={handleToggleExpansion}>
              {listExpanded ? <ChevronLeft /> : <ChevronRight />}
            </IconButton>
            <Typography>
              {selectedRoom?.name ||
                selectedRoom?.usernames.find((roomUsername) => {
                  return rocketChatUser.data?.username != roomUsername;
                }) ||
                "Geral"}
            </Typography>
          </ChatHeader>
          <div class="chatContent">
            <ChatRoomsList onSelectRoom={handleSelectRoom} />
            <ChatIframe
              listExpanded={listExpanded}
              id={"rocketChatIframe"}
              src={
                "https://api.cadernovirtual.com.br/channel/general?layout=embedded"
              }
            />
          </div>
        </HideableWrapper>
      </ChatBox>

      <ChatIcon onClick={handleClickChatBubble}>
        {chatOpen ? <Close /> : <ChatBubbleOutline />}
      </ChatIcon>
    </ChatWrapper>
  );
};

export default Chat;
