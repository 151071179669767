import { api } from "../api";
import { isLocal } from "../config/urlConfig";
import { baseUrl } from "../config";

const INITIAL_STATE = {
  list: [],
};

// actions
const GAMES_SET_CURRENT_GAME = "GAMES_SET_CURRENT_GAME";
const GAMES_FETCHED_STUDENT_GAMES = "GAMES_FETCHED_STUDENT_GAMES";
const STATUS = "STATUS";

export const getGameUrl = (gameId, auth) => {
  const isCartola = gameId.startsWith("02");
  const game = gameId.slice("2");
  const url = isLocal
    ? isCartola
      ? `http://localhost:3001/aluno-cartola?gid=${game}&a=${auth}`
      : `http://localhost:3001?gid=${game}&a=${auth}`
    : isCartola
    ? `${baseUrl}/aluno-cartola?gid=${game}&a=${auth}`
    : `${baseUrl}/aluno-games?gid=${game}&a=${auth}`;
  return url;
};

export const insertGameTypeOnId = (gameList) => {
  
  const sanitizedGameList = [];
  gameList.forEach((game) => {
    const sanitizedGame = { ...game };
    if (sanitizedGame.IDENTIFICADOR === "CARTOLA") {
      
      sanitizedGame.GAMEID = "02" + sanitizedGame.GAMEID;
      
      sanitizedGameList.push(sanitizedGame);
      return;
    }
    sanitizedGame.GAMEID = "01" + sanitizedGame.GAMEID;
    sanitizedGameList.push(sanitizedGame);
  });
  
  return sanitizedGameList;
};

// action creators
export const setCurrentGame = (game) => {
  return (dispatch) => {
    dispatch({ type: GAMES_SET_CURRENT_GAME, payload: game });
  };
};

export const getAvailableGames = ({ studentId, token }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: STATUS, status: "loading" });
      const res = await fetch(`${api.games_aluno}?alunoid=${studentId}`, {
        method: "get",
        headers: { token },
      });
      if (res.status < 200 || res.status >= 300)
        throw new Error(res.statusText);
      const json = await res.json();
      
      const data = json[0] ? json[0].data : [];
      console.log('data',data);
      const gameList = data.length ? insertGameTypeOnId(data) : [];
      dispatch({ type: GAMES_FETCHED_STUDENT_GAMES, payload: gameList });
      dispatch({ type: STATUS, status: "success" });
    } catch (error) {
      console.error("GAMES_FETCHED_STUDENT_GAMES error", error);
      dispatch({ type: STATUS, status: "error" });
      throw error;
    }
  };
};

// reducer
export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GAMES_FETCHED_STUDENT_GAMES:
      return { ...state, list: action.payload };
    case STATUS:
      return { ...state, status: action.status };
    default:
      return state;
  }
}
