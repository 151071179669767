export const SYNC_EVENTS = "SYNC_EVENTS";
export const TASKS = "TASKS";

export default function reducer(state = {}, action) {
  switch (action.type) {
    case SYNC_EVENTS:
      return { ...state, syncEvents: action.events };
    case TASKS:
      return { ...state, tasks: action.tasks };
    default:
      return state;
  }
}
