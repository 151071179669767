import { api } from "../api";
import axios from "axios";
import { getClient } from "../config/urlConfig";
import _cookies from "../utils/_cookies";

// actions
const SURVEY_DADOS_AVALIACAO = "SURVEY_DADOS_AVALIACAO";
const SURVEY_RESULTADO_AVALIACAO = "SURVEY_RESULTADO_AVALIACAO";
const SURVEY_AVALIACAO_QUESTOES = "SURVEY_AVALIACAO_QUESTOES";
const SURVEY_INICIA_AVALIACAO = "SURVEY_INICIA_AVALIACAO";
const SURVEY_SALVA_RESULTADO_TENTATIVA = "SURVEY_SALVA_RESULTADO_TENTATIVA";
const SURVEY_INSERE_QUESTOES_TENTATIVA = "SURVEY_INSERE_QUESTOES_TENTATIVA";
const SURVEY_SET_SURVEY_USER = "SURVEY_SET_SURVEY_USER";
const SURVEY_TRIM_QUESTIONS = "SURVEY_TRIM_QUESTIONS";
const SURVEY_SET_STEP = "SURVEY_SET_STEP";
const SURVEY_RESET = "SURVEY_RESET";
export const SURVEYS_LIST = "SURVEYS_LIST";
export const LOGIN_SURVEYS_LIST = "LOGIN_SURVEYS_LIST";
export const PROFESSOR_QUESTIONS_LIST = "PROFESSOR_QUESTIONS_LIST";

export const GET_FULL_RESEARCH = "getFullResearch";
export const START_ATTEMPT = "startResearchAttempt";
export const FINISH_ATTEMPT = "finishResearchAttempt";
export const GET_ANSWERS = "getAnswers";
const SET_STATUS = "setStatus";

const baseUrl = "https://cadernovirtual.inf.br/survey-service";
const oldBaseUrl = "https://middleware.cadernovirtual.com.br/pesquisas.asmx";

const domain = getClient();

const researchApi = {
  getFullResearch: (researchId, studentId) =>
    axios.get(`${baseUrl}/research/fullResearch`, {
      params: { id: researchId, studentId },
      data: {},
      headers: { "Access-Control-Allow-Origin": "*", domain },
    }),
  startAttempt: (researchId, studentId, token) => {
    const payload = {
      researchId: researchId,
      studentId: studentId,
    };
    return axios.post(`${baseUrl}/research/startResearch`, payload, {
      headers: { token, domain, "Access-Control-Allow-Origin": "*" },
    });
  },
  finishAttempt: (attemptId, answersArray) =>
    axios.post(
      `${baseUrl}2/research/answer`,
      {
        researchAttemptsId: attemptId,
        attempts: answersArray,
      },
      {
        headers: { "Access-Control-Allow-Origin": "*", domain },
      }
    ),
  getAnswers: (attemptId) =>
    axios.get(`${baseUrl}/research/attemptsAnswer`, {
      params: { attemptsId: attemptId },
      headers: { "Access-Control-Allow-Origin": "*", domain },
    }),
};

export const getFullResearch = (researchId, studentId) => async (dispatch) => {
  try {
    dispatch({
      type: SET_STATUS,
      payload: { key: GET_FULL_RESEARCH, value: "loading" },
    });

    const response = await researchApi.getFullResearch(researchId, studentId);

    dispatch({
      type: GET_FULL_RESEARCH,
      payload: response.data.object[0],
    });
  } catch (error) {
    console.log(error);
  } finally {
    dispatch({
      type: SET_STATUS,
      payload: { key: GET_FULL_RESEARCH, value: "idle" },
    });
  }

  return;
};

export const startResearchAttempt =
  (researchId, studentId, token) => async (dispatch) => {
    try {
      dispatch({
        type: SET_STATUS,
        payload: { key: START_ATTEMPT, value: "loading" },
      });

      const response = await researchApi.startAttempt(
        researchId,
        studentId,
        token
      );

      dispatch({
        type: START_ATTEMPT,
        payload: response?.data?.object,
      });
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({
        type: SET_STATUS,
        payload: { key: START_ATTEMPT, value: "idle" },
      });
    }

    return;
  };

export const finishResearchAttempt =
  (attemptId, answersArray) => async (dispatch) => {
    try {
      dispatch({
        type: SET_STATUS,
        payload: { key: FINISH_ATTEMPT, value: "loading" },
      });

      const response = await researchApi.finishAttempt(attemptId, answersArray);

      dispatch({
        type: FINISH_ATTEMPT,
        payload: response.data.object,
      });

      return response;
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({
        type: SET_STATUS,
        payload: { key: FINISH_ATTEMPT, value: "idle" },
      });
    }
  };

export const getAnswers = (attemptId) => async (dispatch) => {
  try {
    dispatch({
      type: SET_STATUS,
      payload: { key: GET_ANSWERS, value: "loading" },
    });

    const response = await researchApi.getAnswers(attemptId);

    dispatch({
      type: GET_ANSWERS,
      payload: response.data.object,
    });

    return response.data.object;
  } catch (error) {
    console.log(error);
  } finally {
    dispatch({
      type: SET_STATUS,
      payload: { key: GET_ANSWERS, value: "idle" },
    });
  }
};

// action creators
export const dadosAvaliacao = (avaliacaoid, disciplinaid, token) => {
  return async (dispatch) => {
    const header = new Headers();
    header.append("Access-Control-Allow-Origin", "*");
    header.append("token", token);
    const apiUrl =
      disciplinaid > 900000
        ? api.dados_avaliacao_convenio
        : api.dados_avaliacao;
    try {
      const res = await fetch(`${apiUrl}?avaliacaoid=${avaliacaoid}`, {
        method: "get",
        headers: header,
      });
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        const details = json[0].data[0];
        dispatch({ type: SURVEY_DADOS_AVALIACAO, details });
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      dispatch({ type: SURVEY_DADOS_AVALIACAO, details: null });
      throw new Error(error);
    }
  };
};

export const resultadoAvaliacao = (avaliacaoid, matriculaid, token) => {
  return async (dispatch) => {
    try {
      const header = new Headers();
      header.append("Access-Control-Allow-Origin", "*");
      header.append("domain", domain);
      const res = await fetch(
        `${baseUrl}/survey/result?surveyId=${avaliacaoid}&registerId=${matriculaid}`,
        {
          method: "get",
          headers: header,
        }
      );
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        const result = json.object;
        dispatch({ type: SURVEY_RESULTADO_AVALIACAO, result });
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      dispatch({ type: SURVEY_RESULTADO_AVALIACAO, result: null });
      throw new Error(error);
    }
  };
};

export const avaliacaoQuestoes = (avaliacaoid, disciplinaid, token) => {
  return async (dispatch) => {
    const header = new Headers();
    header.append("Access-Control-Allow-Origin", "*");
    header.append("token", token);
    const apiUrl =
      disciplinaid > 900000
        ? api.avaliacao_questoes_convenio
        : api.avaliacao_questoes;
    try {
      const res = await fetch(`${apiUrl}?avaliacaoid=${avaliacaoid}`, {
        method: "get",
        headers: header,
      });
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        const questions = json[0].data;
        dispatch({ type: SURVEY_AVALIACAO_QUESTOES, questions });
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      dispatch({ type: SURVEY_AVALIACAO_QUESTOES, questions: null });
      throw new Error(error);
    }
  };
};

export const iniciaAvaliacao = (avaliacaoid, alunoid, matriculaid, token) => {
  return async (dispatch) => {
    const header = new Headers();
    header.append("Access-Control-Allow-Origin", "*");
    header.append("domain", domain);
    const body = new FormData();
    body.append(
      `{"surveyId":${avaliacaoid},"studentId":${alunoid},"registrationId":"${matriculaid}"}`
    );
    try {
      const res = await fetch(api.inicia_avaliacao, {
        method: "post",
        headers: header,
        body,
      });
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        const init = json[0].object;
        dispatch({ type: SURVEY_INICIA_AVALIACAO, init });
        return init.id;
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      dispatch({ type: SURVEY_INICIA_AVALIACAO, init: null });
      throw new Error(error);
    }
  };
};

export const salvaResultadoTentativa = (dados, token) => {
  return async (dispatch) => {
    const header = new Headers();
    header.append("Access-Control-Allow-Origin", "*");
    header.append("token", token);
    const body = new FormData();
    body.append("dados", dados);
    try {
      const res = await fetch(api.salva_resultado_tentativa, {
        method: "post",
        headers: header,
        body,
      });
      if (res.status >= 200 && res.status < 300) {
        dispatch({
          type: SURVEY_SALVA_RESULTADO_TENTATIVA,
          saveResult: res.status,
        });
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      dispatch({ type: SURVEY_SALVA_RESULTADO_TENTATIVA, saveResult: null });
      throw new Error(error);
    }
  };
};

export const insereQuestoesTentativa = (dados, token) => {
  return async (dispatch) => {
    const header = new Headers();
    header.append("Access-Control-Allow-Origin", "*");
    header.append("token", token);
    const body = new FormData();
    body.append("dados", dados);
    try {
      const res = await fetch(api.insere_questoes_tentativa, {
        method: "post",
        headers: header,
        body,
      });
      if (res.status >= 200 && res.status < 300) {
        dispatch({
          type: SURVEY_INSERE_QUESTOES_TENTATIVA,
          insertQuestions: res.status,
        });
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      dispatch({
        type: SURVEY_INSERE_QUESTOES_TENTATIVA,
        insertQuestions: null,
      });
      throw new Error(error);
    }
  };
};

export const trimQuestions = () => {
  return { type: SURVEY_TRIM_QUESTIONS };
};

export const setSurveyUser = (surveyUser) => {
  return { type: SURVEY_SET_SURVEY_USER, surveyUser };
};

export const setStep = (step) => {
  return { type: SURVEY_SET_STEP, step };
};

export const surveyReset = () => {
  return { type: SURVEY_RESET };
};

export const listaPesquisas = (alunoid, token) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: SET_STATUS,
        payload: { key: SURVEYS_LIST, value: "loading" },
      });

      const response = await fetch(
        `${api.lista_pesquisas}?alunoid=${alunoid}`,
        {
          method: "get",
          headers: {
            token,
          },
        }
      );

      const json = await response.json();

      dispatch({
        type: SURVEYS_LIST,
        surveys: json[0].data,
      });
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({
        type: SET_STATUS,
        payload: { key: SURVEYS_LIST, value: "idle" },
      });
    }
  };
};

export const listaPesquisasLogin = (alunoid, token) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: SET_STATUS,
        payload: { key: LOGIN_SURVEYS_LIST, value: "loading" },
      });

      const response = await fetch(
        `${api.pesquisas_responder}?alunoid=${alunoid}`,
        {
          method: "get",
          headers: {
            token,
          },
        }
      );

      const json = await response.json();

      _cookies.setItem(
        "hasUnansweredSurveys",
        json.data?.some((survey) => survey.OBRIGATORIO)
      );

      dispatch({
        type: LOGIN_SURVEYS_LIST,
        surveys: json.data || [],
      });
      return json.data;
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({
        type: SET_STATUS,
        payload: { key: LOGIN_SURVEYS_LIST, value: "idle" },
      });
    }
  };
};

// export const getQuestions = async () => {
//   setIsLoadingQuestions(true);
//   const type = currentEvaluation?.type === "CAP" ? "CAP" : "ECAP";
//   const surveyid = currentEvaluation?.surveyId;
//   const professorId = await getProfessorId(username, token);
//   const data = await fetch(
//       `${baseUrl}/myQuestions?professorId=${professorId}&type=${type}&surveyId=${surveyid}`,
//       {
//         headers: { "Access-Control-Allow-Origin": "*", token, domain },
//       }
//     )

// };

export const getProfessorQuestions = (token, professorId, type, surveyid) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: SET_STATUS,
        payload: { key: PROFESSOR_QUESTIONS_LIST, value: "loading" },
      });

      const { data } = await axios.get(
        `${baseUrl}/surveyQuestions/myQuestions?professorId=${professorId}&type=${type}&surveyId=${surveyid}`,
        {
          headers: { "Access-Control-Allow-Origin": "*", domain, token },
        }
      );

      dispatch({
        type: PROFESSOR_QUESTIONS_LIST,
        professorQuestions: data.object || [],
      });
    } catch (error) {
      dispatch({
        type: PROFESSOR_QUESTIONS_LIST,
        professorQuestions: [],
      });
      console.log(error);
    } finally {
      dispatch({
        type: SET_STATUS,
        payload: { key: PROFESSOR_QUESTIONS_LIST, value: "idle" },
      });
    }
  };
};

// export const getProfessorQuestions (token, professorIdm ty)
// reducer
export default function reducer(state = { status: {} }, action) {
  switch (action.type) {
    case SURVEY_DADOS_AVALIACAO:
      return { ...state, details: action.details };

    case SURVEY_RESULTADO_AVALIACAO:
      return { ...state, result: action.result };

    case SURVEY_AVALIACAO_QUESTOES:
      return { ...state, questions: action.questions };

    case SURVEY_INICIA_AVALIACAO:
      return { ...state, init: action.init };

    case SURVEY_SALVA_RESULTADO_TENTATIVA:
      return { ...state, saveResult: action.saveResult };

    case SURVEY_INSERE_QUESTOES_TENTATIVA:
      return { ...state, insertQuestions: action.insertQuestions };

    case SURVEY_SET_SURVEY_USER:
      return { ...state, surveyUser: action.surveyUser };

    case SURVEY_SET_STEP:
      return { ...state, step: action.step };

    case SURVEY_TRIM_QUESTIONS:
      const { details, questions } = state;
      const trimmed = questions.slice(0, details.qtd_questoes);
      return { ...state, questions: trimmed };

    case SURVEYS_LIST:
      return { ...state, surveys: action.surveys };
    case LOGIN_SURVEYS_LIST:
      return { ...state, loginSurveys: action.surveys };
    case PROFESSOR_QUESTIONS_LIST:
      return { ...state, professorQuestions: action.professorQuestions };

    case GET_FULL_RESEARCH:
      return { ...state, currentResearch: action.payload };
    case START_ATTEMPT:
      return { ...state, attemptInfo: action.payload };
    case FINISH_ATTEMPT:
      return { ...state, results: action.payload };
    case GET_ANSWERS:
      return { ...state, answers: action.payload };

    case SET_STATUS:
      const newStatus = { ...state.status };
      const { key, value } = action.payload;

      newStatus[key] = value;

      return { ...state, status: newStatus };

    case SURVEY_RESET:
      return {};

    default:
      return state;
  }
}
