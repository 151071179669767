import {
  ListItem,
  Box,
  Checkbox,
  Typography,
  IconButton,
  Collapse,
  CircularProgress,
} from "@mui/material";

import { Error, ExpandLess, ExpandMore } from "@mui/icons-material";

export default function CertificatesListItem({
  data,
  isError,
  isLoading,
  expanded,
  selected,
  onSelect,
  onExpand,
}) {
  return (
    <ListItem divider sx={{ display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Checkbox
          sx={{ ml: 6 }}
          onClick={onSelect}
          checked={selected}
          disabled={isError}
        />
        <Typography>{data.id}</Typography>
        <Box
          sx={{
            display: "flex",
            width: "100px",
            justifyContent: "flex-end",
          }}
        >
          {isLoading && (
            <IconButton disabled>
              <CircularProgress size={15} />
            </IconButton>
          )}
          {isError && <Error color="error" />}

          <IconButton disabled={isLoading || isError} onClick={onExpand}>
            {expanded ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </Box>
      </Box>
      <Collapse
        sx={{ display: "flex", flexDirection: "column", width: "100%" }}
        in={expanded}
      >
        <Box
          sx={{
            ml: 12,
            display: "flex",
            width: "100%",
            flexDirection: "column",
            justifyContent: "flex-start",
          }}
        >
          <div>
            <b>Carga horária:</b>
            {data.Carga_Horaria}
          </div>
          <div>
            <b>Id do aluno:</b>
            {data.alunoid}
          </div>
          <div>
            <b>Id da disciplina:</b>
            {data.disciplinaid}
          </div>
          <div>
            <b>Id de matrícula:</b>
            {data.matriculaid}
          </div>
          <div>
            <b>Data de conclusão:</b>
            {data.dtconclusao}
          </div>
          <div>
            <b>Data de liberação:</b>
            {data.dtliberacao}
          </div>
          <div>
            <b>Nome do aluno:</b>
            {data.nome}
          </div>
          <div>
            <b>Nome do curso:</b>
            {data.nome_curso}
          </div>
          <div>
            <b>Nome do professor:</b>
            {data.nome_professor}
          </div>
          <div>
            <b>Id do professor:</b>
            {data.professorid}
          </div>
          <div>
            <b>Texto</b>
            {data.texto}
          </div>
        </Box>
      </Collapse>
    </ListItem>
  );
}
