import RouterBuilder from "./RouterBuilder";
import { lazy } from "react";
import Modal from "../../ui/partials/Modal";
import ManageCourseModal from "../../features/courses/routes/ManageCourseModal";
import ManageCourse from "../../features/courses/routes/ManageCourse:courseId";
import ManageCourseTracks from "../../features/courses/routes/ManageCourse:courseId.trilhas";
import ManageCourseCatalogue from "../../features/courses/routes/ManageCourse:courseId.catalogo";
// import TrackRegistrationModal from "../../ui/pages/TrackPage/views/TrackRegistrationModal/";
// import GamePlayerPage from "../../ui/pages/Games/pages/GamePlayerPage";

const TrackRegistrationModal = lazy(() =>
  import("../../ui/pages/TrackPage/views/TrackRegistrationModal/")
);
const GamePlayerPage = lazy(() =>
  import("../../ui/pages/Games/pages/GamePlayerPage")
);

const routeFactory = RouterBuilder.routeFactory;

const mainRouteid = "modal";
const mainRoute = routeFactory(mainRouteid, "/", Modal);

const routes = [
  mainRoute,
  routeFactory(
    "track-registration",
    "registration/:disciplinaid",
    TrackRegistrationModal,
    mainRouteid
  ),
  routeFactory(
    "manage-course-modal",
    "manage/course",
    ManageCourseModal,
    mainRouteid
  ),

  routeFactory(
    "manage-course",
    ":courseId",
    ManageCourse,
    "manage-course-modal"
  ),
  routeFactory(
    "manage-course-tracks",
    "trilhas",
    ManageCourseTracks,
    "manage-course"
  ),
  routeFactory(
    "manage-course-catalogue",
    "catalogo",
    ManageCourseCatalogue,
    "manage-course"
  ),
  routeFactory("game", "game/:gameid", GamePlayerPage, mainRouteid),
];

const ModalRoutes = new RouterBuilder(routes).nestedRoutes;

export default ModalRoutes;
