import React, { useState } from "react";
import { connect } from "react-redux";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { CheckCircleRounded } from "@mui/icons-material";
import withStyles from "@mui/styles/withStyles";

import DialogFull from "../components/DialogFull";
import Hr from "../components/Hr";
import Loading from "../components/Loading";
import Logout from "./Logout";

const styles = (theme) => ({
  container: {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(8),
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(2),
    },
  },
  section: {
    maxWidth: 650,
    margin: "auto",
    padding: 20,
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  checkbox: {
    margin: "48px 0",
  },
  listItem: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  logout: {
    marginLeft: 24,
  },
});

const TermsOfUseDialog = ({ app, classes, setAccepted }) => {
  const [open, setOpen] = useState(true);
  const [checked, setChecked] = useState(false);
  const [logout, setLogout] = useState(false);
  const [showMore, setShowMore] = useState(false);

  const { termsOfUse } = app;

  function handleClose() {
    setOpen(false);
  }

  function handleCheck() {
    setChecked((current) => !current);
  }

  function handleConfirm() {
    setAccepted(true);
    setOpen(false);
  }

  function showReadMoreButton(termIndex) {
    if (termIndex === 0) {
      return "inline-block";
    } else {
      return "none";
    }
  }

  function toggleShowMore() {
    setShowMore((current) => !current);
  }

  function toggleTermTextLength(text) {
    if (showMore) {
      return text;
    } else {
      return text.slice(0, 200) + "";
    }
  }

  if (logout) {
    window.location.href = "/aluno/logout"
    //return <Logout />;
  }

  if (!termsOfUse || termsOfUse.length === 0) {
    return <Loading />;
  }

  return (
    <DialogFull handleClose={handleClose} open={open} showCloseButton={false}>
      <div className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={10}>
            <Typography variant="h4">Termos de uso</Typography>
          </Grid>
        </Grid>
        <Hr mt={3} mb={3} wide />
        <Box className={classes.section}>
          <List>
            {termsOfUse?.map((term, i) => {
              return (
                <div key={`termofuse${i}`}>
                  <ListItem
                    className={classes.listItem}
                    alignItems="flex-start"
                  >
                    <ListItemIcon>
                      <CheckCircleRounded color="primary" />
                    </ListItemIcon>
                    <ListItemText>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: toggleTermTextLength(term.TEXTO),
                        }}
                      />
                    </ListItemText>
                  </ListItem>

                  <ListItem
                    style={{ marginTop: "0", paddingTop: 0 }}
                    className={classes.listItem}
                    alignItems="flex-start"
                  >
                    <Button
                      style={{
                        display: showReadMoreButton(i),
                        marginLeft: "auto",
                      }}
                      color="primary"
                      onClick={toggleShowMore}
                    >
                      Ver {showMore ? "menos" : "mais"}
                    </Button>
                  </ListItem>
                </div>
              );
            })}
          </List>

          <FormGroup row className={classes.checkbox}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onChange={handleCheck}
                  name="checke"
                  color="primary"
                />
              }
              label="Declaro que li, entendi e aceito."
            />
          </FormGroup>

          <Button
            color="primary"
            disabled={!checked}
            onClick={handleConfirm}
            variant="contained"
          >
            Continuar
          </Button>
          <Button
            className={classes.logout}
            onClick={() => {
              setLogout(true);
            }}
          >
            Sair
          </Button>
        </Box>
      </div>
    </DialogFull>
  );
};

const mapStateToProps = (state) => {
  return { app: state.app, auth: state.auth };
};

export default connect(mapStateToProps)(
  withStyles(styles, { withTheme: true })(TermsOfUseDialog)
);
