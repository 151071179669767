import { Notifications, NotificationsOff } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { IconButton, styled } from "@mui/material";

import OneSignal from "react-onesignal";
import { useOneSignalOptIn } from "../../../shared/lib/push";
import { useTenantParameters } from "../api/getTenantParameters";

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: "white",
  "& > .MuiIcon-root": { height: "36px", width: "36px" },
  height: "36px",
  width: "36px",
}));

export default function TogglePushNotifications() {
  const OneSignalStore = useSelector((state) => state?.OneSignal);
  const OneSignalOptIn = useOneSignalOptIn();

  const tenantParams = useTenantParameters();

  const handleClickOptIn = () => {
    if (OneSignal.Notifications.permission) {
      OneSignal.Slidedown.promptPush();
      OneSignalOptIn.mutate();
    } else {
      OneSignal.Notifications.requestPermission();
    }
  };

  if (
    OneSignalStore.optedIn === undefined ||
    !tenantParams.data?.Onesignal_AppID
  ) {
    return <></>;
  } else if (!OneSignal.Notifications.isPushSupported()) {
    return (
      <StyledIconButton disabled color="error">
        <NotificationsOff />
      </StyledIconButton>
    );
  } else {
    return (
      <>
        {!OneSignalStore.optedIn && (
          <StyledIconButton onClick={handleClickOptIn}>
            <Notifications />
          </StyledIconButton>
        )}
      </>
    );
  }
}
