import { api } from "../../api";
import moment from "moment";
import "moment/locale/pt-br";

moment.locale("pt-br");

const IDEAS_LIST_REACTIONS = "IDEAS_LIST_REACTIONS";
const REACTIONS_MARCAR_REACAO = "REACTIONS_MARCAR_REACAO";
const REACTIONS_REACAO_ALUNO_IDEIA = "REACTIONS_REACAO_ALUNO_IDEIA";

export const reacoesIdeia = (propostaid, token) => {
  return async (dispatch) => {
    try {
      const res = await fetch(
        `${api.lista_reacoes_ideias}?propostaid=${propostaid}`,
        {
          method: "get",
          headers: { token },
        }
      );
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        dispatch({
          type: IDEAS_LIST_REACTIONS,
          postReactions: json[0].data[0],
        });
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      dispatch({ type: IDEAS_LIST_REACTIONS, postReactions: null });
    }
  };
};

export const marcarReacaoIdeia = (body, reaction, token) => {
  return async (dispatch) => {
    try {
      const res = await fetch(api.marcar_reacoes_ideias, {
        method: "post",
        headers: { token },
        body,
      });

      const reactionKey = Object.keys(reaction)[0];

      if (res.status >= 200 && res.status < 300) {
        // const json = await res.json();
        dispatch({
          type: REACTIONS_MARCAR_REACAO,
          currentReaction: {
            key: reactionKey,
            value: reaction[reactionKey],
          },
        });
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      dispatch({ type: REACTIONS_MARCAR_REACAO, currentReaction: null });
      console.log("marcarReacao error", error);
    }
  };
};

export const reacaoAlunoIdeia = (alunoid, propostaid, token) => {
  return async (dispatch) => {
    try {
      const res = await fetch(
        `${api.lista_reacoes_ideias_aluno}?alunoid=${alunoid}&propostaid=${propostaid}`,
        {
          method: "get",
          headers: { token },
        }
      );

      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();

        dispatch({
          type: REACTIONS_REACAO_ALUNO_IDEIA,
          userReactions: json[0].data[0],
        });
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      console.log("reacaoAlunoPost error", error);
      dispatch({ type: REACTIONS_REACAO_ALUNO_IDEIA, reactions: null });
    }
  };
};
// reducer
export default function reducer(state = {}, action) {
  switch (action.type) {
    case IDEAS_LIST_REACTIONS:
      return { ...state, postReactions: action.postReactions };
    case REACTIONS_MARCAR_REACAO:
      return {
        ...state,
        userIdeaReactions: {
          ...state.userReactions,
          [action.currentReaction.key]: action.currentReaction.value,
        },
      };
    case REACTIONS_REACAO_ALUNO_IDEIA:
      return { ...state, userIdeaReactions: action.userReactions };
    default:
      return state;
  }
}
