import RouteConstructor, { idKey } from "./RouterBuilder";

import LoginPage from "../../ui/pages/LoginPage";
import NotFound from "../../ui/pages/404";
import IntegratedLoginPage from "../../ui/pages/LoginPage/IntegratedLoginPage";
import MultipleCertificates from "../../ui/pages/MultipleCertificates";
import SignUpPage from "../../features/auth/routes/signup";
const routeFactory = RouteConstructor.routeFactory;

const routes = [
  routeFactory("login", "login", LoginPage),
  // routeFactory("signup","signup",SignUpPage),

  {
    [idKey]: "signup",
    path: "signup",
    Page: SignUpPage,
  },
  {
    [idKey]: "forgotten",
    path: "password-recovery",
    Page: LoginPage,
  },
  {
    [idKey]: "multiplecertificate",
    path: "certificates",
    Page: MultipleCertificates,
  },
  {
    [idKey]: "404",
    path: "*",
    Page: NotFound,
  },
];

const AuthRoutes = new RouteConstructor(routes).nestedRoutes;

export default AuthRoutes;
