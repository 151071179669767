import {
  Dialog,
  IconButton,
  Button,
  Box,
  Typography,
  TextField,
  Container,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { recuperaSenha } from "../../../shared/store/auth";
import { useState } from "react";
const ForgotPassword = ({
  recoveredEmail,
  open,
  onClose,
  // recoverySent = false,
  recoveryPassword,
}) => {
  const [userName, setUserName] = useState("");
  return (
    <Dialog open={open}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          padding: " 36px 0px 36px 36px",
        }}
      >
        {!recoveredEmail ? (
          <Container
            sx={{
              display: "grid",
              gridAutoFlow: { xs: "row", md: "column" },
              gridRowGap: "10px",
            }}
          >
            <TextField
              variant="outlined"
              label="Usuário"
              placeholder="Digite seu usuário"
              onChange={(e) => {
                setUserName(e.target.value);
              }}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  recoveryPassword(userName);
                }
              }}
              sx={{ height: "55px" }}
            ></TextField>
            <Button
              sx={{ height: "53px" }}
              variant="outlined"
              onClick={() => {
                recoveryPassword(userName);
              }}
            >
              <Typography>OK</Typography>
            </Button>
          </Container>
        ) : (
          <Container
            sx={{
              display: "flex",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <Typography sx={{ justifyContent: "center", textAlign: "justify" }}>
              {recoveredEmail.status == 200
                ? "Em breve te enviaremos uma mensagem de recuperação de senha, verifique a caixa de entrada do seu e-mail."
                : `${recoveredEmail.message}. Se preferir, procure seu gestor e solicite a verificação dos seus dados de cadastro!`}
            </Typography>
          </Container>
        )}

        <Button sx={{ padding: "0", height: "24px" }} onClick={onClose}>
          <Close />
        </Button>
      </Box>
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  return {
    recoveredEmail: state.auth.recoverEmail,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      recoveryPassword: recuperaSenha,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
