import { api, courseApi } from "../api";
import { database } from "../config";
import jwt_decode from "jwt-decode";
import _cookies from "../utils/_cookies";
import _cookiesChunked from "../utils/_cookiesChunked";

// helpers
const recuperaAlunoId = async (username, token) => {
  try {
    const res = await fetch(
      `${api.recupera_alunoId}?username=${username}&possui_APP=N`,
      {
        method: "get",
        headers: { token },
      }
    );
    if (res.status >= 200 && res.status < 300) {
      const json = await res.json();
      return parseInt(json[0].data.alunoid);
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

const recuperaGestorId = async (alunoid, token) => {
  try {
    const res = await fetch(`${api.verifica_gestorid}?alunoid=${alunoid}`, {
      method: "get",
      headers: { token },
    });
    if (res.status >= 200 && res.status < 300) {
      const json = await res.json();
      return parseInt(json[0].data.gestorid);
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const dadosAluno = async (alunoid, token) => {
  try {
    const res = await fetch(`${api.dados_aluno}?alunoid=${alunoid}`, {
      method: "get",
      headers: { token },
    });
    if (res.status >= 200 && res.status < 300) {
      const json = await res.json();
      const details = json[0].data[0];
      return details;
    } else {
      throw new Error(res.statusText);
    }
  } catch (error) {
    throw new Error(error);
  }
};

const getUserProfile = async (username, token) => {
  const data = new URLSearchParams();

  data.append("dados", `[{"username":"${username}"}]`);
  try {
    const res = await fetch(api.perfil_usuario, {
      method: "POST",
      headers: { token },
      body: data,
    });
    if (res.status >= 200 && res.status < 300) {
      const json = await res.json();
      const data = json[0].data;
      const isGestor = data.find((profile) => profile.TIPO === "GESTOR");
      const isAluno = data.find((profile) => profile.TIPO === "ALUNO");
      const isProfessor = data.find((profile) => profile.TIPO === "PROFESSOR");
      const isGestorAluno = isGestor && isAluno;
      const userProfile = { isGestor, isAluno, isProfessor, isGestorAluno };

      return userProfile;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

// actions
const AUTH_SET_AUTH = "AUTH_SET_AUTH";
export const AUTH_GET_TOKEN = "AUTH_GET_TOKEN";
const AUTH_CLEAR = "AUTH_CLEAR";
const AUTH_RECUPERA_SENHA = "AUTH_RECUPERA_SENHA";
const AUTH_VERIFICACAO_COMUNICACAO = "AUTH_VERIFICACAO_COMUNICACAO";
const AUTH_SALVA_COMUNICACAO = "AUTH_SALVA_COMUNICACAO";
const AUTH_PICK_MODULE = "AUTH_PICK_MODULE";
const SET_STATUS = "SET_STATUS";

// action creators
export const setAuth = (auth) => {
  _cookies.setItem("auth", auth);
  return { type: AUTH_SET_AUTH, auth };
};

export const logout = (alunoid, token) => {
  _cookies.remove("auth");
  _cookies.remove("host");
  _cookies.remove("popupCount");
  _cookies.remove("title");
  _cookies.removeAll();
  _cookies.remove("logout");
  // _cookies.setItem("logout", true);
  _cookiesChunked.remove("params");

  if (!alunoid || !token) return { type: AUTH_CLEAR };

  return async (dispatch) => {
    try {
      const res = await fetch(`${api.logout_plataforma}?alunoid=${alunoid}`, {
        method: "get",
        headers: { token },
      });

      if (res.status >= 200 && res.status < 300) {
        dispatch({ type: AUTH_CLEAR });
        dispatch({
          type: AUTH_GET_TOKEN,
          auth: { user: { valid: null } },
        });
        return res;
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      throw new Error(error);
    }
  };
};

export const loginIntegrado = (data) => {
  // const value = `[{"id_ad":"jaime.souza","base":"PIRACANJUBA"}]`
  const value = `[{"id_ad":${data.idUser},"base":"${data.base}"}]`;

  const body = new FormData();
  body.append("credenciais", value);

  // const newApiBody = `{"username":"${data.user}","password":"${data.password}"}`;
  // const newApiHeaders = new Headers();
  // newApiHeaders.append("schema", database.toLowerCase());
  // newApiHeaders.append("content-type", "application/json");

  return async (dispatch) => {
    try {
      dispatch({
        type: SET_STATUS,
        payload: { key: AUTH_GET_TOKEN, value: "loading" },
      });

      const res = await fetch(data.base=="PIRACANJUBA"?api.get_token_integrado:api.get_token_integrado_generico, {
        method: "post",
        body,
      });

      const json = await res.json();

      if (json.status >= 200 && json.status < 300) {
        const token = json.data[0].token;
        const { user, base, exp } = jwt_decode(token);

        const userProfile = await getUserProfile(user, token);
        console.log(userProfile, "userProfile");
        if (userProfile.isGestor && !userProfile.isGestorAluno) {
          const auth = {
            token,
            user: {
              username: user,
              alunoid: 0,
              email: "",
              primeiro_acesso: "",
              nome: "",
              foto: "",
              gestorid: "",
            },
            base,
            exp,
          };
          dispatch({
            type: AUTH_GET_TOKEN,
            auth: { ...auth, user: { ...auth.user, profile: userProfile } },
          });
        }

        let auth = {
          token,
          // newToken,
          user: {
            username: user,
            alunoid: null,
            email: null,
            primeiro_acesso: null,
            nome: null,
            foto: null,
            gestorid: null,
          },
          base,
          exp,
        };

        if (!userProfile.isGestor || userProfile.isGestorAluno) {
          // get user id
          const alunoid = await recuperaAlunoId(user, token);

          // get gestor id
          const gestorid = await recuperaGestorId(alunoid, token);
          // user details
          const details = await dadosAluno(alunoid, token);
          const { email, primeiro_acesso, nome, foto } = details;

          const newAuth = {
            ...auth,
            user: {
              ...auth.user,
              alunoid,
              email: email.toLowerCase(),
              primeiro_acesso,
              nome,
              foto,
              gestorid,
            },
          };

          auth = newAuth;
        }

        // _cookies.setItem("valid", data.password);
        dispatch({
          type: AUTH_GET_TOKEN,
          auth: { ...auth, user: { ...auth.user, profile: userProfile } },
        });
      } else {
        throw json;
      }
    } catch (error) {
      logout();
      throw error;
    } finally {
      dispatch({
        type: SET_STATUS,
        payload: { key: AUTH_GET_TOKEN, value: "idle" },
      });
    }
  };
};
export const login = (data) => {
  const value = `[{"usuario":"${data.user}","senha":"${data.password}", "base":"${database}"}]`;

  const body = new FormData();
  body.append("credenciais", value);

  const newApiBody = `{"username":"${data.user}","password":"${data.password}"}`;
  const newApiHeaders = new Headers();
  newApiHeaders.append("schema", database.toLowerCase());
  newApiHeaders.append("content-type", "application/json");

  return async (dispatch) => {
    try {
      dispatch({
        type: SET_STATUS,
        payload: { key: AUTH_GET_TOKEN, value: "loading" },
      });

      const res = await fetch(api.get_token, {
        method: "post",
        body,
      });
      const newApiResponse = await fetch(courseApi.authenticate.url, {
        method: courseApi.authenticate.method,
        body: newApiBody,
        headers: newApiHeaders,
      });

      const json = await res.json();

      if (
        json.status >= 200 &&
        json.status < 300 &&
        newApiResponse.status >= 200 &&
        newApiResponse.status < 300
      ) {
        const token = json.data[0].token;
        const { user, base, exp } = jwt_decode(token);

        const newToken = (await newApiResponse.json())["X-Token"];

        const userProfile = await getUserProfile(user, token);
        
        if (userProfile.isGestor && !userProfile.isGestorAluno) {
          const auth = {
            token,
            newToken,
            user: {
              username: user,
              alunoid: 0,
              email: "",
              primeiro_acesso: "",
              nome: "",
              foto: "",
              gestorid: "",
              
            },
            base,
            exp,
          };
          dispatch({
            type: AUTH_GET_TOKEN,
            auth: { ...auth, user: { ...auth.user, profile: userProfile } },
          });
        }

        let auth = {
          token,
          newToken,
          user: {
            username: user,
            alunoid: null,
            email: null,
            primeiro_acesso: null,
            nome: null,
            foto: null,
            gestorid: null,
          },
          base,
          exp,
        };

        if (!userProfile.isGestor || userProfile.isGestorAluno) {
          // get user id
          const alunoid = await recuperaAlunoId(user, token);

          // get gestor id
          const gestorid = await recuperaGestorId(alunoid, token);
          // user details
          const details = await dadosAluno(alunoid, token);
          const { email, primeiro_acesso, nome, foto } = details;

          const newAuth = {
            ...auth,
            user: {
              ...auth.user,
              alunoid,
              email: email.toLowerCase(),
              primeiro_acesso,
              nome,
              foto,
              gestorid,
             
            },
          };

          auth = newAuth;
        }

        // _cookies.setItem("auth", auth);

        // _cookies.setItem(
        //   "host",
        //   window.location.host.replace("www.", ""),
        //   false
        // );
        // _cookies.setItem("title", document.title, false);
        _cookies.setItem("valid",data.password );
        dispatch({
          type: AUTH_GET_TOKEN,
          auth: { ...auth, user: { ...auth.user, profile: userProfile } },
        });
      } else {
        throw json;
      }
    } catch (error) {
      logout();
      throw error;
    } finally {
      dispatch({
        type: SET_STATUS,
        payload: { key: AUTH_GET_TOKEN, value: "idle" },
      });
    }
  };
};

export const recuperaSenha = (user) => {
  const value = `[{"usuario":"caderno","senha":"230572gu@", "base":"${database}"}]`;

  const body = new FormData();
  body.append("credenciais", value);

  // const newApiBody = `{"username":"caderno","password":"230572gu@"}`;
  const newApiHeaders = new Headers();
  newApiHeaders.append("schema", database.toLowerCase());
  newApiHeaders.append("content-type", "application/json");
  
  return async (dispatch) => {
    try {
      const restoken = await fetch(api.get_token, {
        method: "post",
        body,
      });
      const json = await restoken.json();
      const token = json.data[0].token;

      const res = await fetch(`${api.recupera_senha}?username=${user}`, {
        method: "get",
        headers: {token}
      });

      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        dispatch({ type: AUTH_RECUPERA_SENHA, email: json[0] });
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      dispatch({ type: AUTH_RECUPERA_SENHA, email: null });
      throw new Error(error);
    }
  };
};

export const checkNeedValidateCon = (token, alunoid) => {
  return async (dispatch) => {
    try {
      const res = await fetch(
        `${api.verifica_comunicacao}?alunoid=${alunoid}`,
        {
          method: "GET",
          headers: { token },
        }
      );
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        const conValidation = json[0].data[0];
        dispatch({
          type: AUTH_VERIFICACAO_COMUNICACAO,
          conValidation: conValidation,
        });
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      dispatch({ type: AUTH_VERIFICACAO_COMUNICACAO, conValidation: {} });
      throw new Error(error);
    }
  };
};

export const sendEmailValidationCode = (data, token) => {
  return async (dispatch) => {
    try {
      const body = new FormData();
      body.append("dados", data);
      const res = await fetch(api.engajamento_email, {
        method: "POST",
        headers: { token },
        body,
      });
      if (res.status >= 200 && res.status < 300) {
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      throw new Error(error);
    }
  };
};

export const sendSMSValidationCode = (data, token) => {
  return async (dispatch) => {
    try {
      const body = new FormData();
      body.append("dados", data);
      const res = await fetch(api.engajamento_sms, {
        method: "POST",
        headers: { token },
        body,
      });
      if (res.status >= 200 && res.status < 300) {
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      throw new Error(error);
    }
  };
};

export const sendWhatsappValidationCode = (data, token) => {
  return async (dispatch) => {
    try {
      const body = new FormData();
      body.append("dados", data);
      const res = await fetch(api.engajamento_wpp, {
        method: "POST",
        headers: { token },
        body,
      });
      if (res.status >= 200 && res.status < 300) {
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      throw new Error(error);
    }
  };
};

export const saveConConfirmation = (data, alunoid, token) => {
  return async (dispatch) => {
    try {
      const body = new FormData();
      body.append("dados", data);
      const res = await fetch(`${api.salva_confirmacao}?alunoid=${alunoid}`, {
        method: "POST",
        headers: { token },
        body,
      });
      if (res.status >= 200 && res.status < 300) {
        dispatch({
          type: AUTH_SALVA_COMUNICACAO,
          conValidationSaved: res.status,
        });
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      dispatch({ type: AUTH_SALVA_COMUNICACAO, conValidationSaved: -1 });
      throw new Error(error);
    }
  };
};

export const pickModule = () => {
  return (dispatch) => {
    dispatch({
      type: AUTH_PICK_MODULE,
    });
  };
};

// reducer
export default function reducer(state = { status: {} }, action) {
  switch (action.type) {
    case AUTH_SET_AUTH:
      return action.auth;

    case AUTH_GET_TOKEN:
      return { ...action.auth, notSelected: true };

    case AUTH_CLEAR:
      return {};

    case AUTH_RECUPERA_SENHA:
      return { ...state, recoverEmail: action.email };

    case AUTH_VERIFICACAO_COMUNICACAO:
      return { ...state, conValidation: action.conValidation };

    case AUTH_SALVA_COMUNICACAO:
      return { ...state, conValidationSaved: action.conValidationSaved };

    case AUTH_PICK_MODULE:
      return { ...state, notSelected: false };

    case SET_STATUS:
      const newStatus = { ...state.status };
      const { key, value } = action.payload;

      newStatus[key] = value;

      return { ...state, status: newStatus };

    default:
      return state;
  }
}
