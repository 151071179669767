//App Context Managment
import axios from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import { bindActionCreators } from "redux";

import RouterController from "./shared/routes/Router";

//Actions
import {
  acceptTerms,
  setParams,
  termosDeUso,
  saveInstallPrompt,
  setColors,
} from "./shared/store/app";
import {
  checkNeedValidateCon,
  logout,
  recuperaSenha,
  setAuth,
} from "./shared/store/auth";
import {
  checkAccess,
  listaMenu,
  listaMenuCanais,
  listaMenuEducador,
  listaPerfis,
} from "./shared/store/routes";

//Configs
import packageJson from "../package.json";
import { api } from "./shared/api";

//Styling
import CssBaseline from "@mui/material/CssBaseline";

//Components
import CookieComplianceDialog from "./ui/blocks/CookieComplianceDialog";

//Utils
import _cookies from "./shared/utils/_cookies";
import _cookiesChunked from "./shared/utils/_cookiesChunked";
import "../src/colors.css";
import TermsOfUseDialog from "./ui/components/TermsOfUseDialog";
import UserStep from "./ui/components/UserStep";
import { qtdMensagensNaoLidas } from "./shared/store/mail";

function App({
  children,
  auth,
  app,
  listaMenu,
  setAuth,
  setParams,
  listaMenuCanais,
  checkNeedValidateCon,
  termosDeUso,
  acceptTerms,
  checkAccess,
  logout,
  listaMenuEducador,
  listaPerfis,
  qtdMensagensNaoLidas,
  saveInstallPrompt,
  setColors,
  routes,
}) {
  const [termsOfUseAccepted, setTermsOfUseAccepted] = useState(false);

  const [firstTimeLogin, setFirstTimeLogin] = useState(false);
  const [state] = useState(() => {
    return {
      accessedRoute: window.location.pathname.replace("/aluno", ""),
      pwaInstallEvent: null,
    };
  });
  const checkCookies = () => {
    const authCookie = _cookies.getItem("auth");
    const params = _cookiesChunked.getItem("params");
    const isLogoutOccurs = _cookies.getItem("logout");

    if (isLogoutOccurs) {
      _cookies.removeAll();
    }
    return authCookie && params && !isLogoutOccurs;
  };

  const checkToken = async (token) => {
    const res = await axios.get(api.valida_token, {
      headers: { token },
    });

    return res.status === 200;
  };

  const isUserAuthenticated = useMemo(() => {
    return !!auth?.token || !!checkCookies();
  }, [auth?.token]);

  const checkLogin = useCallback(async () => {
    if (!checkCookies()) return false;
    if (_cookies.getItem("hasUnansweredsurveys")) return false;

    const authCookie = _cookies.getItem("auth");

    if (!(await checkToken(authCookie?.token))) {
      return false;
    }

    await setAuth(authCookie);
    return true;
  }, [setAuth]);

  async function acceptTermsOfUse() {
    const { termsOfUse } = app;

    const platform = navigator?.userAgentData?.platform || navigator?.platform;

    termsOfUse.forEach((item) => {
      try {
        acceptTerms(
          '[{"alunoid":' +
            auth.user.alunoid +
            ',"termoid":' +
            item.TERMOID +
            ',"dados_device":"' +
            "ip:" +
            app.ipData +
            ",navegador:" +
            navigator?.userAgent +
            ",plataforma:" +
            platform +
            '"}]',
          auth.token
        );
      } catch (error) {
        console.error("erro:", error);
        throw new Error(error);
      }
    });
  }

  function setAccepted(accepted) {
    setTermsOfUseAccepted(accepted);
    acceptTermsOfUse();
  }

  //Side Effects
  // Capture Install PWA Prompt Event
  const appMountHandler = useCallback(() => {
    let _isMounted = true;
    let event;
    const isInStandaloneMode = () =>
      "standalone" in window.navigator && window.navigator.standalone;

    const hostCookie = _cookies.getItem("host", false);
    const host = window.location.host.replace("www.", "");

    if (hostCookie && hostCookie !== host) {
      logout();
    }

    checkLogin()
      .then((loggedIn) => {
        if (!loggedIn) {
          logout();
        }
      })
      .catch((error) => console.error(error));

    // Checks if should display install popup notification:
    if (!isInStandaloneMode() && _isMounted) {
      window.addEventListener("beforeinstallprompt", (e) => {
        e.preventDefault();
        saveInstallPrompt(e);
      });
    }

    return function cleanEvent() {
      _isMounted = false;
      typeof event === "object" &&
        window.removeEventListener(event.type, event.func);
    };
  }, [checkLogin, logout, state]);

  useEffect(appMountHandler, [appMountHandler]);

  useEffect(() => {
    if (!app.termsOfUse && auth?.user?.alunoid) {
      termosDeUso(auth?.user?.alunoid, auth?.token);
    }

    setTermsOfUseAccepted(!(auth?.user?.alunoid && app.termsOfUse?.length > 0));
  }, [auth, app?.termosDeUso]);

  //Fetch App Params
  const initAppParams = useCallback(async () => {
    if (!auth.token || !auth?.user?.alunoid) return;
    await setParams(auth.token, auth.user.alunoid);
    await listaMenu(auth.token, auth.user.alunoid);
    await listaMenuCanais(auth.token, auth.user.alunoid);
    await checkNeedValidateCon(auth.token, auth.user.alunoid);
    await checkAccess(auth.token, auth.user.alunoid);

    await qtdMensagensNaoLidas(auth.user.alunoid, auth.token);
    listaMenuEducador(auth.token, auth.user.username);
    listaPerfis(auth.token, auth.user.username);
  }, [
    auth?.token,
    auth?.user?.alunoid,
    checkAccess,
    checkNeedValidateCon,
    listaMenu,
    listaMenuCanais,
    setParams,
    listaMenuEducador,
    listaPerfis,
  ]);

  useEffect(() => {
    initAppParams();
  }, [initAppParams]);

  useEffect(() => {
    if (auth && auth?.user?.primeiro_acesso === "N") {
      setFirstTimeLogin(false);
    } else if (auth && auth?.user?.primeiro_acesso === "S") {
      setFirstTimeLogin(true);
    }
  }, [auth?.user, auth.user?.primeiro_acesso, firstTimeLogin]);

  useEffect(() => {
    if (app?.params) {
      setColors(app.params);
    }
  }, [app]);

  useEffect(() => {
    window.document
      .querySelector('meta[name="viewport"]')
      .setAttribute(
        "content",
        "width=device-width, height=device-height, initial-scale=1, minimum-scale=1, maximum-scale=1"
      );
  }, []);

  if (auth && auth?.user?.primeiro_acesso) {
    _cookies.setItem("version", packageJson.buildDate);
  }
  const validPass = _cookies.getItem("valid", true);

  if (
    (firstTimeLogin && auth.user) ||
    (validPass &&
      validPass == app?.params?.SENHA_PADRAO &&
      app?.params?.EXIGE_TROCA_SENHA === "S")
  )
    return <UserStep />;

  const disciplinaId = _cookies.getItem("disciplinaId", true);
  const postCapituloid = _cookies.getItem("postCapituloid", true);
  const canalid = _cookies.getItem("linkcanalid", true);
  const presencaId = _cookies.getItem("presencaid", true);
  if (disciplinaId) {
    _cookies.remove("disciplinaId");
    return <Navigate replace to={`/extras/${disciplinaId}`} />;
  }
  if (postCapituloid) {
    _cookies.remove("postCapituloid");
    return <Navigate replace to={`/postagens/${postCapituloid}`} />;
  }
  if (canalid) {
    _cookies.remove("linkcanalid");
    return <Navigate replace to={`/canais/${canalid}`} />;
  }
  if (presencaId) {
    _cookies.remove("presencaid");
    return <Navigate replace to={`/presenca/${presencaId}`} />;
  }

  if (!termsOfUseAccepted) {
    return <TermsOfUseDialog setAccepted={setAccepted} />;
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <CssBaseline enableColorScheme />
      <RouterController isAuthenticated={isUserAuthenticated} />
      <CookieComplianceDialog />
    </DndProvider>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  app: state.app,
  routes: state.routes,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setAuth,
      setParams,
      listaMenu,
      listaMenuCanais,
      recuperaSenha,
      logout,
      checkNeedValidateCon,
      acceptTerms,
      termosDeUso,
      checkAccess,
      listaMenuEducador,
      listaPerfis,
      qtdMensagensNaoLidas,
      saveInstallPrompt,
      setColors,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(App);
