import RouterBuilder from "./RouterBuilder";
import IntegratedLoginPage from "../../ui/pages/LoginPage/IntegratedLoginPage";
const routeFactory = RouterBuilder.routeFactory;

const routes = [
  routeFactory(
    "integrated-piracanjuba",
    "login-piracanjuba",
    IntegratedLoginPage
  ),
  routeFactory("integrated-login", "integratedlogin", IntegratedLoginPage),
  // routeFactory("integrated-login", "integratedlogin", IntegratedLoginPage),
];

const IntegratedRoutes = new RouterBuilder(routes).nestedRoutes;

export default IntegratedRoutes;
