import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../../shared/lib/axios";
import { useContext } from "react";
import { ManagerCatalogueContext } from "../common/context";
import { createNotification } from "../../../../shared/store/notifications";
import { queryClient } from "../../../../shared/lib/reactQuery";

export const saveGroupSelection = ({ data }) => {
  return axios.middleware.post(
    "/Backend.asmx/salvar_grupos_prateleira",
    new URLSearchParams({
      dados: `[${JSON.stringify(data)}]`,
    }).toString(),

    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );
};

export const useSaveGroupSelection = ({ config, courseId } = {}) => {
  return useMutation({
    ...config,
    mutationFn: saveGroupSelection,
    onSuccess: () => {
      createNotification({
        type: "success",
        message: "Grupos adicionados com sucesso",
      });
      queryClient.invalidateQueries(["shelfCourseGroups", { courseId }]);
    },
  });
};
