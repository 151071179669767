import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  Rating,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { ReactComponent as MoodIcon1 } from "../assets/Mood1.svg";
import { ReactComponent as MoodIcon2 } from "../assets/Mood2.svg";
import { ReactComponent as MoodIcon3 } from "../assets/Mood3.svg";
import { ReactComponent as MoodIcon4 } from "../assets/Mood4.svg";
import { ReactComponent as MoodIcon5 } from "../assets/Mood5.svg";
import { ReactComponent as MoodIcon6 } from "../assets/Mood3Grey.svg";
import { useState } from "react";
import { useTenantParameters } from "../api/getTenantParameters";
import { useStudentMood } from "../api/getStudentMood";
import { useAnswerMoodFeedback } from "../api/answerMoodFeedback";
import { AddReaction, Close, Help, QuestionMark } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { Console } from "mdi-material-ui";
const StyledRating = styled(Rating)(({ theme }) => ({
  "& .MuiRating-iconEmpty .MuiSvgIcon-root": {
    color: theme.palette.action.disabled,
  },
}));

function IconContainer(props) {
  const { value, selected, ...other } = props;

  return (
    <span {...other} style={selected ? { transform: "scale(1.2)" } : {}}>
      {customIcons[value].icon}
    </span>
  );
}

const customIcons = {
  "-1": {
    icon: (
      <Icon fontSize="large">
        <AddReaction
          sx={{
            color: "#A0A0A0",
            height: "36px",
            width: "36px",
            fontSize: "36px",
            marginTop: "-10px",
            // transform: "scale(1.2)",
          }}
          id="test2"
        />
      </Icon>
    ),
    label: "Com raiva",
    color: "#E62D29",
  },
  1: {
    icon: (
      <Icon className="MuiRating-iconHover" fontSize="large">
        <MoodIcon1 id="test2" />
      </Icon>
    ),
    label: "Com raiva",
    color: "#E62D29",
  },
  2: {
    icon: (
      <Icon fontSize="large">
        <MoodIcon2 />
      </Icon>
    ),
    label: "Insatisfeito",
    color: "#EF864C",
  },
  3: {
    icon: (
      <Icon fontSize="large">
        <MoodIcon3 />
      </Icon>
    ),
    label: "Neutro",
    color: "#000000",
  },
  4: {
    icon: (
      <Icon fontSize="large">
        <MoodIcon4 />
      </Icon>
    ),
    label: "Contente",
    color: "#8CBAE5",
  },
  5: {
    icon: (
      <Icon fontSize="large">
        <MoodIcon5 />
      </Icon>
    ),
    label: "Feliz",
    color: "#4DAF4B",
  },
};
export default function MoodFeedback() {
  // const pickModuleDialogOpen = useSelector((state) => state.auth.notSelected);
  const [value, setValue] = useState(-1);
  const [hover, setHover] = useState(-1);
  const [textFieldValue, setTextFieldValue] = useState();
  const [dialogOpen, setDialogOpen] = useState(false);
  const tenantParamaters = useTenantParameters();
  const studentMood = useStudentMood({
    config: {
      onSuccess: (data) => {
        if (
          data.message === "Humor não registrado neste dia." ||
          data.message === "Nenhum humor registrado."
        ) {
          setDialogOpen(true);
        }
      },
    },
  });
  const alreadyAnswered = studentMood.data?.object?.mood != undefined;
  const MoodMutation = useAnswerMoodFeedback({
    config: {
      onSuccess: () => {
        setDialogOpen(false);
      },
    },
  });
  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  if (
    tenantParamaters.isLoading ||
    tenantParamaters.isError ||
    studentMood.isLoading ||
    studentMood.isError ||
    MoodMutation.isLoading
  ) {
    return <></>;
  } else if (tenantParamaters.data?.ATIVAR_CONTROLE_HUMOR == "S") {
    return (
      <>
        <IconButton
          onClick={() => {
            setDialogOpen(true);
          }}
          sx={{
            "& > .MuiIcon-root": { height: "36px", width: "36px" },
            padding: 0,
          }}
        >
          {customIcons[studentMood?.data?.object?.mood || -1].icon}
        </IconButton>
        <Dialog open={dialogOpen}>
          <DialogTitle sx={{ paddingRight: 5 }}>
            Como você está agora?
          </DialogTitle>
          <DialogContent>
            <Box
              sx={{
                // backgroundColor: "red",
                paddingTop: 1,
                gap: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* <MoodIcon5 /> */}
              <Rating
                sx={{ "& .MuiIcon-root": { height: "50px", width: "50px" } }}
                IconContainerComponent={(props) =>
                  IconContainer({ ...props, selected: props.value === value })
                }
                getLabelText={(value) => customIcons[value].label}
                onChange={(event, newValue) => {
                  !alreadyAnswered
                    ? MoodMutation.mutate({
                        mood: newValue,
                        reason: textFieldValue,
                      })
                    : setValue(newValue);
                }}
                onChangeActive={(event, newHover) => {
                  setHover(newHover);
                }}
              />
              <TextField
                value={textFieldValue}
                onChange={(event) => {
                  setTextFieldValue(event.target.value);
                }}
                sx={{
                  width: "100%",
                  display: alreadyAnswered ? "auto" : "none",
                }}
                multiline
                rows={4}
                label="Motivo(opcional)"
                hidden
                placeholder="Deixe aqui seu comentário"
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button disabled={!alreadyAnswered} onClick={handleCloseDialog}>
              Cancelar
            </Button>
            <Button
              sx={{ display: value || value == null > -1 ? "auto" : "none" }}
              onClick={() => {
                MoodMutation.mutate({ mood: value, reason: textFieldValue });
              }}
            >
              Enviar
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  } else {
    return <></>;
  }
}
