import React from "react";
import withStyles from "@mui/styles/withStyles";
import { Box } from "@mui/material";

import openingBook from "./../../shared/assets/images/caderno_abrindo_circular.gif";

const styles = (theme) => ({
  loading: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
});

function Loading({ classes, sx, size = "4.5rem", ...props }) {
  const imageAspect = 830 / 585;

  return (
    <div className={classes.loading}>
      <Box
        component="img"
        src={openingBook}
        sx={{
          maxWidth: "unset",
          objectFit: "cover",
          width: `calc(${imageAspect} * ${size})`,
          height: size,
        }}
        className={classes.progress}
        {...props}
      />
    </div>
  );
}

export default withStyles(styles, { withTheme: true })(Loading);
