import { api } from "../api";

const INITIAL_STATE = {
  recipients: [],
  messages: [],
};

// actions
const MAIL_MENSAGENS = "MAIL_MENSAGENS";
const MAIL_EXCLUIR_MENSAGEM = "MAIL_EXCLUIR_MENSAGEM";
const MAIL_RECUPERAR_MENSAGEM = "MAIL_RECUPERAR_MENSAGEM";
const MAIL_RESET = "MAIL_RESET";
const MAIL_LISTAR_DESTINATARIOS = "MAIL_LISTAR_DESTINATARIOS";
const MAIL_LISTAR_DESTINATARIOS_CURSO = "MAIL_LISTAR_DESTINATARIOS_CURSO";
const MAIL_MARCAR_COMO_LIDA = "MAIL_MARCAR_COMO_LIDA";
const MAIL_QTD_MENSAGENS_NAO_LIDAS = "MAIL_QTD_MENSAGENS_NAO_LIDAS";

// action creators
export const mensagens = (body, token) => {
  return async (dispatch) => {
    try {
      const res = await fetch(api.mensagens, {
        method: "post",
        headers: { token },
        body,
      });
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        const messages = json[0].data;
        dispatch({ type: MAIL_MENSAGENS, messages });
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      console.log("mensagens error", error);
      throw new Error(error);
    }
  };
};

export const excluirMensagem = (body, MENSAGEMID, token) => {
  return async (dispatch) => {
    try {
      const res = await fetch(api.excluir_mensagem, {
        method: "post",
        headers: { token },
        body,
      });
      if (res.status >= 200 && res.status < 300) {
        // const json = await res.json();
        dispatch({ type: MAIL_EXCLUIR_MENSAGEM, MENSAGEMID });
        return "success";
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      console.log("excluirMensagem error", error);
      return "error";
    }
  };
};

export const recuperarMensagem = (body, MENSAGEMID, token) => {
  return async (dispatch) => {
    try {
      const res = await fetch(api.recuperar_mensagem, {
        method: "POST",
        headers: { token },
        body,
      });
      if (res.status >= 200 && res.status < 300) {
        // const json = await res.json();
        dispatch({ type: MAIL_RECUPERAR_MENSAGEM, MENSAGEMID });
        return "success";
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      console.log("excluirMensagem error", error);
      return "error";
    }
  };
};

export const listarDestinatarios = (alunoid, token) => {
  return async (dispatch) => {
    try {
      const res = await fetch(
        `${api.listar_destinatarios}?alunoid=${alunoid}`,
        {
          method: "get",
          headers: { token },
        }
      );
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        const recipients = json[0].data;
        dispatch({ type: MAIL_LISTAR_DESTINATARIOS, recipients });
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      dispatch({ type: MAIL_LISTAR_DESTINATARIOS, recipients: null });
      console.log("listarDestinatarios error", error);
      throw new Error(error);
    }
  };
};

export const listaDestinatariosCurso = (disciplinaid, token) => {
  return async (dispatch) => {
    try {
      const res = await fetch(
        `${api.listar_destinatarios_curso}?disciplinaid=${disciplinaid}`,
        {
          method: "get",
          headers: { token },
        }
      );
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        const courseRecipients = json[0].data;
        dispatch({ type: MAIL_LISTAR_DESTINATARIOS_CURSO, courseRecipients });
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      console.log("listaDestinatariosCurso error", error);
      dispatch({
        type: MAIL_LISTAR_DESTINATARIOS_CURSO,
        courseRecipients: null,
      });
      throw new Error(error);
    }
  };
};

export const enviarMensagem = (body, token) => {
  return async (dispatch) => {
    try {
      const res = await fetch(api.enviar_mensagem, {
        method: "post",
        headers: { token },
        body,
      });
      if (res.status >= 200 && res.status < 300) {
        // const json = await res.json();
        return "success";
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      console.log("enviarMensagem error", error);
      return "error";
    }
  };
};

export const marcarComoLida = (body, MENSAGEMID, token) => {
  return async (dispatch) => {
    try {
      const res = await fetch(api.marcar_como_lida, {
        method: "post",
        headers: { token },
        body,
      });
      if (res.status >= 200 && res.status < 300) {
        // const json = await res.json();
        dispatch({ type: MAIL_MARCAR_COMO_LIDA, MENSAGEMID });
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      console.log("marcarComoLida error", error);
      throw new Error(error);
    }
  };
};

export const qtdMensagensNaoLidas = (alunoid, token) => {
  return async (dispatch) => {
    try {
      const res = await fetch(
        `${api.qtd_mensagens_nao_lidas}?alunoid=${alunoid}`,
        {
          method: "get",
          headers: { token },
        }
      );
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        const unread = json[0].data.nao_lidas;
        dispatch({ type: MAIL_QTD_MENSAGENS_NAO_LIDAS, unread });
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      console.log("qtdMensagensNaoLidas error", error);
      dispatch({ type: MAIL_QTD_MENSAGENS_NAO_LIDAS, unread: 0 });
    }
  };
};

export const resetMail = () => {
  return { type: MAIL_RESET };
};

// reducer
export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case MAIL_MENSAGENS:
      return { ...state, messages: action.messages };

    case MAIL_EXCLUIR_MENSAGEM:
      const notRemoved = state.messages.filter(
        (message) => message.MENSAGEMID !== action.MENSAGEMID
      );
      return { ...state, messages: notRemoved };

    case MAIL_RECUPERAR_MENSAGEM:
      const notRestored = state.messages.filter(
        (message) => message.MENSAGEMID !== action.MENSAGEMID
      );
      return { ...state, messages: notRestored };

    case MAIL_LISTAR_DESTINATARIOS:
      return { ...state, recipients: action.recipients };

    case MAIL_LISTAR_DESTINATARIOS_CURSO:
      return { ...state, courseRecipients: action.courseRecipients };

    case MAIL_MARCAR_COMO_LIDA:
      const checkedRead = state.messages.map((message) => {
        if (message.MENSAGEMID === action.MENSAGEMID) {
          message.LIDA = "S";
        }
        return message;
      });
      return { ...state, messages: checkedRead };

    case MAIL_QTD_MENSAGENS_NAO_LIDAS:
      return { ...state, unread: action.unread };

    case MAIL_RESET:
      return { ...state, messages: null };

    default:
      return state;
  }
}
