import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import withStyles from "@mui/styles/withStyles";
import clsx from "clsx";
import React from "react";
import _cookiesChunked from "../../../../../shared/utils/_cookiesChunked";

const params = _cookiesChunked.getItem("params");

const styles = (theme) => ({
  textField: { width: "100%" },
  dense: { marginTop: theme.spacing(3) },
  noMarginTop: { marginTop: 0 },
});

function DynamicField(classes, handleChange, paramIndex, name, user) {
  if (!params[`CAMPO${paramIndex}_NOME`]) return null;

  let label = params[`CAMPO${paramIndex}_NOME`];
  label =
    params?.CAMPOS_OBRIGATORIOS_PERFIL?.toUpperCase().search(
      label.toUpperCase()
    ) > -1
      ? label + "*"
      : label;
  const optionsString = params[`CAMPO${paramIndex}_OPCOES`];

  if (!optionsString) {
    return (
      <TextField
        label={label}
        className={clsx(classes.textField, classes.dense)}
        margin="dense"
        variant="outlined"
        name={name}
        value={user[name]}
        onChange={(event) => handleChange(event)}
      />
    );
  }

  const options = optionsString.split(",");

  const menuItems = options.map((option, i) => {
    return (
      <MenuItem key={`${name}MenuItem${i}`} value={option}>
        {option}
      </MenuItem>
    );
  });
  return (
    <FormControl
      variant="outlined"
      className={clsx(classes.textField, classes.dense)}
      margin="dense"
    >
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <Select
        value={user[name]}
        onChange={(event) => handleChange(event)}
        margin="dense"
        id={name}
        label={label}
      >
        <MenuItem value="" />
        {menuItems}
      </Select>
    </FormControl>
  );
}
function FormCol1({ classes, handleChange, user }) {
  
  return (
    <Box>
      <TextField
        label={params?.CAMPOS_OBRIGATORIOS_PERFIL?.toUpperCase().search('EMAIL') > -1 ? "Email*" : "Email"}
        className={clsx(classes.textField, classes.dense, classes.noMarginTop)}
        margin="dense"
        variant="outlined"
        name="email"
        value={user.email}
        onChange={(event) => handleChange(event)}
        InputLabelProps={{
          shrink: user.cep,
          style: { fontSize: "1rem", backgroundColor: "white" },
        }}
      />

      {DynamicField(classes, handleChange, 1, "msn", user)}
      {DynamicField(classes, handleChange, 2, "orkut", user)}
      {DynamicField(classes, handleChange, 3, "lattes", user)}
      {DynamicField(classes, handleChange, 4, "empresa", user)}
      {DynamicField(classes, handleChange, 5, "cargo", user)}

      <FormControl
        variant="outlined"
        className={clsx(classes.textField, classes.dense)}
        margin="dense"
      >
        <InputLabel htmlFor="sexo">{params?.CAMPOS_OBRIGATORIOS_PERFIL?.toUpperCase().search('SEXO') > -1 ? "Sexo*" : "Sexo"}</InputLabel>
        <Select
          required={
            params?.CAMPOS_OBRIGATORIOS_PERFIL?.toUpperCase().search("SEXO") > -1
          }
          value={user.sexo}
          onChange={(event) => {
            if (event.target.value !== user.sexo) {
              handleChange(event);
            }
            }}
          margin="dense"
          id="sexo"
          label="Sexo"
          name="sexo"
        >
          <MenuItem value="" />
          <MenuItem value="Masculino">Masculino</MenuItem>
          <MenuItem value="Feminino">Feminino</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}

export default withStyles(styles, { withTheme: true })(FormCol1);
