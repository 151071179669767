import {
  Dialog,
  Button,
  Box,
  Checkbox,
  FormControlLabel,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Add } from "@mui/icons-material";
import { useTrackCourses } from "../api/getTrackCourses";
import { useSetCourseOnTrack } from "../api/setCourseOnTrack";
import { createNotification } from "../../../shared/store/notifications";
import { useDispatch, useSelector } from "react-redux";
import { setSkipWarning } from "../../../shared/store/warnings";

export default function AddCourseToTrack({
  sx = {},
  trackId,
  isCertification = false,
  courseId,
  onSuccess,
}) {
  const dispatch = useDispatch();
  const { skipAddCourseToTrackWarning, skipAddCourseToCertificationWarning } =
    useSelector((state) => {
      return {
        skipAddCourseToTrackWarning:
          state.warnings?.skipAddCourseToTrackWarning,
        skipAddCourseToCertificationWarning:
          state.warnings.skipAddCourseToCertificationWarning,
      };
    });

  const [checked, setChecked] = useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  // const [isCourseOnTrack, setIsCourseOnTrack] = useState(false);
  const [isFirtFetchSuccess, setIsFirstFetchSuccess] = useState(true);
  const courses = useTrackCourses({
    trackId,
  });
  const isCourseOnTrack = courses?.data?.some((course) => {
    return course.courseId == courseId;
  });
  const addCourseToTrack = useSetCourseOnTrack({ trackId });

  const handleAddCourse = () => {
    addCourseToTrack.mutate(
      {
        trackId,
        courseId,
        orderID: courses.data.length,
      },
      { onSuccess }
    );
  };
  const handleCloseConfirm = () => {
    setConfirmationDialogOpen(false);
  };
  const handleOpenConfirm = () => {
    setConfirmationDialogOpen(true);
  };
  const handleConfirm = () => {
    dispatch(
      setSkipWarning(
        isCertification
          ? "skipAddCourseToCertificationWarning"
          : "skipAddCourseToTrackWarning",
        checked
      )
    );
    handleAddCourse();

    handleCloseConfirm();
  };

  const handleCheck = (event) => {
    setChecked(event.target.checked);
  };
  const handleClick = () => {
    if (
      (isCertification && skipAddCourseToCertificationWarning) ||
      (!isCertification && skipAddCourseToTrackWarning)
    ) {
      handleAddCourse();
    } else {
      handleOpenConfirm();
    }
  };
  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", ...sx }}>
        {isCourseOnTrack && (
          <Typography variant="caption" color="error" sx={sx}>
            Curso já existente nessa{" "}
            {isCertification ? "certificação" : "trilha"}
          </Typography>
        )}
        <Button
          disabled={
            isCourseOnTrack || courses.isLoading || addCourseToTrack.isLoading
          }
          startIcon={<Add />}
          onClick={handleClick}
        >
          Adicionar Curso
        </Button>
      </Box>

      <Dialog onClose={handleCloseConfirm} open={confirmationDialogOpen}>
        <DialogTitle>Confirmar ação</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ textAlign: "justify" }}>
            {isCertification
              ? `Existem alunos matriculados nessa certificação. Qualquer alteração realizada irá impactá-los imediatamente. Alunos que já concluíram a Certificação NÃO TERÃO SEUS CERTIFICAOS ANULADOS se um novo curso for inserido. Se a certificação for do tipo Sequencial (com pré-requisitos), os cursos que estiverem posicionados após os novos cursos inseridos ficarão indisponíveis até que o aluno conclua o novo curso inserido.`
              : `Existem alunos cursando essa Trilha. Qualquer alteração realizada irá impactá-los imediatamente. Alunos que já concluíram a Trilha terão seu status alterado para "Em Andamento". Se a trilha for Sequencial, os cursos que estiverem posicionados após os novos cursos inseridos ficarão indisponíveis até que o aluno conclua o novo curso inserido.`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <FormControlLabel
            label="Entendi! Não é necessário mostrar essa mensagem novamente!"
            control={<Checkbox checked={checked} onChange={handleCheck} />}
          />
          <Button onClick={handleConfirm}>Confirmar</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
