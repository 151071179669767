import {
  Container,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  Dialog,
  DialogContent,
  IconButton,
} from "@mui/material";
import React, { useState } from "react";
import _cookies from "../../../shared/utils/_cookies";
import DynamicIcons from "../../blocks/DynamicIcons";
import { AiOutlinePlusSquare } from "react-icons/ai";
import { IoIosShare } from "react-icons/io";
import { connect } from "react-redux";
import { Close } from "@mui/icons-material";

function getOperationSystemName() {
  if (navigator.appVersion.search(/iPhone/i) > -1) return "ios";
  if (navigator.appVersion.search(/Android/i) > -1) return "android";
  return null;
}

const AppMenuItem = ({
  app,
  breakString,
  theme,
  isDisplayLarge,
  primaryTypographyStyles,
  primaryTypographyStyleMedia,
}) => {
  const [open, setOpen] = useState();
  const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  };
  if (
    (!app.installEvent && getOperationSystemName() == "android") ||
    getOperationSystemName() == null
  ) {
    return false;
  }
  // Detects if device is in standalone mode
  const isInStandaloneMode = () =>
    "standalone" in window.navigator && window.navigator.standalone;
  const onClick = () => {
    // Checks if should display install popup notification:
    if (isIos() && !isInStandaloneMode()) {
      setOpen(true);
    } else {
      app.installEvent.prompt();
    }
  };

  return (
    <Box
      sx={{
        display: {
          xs: "block", // theme.breakpoints.up('xs')
          sm: "none", // theme.breakpoints.up('sm')
          md: "none", // theme.breakpoints.up('md')
          lg: "none", // theme.breakpoints.up('lg')
          xl: "none", // theme.breakpoints.up('xl')
        },
      }}
    >
      <ListItemButton
        id={"app"}
        onClick={() => {
          onClick();
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: 1.4,
            justifyContent: "center",
          }}
        >
          <Container
            disableGutters
            sx={{ width: "20px", [breakString]: { width: "30px" } }}
          >
            <DynamicIcons
              originalIconName={"app"}
              iconName={"app"}
              stroke={theme.palette.menu.font}
              defaultPathColor={theme.palette.menu.font}
            />
          </Container>
        </ListItemIcon>
        <ListItemText
          primary={"Instalar App"}
          primaryTypographyProps={{
            style: isDisplayLarge
              ? primaryTypographyStyles
              : primaryTypographyStyleMedia,
          }}
          sx={{
            color: theme.palette.menu.font,
          }}
        />
      </ListItemButton>
      <Dialog onClose={() => setOpen(false)} open={open} title="Instalar App">
        <IconButton
          onClick={() => setOpen(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "error.main",
          }}
        >
          <Close />
        </IconButton>
        <DialogContent sx={{ padding: "30px" }}>
          <AiOutlinePlusSquare
            size="1.5em"
            color="#7F8C8D"
            style={{ display: "unset" }}
          />
          {`: Para instalar o App no seu iPhone, clique em `}
          <IoIosShare
            size="1.5em"
            color="#7F8C8D"
            style={{ display: "unset" }}
          />
          {` e então clique em adicionar à tela inicial`}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

const mapStateToProps = (state) => ({ app: state.app });

export default connect(mapStateToProps)(AppMenuItem);
