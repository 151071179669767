import { useCallback, useEffect, useState } from "react";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { ReactComponent as CatalogIcon } from "../../shared/assets/svg/icons/catalog-icon.svg";
import { ReactComponent as ChannelsIcon } from "../../shared/assets/svg/icons/channels-icon.svg";
import { ReactComponent as ExtraCoursesIcon } from "../../shared/assets/svg/icons/extra-courses-icon.svg";
import { ReactComponent as GameficationIcon } from "../../shared/assets/svg/icons/gamefication-icon.svg";
import IdeasIcon from "../../shared/assets/svg/icons/Ideas";
import { ReactComponent as LibraryIcon } from "../../shared/assets/svg/icons/library-icon.svg";
import { ReactComponent as LiveIcon } from "../../shared/assets/svg/icons/live-icon.svg";
import { ReactComponent as SocialNetworkIcon } from "../../shared/assets/svg/icons/social-network-icon.svg";
import { ReactComponent as TrackIcon } from "../../shared/assets/svg/icons/track-icon.svg";
import GetAppIcon from "@mui/icons-material/GetApp";
import { menuIconsUrl } from "../../shared/config";
import { Box } from "@mui/material";

const iconsDefault = {
	trilhas: TrackIcon,
	noticias: LiveIcon,
	extras: ExtraCoursesIcon,
	tutoriais: LibraryIcon,
	base: CatalogIcon,
	canais: ChannelsIcon,
	game: GameficationIcon,
	postagens: SocialNetworkIcon,
	ideias: IdeasIcon,
	"menu-educador": TrackIcon,
	default: QuestionMarkIcon,
	app: GetAppIcon,
};

export default function DynamicIcons({
	iconName,
	originalIconName,
	stroke,
	defaultPathColor,
}) {
	const url = `${menuIconsUrl}${iconName?.replaceAll(" ", "%20")}`;
	const Default = iconsDefault?.[originalIconName] || iconsDefault?.default;

	const [isImage, setIsImage] = useState(false);

	const doesImageExist = (url) =>
		new Promise((resolve) => {
			const img = new Image();
			img.src = url;
			img.onload = () => resolve(true);
			img.onerror = () => resolve(false);
		});

	const handleIcon = useCallback(async () => {
		const haveImage = await doesImageExist(url);
		setIsImage(haveImage);
	}, [url]);

	useEffect(() => {
		handleIcon();
	}, [handleIcon]);

	return isImage ? (
		<Box
			sx={{
				height: "1.5rem",
				width: "1.5rem",
				...(stroke
					? {
							maskImage: `url(${url})`,
							backgroundColor: stroke,
							maskRepeat: "no-repeat",
							maskSize: "100%",
					  }
					: {
							backgroundImage: `url(${url})`,
					  }),
			}}
		/>
	) : (
		<Default stroke={defaultPathColor} />
	);
}
