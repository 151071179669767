import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import withStyles from "@mui/styles/withStyles";
import clsx from "clsx";
import React from "react";
import MaskedInput from "react-text-mask";
import _cookiesChunked from "../../../../../shared/utils/_cookiesChunked";
import estados from "./estados";

const params = _cookiesChunked.getItem("params");

const styles = (theme) => ({
  textField: { width: "100%" },
  dense: { marginTop: theme.spacing(3) },
  noMarginTop: { marginTop: 0 },
});

const MaskedField = (props) => {
  const { ref, ...other } = props;
  let mask = null;
  switch (props.name) {
    case "telefone":
      mask = (value) =>
        value.length < 15
          ? // prettier-ignore
            ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
          : // prettier-ignore
            ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
      break;

    case "cep":
      // prettier-ignore
      mask = [/\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/]
      break;

    case "datanascimento":
      // prettier-ignore
      mask = [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]
      break;

    default:
      break;
  }
  return (
    <MaskedInput
      {...other}
      ref={ref}
      mask={mask}
      placeholderChar={"\u2000"}
      guide={false}
    />
  );
};

function FormCol2({ classes, handleChange, user }) {
  const optEstados = estados.map((estado, i) => (
    <MenuItem key={`optestado${i}`} value={estado}>
      {estado}
    </MenuItem>
  ));
  return (
    <Box>
      <TextField
        label={params?.CAMPOS_OBRIGATORIOS_PERFIL?.toUpperCase().search('TELEFONE') > -1 ? "Celular*" : "Celular"}
        className={clsx(classes.textField, classes.dense, classes.noMarginTop)}
        margin="dense"
        variant="outlined"
        name="telefone"
        value={user.telefone || ""}
        onChange={(event) => handleChange(event)}
        InputProps={{
          inputComponent: MaskedField,
        }}
        InputLabelProps={{
          shrink: user.telefone,
          style: { fontSize: "1rem", backgroundColor: "white" },
        }}
      />
      <TextField
        label={params?.CAMPOS_OBRIGATORIOS_PERFIL?.toUpperCase().search('NASCIMENTO') > -1 ? "Data de Nascimento*" : "Data de Nascimento"}
        className={clsx(classes.textField, classes.dense)}
        margin="normal"
        variant="outlined"
        name="datanascimento"
        value={user.datanascimento}
        onChange={(event) => handleChange(event)}
        InputProps={{ inputComponent: MaskedField }}
        InputLabelProps={{
          shrink: user.datanascimento,
          style: { fontSize: "1rem", backgroundColor: "white" },
        }}
      />
      <TextField
        label={params?.CAMPOS_OBRIGATORIOS_PERFIL?.toUpperCase().search('ENDERECO') > -1 ? "Endereço*" : "Endereço"}
        className={clsx(classes.textField, classes.dense)}
        margin="dense"
        variant="outlined"
        name="endereco"
        value={user.endereco}
        onChange={(event) => handleChange(event)}
        InputLabelProps={{
          shrink: user.endereco,
          style: { fontSize: "1rem", backgroundColor: "white" },
        }}
      />
      <TextField
        label={params?.CAMPOS_OBRIGATORIOS_PERFIL?.toUpperCase().search('CIDADE') > -1 ? "Cidade*" : "Cidade"}
        className={clsx(classes.textField, classes.dense)}
        margin="dense"
        variant="outlined"
        name="cidade"
        value={user.cidade}
        onChange={(event) => handleChange(event)}
        InputLabelProps={{
          shrink: user.cidade,
          style: { fontSize: "1rem", backgroundColor: "white" },
        }}
      />
      <FormControl
        variant="outlined"
        className={clsx(classes.textField, classes.dense)}
        margin="dense"
      >
        <InputLabel htmlFor="estado">{params?.CAMPOS_OBRIGATORIOS_PERFIL?.toUpperCase().search('ESTADO') > -1 ? "Estado*" : "Estado"}</InputLabel>
        <Select
          required={
            params?.CAMPOS_OBRIGATORIOS_PERFIL?.toUpperCase().search("ESTADO") >
            -1
          }
          value={user.estado}
          onChange={(event) => {
            event.target.name = "estado";
            if (event.target.value !== user.estado) {
              handleChange(event);
            }
          }}
          margin="dense"
          id="estado"
          label="Estado"
          name="estado"
        >
          <MenuItem value="" />
          {optEstados}
        </Select>
      </FormControl>
      <TextField
        label={params?.CAMPOS_OBRIGATORIOS_PERFIL?.toUpperCase().search('PAIS') > -1 ? "País*" : "País"}
        className={clsx(classes.textField, classes.dense)}
        margin="dense"
        variant="outlined"
        name="pais"
        value={user.pais}
        onChange={(event) => handleChange(event)}
        InputLabelProps={{
          shrink: user.cidade,
          style: { fontSize: "1rem", backgroundColor: "white" },
        }}
      />
      <TextField
        label={params?.CAMPOS_OBRIGATORIOS_PERFIL?.toUpperCase().search('CEP') > -1 ? "CEP*" : "CEP"}
        className={clsx(classes.textField, classes.dense)}
        margin="dense"
        variant="outlined"
        name="cep"
        value={user.cep}
        onChange={(event) => handleChange(event)}
        InputProps={{ inputComponent: MaskedField }}
        InputLabelProps={{
          shrink: user.cep,
          style: { fontSize: "1rem", backgroundColor: "white" },
        }}
      />
    </Box>
  );
}

export default withStyles(styles, { withTheme: true })(FormCol2);
