import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

function Alert({
  closeAlert,
  loading,
  showAlert,
  text,
  title,
  closeButtonText = "FECHAR",
  footerActions = <></>,
}) {
  return (
    <Dialog open={showAlert}>
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>

      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {text}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        {footerActions}
        <Button disabled={loading} onClick={closeAlert}>
          {closeButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Alert;
