import { Typography } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "./Switch";

function LabelledSwitch({
  color,
  label,
  labelPlacement,
  name,
  value,
  onChange,
  textTransform,
  fontWeight,
  fontSize,
  disabled = false,
  sx = {},
  ...props
}) {
  return (
    <FormControlLabel
      sx={sx}
      label={
        <Typography
          sx={{
            color: color,
            ...(fontWeight && { fontWeight }),
            ...(textTransform && { textTransform }),
            ...(fontSize && { fontSize }),
          }}
        >
          {label}
        </Typography>
      }
      labelPlacement={labelPlacement}
      control={
        <Switch
          name={name}
          checked={value}
          onChange={onChange}
          disabled={disabled}
          {...props}
        />
      }
    />
  );
}

export default LabelledSwitch;
