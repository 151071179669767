import { Container, useTheme } from "@mui/material";
import MainContent from "../../partials/MainContent";
import Navbar from "../../blocks/Navbar";
import BreadCrumbs from "../../components/BreadCrumbs";
import { useLocation } from "react-router-dom";

const NavigationPage = ({
  children,
  rootRoute,
  themeRoute,
  postLabel,
  actions,
  aliases,
  searchBar = false,
  onClickSearch = () => {},
}) => {
  const location = useLocation();

  const theme = useTheme();
  const largeDisplay = theme.breakpoints.up("md");

  return (
    <>
      <Navbar />
      <MainContent>
        <Container
          disableGutters
          sx={{
            [largeDisplay]: {
              maxWidth: "unset",
            },

            height: "100%",
          }}
        >
          <BreadCrumbs
            root={rootRoute}
            themeRoute={themeRoute}
            postLabel={postLabel}
            actions={actions}
            aliases={aliases}
            breakActions={location.pathname.includes("canais")}
            searchBar={searchBar}
            onClickSearch={onClickSearch}
          />
          {children}
        </Container>
      </MainContent>
    </>
  );
};

export default NavigationPage;
