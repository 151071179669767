import { useQuery } from "@tanstack/react-query";
import { axios } from "../../shared/lib/axios";

export const getCertificate = async ({
  matriculaid,
  courseType = "proprio",
}) => {
  const res = await axios.middleware.get(
    courseType === "proprio"
      ? "/aluno.asmx/Novo_Certificado_Proprio"
      : "/aluno.asmx/Novo_Certificado_Convenio",
    {
      params: {
        matriculaid,
      },
    }
  );
  return res[0].data[0][0];
};

export const getCertificateConvenio = ({ matriculaid = "" }) => {
  return axios.middleware.get("/Novo_Certificado_Convenio", {
    params: {
      matriculaid,
    },
  });
};

// export const getCertificateImageURL =
export const useGetCertificate = ({
  config,
  courseType,
  enrollmentId,
} = {}) => {
  return useQuery({
    ...config,
    queryKey: ["certificate", { courseType, enrollmentId }],
    queryFn: () => getCertificate({ courseType, matriculaid: enrollmentId }),
  });
};
