import {
  Box,
  Button,
  Chip,
  Container,
  Divider,
  Link,
  Typography,
  useTheme,
  List,
  ListItem,
  ListItemIcon,
  ListItemButton,
  ListItemText,
  useMediaQuery,
  lighten,
} from "@mui/material";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { keysNav } from "../../shared/constants/routes";
import { logout } from "../../shared/store/auth";
import isMobile from "../../shared/utils/isMobile";
import Drawer from "../components/Drawer/Drawer";
import DynamicIcons from "./DynamicIcons";
import AppMenuItem from "../components/AppMenuItem";

import {
  setBreadCrumbsElement,
  setCurrentTool,
  setLastMenuPath,
} from "../../shared/store/menu/menu";
import {
  Build,
  SignalWifiConnectedNoInternet4TwoTone,
  ToysOutlined,
} from "@mui/icons-material";

export function getAliasProps({ ferramenta, filtros, link }) {
  filtros = filtros?.replace(/\s/g, "");
  const routeKey = keysNav?.[ferramenta];
  const parsedFilters = filtros?.length
    ? filtros.replace(/\s/g, "").split(",")
    : [];
  const isDirectChannel = parsedFilters.length === 1 && ferramenta != "EXTRAS";
  const route =
    link.replace(/\s/g, "") ||
    `/${routeKey}${
      isDirectChannel
        ? `/${parsedFilters[0]}`
        : parsedFilters.length > 1 ||
          (ferramenta === "EXTRAS" && parsedFilters.length > 0)
        ? `?ids=${filtros}`
        : ""
    }`;
  return { routeKey, parsedFilters, isDirectChannel, route };
}

function Navbar({ auth, logout, params, ...props }) {
  const [open, setOpen] = useState(!isMobile());

  const toggleDrawer = () => {
    setOpen((prev) => !prev);
  };

  return (
    <div>
      <Drawer
        sx={{ backgroundColor: "menu.background" }}
        open={open}
        onClickOutside={() => {
          if (isMobile()) setOpen(!open);
        }}
        onClick={toggleDrawer}
        logoAdicional={params?.logomarca_adicional}
        title={<NavTitle logout={logout} auth={auth} />}
      >
        <Nav open={open} toggleDrawer={toggleDrawer} {...props} />
      </Drawer>
    </div>
  );
}

function NavTitle({ auth, logout }) {
  const navigate = useNavigate();

  const handleLogout = async () => {
    await logout(auth.user.alunoid, auth.token);
    navigate("/login");
  };

  return (
    <Box sx={{ width: "80%" }} margin="0.5rem">
      <Typography fontSize="1.25rem" fontWeight="600" color="menu.font">
        Bem-vindo
      </Typography>
      <Typography noWrap color="menu.font">
        {auth?.user?.nome}
      </Typography>
    </Box>
  );
}

function Nav({ open, toggleDrawer, aliases, ...props }) {
  const { setBreadCrumbsElement, setCurrentTool, setLastMenuPath } = props;
  const navigate = useNavigate();
  const pathName = useLocation().pathname;

  const [selected, _setSelected] = useState(null);

  function setSelected(index) {
    _setSelected(index);
    sessionStorage.setItem("cvMenuItem", index);
  }

  useEffect(() => {
    const storedSelected = sessionStorage.getItem("cvMenuItem");

    if (storedSelected !== "null" && pathName !== "/") {
      _setSelected(Number(storedSelected));
    }
  }, []);

  const primaryTypographyStyles = {
    fontWeight: "800",
    fontSize: "14px",
  };
  const primaryTypographyStyleMedia = {
    fontWeight: "800",
    fontSize: "12px",
  };

  const theme = useTheme();
  const breakString = theme.breakpoints.up("md");
  const isDisplayLarge = useMediaQuery(breakString);

  const getPathColor = (index) =>
    selected === index ? theme.palette.primary.dark : theme.palette.menu.font;

  const getPathColorIcon = (index) =>
    theme.palette.menu.icon
      ? selected === index
        ? theme.palette.primary.dark
        : theme.palette.menu.font
      : null;

  return (
    <List
      sx={{
        [theme.breakpoints.down("md")]: {
          paddingBottom: "25px",
        },
      }}
    >
      <AppMenuItem
        breakString={breakString}
        theme={theme}
        isDisplayLarge={isDisplayLarge}
        primaryTypographyStyles={primaryTypographyStyles}
        primaryTypographyStyleMedia={primaryTypographyStyleMedia}
      />
      {!!aliases &&
        aliases.map(
          ({ titulo, ferramenta, filtros, link, local, icone }, index) => {
            const { routeKey, parsedFilters, isDirectChannel, route } =
              getAliasProps(
                { titulo, ferramenta, filtros, link, local, icone },
                index
              );

            if (ferramenta == "TITULO") {
              return (
                <Divider
                  sx={{
                    display: open ? "auto" : "none",
                    "&::before, &::after": {
                      borderColor: "secondary.light",
                    },
                  }}
                >
                  <Chip
                    // avatar={<Build />}
                    size="small"
                    label={titulo}
                    color="primary"
                  ></Chip>
                </Divider>
              );
            }
            return (
              <div key={"link - " + route + index}>
                <ListItemButton
                  id={routeKey}
                  sx={{
                    "&:hover": {
                      backgroundColor: lighten(
                        theme.palette.menu.background,
                        0.3
                      ),
                    },
                  }}
                  onClick={() => {
                    setCurrentTool(titulo, ferramenta);
                    setBreadCrumbsElement(route, 0, isDirectChannel);
                    setSelected(index);

                    link.replace(/\s/g, "");
                    setLastMenuPath(route);
                    if (ferramenta == "LINK") {
                      if (local == "EXTERNO") {
                        window.open(route, "_blank");
                      } else {
                        navigate(`/local/${titulo}/`);
                      }
                    } else {
                      navigate(route, {
                        state: { parsedFilters: parsedFilters },
                      });
                    }
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 1.4 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <Container
                      disableGutters
                      sx={{ width: "20px", [breakString]: { width: "30px" } }}
                    >
                      <DynamicIcons
                        originalIconName={routeKey}
                        iconName={icone}
                        stroke={getPathColorIcon(index)}
                        defaultPathColor={getPathColor(index)}
                      />
                    </Container>
                  </ListItemIcon>
                  <ListItemText
                    primary={titulo}
                    primaryTypographyProps={{
                      style: isDisplayLarge
                        ? primaryTypographyStyles
                        : primaryTypographyStyleMedia,
                    }}
                    sx={{
                      opacity: open ? 1 : 0,
                      color: getPathColor(index),
                    }}
                  />
                </ListItemButton>
                {aliases[index + 1]?.ferramenta != "TITULO" && (
                  <Divider sx={{ backgroundColor: "menu.background" }} />
                )}
              </div>
            );
          }
        )}
    </List>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  aliases: state.routes.aliases,
  aliasesEducador: state.routes.aliasesEducador,
  pinnedChannels: state.routes.aliasesCanais,
  params: state.app.params,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logout,
      setBreadCrumbsElement,
      setCurrentTool,
      setLastMenuPath,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
