import IconButton from "@mui/material/IconButton";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

function ToggleButton({ sx = {}, open, onClick, hidden }) {
  const Icon = open ? ArrowBackIosNewIcon : ArrowForwardIosIcon;

  return (
    <IconButton
      sx={{
        backgroundColor: "menu.icon",
        color: "menu.background",
        ["&:hover"]: {
          backgroundColor: "menu.icon",
          filter: "drop-shadow(0px 0px 10px #000000)",
        },
        ...sx,
      }}
      onClick={onClick}
    >
      <Icon />
    </IconButton>
  );
}

export default ToggleButton;
