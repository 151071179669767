import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../shared/lib/axios";

export const finishSurveyAttempt = async (data) => {
  const res = await axios.cadernoVirtualBase.post(
    "/survey-service2/research/answer",
    data
  );
  return res;
};

export const useFinishSurveyAttempt = ({ config } = {}) => {
  return useMutation({ ...config, mutationFn: finishSurveyAttempt });
};
