import { Box, Button } from "@mui/material";
import { CloudUpload } from "@mui/icons-material";
import { useMemo } from "react";
import FileMiniature from "../FileMiniature";

function list(files, onDelete) {
  const label = (file) =>
    `'${file.name}' of size '${file.size}' and type '${file.type}'`;

  return (
    <>
      {files?.map((file, index) => {
        return (
          <FileMiniature
            key={`file-miniature-${index}`}
            file={file}
            onDelete={() => {
              onDelete(index);
            }}
          />
        );
      })}
    </>
  );
}

export const FilesList = ({ fileInputRef, files, onDelete }) => {
  const fileList = useMemo(() => list(files), [files]);
  if (files.length === 0) {
    return <></>;
  }
  return (
    <Box
      sx={{
        display: "flex",
        padding: 1,
        gap: 3,
        flexDirection: "row",
        flexWrap: "wrap",
        width: "100%",
        height: "100%",
        
      }}
    >
      <Button
        sx={{ width: "90px", height: "90px" }}
        variant="outlined"
        onClick={() => {
          fileInputRef.current.click();
        }}
      >
        <CloudUpload />
      </Button>
      {list(files, onDelete)}
    </Box>
  );
};
