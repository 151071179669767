import React, { useEffect } from "react";
import { styled } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const DefaultStyledForm = styled("form")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 10,
}));

export default function Form({
  defaultValues,
  children,
  onSubmit,
  schema = {},
  options = {},
  Component = DefaultStyledForm,
}) {
  const config = {
    resolver: yupResolver(schema),
    defaultValues,
    ...options,
  };
  const methods = useForm(config);
  const { handleSubmit } = methods;

  return (
    <Component onSubmit={handleSubmit(onSubmit)}>{children(methods)}</Component>
  );
}
