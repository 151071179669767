import { lazy } from "react";
import RouterBuilder from "./RouterBuilder";
// import MailPage from "../../ui/pages/MailPage";
// import ProfilePage from "../../ui/pages/ProfilePage";

const MailPage = lazy(() => import("../../ui/pages/MailPage"));
const ProfilePage = lazy(() => import("../../ui/pages/ProfilePage"));

const routeFactory = RouterBuilder.routeFactory;

const routes = [
  routeFactory("mail", "mail", MailPage),
  routeFactory("profile", "profile", ProfilePage),
];

const UserRoutes = new RouterBuilder(routes).nestedRoutes;

export default UserRoutes;
