import { api } from "../api";

// actions
const REACTIONS_MARCAR_REACAO = "REACTIONS_MARCAR_REACAO";
const REACTIONS_REACOES_POST = "REACTIONS_REACOES_POST";
const REACTIONS_REACAO_ALUNO_POST = "REACTIONS_REACAO_ALUNO_POST";

// action creators
export const marcarReacao = (body, reaction, token, postagemid) => {
  return async dispatch => {
    try {
      const res = postagemid == null ? await fetch(api.marcar_reacao, {
        method: "post",
        headers: { token },
        body
      })
      :
      await fetch(api.marcar_reacao_postagem, {
        method: "post",
        headers: { token },
        body
      });

      const reactionKey = Object.keys(reaction)[0];

      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        dispatch({
          type: REACTIONS_MARCAR_REACAO,
          currentReaction: {
            key: reactionKey,
            value: reaction[reactionKey]
          }
        });
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      dispatch({ type: REACTIONS_MARCAR_REACAO, currentReaction: null });
      console.log("marcarReacao error", error);
    }
  };
};

export const reacoesPost = (capituloid, token, postagemid) => {
  return async dispatch => {
    try {

      const res = postagemid == null ? await fetch(`${api.reacoes_post}?capituloid=${capituloid}`, {
        method: "get",
        headers: { token }
      }) :
      await fetch(`${api.reacao_postagem_aluno}?postagemid=${postagemid}`, {
        method: "get",
        headers: { token }
      });
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        dispatch({ type: REACTIONS_REACOES_POST, postReactions: json[0].data[0] });
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      console.log("reacoesPost error", error);
      dispatch({ type: REACTIONS_REACOES_POST, postReactions: null });
    }
  };
};

export const reacaoAlunoPost = (alunoid, capituloid, token, postagemid) => {
  return async dispatch => {
    try {
      const res = postagemid == null ? await fetch(
        `${api.reacao_aluno_post}?alunoid=${alunoid}&capituloid=${capituloid}`,
        {
          method: "get",
          headers: { token }
        }
      ) :
      await fetch(
        `${api.reacao_postagem_aluno}?alunoid=${alunoid}&postagemid=${postagemid}`,
        {
          method: "get",
          headers: { token }
        }
      );
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        dispatch({
          type: REACTIONS_REACAO_ALUNO_POST,
          userReactions: json[0].data[0]
        });
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      console.log("reacaoAlunoPost error", error);
      dispatch({ type: REACTIONS_REACAO_ALUNO_POST, reactions: null });
    }
  };
};

// reducer
export default function reducer(state = {}, action) {
  switch (action.type) {
    case REACTIONS_MARCAR_REACAO:
      return {
        ...state,
        userReactions: {
          ...state.userReactions,
          [action.currentReaction.key]: action.currentReaction.value
        }
      };

    case REACTIONS_REACOES_POST:
      return { ...state, postReactions: action.postReactions };

    case REACTIONS_REACAO_ALUNO_POST:
      return { ...state, userReactions: action.userReactions };

    default:
      return state;
  }
}
