import {
  Container,
  FormControl,
  Input,
  Button,
  Box,
  InputLabel,
  TextField,
  useFormControl,
  styled,
} from "@mui/material";
import { useEffect, useState } from "react";
import { baseUrl, getClient } from "../../../shared/config";
import { useNavigate } from "react-router-dom";
import MaskedInput from "react-text-mask";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../shared/lib/axios";

const MaskedField = (props) => {
  const { ref, ...other } = props;
  let mask = null;
  mask = [
    /\d/,
    /\d/,
    /\d/,
    ".",
    /\d/,
    /\d/,
    /\d/,
    ".",
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
  ];
  return (
    <MaskedInput
      {...other}
      ref={ref}
      mask={mask}
      placeholderChar={"\u2000"}
      guide
    />
  );
};

async function signUpRequest(data) {
  const body = new FormData();
  Object.entries(data).map(([key, value]) => {
    body.append(`${key}`, value);
  });
  const temp = {
    ...data,
    documentNumber: data.documentNumber.replaceAll(".", "").replaceAll("-", ""),
  };
  const res = await axios.cadernoVirtualBase.post(
    "/autenticacao-service/newUser",
    temp,
    { suppressNotification: true }
  );
  return res;
}

const useRegisterUser = ({ config } = {}) => {
  return useMutation({ ...config, mutationFn: signUpRequest });
};

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiFilledInput-input": {
    backgroundColor: "rgb(232, 241, 250)",
  },
}));

function SignUpForm({ formData, onChangeHandle }) {
  const [submitDisabled, SetSubmitDisabled] = useState(true);
  const { mutate, isLoading } = useRegisterUser();
  const navigate = useNavigate();
  useEffect(() => {
    if (
      formData["name"] == "" ||
      formData["email"] == "" ||
      formData["postalCode"] == "" ||
      formData["number"] == "" ||
      formData["neighborhood"] == "" ||
      formData["city"] == "" ||
      formData["state"] == ""
    ) {
      SetSubmitDisabled(true);
    } else {
      SetSubmitDisabled(false);
    }
  }, [formData]);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        rowGap: "8px",
        marginTop: "30px",
        height: "500px",
      }}
    >
      <StyledTextField
        onChange={onChangeHandle}
        value={formData["name"]}
        id="name"
        label="Nome"
        variant="filled"
        required
      />
      <StyledTextField
        onChange={onChangeHandle}
        value={formData["email"]}
        id="email"
        label="Email"
        variant="filled"
        required
      />

      <StyledTextField
        onChange={onChangeHandle}
        value={formData["postalCode"]}
        id="postalCode"
        label="CEP"
        variant="filled"
        required
      />
      <StyledTextField
        onChange={onChangeHandle}
        value={formData["addressDescription"]}
        id="addressDescription"
        label="Endereco Completo"
        variant="filled"
        required
      />
      <StyledTextField
        onChange={onChangeHandle}
        value={formData["number"]}
        id="number"
        label="Número da Casa"
        variant="filled"
        required
      />
      <StyledTextField
        onChange={onChangeHandle}
        value={formData["addressComplement"]}
        id="addressComplement"
        label="Referência"
        variant="filled"
      />
      <StyledTextField
        onChange={onChangeHandle}
        value={formData["neighborhood"]}
        id="neighborhood"
        label="Bairro"
        variant="filled"
        required
      />
      <StyledTextField
        onChange={onChangeHandle}
        value={formData["city"]}
        id="city"
        label="Cidade"
        variant="filled"
        required
      />
      <StyledTextField
        onChange={onChangeHandle}
        value={formData["state"]}
        id="state"
        label="Estado"
        variant="filled"
        required
      />
      <Button
        type="submit"
        variant="contained"
        sx={{ backgroundColor: "#CCFF33" }}
        disabled={submitDisabled || isLoading}
        onClick={() => {
          mutate(formData, {
            onSuccess: (data) => {
              navigate(
                `../login?password=${btoa("ABRH")}&username=${btoa(
                  data.object.username.replaceAll(".", "").replaceAll("-", "")
                )}`
              );
            },
          });
        }}
      >
        {!isLoading ? "Registar Usuário " : " Carregando"}
      </Button>
    </Box>
  );
}

function FirstStep({ isLoading, formData, onClickSubmit, onChangeHandle }) {
  const [submitDisabled, SetSubmitDisabled] = useState(true);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        rowGap: "8px",
        marginTop: "30px",
        height: "500px",
      }}
    >
      <StyledTextField
        onChange={onChangeHandle}
        value={formData["documentNumber"]}
        id="documentNumber"
        label="CPF"
        variant="filled"
        InputProps={{
          inputComponent: MaskedField,
        }}
        required
      />
      <Button
        variant="contained"
        sx={{ backgroundColor: "#CCFF33" }}
        disabled={formData["documentNumber"] == "" || isLoading}
        onClick={onClickSubmit}
      >
        {!isLoading ? "Cadastrar CPF " : " Carregando"}
      </Button>
    </Box>
  );
}

const FormLogo = styled("img")(({ theme }) => ({
  [theme.breakpoints.up(theme.breakpoints.values.sm)]: {},
  [theme.breakpoints.up(theme.breakpoints.values.md)]: {},
  [theme.breakpoints.up(theme.breakpoints.values.lg)]: {},
  [theme.breakpoints.up(theme.breakpoints.values.xl)]: {},

  width: "auto",
  maxHeight: "10vh",
  // width: "auto",
}));

function SignUpPage({}) {
  const navigate = useNavigate();
  const { mutate, isLoading } = useRegisterUser();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    documentNumber: "",
    postalCode: "",
    addressDescription: "",
    number: "",
    addressComplement: "",
    neighborhood: "",
    city: "",
    state: "",
  });
  const [showFirstStep, setShowFirstStep] = useState(true);
  const onChangeHandle = (event) => {
    const temp = { ...formData, [event.target.id]: event.target.value };
    setFormData(temp);
  };
  return (
    <Container
      maxWidth="false"
      disableGutters
      sx={{
        backgroundColor: "#136AC1",
        height: "100vh",
        height: "100vh",
        width: "100vw",
        position: "absolute",
        overflow: "scroll",
      }}
    >
      <Container>
        <Container
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
          }}
        >
          <FormLogo src={`${baseUrl}/img/logo_horizontal_branca.png`} alt="" />
          <FormLogo src={`${baseUrl}/img/logo_totalmente_branca.png`} alt="" />
        </Container>

        {showFirstStep && (
          <FirstStep
            isLoading={isLoading}
            formData={formData}
            setFormData={setFormData}
            onChangeHandle={onChangeHandle}
            onClickSubmit={() => {
              mutate(formData, {
                onSuccess: (data) => {
                  if (data.message == "User Already Exists") {
                    navigate(
                      `../login?password=${btoa("ABRH")}&username=${btoa(
                        formData.documentNumber
                          .replaceAll(".", "")
                          .replaceAll("-", "")
                      )}`
                    );
                  }
                },
                onError: (data) => {
                  if (data.message == "User Already Exists") {
                    navigate(
                      `../login?password=${btoa("ABRH")}&username=${btoa(
                        formData.documentNumber
                          .replaceAll(".", "")
                          .replaceAll("-", "")
                      )}`
                    );
                  } else {
                    setShowFirstStep(false);
                  }
                },
              });
            }}
          />
        )}
        {!showFirstStep && (
          <SignUpForm
            isLoading={isLoading}
            formData={formData}
            setFormData={setFormData}
            onChangeHandle={onChangeHandle}
          />
        )}
      </Container>
    </Container>
  );
}

export default SignUpPage;
