import axios from "axios";
import { api } from "../api";
import isMobile from "../utils/isMobile";
import isPwa from "../utils/isPwa";

// actions
const ROUTES_SET_CURRENT_ROUTE = "ROUTES_SET_CURRENT_ROUTE";
const ROUTES_LISTA_MENU = "ROUTES_LISTA_MENU";
const ROUTES_LISTA_MENU_CANAIS = "ROUTES_LISTA_MENU_CANAIS";
const ROUTES_LISTA_MENU_EDUCADOR = "ROUTES_LISTA_MENU_EDUCADOR";
const ROUTES_LISTA_PERFIS = "ROUTES_LISTA_PERFIS";

// action creators
export const setCurrentRoute = (currentRoute) => {
  currentRoute = currentRoute || { currentRoute: null };
  return { type: ROUTES_SET_CURRENT_ROUTE, currentRoute };
};

export const checkAccess = (token, alunoid) => {
  return async () => {
    const isApp = isMobile() || isPwa() ? "S" : "N";
    fetch(`${api.acessou_plataforma}?alunoid=${alunoid}&is_app=${isApp}`, {
      method: "get",
      headers: { token: token },
    });
  };
};

export const listaMenuCanais = (token, alunoid) => {
  return async (dispatch) => {
    const url = api.lista_menu_canais + "?alunoid=" + alunoid;
    try {
      const res = await fetch(url, {
        method: "get",
        headers: { token },
      });
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();

        const data = json[0].data;
        dispatch({ type: ROUTES_LISTA_MENU_CANAIS, aliasesCanais: data });
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      dispatch({ type: ROUTES_LISTA_MENU_CANAIS, aliasesCanais: null });
      //throw new Error(error);
    }
  };
};
export const listaMenu = (token, alunoId) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `https://middleware.cadernovirtual.com.br/aluno.asmx/Lista_Menu_Dinamico?alunoid=${alunoId}`,
        {
          headers: {
            token,
          },
        }
      );
      if (response.status >= 200 && response.status < 300) {
        //TODO remover map quando houver menu certo
        //antigo: const aliases = response.data[0].data;
        const aliases = response.data[0].data.map((menuItem) =>
          menuItem.titulo === "Pesquisa"
            ? { ...menuItem, ferramenta: "PESQUISA" }
            : menuItem
        );

        dispatch({ type: ROUTES_LISTA_MENU, aliases });
      } else {
        throw new Error(response.statusText);
      }
    } catch (error) {
      dispatch({ type: ROUTES_LISTA_MENU, aliases: null });
      console.error("listaMenu error", error);
      throw new Error(error);
    }
  };
};

export const listaMenuEducador = (token, username) => {
  return async (dispatch) => {
    try {
      const data = new URLSearchParams();

      data.append("dados", `[{"username":"${username}"}]`);

      const response = await axios.post(api.menu_educador, data, {
        headers: {
          token,
        },
      });
      if (response.status >= 200 && response.status < 300) {
        const aliasesEducador = response.data[0].data;
        dispatch({ type: ROUTES_LISTA_MENU_EDUCADOR, aliasesEducador });
      } else {
        throw new Error(response.statusText);
      }
    } catch (error) {
      dispatch({ type: ROUTES_LISTA_MENU_EDUCADOR, aliasesEducador: null });
      console.error("listaMenuEducador", error);
      throw new Error(error);
    }
  };
};

export const listaPerfis = (token, username) => {
  return async (dispatch) => {
    try {
      const data = new URLSearchParams();

      data.append("dados", `[{"username":"${username}"}]`);

      const response = await axios.post(api.profiles, data, {
        headers: {
          token,
        },
      });
      if (response.status >= 200 && response.status < 300) {
        const perfis = response.data[0].data;
        dispatch({ type: ROUTES_LISTA_PERFIS, perfis });
      } else {
        throw new Error(response.statusText);
      }
    } catch (error) {
      dispatch({ type: ROUTES_LISTA_PERFIS, perfis: null });
      console.error("listaMenuEducador", error);
      throw new Error(error);
    }
  };
};

// reducer
const initialState = { currentRoute: null };
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ROUTES_SET_CURRENT_ROUTE:
      return { ...state, currentRoute: action.currentRoute };

    case ROUTES_LISTA_MENU:
      return { ...state, aliases: action.aliases };
    case ROUTES_LISTA_MENU_CANAIS:
      return { ...state, aliasesCanais: action.aliasesCanais };
    case ROUTES_LISTA_MENU_EDUCADOR:
      return { ...state, aliasesEducador: action.aliasesEducador };
    case ROUTES_LISTA_PERFIS:
      return { ...state, perfis: action.perfis };
    default:
      return state;
  }
}
