import { Typography, Chip } from "@mui/material";
import { Delete, Done } from "@mui/icons-material";

export default function PreviewMarker({ viewed }) {
  return (
    <Chip
      sx={{
        opacity: "0.25",
      }}
      size="small"
      label={viewed ? "Concluído" : "Não concluído"}
      color={viewed ? "success" : "error"}
    ></Chip>
  );
}
