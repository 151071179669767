import FileUploadQuestion from "../../questions/components/FileUploadQuestion";
import { defaultSurveyQuestionTemplates } from "../../questions/utils/defaultQuestionTemplates";
import { uploadFileAnswer } from "../api/uploadFileAnswer";

export const surveyQuestionTemplates = {
  ...defaultSurveyQuestionTemplates,
  upload: {
    label: "Upload de arquivo",
    component: FileUploadQuestion,
    componentProps: { uploadMutationFn: uploadFileAnswer },
    // disabled: true,
    defaultValues: {
      mandatory: "N",
      category: "upload",
      type: "upload",
    },
  },
};
