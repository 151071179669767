import RichTextInput from "./RichTextInput";
import RichTextContent from "./RichTextContent";

export default function RichText({ onChange, value, isEditing, ...rest }) {
  return isEditing ? (
    <>
      <RichTextInput onChange={onChange} value={value} {...rest} />
    </>
  ) : (
    <RichTextContent value={value} {...rest} modules={{ toolbar: false }} />
  );
}
