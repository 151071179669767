import { Star } from "@mui/icons-material";
import { Box } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";

export const FiveStars = (props) => <Stars starsNumber={5} {...props} />;
export const TenStars = (props) => <Stars starsNumber={10} {...props} />;

function Stars({ value = 0, onChange, starsNumber }) {
  const initialStarsValue = Array(starsNumber).fill(false);
  const [currentStars, setCurrentStars] = useState(initialStarsValue);
  const [currentHover, setCurrentHover] = useState(initialStarsValue);
  const [isSelecting, setIsSelecting] = useState(false);

  useEffect(() => {
    if (value) {
      setCurrentStars((prev) => {
        prev = initialStarsValue;
        prev.splice(0, value, ...Array(value).fill(true));

        return [...prev];
      });
    }
  }, [value]);

  function setNote(newValue) {
    setIsSelecting(false);
    setCurrentStars((prev) => {
      prev = initialStarsValue;
      prev.splice(0, newValue, ...Array(newValue).fill(true));

      return [...prev];
    });
    onChange(newValue);
  }

  return (
    <Box
      sx={{
        display: "flex",
        margin: "1rem",
        padding: "0.25rem",
        backgroundColor: "gray",
        width: "fit-content",
        borderRadius: "10px",
      }}
    >
      {currentStars.map((value, index) => (
        <Star
          fontSize="large"
          sx={{
            cursor: "pointer",
            color: isSelecting
              ? currentHover[index]
                ? "goldenrod"
                : "white"
              : value
              ? "yellow"
              : "white",
          }}
          onClick={() => setNote(index + 1)}
          onMouseEnter={() => {
            setIsSelecting(true);
            setCurrentHover((prev) => {
              const newValue = index + 1;
              prev = [...initialStarsValue];
              prev.splice(0, newValue, ...Array(newValue).fill(true));

              return [...prev];
            });
          }}
          onMouseLeave={() => {
            setIsSelecting(false);
            setCurrentHover(initialStarsValue);
          }}
        />
      ))}
    </Box>
  );
}
