import { api } from "../api";

// actions
const ONDEMAND_DISCIPLINAS_LOJA = "ONDEMAND_DISCIPLINAS_LOJA";
const ONDEMAND_NOVO_PEDIDO_MATRICULA = "ONDEMAND_NOVO_PEDIDO_MATRICULA";

// action creators
export const disciplinasLoja = (alunoid, token) => {
  return async (dispatch) => {
    try {
      const res = await fetch(`${api.disciplinas_loja}?alunoid=${alunoid}`, {
        method: "get",
        headers: { token },
      });
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        const onDemand = json[0].data;
        dispatch({ type: ONDEMAND_DISCIPLINAS_LOJA, onDemand });
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      throw new Error(error);
    }
  };
};

export const novoPedidoMatricula = (user, courseData, token) => {
  return async (dispatch) => {
    try {
      const payload = {
        alunoid: user.alunoid,
        email: user.email,
        cursoid: courseData.cursoid,
        disciplinaid: courseData.disciplinaid,
      };
      const body = new FormData();

      body.append("dados",`${JSON.stringify([payload])}`);
      const res = await fetch(api.novo_pedido_matricula, {
        method: "post",
        headers: { token  },
        body,
      });
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        return "success";
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      console.log("novoPedidoMatricula error", error);
      throw new Error(error);
    }
  };
};

// reducer
export default function reducer(state = {}, action) {
  switch (action.type) {
    case ONDEMAND_DISCIPLINAS_LOJA:
      return action.onDemand;
    default:
      return state;
  }
}
