import { useQuery } from "@tanstack/react-query";
import { axios } from "../../shared/lib/axios";
import { useState } from "react";

export const registerLogon = ({ params }) => {
  return axios.cadernoVirtualBase.get(
    "/autenticacao-service/logon?" + new URLSearchParams(params)
  );
};

export const useMaintainLogon = ({ config, params } = {}) => {
  const [failedOnce, setFailedOnce] = useState(false);
  return useQuery({
    refetchInterval: 1000 * 5,
    ...config,
    retry: false,
    enabled: !failedOnce && config.enabled,
    queryKey: ["courseLogon", params],
    queryFn: () => registerLogon({ params }),
    onError: () => {
      setFailedOnce(true);
    },
  });
};
