import {
  Alert,
  Box,
  Container,
  Paper,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { Settings } from "@mui/icons-material";
import NavigationPage from "../../../ui/templates/NavigationPage";
import { SaveSurveyForm } from "../components/SaveSurveyForm";
import { useNavigate } from "react-router-dom";
import Wizard from "../../../ui/components/Wizard";
import { NavigationVariantOutline } from "mdi-material-ui";
import { useRef, useState } from "react";
import { useIsMutating } from "@tanstack/react-query";

export const CreateSurvey = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const submitButtonRef = useRef();
  const [isSurveyDirty, setSurveyDirty] = useState(false);
  const isSurveyMutating = useIsMutating({
    mutationKey: ["saveSurvey"],
  });
  const isMobileDisplay = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <NavigationPage>
      <Container disableGutters={isMobileDisplay} maxWidth={"false"}>
        <Paper sx={{ padding: 4 }}>
          <Wizard
            currentStepIndex={0}
            steps={[
              {
                label: "Criar pesquisa",
                icon: <Settings />,
                onNextStep: () => {
                  submitButtonRef.current.click();
                },
                onPreviousStep: () => {
                  navigate("/pesquisa");
                },
                openWarningOnPreviousStep: true,
                isDirty: true,
                nextStepIsLoading: isSurveyMutating > 0,
              },
            ]}
            onCancelFirstStep={() => {
              navigate("/pesquisa");
            }}
          />
          <SaveSurveyForm
            onDirty={setSurveyDirty}
            onSuccess={(data, variables, context) => {
              navigate(`../pesquisa/editar/${data.researchId}?initialStep=1`);
            }}
            submitButtonRef={submitButtonRef}
          />
        </Paper>
      </Container>
    </NavigationPage>
  );
};
