import { Route, Outlet } from "react-router-dom";
import { unflattenObjArr } from "../utils/objList";
import { Suspense } from "react";
import Loading from "../../ui/components/Loading";

export const idKey = "key",
  fatherKey = "fatherKey";

class RouteConstructor {
  constructor(flatList) {
    if (!flatList || !flatList.length)
      throw new Error("Lista de rotas não pode estar vazia");
    this.routeList = unflattenObjArr(flatList, idKey, fatherKey);
  }

  get nestedRoutes() {
    return RouteConstructor.generateNestedRoutes(this.routeList);
  }

  static generateNestedRoutes = (routeList) => {
    return routeList?.map(({ key, path, Page, index, children }) => (
      <Route
        key={key}
        index={index}
        path={path}
        element={<Suspense
          fallback={
            <div>
              <Loading />
            </div>
          }
        >
          {(Page && <Page />) || <Outlet />}
        </Suspense>}
        // render={(props) => (
          
        // )}
      >
        {this.generateNestedRoutes(children)}
      </Route>
    ));
  };

  static routeFactory(id, path, render, father) {
    const route = {};
    route[idKey] = id;
    route.path = path;
    route.Page = render;
    if (father) route[fatherKey] = father;
    return route;
  }
}

export default RouteConstructor;
