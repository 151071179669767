import {
  RichTreeView,
  SimpleTreeView,
  TreeItem,
  useTreeItemState,
} from "@mui/x-tree-view";
import { Typography, Box, IconButton, Checkbox, Icon } from "@mui/material";
import clsx from "clsx";
import * as React from "react";
import _ from "lodash";
import { flatListToNested, getNestedChildren } from "./utils/flatListToNested";
import { CheckBox, CheckBoxOutlineBlank, Close } from "@mui/icons-material";

const TreeItemContent = React.forwardRef(function CustomContent(props, ref) {
  const {
    classes,
    className,
    nodeId,
    itemId,
    label,
    iconProp,
    expansionIcon,
    displayIcon,
  } = props;
  const {
    disabled,
    expanded,
    handleExpansion,
    handleSelection,
    preventSelection,
  } = useTreeItemState(itemId);
  // console.log({ props });
  const icon = iconProp || expansionIcon || displayIcon;
  const handleMouseDown = (event) => {
    preventSelection(event);
  };
  const handleExpansionClick = (event) => {
    handleExpansion(event);
  };
  const handleSelectionClick = (event) => {
    handleSelection(event);
  };

  return (
    <div
      className={clsx(className, classes.root, {
        [classes.expanded]: expanded,
        [classes.disabled]: disabled,
      })}
      // onMouseDown={handleMouseDown}
      ref={ref}
    >
      <div onClick={handleExpansionClick} className={classes.iconContainer}>
        {icon}
      </div>
      <Typography
        onClick={handleSelectionClick}
        component="div"
        className={classes.label}
      >
        {label}
      </Typography>
    </div>
  );
});

function CustomTreeItem(props) {
  return <TreeItem ContentComponent={TreeItemContent} {...props} />;
}

function Branch({ item, onSelect }) {
  return (
    <CustomTreeItem
      itemId={item?.id}
      label={
        <>
          <IconButton
            onClick={(event) => {
              onSelect(item, !item.selected);
            }}
          >
            {item.selected ? <CheckBox /> : <CheckBoxOutlineBlank />}
          </IconButton>
          {item?.label}
        </>
      }
    >
      {item?.children?.map((child) => {
        return <Branch item={child} onSelect={onSelect} />;
      })}
    </CustomTreeItem>
  );
}
export default function TreeSelector({
  list,
  setList,
  onDirty = () => {},
  sx,
  ...rest
}) {
  function selectItemAndChildren(item, value) {
    setList((prev) => {
      const foo = prev;

      foo[foo.findIndex((obj) => obj.id == item.id)] = {
        ...item,
        selected: value,
      };

      return [...foo];
    });
    if (!item.children) {
      return;
    }
    return item.children.map((child) => selectItemAndChildren(child, value));
  }
  function handleSelect(item, value) {
    selectItemAndChildren(item, value);
    onDirty(true);
  }

  return (
    <Box sx={{ overflow: "auto", ...sx }}>
      <SimpleTreeView {...rest}>
        {flatListToNested(
          _.uniqBy(list, "id").filter((item) => !!item),
          (item) => item?.id,
          (item) => item?.parentId
        ).map((item) => {
          return <Branch item={item} onSelect={handleSelect} />;
        })}
      </SimpleTreeView>
    </Box>
  );
}
