import axios from "axios";
import { api, courseApi } from "../api";
import { getClient } from "../config/urlConfig";

import moment from "moment";
import "moment/locale/pt-br";
moment.locale("pt-br");

// actions
const NEWS_FEED_ALUNO = "NEWS_FEED_ALUNO";
const NEWS_QTD_FEED_ALUNO = "NEWS_QTD_FEED_ALUNO";
const NEWS_QTD_FEED_ALUNO_FILTRADO = "NEWS_QTD_FEED_ALUNO_FILTRADO";
const NEWS_POST_ALUNO = "NEWS_POST_ALUNO";
const IS_GESTOR_ID = "IS_GESTOR_ID";
const SET_STATUS = "SET_STATUS";

const domain = getClient();
// action creators
export const feedAluno = (alunoid, token) => {
  return async (dispatch) => {
    // dispatch({
    //   type: SET_STATUS,
    //   payload: { key: NEWS_FEED_ALUNO, value: "loading" },
    // });
    try {
      const res = await fetch(`${api.feed_aluno}?alunoid=${alunoid}`, {
        method: "get",
        headers: { token },
      });
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        const items = json[0].data;
        dispatch({ type: NEWS_FEED_ALUNO, items });
        return json;
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      console.log("feedAluno error", error);
      throw new Error(error);
    }
    //  finally {
    //   dispatch({
    //     type: SET_STATUS,
    //     payload: { key: NEWS_FEED_ALUNO, value: "idle" },
    //   });
    // }
  };
};

export const feedAlunoPaginado = (
  token,
  alunoid,
  pageNumber,
  pageSize,
  filter = "*"
) => {
  return async (dispatch) => {
    // dispatch({
    //   type: SET_STATUS,
    //   payload: { key: NEWS_FEED_ALUNO, value: "loading" },
    // });
    try {
      const res = await fetch(
        `${
          api.feed_aluno_paginado
        }?alunoid=${alunoid}&pageNumber=${pageNumber}&pageSize=${pageSize}&filtro=${
          filter == "" ? '*' : encodeURI(filter)
        }`,
        {
          method: "get",
          headers: { token },
        }
      );
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        const items = json[0].data;
        dispatch({ type: NEWS_FEED_ALUNO, items });
        return json;
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      console.log("feedAlunoPaginado error", error);
      throw new Error(error);
    }
    //  finally {
    //   dispatch({
    //     type: SET_STATUS,
    //     payload: { key: NEWS_FEED_ALUNO, value: "idle" },
    //   });
    // }
  };
};

export const feedAlunoFiltrado = (token, alunoid, apenas_ativos, dias) => {
  return async (dispatch) => {
    // dispatch({
    //   type: SET_STATUS,
    //   payload: { key: NEWS_FEED_ALUNO, value: "loading" },
    // });
    try {
      const res = await fetch(
        `${api.feed_aluno_filtrado}?alunoid=${alunoid}&apenas_ativos=${apenas_ativos}&dias=${dias}`,
        {
          method: "get",
          headers: { token },
        }
      );
      const json = await res.json();
      const items = json[0].data;
      dispatch({ type: NEWS_FEED_ALUNO, items });
      return json;
    } catch (error) {
      console.log("feedGestorFiltered error", error);
      throw new Error(error);
    } finally {
      // dispatch({
      //   type: SET_STATUS,
      //   payload: { key: NEWS_FEED_ALUNO, value: "idle" },
      // });
    }
  };
};

export const feedAlunoFiltradoPaginado = (
  token,
  alunoid,
  apenas_ativos,
  dias,
  pageNumber,
  pageSize,
  filter = "*"
) => {
  return async (dispatch) => {
    // dispatch({
    //   type: SET_STATUS,
    //   payload: { key: NEWS_FEED_ALUNO, value: "loading" },
    // });
    try {
      const res = await fetch(
        `${
          api.feed_aluno_filtrado_paginado
        }?alunoid=${alunoid}&apenas_ativos=${apenas_ativos}&dias=${dias}&pageNumber=${pageNumber}&pageSize=${pageSize}&filtro=${
          filter == "" ? '*' : encodeURI(filter)
        }`,
        {
          method: "get",
          headers: { token },
        }
      );
      const json = await res.json();
      const items = json[0].data;
      dispatch({ type: NEWS_FEED_ALUNO, items });
      return json;
    } catch (error) {
      console.log("feedGestorFiltered error", error);
      throw new Error(error);
    } finally {
      // dispatch({
      //   type: SET_STATUS,
      //   payload: { key: NEWS_FEED_ALUNO, value: "idle" },
      // });
    }
  };
};

export const postsAluno = (temaid, alunoid, token) => {
  return async (dispatch) => {
    try {
      const res = await fetch(
        `${api.posts_aluno}?alunoid=${alunoid}&temaid=${temaid}`,
        {
          method: "get",
          headers: { token },
        }
      );
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        const items = json[0].data;
        dispatch({ type: NEWS_POST_ALUNO, items });
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      console.log("feedAluno error", error);
      throw new Error(error);
    }
  };
};

export const recuperaGestorId = (alunoid, token) => {
  return async (dispatch) => {
    try {
      const res = await fetch(`${api.verifica_gestorid}?alunoid=${alunoid}`, {
        method: "get",
        headers: { token },
      });
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        const items = json[0].data.gestorid;
        dispatch({ type: IS_GESTOR_ID, items });
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      console.log("IS_GESTOR_ID error", error);
      throw new Error(error);
    }
  };
};

export const qtdFeedAluno = (token, alunoid) => {
  return async (dispatch) => {
    try {
      const res = await fetch(`${api.qtd_feed_aluno}?alunoid=${alunoid}`, {
        method: "get",
        headers: { token },
      });
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        dispatch({ type: NEWS_QTD_FEED_ALUNO, unread: json[0].data.nao_lidos });
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      dispatch({ type: NEWS_QTD_FEED_ALUNO, unread: 0 });
      console.log("qtdFeedAluno error", error);
      throw new Error(error);
    }
  };
};

export const qtdFeedAlunoFiltrado = (token, alunoid, apenas_ativos, dias) => {
  return async (dispatch) => {
    try {
      const res = await fetch(
        `${api.qtd_feed_aluno_filtrado}?alunoid=${alunoid}&apenas_ativos=${apenas_ativos}&dias=${dias}`,
        {
          method: "get",
          headers: { token },
        }
      );
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        dispatch({ type: NEWS_QTD_FEED_ALUNO_FILTRADO, total: json[0].data });
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      dispatch({ type: NEWS_QTD_FEED_ALUNO_FILTRADO, total: 0 });
      console.log("qtdFeedAluno error", error);
      throw new Error(error);
    }
  };
};

export const removeNews = (newsId, token) => async (dispatch, getState) => {
  try {
    const res = await axios.get(api.excluir_postagem_gestor, {
      headers: { token },
      params: { capituloid: newsId },
    });

    if (res.status >= 200 && res.status < 300) {
      const alunoid = getState().auth.user.alunoid;

      await feedAluno(alunoid, token)(dispatch);

      return res;
    } else {
      throw new Error(res.statusText);
    }
  } catch (error) {
    console.error(error);
  }
};

export const enableNews = (newsId, token) => async (dispatch, getState) => {
  try {
    const res = await axios.post(
      courseApi.save_chapter.url,
      {
        chapterId: newsId,
        testType: "S",
      },
      {
        headers: { token, domain },
      }
    );

    if (res.status >= 200 && res.status < 300) {
      const alunoid = getState().auth.user.alunoid;

      await feedAluno(alunoid, token)(dispatch);

      return res;
    } else {
      throw new Error(res.statusText);
    }
  } catch (error) {
    console.error(error);
  }
};

export const disableNews = (newsId, token) => async (dispatch, getState) => {
  try {
    const res = await axios.post(
      courseApi.save_chapter.url,
      {
        chapterId: newsId,
        testType: "N",
      },
      {
        headers: { token, domain },
      }
    );

    if (res.status >= 200 && res.status < 300) {
      const alunoid = getState().auth.user.alunoid;

      await feedAluno(alunoid, token)(dispatch);

      return res;
    } else {
      throw new Error(res.statusText);
    }
  } catch (error) {
    console.error(error);
  }
};

// reducer
export default function reducer(state = {}, action) {
  switch (action.type) {
    case NEWS_FEED_ALUNO:
      return { ...state, items: action.items };

    case NEWS_POST_ALUNO:
      return { ...state, itemsAluno: action.items };

    case NEWS_QTD_FEED_ALUNO:
      return { ...state, unread: action.unread };
    case NEWS_QTD_FEED_ALUNO_FILTRADO:
      return { ...state, total: action.total.qtd };
    case IS_GESTOR_ID:
      return { ...state, gestorid: action.items };
    case SET_STATUS:
      const newStatus = { ...state.status };
      const { key, value } = action.payload;

      newStatus[key] = value;

      return { ...state, status: newStatus };
    default:
      return state;
  }
}
