export function getNestedChildren(
  item,
  list,
  getItemId = (item) => item.id,
  getItemParentId = (item) => item.parentId
) {
  let itemChildren = list.filter(
    (obj) => getItemParentId(obj) == getItemId(item)
  );
  if (!itemChildren.length) {
    return item;
  }
  return {
    ...item,
    children: itemChildren.map((children) => getNestedChildren(children)),
  };
}

export function flatListToNested(list, getItemId, getItemParentId) {
  function getChildren(item) {
    let itemChildren = list.filter(
      (obj) => getItemParentId(obj) == getItemId(item)
    );
    if (!itemChildren.length) {
      return item;
    }
    return {
      ...item,
      children: itemChildren.map((children) => getChildren(children)),
    };
  }
  return list
    .filter(
      (item) =>
        getItemParentId(item) == undefined || getItemParentId(item) == null
    )
    .map(getChildren);
}
