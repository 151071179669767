import { useQuery } from "@tanstack/react-query";
import { axios } from "../../../shared/lib/axios";
import _cookies from "../../../shared/utils/_cookies";

export const getStudentMood = async () => {
  const res = await axios.cadernoVirtualBase("/mood-service/mood", {
    params: { studentId: _cookies.getItem("auth").user.alunoid },
  });
  return res;
};

export const useStudentMood = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["studentMood"],
    queryFn: getStudentMood,
  });
};
