import { Tabs, Tab, Box, Typography, Avatar, Skeleton } from "@mui/material";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { useCourse } from "../api/getCourse";
import { imagesUrl, resourcesUrl } from "../../../shared/config";

export default function ManageCourse() {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const course = useCourse({ courseId: params.courseId });

  const handleChangeTab = (event, value) => {
    navigate(`/manage/course/${params.courseId}/${value}`, {
      state: { backgroundLocation: location.state?.backgroundLocation },
      replace: true,
      // replace: true,
    });
  };
  const tab = location.pathname.split("/")[4];
  const isOutsourcedCourse = params.courseId > 900000000;

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        {course.isSuccess ? (
          <>
            <Avatar
              src={
                course.data.foto.includes("http")
                  ? course.data.foto
                  : `${imagesUrl}/${course.data.foto}`
              }
            />
            <Typography sx={{ fontWeight: "600" }}>
              {course.data.nome}
            </Typography>
          </>
        ) : (
          <>
            {" "}
            <Skeleton
              animation="wave"
              variant="circular"
              width={40}
              height={40}
            />
            <Typography sx={{ width: "100px" }}>
              <Skeleton />
            </Typography>
          </>
        )}
      </Box>
      <Tabs value={tab} onChange={handleChangeTab}>
        <Tab
          value={"trilhas"}
          label={
            <Typography sx={{ fontSize: "10px" }} variant="caption">
              Trilhas <br /> & <br /> Certificações
            </Typography>
          }
        />
        <Tab
          value={"catalogo"}
          label="Catálogo"
          disabled={!isOutsourcedCourse}
        />
      </Tabs>
      <Box sx={{ paddingTop: 3 }}>
        <Outlet />
      </Box>
    </>
  );
}
