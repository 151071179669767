import { useState, useEffect } from "react";

export const useProgressiveImage = (src) => {
  const [sourceLoaded, setSourceLoaded] = useState({
    src: null,
    isSuccess: false,
    isError: false,
  });

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () =>
      setSourceLoaded({ src, isSuccess: true, isError: false });
    img.onerror = () =>
      setSourceLoaded({ src: null, isSuccess: false, isError: true });
    return () => {
      setSourceLoaded({
        src: null,
        isSuccess: false,
        isError: false,
      });
    };
  }, [src]);

  return sourceLoaded;
};
