import { useCallback, useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import isMobile from "is-mobile";
import _cookies from "../../shared/utils/_cookies";
import {
  Avatar,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { CloudUpload } from "@mui/icons-material";
import moment from "moment";

import {
  alteraFoto,
  atualizaPerfil,
  dadosAluno,
  alteraSenha,
  delay,
} from "../../shared/store/profile";
import { logout, setAuth } from "../../shared/store/auth";
import { setSnackbar } from "../../shared/store/snackbar";
import Alert from "./Alert";
import DialogFull from "./DialogFull";
import CircularProgress from "./ProgressTracker/ui/components/CircularProgress";
import FormCol2 from "../pages/ProfilePage/components/user/FormCol2";
import FormCol1 from "../pages/ProfilePage/components/user/FormCol1";
import { imagesUrl } from "../../shared/config";
import { login } from "../../shared/store/auth";

import "moment/locale/pt-br";
import { registerLogon } from "../../features/auth/logon";

moment.locale("pt-br");

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  avatar: { width: 64, height: 64, backgroundColor: "#eee" },
  uploadButton: {
    minWidth: 128,
    margin: theme.spacing(3),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  avatarContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  input: {
    display: "none",
  },
  helperTextLarge: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  helperTextSmall: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  buttonLoading: { color: "rgba(0,0,0,0.8)" },
  buttonLoadingWhite: { color: "rgba(255,255,255,0.8)" },
  buttonUpdateProfile: { minWidth: 136 },
});

const UserStep = ({
  app,
  step,
  auth,
  profile,
  updatedPassword,
  classes,
  login,
  ...props
}) => {
  const { params } = app;
  const {
    setAuth,
    dadosAluno,
    alteraFoto,
    atualizaPerfil,
    setSnackbar,
    alteraSenha,
  } = props;
  const [imageLoading, setImageLoading] = useState(false);
  const [profileLoading, setProfileLoading] = useState(false);
  const [open, setOpen] = useState(params?.FICHA_OBRIGATORIA === "S");
  const [openPassword, setOpenPassword] = useState(
    params?.FICHA_OBRIGATORIA === "N" && params?.EXIGE_TROCA_SENHA === "S"
  );
  const [dialog, setDialog] = useState({ message: "", title: "" });
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogOpenPassword, setDialogOpenPassword] = useState(false);
  const [loadingPassword, setLoadingPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [user, setUser] = useState({
    cargo: "",
    cep: "",
    cidade: "",
    complemento: "",
    datanascimento: "",
    email: "",
    empresa: "",
    estado: "",
    foto: "",
    lattes: "",
    msn: "",
    nome: "",
    orkut: "",
    pais: "",
    sexo: "",
    telefone: "",
  });

  const fetchUser = useCallback(async () => {
    try {
      await dadosAluno(auth.user.alunoid, auth.token);
      const { details } = profile;
      setUser({
        cargo: details.cargo,
        cep: details.cep,
        cidade: details.cidade,
        complemento: details.complemento,
        datanascimento: moment(details.dtnasc).format("DD/MM/YYYY"),
        email: details.email,
        empresa: details.empresa,
        estado: details.estado,
        foto: details.foto,
        lattes: details.lattes,
        msn: details.msn,
        nome: details.nome,
        orkut: details.orkut,
        pais: details.pais,
        sexo: details.sexo,
        telefone: details.telefone,
      });
    } catch (error) {
      console.log("Error fetchUser");
    }
  }, [auth.token, auth?.user?.alunoid, dadosAluno, profile]);

  useEffect(() => {
    setOpen(params?.FICHA_OBRIGATORIA === "S");
    setOpenPassword(
      params?.FICHA_OBRIGATORIA === "N" && params?.EXIGE_TROCA_SENHA === "S"
    );
  }, [params]);

  useEffect(() => {
    if (!open && !openPassword) {
      async function close() {
        const newAuth = {
          ...auth,
          user: { ...auth.user, primeiro_acesso: "N" },
        };
        await setAuth(newAuth);
      }

      close();
    }
  }, [open, openPassword]);

  useEffect(() => {
    fetchUser();
  }, [auth]);

  useEffect(() => {
    if (step) {
      setDialog({
        message: "Por favor, preencha o seu perfil antes de continuar.",
        title: "Perfil",
      });
      setDialogOpen(true);
    }
  }, [step]);

  function handleUserFormChange(event) {
    setUser({
      ...user,
      [event.target.name]: event.target.value,
    });
  }

  function validateUser() {
    for (let key in user) {
      if (key === "msn" && !params["CAMPO1_NOME"]) continue;
      if (key === "orkut" && !params["CAMPO2_NOME"]) continue;
      if (key === "lattes" && !params["CAMPO3_NOME"]) continue;
      if (key === "empresa" && !params["CAMPO4_NOME"]) continue;
      if (key === "cargo" && !params["CAMPO5_NOME"]) continue;

      if (!user[key]) return false;
    }
    return true;
  }

  async function updateImage(event) {
    setImageLoading(true);

    const file = event.target.files[0];

    try {
      await alteraFoto(auth.user.alunoid, file, auth.token);
      fetchUser();
    } catch (error) {
      console.log("updateImage error", error);
    }

    setImageLoading(false);
  }

  async function updateProfile() {
    setProfileLoading(true);

    if (params?.CAMPOS_OBRIGATORIOS_PERFIL === "S") {
      if (!validateUser()) {
        setDialog({
          message: "Por favor, preencha todos os campos.",
          title: "Atenção",
        });
        setDialogOpen(true);
        setProfileLoading(false);
        return null;
      }
    }

    const dados = `[{"alunoid":${auth.user.alunoid},"cargo":"${user.cargo}","cep":"${user.cep}","cidade":"${user.cidade}","complemento":"${user.complemento}","dtnasc":"${user.datanascimento}","email":"${user.email}","empresa":"${user.empresa}","estado":"${user.estado}","lattes":"${user.lattes}","msn":"${user.msn}","orkut":"${user.orkut}","pais":"${user.pais}","sexo":"${user.sexo}","telefone":"${user.telefone}"}]`;

    try {
      await atualizaPerfil(dados, auth.token);
      setSnackbar(true, "Perfil salvo", "success");
    } catch (error) {
      setProfileLoading(false);
      console.log("Error save profile", error);
    }
    if (params?.EXIGE_TROCA_SENHA === "S") {
      setOpenPassword(true);
      setProfileLoading(false);
      setOpen(false);
    } else {
      setOpen(false);
      setOpenPassword(false);
    }
  }

  async function updatePassword() {
    if (!confirmNewPassword || !currentPassword || !newPassword) {
      setLoadingPassword(false);
      setDialog({
        message: "Por favor, preencha todos os campos.",
        title: "Atenção",
      });
      setDialogOpen(true);
      return null;
    } else if (newPassword !== confirmNewPassword) {
      setLoadingPassword(false);
      setDialog({
        message:
          'Os campos "Nova senha" e "Confirmar nova senha" devem ser idênticos.',
        title: "Atenção",
      });
      setDialogOpenPassword(true);
      return null;
    }

    const dados = `[{"alunoid":${auth.user.alunoid},"senha_antiga":"${currentPassword}","nova_senha":"${newPassword}"}]`;
    const isApp = isMobile() ? "S" : "N";

    try {
      const result = await alteraSenha(dados, auth.token);
      setSnackbar(true, "Nova senha salva", "success");
      if (result.message === "A senha antiga não confere") {
        setLoadingPassword(false);
        setDialog({
          message: "A senha antiga está incorreta",
          title: "Atenção",
        });
        setDialogOpenPassword(true);
        return null;
      }
      if (result.message === "Success") {
        setDialog({
          message: "Senha alterada com sucesso",
          title: "Atenção",
        });
        setDialogOpenPassword(true);
      }
      setLoadingPassword(false);
      setNewPassword("");
      setConfirmNewPassword("");
      setCurrentPassword("");
    } catch (error) {
      setLoadingPassword(false);
      setDialog({
        message:
          'Houve um erro. Por favor, preencha corretamente os campos "Nova senha", "Confirmar nova senha" e "Senha atual".',
        title: "Atenção",
      });
      setDialogOpenPassword(true);
      return null;
    } finally {
      try {
        await registerLogon({
          params: {
            studentId: auth.user.alunoid,
            courseId: 0,
            origin: "PASSWORD_UPDATE",
            isApp,
          },
        });
        login({ user: auth.user.username, password: newPassword });
      } finally {
      }
    }
    setOpen(false);
    setOpenPassword(false);
  }

  function closeDialog() {
    setDialogOpen(false);
  }
  function closeDialogPassword() {
    setDialogOpenPassword(false);
  }

  if (!profile.details) return null;

  const saveButtonText =
    auth.user.primeiro_acesso === "S" ? "Salvar e continuar" : "Salvar perfil";

  return (
    <>
      <DialogFull handleClose={closeDialog} open={open} showCloseButton={false}>
        <Typography gutterBottom variant="h6" noWrap>
          {user?.nome}
        </Typography>
        <Grid container justify="center" spacing={3} className={classes.top}>
          <Grid item xs={12}>
            <Box component="div" className={classes.avatarContainer}>
              <Avatar
                className={classes.avatar}
                src={`${imagesUrl}/${user.foto}` || null}
                alt=""
              />
              <input
                accept="image/*"
                className={classes.input}
                id="contained-button-file"
                type="file"
                onChange={updateImage}
              />
              <label htmlFor="contained-button-file">
                <Button
                  variant="contained"
                  component="span"
                  color="primary"
                  className={classes.uploadButton}
                >
                  {imageLoading && (
                    <CircularProgress
                      className={classes.buttonLoading}
                      size={24}
                    />
                  )}
                  {!imageLoading && (
                    <>
                      Foto <CloudUpload className={classes.rightIcon} />
                    </>
                  )}
                </Button>
              </label>
              <Typography variant="body2">
                <span className={classes.helperTextLarge}>
                  Para que sua foto não fique distorcida, utilize um programa
                  para recorta-la e deixa-la com 256 x 256 pixels.
                </span>
              </Typography>
            </Box>
          </Grid>

          <Grid item sm={6} xs={12}>
            <FormCol1 handleChange={handleUserFormChange} user={user} />
          </Grid>

          <Grid item sm={6} xs={12}>
            <FormCol2 handleChange={handleUserFormChange} user={user} />
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              className={classes.buttonUpdateProfile}
              color="primary"
              onClick={updateProfile}
            >
              {profileLoading && (
                <CircularProgress
                  className={classes.buttonLoadingWhite}
                  size={24}
                />
              )}
              {!profileLoading && saveButtonText}
            </Button>
          </Grid>
        </Grid>
        <Alert
          closeAlert={closeDialog}
          showAlert={dialogOpen}
          text={dialog.message}
          title={dialog.title}
        />
      </DialogFull>
      <DialogFull
        handleClose={closeDialogPassword}
        open={openPassword || loadingPassword}
        showCloseButton={false}
      >
        <Grid container justify="center" spacing={3}>
          <Grid item xs={12} style={{ margin: 40 }}>
            <Typography gutterBottom variant="h6" noWrap>
              Alterar senha
            </Typography>
          </Grid>
          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="flex-start"
            style={{ marginLeft: 80 }}
          >
            <TextField
              error
              type="password"
              label="Senha atual"
              margin="dense"
              variant="outlined"
              // color="secundary"
              name="currentPassword"
              value={currentPassword}
              onChange={(event) => setCurrentPassword(event.target.value)}
            />
            <TextField
              type="password"
              label="Nova senha"
              margin="dense"
              variant="outlined"
              name="newPassword"
              value={newPassword}
              onChange={(event) => setNewPassword(event.target.value)}
            />
            <TextField
              type="password"
              label="Confirmar nova senha"
              margin="dense"
              variant="outlined"
              name="confirmNewPassword"
              value={confirmNewPassword}
              onChange={(event) => setConfirmNewPassword(event.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              className={classes.buttonUpdate}
              color="primary"
              onClick={updatePassword}
              style={{ marginLeft: 65 }}
            >
              {!loadingPassword && "Salvar senha"}
              {loadingPassword && (
                <CircularProgress
                  className={classes.circularLoading}
                  size={24}
                />
              )}
            </Button>
          </Grid>
        </Grid>
        <Alert
          closeAlert={closeDialogPassword}
          showAlert={dialogOpenPassword}
          text={dialog.message}
          title={dialog.title}
        />
      </DialogFull>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    app: state.app,
    auth: state.auth,
    profile: state.profile,
    snackbar: state.snackbar,
    updatedPassword: state.profile.updatePassword,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      alteraFoto,
      atualizaPerfil,
      dadosAluno,
      setAuth,
      setSnackbar,
      alteraSenha,
      delay,
      login,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(UserStep));
