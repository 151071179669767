import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { loginIntegrado } from "../../../shared/store/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "../../../shared/hooks/useQuery";
import { useEffect, useState } from "react";
import _cookies from "../../../shared/utils/_cookies";
import _cookiesChunked from "../../../shared/utils/_cookiesChunked";
import { getClient } from "../../../shared/config";

// import { loginIntegrado } from "../../../shared/store/auth";
const IntegratedLoginPage = ({ auth, loginIntegrado }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const idUser = useQuery().get("idUser");
  const writeCookies = (auth) => {
    _cookies.setItem("auth", auth);

    _cookies.setItem("host", window.location.host.replace("www.", ""), false);
    _cookies.setItem("title", document.title, false);
  };

  useEffect(() => {
    if (idUser) {
      loginIntegrado({ idUser, base: getClient() });
    }
  }, []);
  useEffect(() => {
    const { token } = auth;

    if (auth?.user?.profile) {
      writeCookies(auth);

      if (auth.user.profile.isGestor && !auth.user.profile.isGestorAluno) {
        _cookies.remove("auth");
        _cookies.remove("host");
        _cookies.remove("popupCount");
        _cookies.remove("title");
        _cookiesChunked.remove("params");
        window.location.href = `${window.location.origin}/REDIRECT_GESTOR.ASPX?token=${token}`;
      } else {
        navigate("/", { replace: true });
      }
    }
  }, [auth?.user?.profile]);
  return <></>;
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      loginIntegrado,
    },
    dispatch
  );
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IntegratedLoginPage);
