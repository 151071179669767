import {
  Box,
  Button,
  FormControl,
  FormLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from "@mui/material";

import { QuestionComponents } from "../../../ui/pages/Course/pages/EditCourse/Evaluation/questionCategories";
import TextComponent from "../../../ui/pages/Course/ui/components/TextComponent";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { createContext, useEffect, useState } from "react";
import { questionTypes } from "../../../ui/pages/Course/pages/EditCourse/Evaluation/questionCategories";
import AutoCompleteList from "./AutoCompleteList";
import { SwitchableQuestions } from "./SwitchableQuestions";
import RichText from "../../../ui/components/RichText";
import _ from "lodash";
import LabelledSwitch from "../../../ui/components/LabelledSwitch";

export const QuestionContext = createContext({});

export function Question({
  error,
  label = "",
  initialQuestionData,
  editable = false,
  mandatoryFieldEditable = true,
  deletable,
  initiallyEditing = false,
  canSave = true,
  onSaveQuestion = () => {},
  onDeleteQuestion = () => {},
  submittedAnswer = null,
  setSubmittedAnswer,
  onDirty = () => {},
  isEditionDirty = false,
  questionTemplates,
  disabledAnswer = false,
  ...rest
}) {
  const theme = useTheme();
  const [isEditing, setIsEditing] = useState(initiallyEditing);
  const [question, setQuestion] = useState(_.cloneDeep(initialQuestionData));

  useEffect(() => {
    if (!_.isEqual(question, initialQuestionData) && isEditing) {
      onDirty(true);
    }
  }, [question]);

  return (
    <Box
      sx={{
        borderColor: error
          ? theme.palette.error.main
          : isEditing
          ? theme.palette.primary.main
          : "#F3F3F4",
        borderWidth: "1px",
        borderRadius: "10px",
        padding: "1rem",
      }}
    >
      <Box
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          rowGap: 1,
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            display: "flex",
            flexDirection: "column",
            fontSize: "1rem",
            fontWeight: "700",
            // color: theme.palette.grey[700],
            marginRight: { xs: "64px", md: 0 },
          }}
        >
          {label} {isEditionDirty && "(Alterações não salvas)"}
          {question.mandatory == "S" && (
            <>
              *
              <Typography fontWeight="600" fontSize={"9px"} variant="caption">
                Questão obrigatória
              </Typography>
            </>
          )}
        </Typography>
        {isEditing && (
          <>
            {question.category !== "questions" && (
              <FormControl sx={{ marginRight: { xs: 0, md: "64px" } }}>
                <InputLabel id="question-type">Tipo de questão</InputLabel>
                <Select
                  value={question.newQuestionType}
                  onChange={(event) => {
                    setQuestion((prev) => {
                      const newState = { ...prev };
                      newState["answerTemplate"] = undefined;
                      const clonedDefaultValues = _.cloneDeep(
                        questionTemplates[event.target.value].defaultValues
                      );
                      const newOptions = [
                        ...(prev?.listOptions
                          ?.filter((oldOption) => oldOption.id != undefined)
                          ?.map((oldOption) => ({
                            ...oldOption,
                            remove: true,
                          })) || []),
                        ...(clonedDefaultValues.listOptions || []),
                      ];

                      return {
                        ...newState,
                        ...clonedDefaultValues,
                        listOptions: [...newOptions],
                        newQuestionType: event.target.value,
                      };
                    });
                  }}
                  id="question-type"
                  size="small"
                  label="Tipo de questão"
                  sx={{
                    "& .MuiSelect-select": {
                      minWidth: "10rem",
                    },
                  }}
                >
                  {Object.keys(questionTemplates).map((obj, index) => {
                    return (
                      <MenuItem
                        value={obj}
                        disabled={questionTemplates[obj].disabled}
                      >
                        {questionTemplates[obj].label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            )}

            {!!question.weight && (
              <FormControl>
                <InputLabel id="question-weight">Peso da questão</InputLabel>
                <Select
                  id="question-weight"
                  size="small"
                  label="Peso da questão"
                  value={question.weight}
                  onChange={(event) =>
                    setQuestion((prev) => ({
                      ...prev,
                      weight: event.target.value,
                    }))
                  }
                  sx={{
                    "& .MuiSelect-select": {
                      minWidth: "10rem",
                    },
                  }}
                >
                  {[1, 2, 3, 4, 5].map((item) => (
                    <MenuItem value={item}>{item}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </>
        )}
        <Box sx={{ position: "absolute", top: 2, right: 2 }}>
          {editable && (
            <IconButton
              onClick={() => {
                setIsEditing(true);
              }}
            >
              <BorderColorOutlinedIcon
                sx={{ color: theme.palette.primary.main, fontSize: "24px" }}
              />
            </IconButton>
          )}
          {deletable && (
            <IconButton
              onClick={() => {
                onDeleteQuestion(question);
              }}
            >
              <DeleteOutlinedIcon color="error" sx={{ fontSize: "24px" }} />
            </IconButton>
          )}
        </Box>
      </Box>
      {isEditing && question.category !== "questions" && (
        <FormLabel>Enunciado:</FormLabel>
      )}
      {question.category !== "questions" && (
        <Box
          sx={{
            border: isEditing ? "2px solid" : "unset",
            borderColor: theme.palette.grey["500"],
          }}
        >
          <RichText
            value={question.statement}
            isEditing={isEditing}
            onChange={(newStatement) => {
              setQuestion((prev) => ({ ...prev, statement: newStatement }));
            }}
          />
        </Box>
      )}
      <QuestionContext.Provider
        value={{
          isEditing,
          setIsEditing,
          question,
          setQuestion,
          submittedAnswer,
          setSubmittedAnswer,
          disabledAnswer,
        }}
      >
        <Box sx={{ paddingY: 3 }}>
          <SwitchableQuestions
            questionTemplates={questionTemplates}
            type={question.newQuestionType}
            {...rest}
          />
        </Box>
      </QuestionContext.Provider>

      {isEditing && (
        <Box
          sx={{
            marginTop: "0.5rem",
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          <LabelledSwitch
            disabled={!mandatoryFieldEditable}
            label="Obrigatório"
            color="#6186F8"
            labelPlacement="left"
            fontWeight="700"
            value={question.mandatory == "S"}
            fontSize="small"
            onChange={(event) => {
              setQuestion((prev) => ({
                ...prev,
                mandatory: event.target.checked ? "S" : "N",
              }));
            }}
          />
          <Button
            sx={{ height: "fit-content" }}
            size="small"
            variant="contained"
            color="error"
            onClick={() => {
              setQuestion(initialQuestionData);
              setIsEditing(false);
              onDirty(false);
            }}
          >
            <Typography
              sx={{
                fontWeight: "500",
                textTransform: "capitalize",
              }}
            >
              Cancelar
            </Typography>
          </Button>
          <Button
            sx={{ height: "fit-content", marginLeft: "12px" }}
            size="small"
            variant="outlined"
            color="success"
            disabled={!canSave}
            onClick={() => {
              onSaveQuestion(question, initialQuestionData);
              setIsEditing(false);
            }}
          >
            <Typography
              sx={{
                fontWeight: "500",
                textTransform: "capitalize",
              }}
            >
              Salvar Edições
            </Typography>
          </Button>
        </Box>
      )}
    </Box>
  );
}
