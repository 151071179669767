var ttChatLoaderS = document.createElement('script');
document.tomticketChatLoaderScriptVersion = 2;
ttChatLoaderS.src = 'https://taua.tomticket.com/scripts-chat/chat.min.js'
+ '?id=EP54761'
+ '&account=3504704P18102021105141'
+ '&autoOpen=0'
+ '&hideWhenOffline=0'
+ '&d=taua'
+ '&ts=' + new Date().getTime()
+ '&ref=' + encodeURIComponent(document.URL);
document.body.appendChild(ttChatLoaderS);