import _cookies from "./_cookies";

// split or reassemble object in 8 cookies

const numOfChunks = 16;

const split = object => {
  let i = 0;
  let chunk = {};
  const chunks = [];
  const length = Object.keys(object).length;
  const chunkSize = Math.ceil(length / numOfChunks);

  for (const key in object) {
    chunk = { ...chunk, [key]: object[key] };
    i++;

    if (i === chunkSize) {
      chunks.push(chunk);
      chunk = {};
      i = 0;
    }
  }

  if (Object.keys(chunk).length > 0) {
    chunks.push(chunk);
  }

  return chunks;
};

const indexesArray = () => {
  const indexesArray = [];
  for (let i = 0; i < numOfChunks; i++) {
    indexesArray.push(i);
  }
  return indexesArray;
};

const _cookiesChunked = {
  setItem: (name, paramsObject) => {
    const chunks = split(paramsObject);
    chunks.forEach((chunk, i) => {
      _cookies.setItem(`${name}${i}`, chunk);
    });
  },
  getItem: name => {
    return indexesArray().reduce((acc, cur) => {
      const chunk = _cookies.getItem(`${name}${cur}`);
      return { ...acc, ...chunk };
    }, {});
  },
  remove: name => {
    indexesArray().forEach(index => {
      _cookies.remove(`${name}${index}`);
    });
  }
};

export default _cookiesChunked;
