import { Stack, Box, Typography } from "@mui/material";
import { AddBox, EventNote } from "@mui/icons-material";

export default function DatesLabel({
  datesArray,
  orientation = "horizontal",
  spacing = 0,
  labelPosition = "top",
  hideIcon = false,
}) {
  return (
    <Stack
      sx={{
        alignItems: "center",
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill)",
        gridTemplateRows: "max-content",
        maxWidth: "100%",
      }}
      orientation={orientation}
      spacing={spacing}
    >
      {datesArray.map((obj) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection:
                labelPosition == "top" || labelPosition == "bottom"
                  ? "column"
                  : "row",
            }}
          >
            {(labelPosition == "top" || labelPosition == "left") && (
              <Typography fontWeight="600" fontSize={"9px"} variant="caption">
                {obj.label}
              </Typography>
            )}
            <Box variant="caption">
              <div item>
                {!hideIcon && (
                  <EventNote
                    color="secondary"
                    // sx={{ position: "absolute", left: "0" }}
                  />
                )}{" "}
                <Typography fontWeight="600" fontSize={"9px"} variant="caption">
                  {obj.date}
                </Typography>
              </div>
            </Box>
            {(labelPosition == "bottom" || labelPosition == "right") && (
              <Typography fontWeight="600" fontSize={"9px"} variant="caption">
                {obj.label}
              </Typography>
            )}
          </div>
        );
      })}
    </Stack>
  );
}
