import AutoCompleteList from "../components/AutoCompleteList";
import MultipleChoice from "../components/MultipleChoice";
import Discursive from "../components/Discursive";
import { FiveStarsRating, TenStarsRating } from "../components/StarsRating";

export const defaultSurveyQuestionTemplates = {
  "0-5": {
    component: FiveStarsRating,
    label: "0 a 5 estrelas",
    defaultValues: {
      mandatory: "S",
      type: "quantity",
      category: "multipleChoice",
      listOptions: [{ optionDescription: "0-5" }],
    },
  },
  "0-10": {
    component: TenStarsRating,
    label: "0 a 10 estrelas",
    defaultValues: {
      mandatory: "S",
      type: "quantity",
      category: "multipleChoice",
      listOptions: [{ optionDescription: "0-10" }],
    },
  },
  autoCompleteList: {
    component: AutoCompleteList,
    label: "Lista com preenchimento automático",
    defaultValues: {
      mandatory: "S",
      listOptions: [{ optionDescription: "Opção 1,Opção 2,Opção 3,Opção 4" }],
      type: "autoCompleteList",
      category: "autoCompleteList",
    },
  },
  "S,N": {
    component: MultipleChoice,
    componentProps: { canEditOptions: false },
    label: "Sim ou não",
    defaultValues: {
      mandatory: "S",
      listOptions: [{ optionDescription: "Sim" }, { optionDescription: "Não" }],
      type: "evaluation",
      category: "multipleChoice",
    },
  },
  "V,F": {
    component: MultipleChoice,
    componentProps: { canEditOptions: false },
    label: "Verdadeiro ou falso",
    defaultValues: {
      mandatory: "S",
      category: "multipleChoice",
      type: "evaluation",
      listOptions: [
        { optionDescription: "Verdadeiro" },
        { optionDescription: "Falso" },
      ],
    },
  },
  "A,B,C": {
    component: MultipleChoice,
    componentProps: { canEditOptions: true },
    label: "Múltipla escolha",
    defaultValues: {
      mandatory: "S",
      type: "evaluation",
      category: "multipleChoice",
      listOptions: [
        { optionDescription: "Opção 1" },
        { optionDescription: "Opção 2" },
        { optionDescription: "Opção 3" },
      ],
    },
  },
  "Alto,Médio,Baixo": {
    component: MultipleChoice,
    componentProps: { canEditOptions: false },
    label: "Alto, Médio, Baixo",
    defaultValues: {
      mandatory: "S",
      type: "evaluation",
      category: "multipleChoice",
      listOptions: [
        { optionDescription: "Alto" },
        { optionDescription: "Médio" },
        { optionDescription: "Baixo" },
      ],
    },
  },
  "Péssimo,Ruim,Regular,Bom,Ótimo": {
    component: MultipleChoice,
    componentProps: { canEditOptions: false },
    label: "Péssimo, Ruim, Regular, Bom, Ótimo",
    defaultValues: {
      mandatory: "S",
      type: "evaluation",
      category: "multipleChoice",
      listOptions: [
        { optionDescription: "Péssimo" },
        { optionDescription: "Ruim" },
        { optionDescription: "Regular" },
        { optionDescription: "Bom" },
        { optionDescription: "Ótimo" },
      ],
    },
  },
  ABERTA: {
    label: "Discursiva",
    component: Discursive,
    defaultValues: {
      mandatory: "S",
      category: "discursive",
      type: "evaluation",
    },
    // disabled: true },
  },
};
