import { Box, Button, CircularProgress } from "@mui/material";
import { useState } from "react";
import { useSurveyGroups } from "../api/getSurveyGroups";
import { useInsertGroupsToSurvey } from "../api/insertGroupsToSurvey";
import TreeSelector from "../../../ui/components/TreeSelector";
import _ from "lodash";
import { LoadingButton } from "@mui/lab";

function InsertGroupsToSurvey({
  surveyId,
  onDirty,
  onSuccess,
  isDirty,
  submitButtonRef,
}) {
  const [groups, setGroups] = useState([]);
  const insertGroupsToSurveyMutation = useInsertGroupsToSurvey({
    surveyId,
  });

  const { data, isLoading } = useSurveyGroups({
    surveyId,
    config: {
      onSuccess: (data) => {
        setGroups([
          { label: "Todos", id: 0 },
          ...data.listGestor.map((item) => ({
            // ...item,
            label: item.nome,
            id: item.grupoid,
            parentId: item.filhode,
            selected:
              data.listResearch.findIndex(
                (selectedItem) => selectedItem.grupoid == item.grupoid
              ) > -1,
          })),
        ]);
      },
    },
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <TreeSelector
            sx={{ maxHeight: "unset" }}
            list={groups}
            setList={setGroups}
            defaultExpandedItems={[0]}
            onDirty={onDirty}
          />
          <button
            hidden
            ref={submitButtonRef}
            onClick={() => {
              insertGroupsToSurveyMutation.mutate(
                {
                  groups: groups
                    .filter((item) => item?.selected)
                    .map((group) => group.id),
                },
                {
                  onSuccess: () => {
                    onSuccess();
                    onDirty(false);
                  },
                }
              );
            }}
          />
        </>
      )}
    </Box>
  );
}

export default InsertGroupsToSurvey;
