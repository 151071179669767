const apiUrl = "https://middleware.cadernovirtual.com.br";
const auth = `${apiUrl}/auth.asmx`;
const aluno = `${apiUrl}/aluno.asmx`;
const dashboard = `${apiUrl}/dashboard.asmx`;
const ideias = `${apiUrl}/ideias.asmx`;
const game = `${apiUrl}/game.asmx`;
const research = `${apiUrl}/pesquisas.asmx`;
const backend = `${apiUrl}/Backend.asmx`;

export * from "./courseApi";

export const api = {
  acessar_capitulo: `${aluno}/Acessar_Capitulo`,
  acessou_plataforma: `${aluno}/acessou_plataforma`,
  altera_foto: `${aluno}/Altera_foto`,
  altera_senha: `${aluno}/Altera_senha`,
  alterar_comentario: `${aluno}/Alterar_Comentario`,
  aniversariante: `${aluno}/Aniversariantes`,
  apaga_anexo_forum: `${aluno}/Apaga_Anexo_Forum`,
  apaga_postagem_forum: `${aluno}/Apaga_Postagem_Forum`,
  atualiza_perfil: `${aluno}/Atualiza_Perfil`,
  avaliacao_questoes_convenio: `${aluno}/Avaliacao_Questoes_Convenio`,
  avaliacao_questoes: `${aluno}/Avaliacao_Questoes`,
  audiencia: `${aluno}/Audiencia_POST`,

  canais: `${aluno}/Canais`,
  carrega_base: `${aluno}/Carrega_Base`,
  certificado_convenio: `${aluno}/Novo_Certificado_Convenio`,
  certificado_proprio: `${aluno}/Novo_Certificado_Proprio`,
  comentarios_post: `${aluno}/Comentarios_Post`,
  comentarios_post_aluno: `${aluno}/Comentarios_Postagem`,
  consulta_base: `${aluno}/consulta_base`,

  dados_aluno: `${aluno}/dados_aluno`,
  dados_avaliacao_convenio: `${aluno}/Dados_Avaliacao_Convenio`,
  dados_avaliacao: `${aluno}/Dados_Avaliacao`,
  dados_canal: `${aluno}/Dados_Canal`,
  dados_capitulo_convenio: `${aluno}/Dados_Capitulo_Convenio`,
  dados_capitulo: `${aluno}/Dados_Capitulo`,
  dados_disciplina_convenio: `${aluno}/Dados_Disciplina_Convenio`,
  dados_disciplina: `${aluno}/Dados_Disciplina`,
  dados_postagem: `${aluno}/Dados_Postagem`,
  dados_trilha: `${aluno}/dados_trilha`,
  dashboard: `${aluno}/Dashboard`,
  disciplinas_extras_convenio: `${aluno}/disciplinas_extras_convenio`,
  disciplinas_extras: `${aluno}/disciplinas_extras`,
  disciplinas_loja: `${aluno}/Disciplinas_Loja`,

  edita_postagem_forum: `${aluno}/Edita_Postagem_Forum`,
  editar_comentario_postagem: `${aluno}/Alterar_Comentario_Postagem`,
  enviar_mensagem: `${aluno}/Enviar_Mensagem`,
  excluir_comentario: `${aluno}/Excluir_Comentario`,
  excluir_comentario_postagem: `${aluno}/Excluir_Comentario_Postagem`,
  excluir_mensagem: `${aluno}/Excluir_Mensagem`,
  excluir_postagem: `${aluno}/Excluir_Postagem`,
  excluir_postagem_gestor: `${aluno}/Excluir_Postagem_Gestor`,
  Excluir_Anexo_Postagem: `${aluno}/Excluir_Anexo_Postagem`,
  Excluir_Anexo_Postagem_Gestor: `${aluno}/Excluir_Anexo_Postagem_Gestor`,
  enviar_notificacao: `${aluno}/Notifica_POST`,

  feed_aluno: `${aluno}/Feed_Aluno`,
  feed_aluno_paginado: `${aluno}/Feed_Aluno_Paginado`,
  feed_aluno_filtrado: `${aluno}/Feed_Aluno_Filtrado`,
  feed_aluno_filtrado_paginado: `${aluno}/Feed_Aluno_Filtrado_Paginado`,

  finaliza_capitulo: `${aluno}/Finaliza_Capitulo`,

  games_aluno: `${game}/Games_Aluno`,
  get_token: `${auth}/get_token`,
  get_token_integrado: `${auth}/get_token_integrado`,
  get_token_integrado_generico: `${auth}/get_token_integrado_generico`,
  
  inicia_avaliacao: `${aluno}/Inicia_Avaliacao`,
  iniciar_trilha: `${aluno}/iniciar_trilha`,
  insere_matricula: `${aluno}/Insere_Matricula`,
  insere_questoes_tentativa: `${aluno}/Insere_Questoes_Tentativa`,
  inserir_comentario: `${aluno}/Inserir_Comentario`,
  inserir_comentario_postagem: `${aluno}/Inserir_Comentario_Postagem`,
  instrucoes_app: `${aluno}/Instrucoes_App`,
  inserir_anexos_post_gestor: `${aluno}/Inserir_Anexo_Postagem_Gestor`,
  inserir_anexos_post_aluno: `${aluno}/Inserir_Anexo_Postagem`,

  ler_caderno: `${aluno}/Ler_Caderno`,
  lista_anexos_forum: `${aluno}/Lista_Anexos_Forum`,

  lista_calendario: `${aluno}/Lista_Calendario`,
  lista_capitulos_convenio: `${aluno}/Lista_Capitulos_Convenio`,
  lista_capitulos_tutorial: `${aluno}/Lista_Capitulos_Tutorial`,
  lista_capitulos: `${aluno}/Lista_Capitulos`,
  lista_capitulos_prof: `${aluno}/Lista_Capitulos_Prof`,
  lista_chat: `${aluno}/Lista_CHAT`,
  lista_colegas: `${aluno}/Lista_Colegas`,
  lista_forum: `${aluno}/Lista_Forum`,
  lista_glossario: `${aluno}/Lista_Glossario`,

  lista_mensagens_forum: `${aluno}/Lista_Mensagens_Forum`,
  lista_menu: `${aluno}/Lista_Menu`,
  lista_menu_canais: `${aluno}/Menu_Canais`,
  lista_protocolos_tarefa: `${aluno}/Lista_Protocolos_Tarefa`,
  lista_salas_tutoria: `${aluno}/Lista_Salas_Tutoria`,
  lista_tarefas: `${aluno}/Lista_Tarefas`,
  listar_anexos_convenio: `${aluno}/Listar_Anexos_Convenio`,
  listar_anexos: `${aluno}/Listar_Anexos`,
  listar_anexos_alunos: `${aluno}/Listar_Anexos_Postagem`,
  listar_botoes_capitulo: `${aluno}/Listar_Botoes_Capitulo`,
  listar_categorias_tutoriais: `${aluno}/Listar_Categorias_Tutoriais`,
  listar_destinatarios_curso: `${aluno}/Listar_Destinatarios_Curso`,
  listar_destinatarios: `${aluno}/Listar_Destinatarios`,
  listar_lives: `${aluno}/Listar_Lives`,
  listar_meetings: `${aluno}/Listar_Meetings`,
  listar_tutoriais: `${aluno}/Listar_Tutoriais`,
  listar_tutoriais_categoria: `${aluno}/Listar_Tutoriais_Categoria`,
  lista_versoes_wiki: `${aluno}/Lista_Versoes_Wiki`,
  lista_wiki: `${aluno}/Lista_Wiki`,
  logon: `${aluno}/Logon`,
  logout: `${aluno}/Logout`,
  logout_plataforma: `${aluno}/LOGOUT_PLATAFORMA`,

  marcar_como_lida: `${aluno}/Marcar_como_Lida`,
  marcar_presenca: `${aluno}/Registra_presenca`,
  marcar_presenca_chat: `${aluno}/Registra_presenca_CHAT`,
  marcar_presenca_live: `${aluno}/Registra_presenca_LIVE`,
  marcar_saida_live: `${aluno}/Registra_saida_LIVE`,
  marcar_reacao: `${aluno}/Marcar_Reacao`,
  marcar_reacao_postagem: `${aluno}/Marcar_Reacao_Postagem`,
  mensagens: `${aluno}/Mensagens`,

  nova_postagem_forum: `${aluno}/Nova_Postagem_Forum`,
  novo_anexo_forum: `${aluno}/Novo_Anexo_Forum`,
  novo_pedido_matricula: `${aluno}/Novo_Pedido_Matricula`,
  novo_protocolo: `${aluno}/Novo_Protocolo`,

  ordem_da_vez: `${aluno}/Ordem_da_vez`,

  parametros_gerais: `${aluno}/parametros_gerais_personalizado`,
  posts_canal: `${aluno}/Posts_Canal`,
  posts_canal_paginado: `${aluno}/Posts_Canal_Paginado`,
  posts_canal_filtrado: `${aluno}/Posts_Canal_Filtrado`,
  posts_canal_filtrado_paginado: `${aluno}/Posts_Canal_Filtrado_Paginado`,
  posts_aluno: `${aluno}/Postagens`,
  professores: `${aluno}/Professores`,

  qtd_feed_aluno: `${aluno}/Qtd_Feed_Aluno`,
  qtd_feed_aluno_filtrado: `${aluno}/Qtd_Feed_Aluno_Filtrado`,
  qtd_posts_canal: `${aluno}/Qtd_Posts_Canal`,
  qtd_posts_canal_filtrado: `${aluno}/Qtd_Posts_Canal_Filtrado`,
  qtd_mensagens_nao_lidas: `${aluno}/Qtd_Mensagens_Nao_Lidas`,

  reacao_aluno_post: `${aluno}/Reacao_Aluno_Post`,
  reacoes_post: `${aluno}/Reacoes_Post`,
  reacao_postagem_aluno: `${aluno}/Reacoes_Postagem`,
  recupera_alunoId: `${aluno}/Recupera_ALUNOID`,
  recupera_senha: `${aluno}/Recupera_senha`,
  registra_aceite: `${aluno}/Registra_aceite`,
  recuperar_mensagem: `${aluno}/Recuperar_Mensagem`,
  resultado_avaliacao: `${aluno}/Resultado_Avaliacao`,

  salva_caderno: `${aluno}/Salva_Caderno`,
  salva_resultado_tentativa: `${aluno}/Salva_Resultado_Tentativa`,
  
  scorm_define_valor: `${aluno}/DefineValorLMS`,
  scorm_finalizar: `${aluno}/FinalizarLMS`,
  scorm_iniciar: `${aluno}/IniciarLMS`,
  scorm_obtem_valor: `${aluno}/ObtemValorLMS`,

  termos_de_uso: `${aluno}/Termos_de_uso`,
  trilhas_disciplinas: `${aluno}/trilhas_disciplinas`,
  trilhas: `${aluno}/trilhas`,

  valida_token: `${aluno}/valida_token`,
  verifica_gestorid: `${aluno}/Verifica_GESTORID`,
  verifica_matricula_aula: `${aluno}/Verifica_matricula_aula`,
  insere_post_gestor: `${aluno}/Inserir_Postagem_Gestor`,
  insere_post_aluno: `${aluno}/Inserir_Postagem`,
  atualiza_post_gestor: `${aluno}/Alterar_Postagem_Gestor`,
  atualiza_post_aluno: `${aluno}/Alterar_Postagem`,
  grupos_aluno_post: `${aluno}/grupos_do_aluno`,
  grupos_gestor_post: `${aluno}/grupos_do_gestor`,
  salva_grupo_aluno_post: `${aluno}/salvar_grupos_postagem`,
  salva_grupo_gestor_post: `${aluno}/salvar_grupos_postagem_gestor`,
  temas: `${aluno}/Temas`,
  dados_tema: `${aluno}/Dados_Tema`,
  verifica_comunicacao: `${aluno}/Verifica_Comunicacao`,
  salva_confirmacao: `${aluno}/Salva_Confirmacao`,
  engajamento_email: `${auth}/EMAIL`,
  engajamento_sms: `${auth}/SMS`,
  engajamento_wpp: `${auth}/WHATSAPP`,
  //IDEIAS
  lista_ideias: `${ideias}/IDEIAS_LISTA_TEMAS`,
  lista_propostas: `${ideias}/IDEIAS_LISTA_PROPOSTAS`,
  lista_campos: `${ideias}/IDEIAS_CAMPOS_TEMAS`,
  lista_anexos_tema: `${ideias}/IDEIAS_ANEXOS_TEMA`,
  lista_anexos_proposta: `${ideias}/IDEIAS_ANEXOS_PROPOSTA`,
  dados_proposta: `${ideias}/IDEIAS_DADOS_PROPOSTA`,
  //Reações Ideias
  lista_reacoes_ideias: `${ideias}/IDEIAS_REACOES`,
  marcar_reacoes_ideias: `${ideias}/IDEIAS_MARCAR_REACAO`,
  lista_reacoes_ideias_aluno: `${ideias}/IDEIAS_REACAO_ALUNO`,
  lista_comentarios_ideia: `${ideias}/IDEIAS_COMENTARIOS`,
  altera_comentario_ideia: `${ideias}/IDEIAS_ALTERAR_COMENTARIO`,
  inseri_comentario_ideia: `${ideias}/IDEIAS_INSERIR_COMENTARIO`,
  inserir_ideia: `${ideias}/IDEIAS_INSERIR`,
  inserir_alterar: `${ideias}/IDEIAS_ALTERAR`,
  inserir_anexo_ideia: `${ideias}/IDEIAS_INSERIR_ANEXO`,
  excluir_anexo_ideia: `${ideias}/IDEIAS_EXCLUIR_ANEXO`,
  excluir_ideia: `${ideias}/IDEIAS_EXCLUIR`,

  //Research
  dados_pesquisa: `${research}/Dados_Pesquisa`,
  pesquisas_responder: `${research}/Pesquisa_a_Responder`,
  Pesquisa_questoes: `${research}/Pesquisa_Questoes`,
  iniciar_pesquisa: `${research}/Inicia_Pesquisa`,
  salvar_resultado: `${research}/Salva_Resultado_Tentativa`,
  insere_questoes: `${research}/Insere_Questoes_Tentativa`,
  valida_pesquisa: `${research}/Valida_Pesquisa`,
  lista_pesquisas: `${backend}/BK_Pesquisas`,

  //Dashboard
  cabecalho: `${dashboard}/Cabecalho`,
  painel: `${dashboard}/Painel`,
  grafico: `${dashboard}/Grafico`,
  table: `${dashboard}/Tabela`,
  aovivo: `${dashboard}/Ao_Vivo`,

  //Backend
  alterar_liberacao: `${backend}/BK_Altera_Liberacao_Disciplina`,
  eventos_aluno: `${backend}/BK_AgendaEventosSincronosAluno?alunoid={alunoid}`,
  eventos_sincronos: `${backend}/BK_AgendaEventosAluno?alunoid={alunoid}`,
  eventos_sincronos_professor: `${backend}/BK_AgendaEventosSincronosProfessor?professorid={professorid}`,
  // https://middleware.cadernovirtual.com.br/Backend.asmx/BK_AgendaEventosSincronosProfessor?professorid=1
  //https://middleware.cadernovirtual.com.br/Backend.asmx/BK_AgendaTotalAluno?alunoid=1
  tarefas: `${backend}/BK_TarefasAlunoDisciplina?alunoid={alunoid}&disciplinaid={disciplinaid}`,
  busca_matricula_no_curso: `${backend}/BK_Matricula_Curso_Extra`,
  perfil_usuario: `${backend}/BK_Perfil_Usuario`,
  menu_educador: `${backend}/BK_Verifica_Professor`,
  profiles: `${backend}/BK_Perfil_Usuario`,
  lista_visualizacoes: `${backend}/BK_Quadrantes_Visualizados`,
  marca_visualizacao: `${backend}/BK_Marca_Visualizacao`,

  //Prateleira
  lista_cursos_prateleira: `${backend}/BK_Prateleira_Full`,
  salvar_grupos_prateleira: `${backend}/salvar_grupos_prateleira`
};
