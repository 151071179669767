import { useQuery } from "@tanstack/react-query";
import { axios } from "../../../shared/lib/axios";
import _cookies from "../../../shared/utils/_cookies";

export const getSurveyGroups = async ({ surveyId }) => {
  const res = await axios.cadernoVirtualBase(
    "survey-service2/research/listGroups",
    {
      params: {
        researchId: surveyId,
        managerId: _cookies.getItem("auth").user.profile.isGestor.ID,
        token: _cookies.getItem("auth").token,
      },
    }
  );
  return res.object;
};

export const useSurveyGroups = ({ config, surveyId = {} }) => {
  return useQuery({
    ...config,
    queryKey: ["surveyGroups", { surveyId }],
    queryFn: () => getSurveyGroups({ surveyId }),
  });
};
