import { api } from "../../../../../shared/api";

export const TRACK_INICIAR_TRILHA = "TRACK_INICIAR_TRILHA";
export const COURSE_INSERE_MATRICULA = "COURSE_INSERE_MATRICULA";
export const TRACK_DADOS_TRILHA = "TRACK_DADOS_TRILHA";

export const getCourseStatus = (course, trackData) => {
  const {
    LIBERADO,
    ORDEM,
    acessoilimitado,
    matricula_ativa,
    matricula_conclusa,
  } = course;

  const { currentOrder, PROGRESSAO } = trackData;

  if (!!matricula_conclusa && matricula_conclusa >= (matricula_ativa || 0)) {
    return "completed";
  } else if (LIBERADO === "N") {
    return "disabled";
  } else if (LIBERADO === "S") {
    if (matricula_ativa) {
      return "active";
    } else if (PROGRESSAO === "MANUAL_LIVRE") {
      return "default";
    } else if (PROGRESSAO === "MANUAL_SEQUENCIAL" && currentOrder === ORDEM) {
      return "default";
    }
  } else {
    return "disabled";
  }
};

//other
const trilhasDisciplinas = async (alunoid, trilhaid, token) => {
  try {
    const res = await fetch(
      `${api.trilhas_disciplinas}?alunoid=${alunoid}&trilhaid=${trilhaid}`,
      {
        method: "get",
        headers: { token },
      }
    );

    if (res.status >= 200 && res.status < 300) {
      const json = await res.json();
      let tracks = json[0].data;
      if (tracks && tracks.length > 0) {
        tracks.sort((a, b) => a.ORDEM - b.ORDEM);
      }
      return tracks;
    } else {
      throw new Error(res.statusText);
    }
  } catch (error) {}
};

const ordemDaVez = async (alunoid, trilhaid, token) => {
  try {
    const res = await fetch(
      `${api.ordem_da_vez}?alunoid=${alunoid}&trilhaid=${trilhaid}`,
      {
        method: "get",
        headers: { token },
      }
    );

    if (res.status >= 200 && res.status < 300) {
      const json = await res.json();
      return parseInt(json[0].data.ORDEM);
    } else {
      throw new Error(res.statusText);
    }
  } catch (error) {}
};

export const fetchTrackData = ({ token, alunoid, trilhaid }) => {
  return async (dispatch) => {
    try {
      const res = await fetch(
        `${api.dados_trilha}?alunoid=${alunoid}&trilhaid=${trilhaid}`,
        {
          method: "get",
          headers: { token },
        }
      );
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        let track = {};
        track.trackData = json[0].data[0];
        track.courses = await trilhasDisciplinas(alunoid, trilhaid, token);
        track.trackData.currentOrder = await ordemDaVez(
          alunoid,
          trilhaid,
          token
        );

        dispatch({ type: TRACK_DADOS_TRILHA, track });
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      throw new Error(error);
    }
  };
};

export const insereMatricula = ({ disciplinaid, token, alunoid }) => {
  return async (dispatch) => {
    const value = `[{"alunoid":${alunoid},"disciplinaid":${disciplinaid}}]`;
    const body = new FormData();
    body.append("dados", value);

    try {
      const res = await fetch(api.insere_matricula, {
        method: "post",
        headers: { token },
        body,
      });
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        const matriculaid = json[0].data.matriculaid;
        dispatch({ type: COURSE_INSERE_MATRICULA, matriculaid });
        return matriculaid;
      } else {
        dispatch({ type: COURSE_INSERE_MATRICULA, matriculaid: null });
        return null;
      }
    } catch (error) {
      throw new Error(error);
    }
  };
};

export const iniciarTrilha = ({ token, alunoid, trilhaid }) => {
  return async (dispatch) => {
    const body = new FormData();
    body.append("dados", `[{"alunoid":${alunoid},"trilhaid":${trilhaid}}]`);
    try {
      const res = await fetch(api.iniciar_trilha, {
        method: "post",
        headers: { token },
        body,
      });

      const json = await res.json();

      dispatch({ type: TRACK_INICIAR_TRILHA, payload: json });
    } catch (error) {
      console.log("iniciarTrilha error", error);
      return "error";
    }
  };
};
