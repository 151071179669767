import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Form from "../../../ui/components/Form";
import * as yup from "yup";
import { useSaveSurvey } from "../api/saveSurvey";
import moment from "moment";
import RichTextInput from "../../../ui/components/RichText/RichTextInput";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useRef } from "react";
import { AttachFile, Close } from "@mui/icons-material";
import { useState } from "react";
import ImageWithBackdrop from "../../../ui/components/ImageWithBackdrop";
import { axios } from "../../../shared/lib/axios";
import { renderTimeViewClock } from "@mui/x-date-pickers";
import { resourcesUrl } from "../../../shared/config";
import { LoadingButton } from "@mui/lab";
import { DateTimePicker } from "@mui/x-date-pickers";
import { ButtonPointer } from "mdi-material-ui";

const createSurveySchema = yup.object().shape({
  title: yup.string().required("Esse campo é obrigatório"),
  orientations: yup.string().required("Esse campo é obrigatório"),
  startDate: yup.mixed(),
  endDate: yup
    .mixed()
    .test(
      "valid-time",
      "A de término deve ser posterior à data de início",
      (value, context) => {
        return value.isAfter(context.parent.startDate);
      }
    ),
  mandatory: yup.string(),
  npsType: yup.string(),
  anonymous: yup.string(),
});

const ACCECPTED_IMG_FILE_TYPES = [".gif", ".jpg", ".jpeg", ".png"];

export function SaveSurveyForm({
  defaultValues = {
    title: "Nova pesquisa",
    orientations: "",
    startDate: moment(new Date()),
    endDate: moment(new Date()).endOf("day"),
    mandatory: "N",
    npsType: "N",
    anonymous: "N",
  },
  onSuccess = () => {},
  onDirty = () => {},
  submitButtonRef,
}) {
  const saveSurveyMutation = useSaveSurvey({
    config: {
      onSuccess: (data, variables, context) => {
        onSuccess(data, variables, context);
        onDirty(false);
      },
    },
  });
  const ref = useRef();
  const [image, setImage] = useState(defaultValues.image);
  const handleFile = (e) => {
    setImage(e.target.files[0]);
    onDirty(true);
  };

  const {
    control,
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState,

    ...rest
  } = useForm({
    defaultValues: {
      ...defaultValues,
      orientations: defaultValues.orientations.replace(/(\r\n|\n|\r)/gm, ""),
    },
    resolver: yupResolver(createSurveySchema),
  });

  useEffect(() => {
    onDirty(!!Object.keys(formState.dirtyFields).length);
  }, [formState.dirtyFields]);

  const disableNPS =
    defaultValues.npsType == "N" && !!defaultValues.questions?.length;
  return (
    <>
      <form
        onSubmit={handleSubmit((values) => {
          saveSurveyMutation.mutate({
            data: {
              ...values,
              orientations: encodeURIComponent(values.orientations),
              questions: undefined,
              startDate: values.startDate.format("DD/MM/YYYY HH:mm:ss"),
              endDate: values.endDate.format("DD/MM/YYYY HH:mm:ss"),
            },
            image,
          });
        })}
      >
        <Box sx={{ display: "flex", flexDirection: "column", rowGap: 2 }}>
          <TextField
            label="Título"
            {...register("title")}
            error={!!formState.errors["title"]}
            helperText={formState.errors["title"]?.message}
          />
          <input
            accept={ACCECPTED_IMG_FILE_TYPES.toString()}
            ref={ref}
            type="file"
            hidden
            onChange={handleFile}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Button
              sx={{ width: "fit-content", height: "fit-content" }}
              startIcon={<AttachFile />}
              onClick={() => ref.current.click()}
            >
              Adicionar imagem
            </Button>
            {image && (
              <Box
                sx={{
                  position: "relative",
                  aspectRatio: "16 / 9",
                  height: "200px",
                  width: "auto",
                }}
              >
                <IconButton
                  onClick={() => setImage(null)}
                  sx={{
                    position: "absolute",
                    top: 3,
                    right: 3,
                    zIndex: "1",
                    border: "1px solid var(--primary_color_light)",
                    backgroundColor: "white",
                  }}
                >
                  <Close />
                </IconButton>

                <Box
                  component="img"
                  sx={{ width: "100%", height: "100%", objectFit: "contain" }}
                  src={
                    typeof image == "string"
                      ? resourcesUrl + "/recursos/" + image
                      : URL.createObjectURL(image)
                  }
                />
              </Box>
            )}
          </Box>
          <FormControl
            sx={{
              border: `1px solid ${
                formState.errors["orientations"] ? "red" : "#e5e7eb"
              }`,
              borderRadius: 1,
              "&:hover": { border: "1px solid black" },
            }}
            helperText={"Teste"}
            error={formState.errors["orientations"]?.message}
          >
            <InputLabel
              sx={{ transform: "translate(14px, -15px) scale(0.75)" }}
              shrink
              htmlFor="my-input"
            >
              Descrição
            </InputLabel>
            <Controller
              control={control}
              name={"orientations"}
              render={({ field }) => {
                return (
                  <RichTextInput
                    id="my-input"
                    value={field.value}
                    onChange={field.onChange}
                    modules={{
                      toolbar: [
                        [{ header: [1, 2, 3, 4, 5, 6, false] }],
                        [
                          "bold",
                          "italic",
                          "underline",
                          "strike",
                          "blockquote",
                          "code-block",
                        ],
                        [{ color: [] }, { background: [] }],
                        [{ align: [] }],
                        [
                          { list: "ordered" },
                          { list: "bullet" },
                          { indent: "-1" },
                          { indent: "+1" },
                        ],
                        // ["link", "image"],
                      ],
                    }}
                  />
                );
              }}
            />
            <FormHelperText>
              {formState.errors["orientations"]?.message}
            </FormHelperText>
          </FormControl>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              gap: 1,
            }}
          >
            <Controller
              control={control}
              name="startDate"
              render={({ field }) => {
                return (
                  <DateTimePicker
                    views={[
                      "year",
                      "month",
                      "day",
                      "hours",
                      "minutes",
                      "seconds",
                    ]}
                    slotProps={{
                      textField: {
                        error: !!formState.errors["startDate"],
                        helperText: formState.errors["startDate"]?.message,
                      },
                    }}
                    sx={{ flexGrow: "1" }}
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    label="Data de início"
                    value={field.value}
                    onChange={field.onChange}
                    placeHolderText="Data de início"
                  />
                );
              }}
            />
            <Controller
              control={control}
              name="endDate"
              render={({ field }) => {
                return (
                  <DateTimePicker
                    views={[
                      "year",
                      "month",
                      "day",
                      "hours",
                      "minutes",
                      "seconds",
                    ]}
                    slotProps={{
                      textField: {
                        error: !!formState.errors["endDate"],
                        helperText: formState.errors["endDate"]?.message,
                      },
                    }}
                    sx={{ flexGrow: "1" }}
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    label="Data de término"
                    value={field.value}
                    onChange={field.onChange}
                    placeHolderText="Data de término"
                  />
                );
              }}
            />
          </Box>

          <Controller
            control={control}
            name="mandatory"
            render={({ field }) => {
              return (
                <TextField
                  select
                  inputProps={register("mandatory")}
                  value={field.value}
                  onChange={field.onChange}
                  disabled={getValues("npsType") == "S"}
                >
                  <MenuItem value={"P"}>Pública</MenuItem>
                  <MenuItem value={"S"}>Obrigatória</MenuItem>
                  <MenuItem value={"N"}>Não obrigatória</MenuItem>
                  <MenuItem value={"L"}>Pesquisa por link</MenuItem>
                  <MenuItem value={"M"}>
                    Permitir responder várias vezes
                  </MenuItem>
                </TextField>
              );
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <FormControlLabel
              label="Pesquisa NPS"
              control={
                <Controller
                  control={control}
                  name="npsType"
                  disabled={disableNPS}
                  render={({ field }) => {
                    return (
                      <Checkbox
                        disabled={disableNPS}
                        checked={field.value == "S"}
                        onChange={(event) => {
                          if (event.target.checked) {
                            setValue("mandatory", "S");
                          }
                          return setValue(
                            "npsType",
                            event.target.checked ? "S" : "N"
                          );
                        }}
                      />
                    );
                  }}
                />
              }
            />
            <FormControlLabel
              label="Anônima"
              control={
                <Controller
                  control={control}
                  name="anonymous"
                  render={({ field }) => {
                    return (
                      <Checkbox
                        checked={field.value == "S"}
                        onChange={(event) => {
                          return setValue(
                            "anonymous",
                            event.target.checked ? "S" : "N"
                          );
                        }}
                      />
                    );
                  }}
                />
              }
            />
          </Box>
          <button
            hidden
            ref={submitButtonRef}
            loading={saveSurveyMutation.isLoading}
            type="submit"
          >
            Salvar
          </button>
        </Box>
      </form>
    </>
  );
}
