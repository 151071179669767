import TextContent from "./TextContent";
import TextInput from "./TextInput";

function TextComponent({
  contentId,
  mandatory,
  sx,
  value,
  onChange,
  isEditing = false,
  choiseComponent = false,
  ...props
}) {
  return choiseComponent ? 
  ( <p
    dangerouslySetInnerHTML={{
      __html: value,
    }}
  />)
  : !isEditing ? (
    
    <TextContent
      sx={sx}
      htmlText={value}
      contentId={contentId}
      mandatory={mandatory}
    />
  ) : (
    <TextInput sx={sx} value={value} onChange={onChange} />
  );
}

export default TextComponent;
