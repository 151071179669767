export const cleanHTML = (function () {
  var element = document.createElement("div");

  function decodeHtmlEntities(htmlString) {
    if (htmlString && typeof htmlString === "string") {
      htmlString = htmlString.replace(
        /<script[^>]*>([\S\s]*?)<\/script>/gim,
        ""
      );
      htmlString = htmlString.replace(
        /<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim,
        ""
      );
      element.innerHTML = htmlString;
      htmlString = element.textContent;
      element.textContent = "";
    }
    return htmlString;
  }
  return decodeHtmlEntities;
})();
