import { Suspense, useEffect, useState } from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { folderBasename } from "../config";
import { getClient } from "../config";
import openRoutes from "./authRoutes";
import protectedRoutes from "./protectedRoutes";
import renderModalRoutes from "./modalRoutes";
import IntegratedRoutes from "./integratedRoutes";
import { useQuery } from "../hooks/useQuery";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { CLIENT_HAS_INTEGRATED_LOGIN } from "../constants/login";
import Loading from "../../ui/components/Loading";
import PublicSurveyPage from "../../features/survey/pages/PublicSurveyPage";
function Router({ isAuthenticated, auth, logonid }) {
  return (
    <BrowserRouter basename={folderBasename}>
      <RouterController
        auth={auth}
        logonid={logonid}
        isAuthenticated={isAuthenticated}
      />
    </BrowserRouter>
  );
}

function RouterController({ isAuthenticated }) {
  const location = useLocation();
  const navigate = useNavigate();

  const researchId = useQuery().get("researchbylink");
  const aulaId = useQuery().get("aulaid");
  const isLogout = useQuery().get("logout");
  const id = useQuery().get("Id");
  const type = useQuery().get("type");
  const idUser = useQuery().get("idUser");
  const password = useQuery().get("password");

  const [pathState, setPathState] = useState({
    previous: "",
    current: location.pathname,
  });

  const goToLogin = () => {
    navigate("/login");
  };

  const client = getClient();

  useEffect(() => {
    if (
      !(
        (CLIENT_HAS_INTEGRATED_LOGIN() && location.pathname.includes("login"))
        //    ||
        // ((isTemplate || client == "ABRHNAPRACA") &&
        //   location.pathname == "/signup")
      ) &&
      !isAuthenticated
    ) {
      goToLogin();
    }
  }, []);

  useEffect(() => {
    if (location.pathname != pathState.current) {
      setPathState({
        previous: pathState.current,
        current: location.pathname,
      });
    }

    if (!!researchId) {
      navigate(`/pesquisa/${researchId}?isByLink=true`);
    } else if (!!researchId && !isAuthenticated) {
      navigate(`./login?redirectTo=/pesquisa/${researchId}?isByLink=true`);
    } else if (isAuthenticated && isLogout === "true") {
      navigate("/logout");
    } else if (
      location.pathname === "/" &&
      type === "newslink" &&
      id &&
      isAuthenticated
    ) {
      navigate(`./noticias/${id}`);
    } else if (
      location.pathname === "/" &&
      type === "channellink" &&
      id &&
      isAuthenticated
    ) {
      navigate(`./canais/${id}`);
    } else if (!!aulaId && isAuthenticated) {
      navigate(`presenca/${aulaId}`);
    } else if (type === "login-piracanjuba" && !isAuthenticated) {
      navigate(`./login-piracanjuba?idUser=${idUser}`);
    } else if (type === "loginintegrated" && !isAuthenticated) {
      navigate(`./integratedlogin?idUser=${idUser}`);
    } else if (
      type === "multiplecertificates" ||
      type === "multiplecertificates_convenio"
    ) {
      navigate(
        `./certificates?matriculaids=${idUser}&certificateType=${
          type === "multiplecertificates_convenio" ? "convenio" : "proprio"
        }`
      );
    } else if (type == "signup" && !isAuthenticated) {
      navigate(`./signup`);
    } else if (type == "login" && idUser && password) {
      navigate(`./login?username=${idUser}&password=${password}`);
    } else if (type && isAuthenticated) {
      navigate(`./${type}/${id ? id.replaceAll(",", "/") : ""}`);
    } else if (type && !isAuthenticated) {
      navigate(
        `./login?redirectTo=${`${type}/${id ? id.replaceAll(",", "/") : ""}`}`
      );
    }
  }, [location]);

  return (
    <>
      <Routes location={location.state?.backgroundLocation || location}>
        {!isAuthenticated && (
          <Route
            index
            path="/pesquisa/:id"
            element={
              <Suspense
                fallback={
                  <div>
                    <Loading />
                  </div>
                }
              >
                <PublicSurveyPage />
              </Suspense>
            }
          />
        )}
        {openRoutes}
        {CLIENT_HAS_INTEGRATED_LOGIN() && IntegratedRoutes}
        {isAuthenticated && protectedRoutes}
      </Routes>
      {location.state?.backgroundLocation && (
        <Routes location={location}>{renderModalRoutes}</Routes>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  logonid: state.course.logonid,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Router);
