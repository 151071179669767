import { /*isBasf,*/ isLocal } from "./urlConfig";
//const localhostClient = "cvcorporate";
// const localhostClient = "unimarche";
const localhostClient = "onbook";

export const folderBasename = isLocal ? "/aluno" : "/aluno";

export const isStMarche =
  (isLocal && localhostClient === "unimarche") ||
  window.location.host.search(/unimarche/) > -1;

export const isTestes = window.location.host.search(/testes/) > -1;

export const getClient = () => {
  return isStMarche
    ? "UNIMARCHE"
    : isTestes
    ? "CVCORPORATE"
    : getClient2().toUpperCase().replace("beta.", "").replace(".", "");
};

export const getClient2 = () => {
  const host = window.location.host;
  const client = isLocal
    ? localhostClient
    : host
        .replace("www.", "")
        .replace("cadernovirtual", "")
        .replace("basf-academiaagro", "")
        .replace(".com.br", "")
        .replace("beta", "")
        .replace(".", "");
  return client || "cooperar";
};

export const isBasf =
  getClient() === "cooperar" ||
  getClient() === "aplicoucolheu" ||
  getClient() === "pontoaponto" ||
  window.location.host.search(/basf-academiaagro/) > -1;

export const baseUrl = isBasf
  ? `https://${
      getClient() === "cooperar" ? "" : getClient() + "."
    }basf-academiaagro.com.br`
  : isStMarche
  ? "https://unimarche.marche.com.br"
  : isTestes
  ? "https://cvcorporate.cadernovirtual.com.br"
  : `https://${getClient().replace(".", "")}.cadernovirtual.com.br`;

export const logoUrl = `${baseUrl}/img/logo_cliente.png`;
export const logoAdicionalUrl = `${baseUrl}/img/`;
export const menuIconsUrl = `${baseUrl}/Menu_Icons/`;
export const certificate = `${baseUrl}/img/certificado_disciplina{disciplinaid}.jpg`;
export const certificateSignature = `${baseUrl}/Membros/fotos/ASSP{professorid}.png`;
export const certificateDefault = `${baseUrl}/img/certificado_mascara.jpg`;
export const membrosUrl = `${baseUrl}/Membros`;
export const imagesUrl = `${membrosUrl}/fotos`;
export const gamesImagesUrl = `${membrosUrl}/games/game`;
export const resourcesUrl = `${membrosUrl}/`;
export const scormUrl = `${membrosUrl}/SCORM`;
export const chatUrl = "https://cadernovirtual.inf.br/index.html";
export const postImageAluno = `${baseUrl}/membros/fotos/`;
export const configVimeo = {
  apiVimeo: "https://cadernovirtual.inf.br/server/api/vimeo",
  cvApiBaseUrl: `https://cvcorporate.cadernovirtual.com.br/api/vimeo/vimeo.asmx`, // url base da api
  user: { id: "10", type: "g", albumId: "5714229" /* "5639975" */ }, // dados do usuario
  apiUrl: "https://middleware.cadernovirtual.com.br",
};

// DATABASE
//export const database = "cvcorporate".toUpperCase();
export const database = isStMarche
  ? "UNIMARCHE"
  : isTestes
  ? "CVCORPORATE"
  : getClient().toUpperCase().replace("beta", "").replace(".", "");
