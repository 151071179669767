import { useMutation, useQueryClient } from "@tanstack/react-query";
import { axios } from "../../../shared/lib/axios";
import _cookies from "../../../shared/utils/_cookies";
import { createNotification } from "../../../shared/store/notifications";

export const saveSurvey = async ({ data, image }) => {
  const formData = new FormData();
  formData.append(
    "content",
    new Blob([JSON.stringify(data)], {
      type: "application/json",
    })
  );
  if (image) {
    formData.append("file", image);
  }
  const res = await axios.cadernoVirtualBase.post(
    "survey-service2/research",
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return res.object[0];
};

export const useSaveSurvey = ({ config, surveyId = 0 } = {}) => {
  const queryClient = useQueryClient();
  return useMutation({
    ...config,
    mutationFn: saveSurvey,
    mutationKey: ["saveSurvey"],
    onSuccess: (data, variables, context) => {
      createNotification({
        type: "success",
        message: "Pesquisa salva com successo",
      });
      config.onSuccess(data, variables, context);
      queryClient.invalidateQueries({ queryKey: ["managerSurveys"] });
      queryClient.invalidateQueries({ queryKey: ["studentSurveys"] });

      if (data.researchId) {
        queryClient.invalidateQueries({
          queryKey: ["survey", { surveyId: data.researchId }],
        });
      }
    },
  });
};
