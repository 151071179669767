const SET_SKIP_WARNING = "SET_SKIP_WARNING";

export const setSkipWarning = (warningIdentifier, value) => {
  return async (dispatch) => {
    console.log("teste teste teste teste teste");
    dispatch({ type: SET_SKIP_WARNING, warningIdentifier, value });
  };
};

const initialState = {
  skipAddCourseToTrackWarning: false,
  skipAddCourseToCertificationWarning: false,
};
export default function reducer(state = initialState, action) {
  
  switch (action.type) {
    case SET_SKIP_WARNING:
      const foo = { ...state };

      foo[action.warningIdentifier] = action.value;

      return foo;

    default:
      return state;
  }
}
