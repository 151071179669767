import { api } from "../api";

// actions
const POST_EXCLUI_POSTAGEM = "POST_EXCLUI_POSTAGEM";
const POST_SET_CURRENT_POST = "POST_SET_CURRENT_POST";
const POST_ATUALIZA_POST_GESTOR = "POST_ATUALIZA_POST_GESTOR";
const POST_ATUALIZA_POST_ALUNO = "POST_ATUALIZA_POST_ALUNO";
const POST_MARCAR_REACAO = "POST_MARCAR_REACAO";
const POST_REACOES_POST = "POST_REACOES_POST";
const POST_REACAO_ALUNO_POST = "POST_REACAO_ALUNO_POST";
const POST_INPUT_FILE = "POST_INPUT_FILE";
// const POST_UPLOAD_FILE = "POST_UPLOAD";
const POST_GRUPOS_ALUNO = "POST_GRUPOS_ALUNO";
const POST_GRUPOS_GESTOR = "POST_GRUPOS_GESTOR";
const POST_SALVE_GRUPO_ALUNO = "POST_SALVE_GRUPO_ALUNO";
const POST_SALVE_GRUPO_GESTOR = "POST_SALVE_GRUPO_GESTOR";
const CLEAN_POST = "CLEAN_POST";
const SET_STATUS = "setStatus";
const AUDIENCIA_ALUNOS_LIST = "AUDIENCIA_ALUNOS_LIST";
const POST_SEND_NOTIFICATION = "POST_SEND_NOTIFICATION";
// action creators

const oldBaseUrl = "https://middleware.cadernovirtual.com.br/aluno.asmx";

export const setCurrentPost = (currentPost) => {
  return { type: POST_SET_CURRENT_POST, currentPost };
};

export const cleanPost = () => {
  return {
    type: CLEAN_POST,
    updatePost: false,
    postagemsaveid: 0,
    status: 0,
    userGroups: null,
    currentPost: null,
  };
};

export const excluirPostagem = (capituloid, token, postagemid) => {
  return async (dispatch) => {
    try {
      const res = await fetch(
        postagemid !== null
          ? `${api.excluir_postagem}?postagemid=${postagemid}`
          : `${api.excluir_postagem_gestor}?capituloid=${capituloid}`,
        {
          method: "get",
          headers: { token },
        }
      );

      if (res.status < 200 || res.status >= 300) {
        throw new Error(res.statusText);
      }

      return res;
    } catch (error) {
      console.log("erro ao excluir :", error);
    }
  };
};

export const excluirAnexoPostagem = (postagemAnexoid, token) => {
  return async (dispatch) => {
    try {
      const res = await fetch(
        `${api.Excluir_Anexo_Postagem}?postagem_anexosid=${postagemAnexoid}`,
        {
          method: "get",
          headers: { token },
        }
      );
      // if (res.status >= 200 && res.status < 300) {
      //   const json = await res.json();
      // } else {
      if (res.status < 200 || res.status >= 300) {
        throw new Error(res.statusText);
      }

      return res;
    } catch (error) {
      console.log("erro ao excluir Anexo postagem:", error);
    }
  };
};

export const excluirAnexoPostagemGestor = (postagemAnexoid, token) => {
  return async (dispatch) => {
    try {
      const res = await fetch(
        `${api.Excluir_Anexo_Postagem_Gestor}?recursoid=${postagemAnexoid}`,
        {
          method: "get",
          headers: { token },
        }
      );
      // if (res.status >= 200 && res.status < 300) {
      //   const json = await res.json();
      // } else {
      if (res.status < 200 || res.status >= 300) {
        throw new Error(res.statusText);
      }
    } catch (error) {
      console.log("erro ao excluir Anexo postagem:", error);
    }
  };
};

export const atualizaPostGestor = (dados, token, file) => {
  return async (dispatch) => {
    const body = new FormData();
    body.append("dados", `[${JSON.stringify(dados)}]`);
    if (file && !!dados.capituloid) {
      body.append("file", file);
    }
    const endpoint = !!dados.capituloid
      ? api.atualiza_post_gestor
      : api.insere_post_gestor;

    try {
      const res = await fetch(endpoint, {
        method: "post",
        headers: { token },
        body,
      });
      const json = await res.json();
      //        console.log("json", json, JSON.stringify(json));
      if (res.status >= 200 && res.status < 300) {
        const status = await json[0].status;
        const postagemid =
          json[0].data != null ? json[0].data.CAPITULOID : dados.capituloid;
        if (status >= 200 && status < 300) {
          dispatch({
            type: POST_ATUALIZA_POST_GESTOR,
            updatePost: true,
            postagemsaveid: postagemid,
          });
        } else {
          throw new Error(json[0].message);
        }
        return json[0];
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      dispatch({ type: POST_ATUALIZA_POST_GESTOR, updatePost: false });
      throw new Error(error);
    }
  };
};

export const insereAnexoPostagem = (dados, file, token) => {
  return async (dispatch) => {
    const body = new FormData();
    body.append("dados", `[${JSON.stringify(dados)}]`);
    body.append("file", file);

    const endpoint = api.inserir_anexos_post_aluno;
    try {
      const res = await fetch(endpoint, {
        method: "post",
        headers: { token },
        body,
      });
      const json = await res.json();
      if (res.status >= 200 && res.status < 300) {
        const status = await json[0].status;
        if (status >= 200 && status < 300) {
          dispatch({
            type: POST_INPUT_FILE,
            status: 200,
          });
        } else {
          throw new Error(json[0].message);
        }
      } else {
        throw new Error(res.statusText);
      }
      return json[0];
    } catch (error) {
      dispatch({ type: POST_INPUT_FILE });
      throw new Error(error);
    }
  };
};

export const insereAnexoPostagemGestor = (dados, file, token) => {
  return async (dispatch) => {
    const body = new FormData();
    body.append("dados", `[${JSON.stringify(dados)}]`);
    body.append("file", file);

    const endpoint = api.inserir_anexos_post_gestor;
    try {
      const res = await fetch(endpoint, {
        method: "post",
        headers: { token },
        body,
      });
      const json = await res.json();
      if (res.status >= 200 && res.status < 300) {
        const status = await json[0].status;
        if (status >= 200 && status < 300) {
          dispatch({
            type: POST_INPUT_FILE,
          });
        } else {
          throw new Error(json[0].message);
        }
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      dispatch({ type: POST_INPUT_FILE });
      throw new Error(error);
    }
  };
};

export const atualizaPostAluno = (dados, token, file) => {
  return async (dispatch) => {
    const body = new FormData();
    body.append("dados", `[${JSON.stringify(dados)}]`);
    if (file && (!!dados.POSTAGEMID || !!dados.postagemid)) {
      body.append("file", file);
    }
    const endpoint =
      !!dados.POSTAGEMID || !!dados.postagemid
        ? api.atualiza_post_aluno
        : api.insere_post_aluno;

    try {
      const res = await fetch(endpoint, {
        method: "post",
        headers: { token },
        body,
      });
      const json = await res.json();
      //            console.log("json", json, JSON.stringify(json));
      if (res.status >= 200 && res.status < 300) {
        const status = await json[0].status;
        const postagemid =
          json[0].data != null ? json[0].data.POSTAGEMID : dados.POSTAGEMID;

        if (status >= 200 && status < 300) {
          dispatch({
            type: POST_ATUALIZA_POST_ALUNO,
            updatePost: true,
            postagemsaveid: postagemid,
          });
        } else {
          throw new Error(json[0].message);
        }
        return json[0];
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      dispatch({
        type: POST_ATUALIZA_POST_ALUNO,
        updatePost: false,
        postagemsaveid: null,
      });
      throw new Error(error);
    }
  };
};

export const marcarReacao = (body, reaction, token, postagemid) => {
  return async (dispatch) => {
    try {
      const res =
        postagemid == null
          ? await fetch(api.marcar_reacao, {
              method: "post",
              headers: { token },
              body,
            })
          : await fetch(api.marcar_reacao_postagem, {
              method: "post",
              headers: { token },
              body,
            });
      const reactionKey = Object.keys(reaction)[0];

      if (res.status >= 200 && res.status < 300) {
        // const json = await res.json();
        dispatch({
          type: POST_MARCAR_REACAO,
          currentReaction: {
            key: reactionKey,
            value: reaction[reactionKey],
          },
        });
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      dispatch({ type: POST_MARCAR_REACAO, currentReaction: null });
      console.log("marcarReacao error", error);
    }
  };
};

export const reacoesPost = (capituloid, token) => {
  return async (dispatch) => {
    try {
      const res = await fetch(`${api.reacoes_post}?capituloid=${capituloid}`, {
        method: "get",
        headers: { token },
      });
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        dispatch({
          type: POST_REACOES_POST,
          postReactions: json[0].data[0],
        });
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      console.log("reacoesPost error", error);
      dispatch({ type: POST_REACOES_POST, postReactions: null });
    }
  };
};

export const reacaoAlunoPost = (alunoid, capituloid, token) => {
  return async (dispatch) => {
    try {
      const res = await fetch(
        `${api.reacao_aluno_post}?alunoid=${alunoid}&capituloid=${capituloid}`,
        {
          method: "get",
          headers: { token },
        }
      );
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        dispatch({
          type: POST_REACAO_ALUNO_POST,
          userReactions: json[0].data[0],
        });
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      console.log("reacaoAlunoPost error", error);
      dispatch({ type: POST_REACAO_ALUNO_POST, reactions: null });
    }
  };
};

export const gruposAlunoPost = (alunoid, token) => {
  return async (dispatch) => {
    try {
      const res = await fetch(`${api.grupos_aluno_post}?alunoid=${alunoid}`, {
        method: "get",
        headers: { token },
      });
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        dispatch({
          type: POST_GRUPOS_ALUNO,
          userGroups: json[0].data,
        });
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      console.log("gruposAlunoPost error", error);
      dispatch({ type: POST_GRUPOS_ALUNO, userGroups: null });
    }
  };
};

export const gruposGestorPost = (gestorid, token) => {
  return async (dispatch) => {
    try {
      const res = await fetch(
        `${api.grupos_gestor_post}?gestorid=${gestorid}`,
        {
          method: "get",
          headers: { token },
        }
      );
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        dispatch({
          type: POST_GRUPOS_GESTOR,
          userGroups: json[0].data,
        });
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      console.log("gruposAlunoPost error", error);
      dispatch({ type: POST_GRUPOS_GESTOR, userGroups: null });
    }
  };
};

export const salvaGrupoAlunoPost = (dados, token) => {
  return async (dispatch) => {
    const body = new FormData();
    body.append("dados", `[${JSON.stringify(dados)}]`);

    const endpoint = api.salva_grupo_aluno_post;
    try {
      const res = await fetch(endpoint, {
        method: "post",
        headers: { token },
        body,
      });
      const json = await res.json();
      if (res.status >= 200 && res.status < 300) {
        const status = await json[0].status;
        if (status >= 200 && status < 300) {
          dispatch({
            type: POST_SALVE_GRUPO_ALUNO,
            status: 200,
          });
        } else {
          throw new Error(json[0].message);
        }
      } else {
        throw new Error(res.statusText);
      }
      return json[0];
    } catch (error) {
      dispatch({ type: POST_SALVE_GRUPO_ALUNO });
      throw new Error(error);
    }
  };
};

export const salvaGrupoGestorPost = (dados, token) => {
  return async (dispatch) => {
    const body = new FormData();
    body.append("dados", `[${JSON.stringify(dados)}]`);

    const endpoint = api.salva_grupo_gestor_post;
    try {
      const res = await fetch(endpoint, {
        method: "post",
        headers: { token },
        body,
      });
      const json = await res.json();
      if (res.status >= 200 && res.status < 300) {
        const status = await json[0].status;
        if (status >= 200 && status < 300) {
          dispatch({
            type: POST_SALVE_GRUPO_GESTOR,
            status: 200,
          });
        } else {
          throw new Error(json[0].message);
        }
      } else {
        throw new Error(res.statusText);
      }
      return res;
    } catch (error) {
      dispatch({ type: POST_SALVE_GRUPO_GESTOR });
      throw new Error(error);
    }
  };
};

export const audiencia = (token, capituloid) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: SET_STATUS,
        payload: { key: AUDIENCIA_ALUNOS_LIST, value: "loading" },
      });
      const res = await fetch(`${api.audiencia}?capituloid=${capituloid}`, {
        method: "get",
        headers: { token },
      });
      const json = await res.json();
      dispatch({
        type: AUDIENCIA_ALUNOS_LIST,
        audienciaAlunosList: json[0].data || [],
      });
    } catch (error) {
      throw new Error(error);
    } finally {
      dispatch({
        type: SET_STATUS,
        payload: { key: AUDIENCIA_ALUNOS_LIST, value: "idle" },
      });
    }
  };
};

export const sendNotification = (token, capituloid) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: SET_STATUS,
        payload: { key: POST_SEND_NOTIFICATION, value: "loading" },
      });
      const res = await fetch(`${api.enviar_notificacao}?capituloid=${capituloid}`, {
        method: "get",
        headers: { token },
      });

      if (res.status >= 200 && res.status < 300) {
        dispatch({
          type: SET_STATUS,
          payload: { key: POST_SEND_NOTIFICATION, value: "success" },
        });
      }
    } catch (error) {
      dispatch({
        type: SET_STATUS,
        payload: { key: POST_SEND_NOTIFICATION, value: "failed" },
      });
      throw new Error(error);
    }
  };
};
// reducer
export default function reducer(state = {}, action) {
  switch (action.type) {
    case POST_SET_CURRENT_POST:
      return { ...state, currentPost: action.currentPost };

    case POST_ATUALIZA_POST_GESTOR:
      return {
        ...state,
        updatePost: action.updatePost,
        postagemsaveid: action.postagemsaveid,
      };

    case POST_ATUALIZA_POST_ALUNO:
      return {
        ...state,
        updatePost: action.updatePost,
        postagemsaveid: action.postagemsaveid,
      };

    case POST_MARCAR_REACAO:
      return {
        ...state,
        userReactions: {
          ...state.userReactions,
          [action.currentReaction.key]: action.currentReaction.value,
        },
      };
    case AUDIENCIA_ALUNOS_LIST:
      return { ...state, audienciaAlunosList: action.audienciaAlunosList };

    case POST_INPUT_FILE:
      return { ...state, status: action.status };

    case POST_REACOES_POST:
      return { ...state, postReactions: action.postReactions };

    case POST_REACAO_ALUNO_POST:
      return { ...state, userReactions: action.userReactions };

    case POST_GRUPOS_ALUNO:
      return { ...state, userGroups: action.userGroups };

    case POST_GRUPOS_GESTOR:
      return { ...state, userGroups: action.userGroups };

    case POST_SALVE_GRUPO_ALUNO:
      return { ...state, status: action.status };

    case POST_SALVE_GRUPO_GESTOR:
      return { ...state, status: action.status };
    case SET_STATUS:
      const newStatus = { ...state.status };
      const { key, value } = action.payload;

      newStatus[key] = value;

      return { ...state, status: newStatus };
    case CLEAN_POST:
      return {
        ...state,
        updatePost: action.updatePost,
        postagemsaveid: action.postagemsaveid,
        status: action.status,
        userGroups: action.userGroups,
        currentPost: action.currentPost,
      };

    default:
      return state;
  }
}
