import { styled } from "@mui/material";

import MuiDialogContent from "@mui/material/DialogContent";

const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  display: "flex",
  flexWrap: "nowrap",
  alignItems: "center",
}));

export default DialogContent;
