import { styled } from "@mui/material";

import { calcCircularProgressRotation } from "../../common/utils";

const WhiteCircle = styled("div")(({ theme }) => ({
  position: "relative",
  overflow: "hidden",
  width: "100%",
  aspectRatio: "1",
  backgroundColor: theme.palette.common.white,
  borderRadius: "50%",
}));

const HalfCircle = styled("div")(({ theme, shouldHide, rotation, color }) => ({
  position: "absolute",
  width: "50%",
  aspectRatio: "1/2",
  backgroundColor: `${
    (shouldHide && "transparent") ||
    theme.palette[color]?.dark ||
    theme.palette.common.white
  }`,
  transform: `rotate(${rotation || 0}deg)`,
  transformOrigin: "right center",
  transition: "transform 1s ease-in-out, background-color 1s ease-in",
}));

function CircularProgress({ progress = 0, color = "secondary", ...props }) {
  return (
    <WhiteCircle {...props}>
      <HalfCircle
        rotation={calcCircularProgressRotation(progress < 50 ? progress : 50)}
        color={color}
      />
      <HalfCircle
        rotation={calcCircularProgressRotation(progress)}
        color={color}
      />
      <HalfCircle shouldHide={progress > 50} />
    </WhiteCircle>
  );
}

export default CircularProgress;
