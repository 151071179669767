import { CloudUpload, UploadFile } from "@mui/icons-material";
import {
  Box,
  Button,
  Icon,
  SvgIcon,
  Typography,
  useTheme,
} from "@mui/material";
import { useRef } from "react";
import { useDrop } from "react-dnd";
import { NativeTypes } from "react-dnd-html5-backend";

const style = {
  border: "1px solid gray",
  height: "15rem",
  width: "15rem",
  padding: "2rem",
  textAlign: "center",
};

export const TargetBox = ({
  multiple,
  listWrapperStyle = {},
  renderList = (ref) => <></>,
  error = false,
  accept = "",
  ...rest
}) => {
  const { onDrop, onDelete, files, FilesComponent } = rest;

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop(item) {
        if (onDrop) {
          onDrop(item);
        }
      },
      canDrop(item) {
        // console.log("canDrop", item.files, item.items);
        return true;
      },
      hover(item) {
        // console.log("hover", item.files, item.items);
      },
      collect: (monitor) => {
        const item = monitor.getItem();
        if (item) {
          // console.log("collect", item.files, item.items);
        }
        return {
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
        };
      },
    }),
    [rest]
  );
  const isActive = canDrop && isOver;
  const theme = useTheme();
  const fileInputRef = useRef();
  const handleButtonUpload = (event) => {
    onDrop({ files: event.target.files });
    // console.log({ files: event.target.files });
  };
  const hasFiles = !!files.length;
  return (
    <>
      {hasFiles ? (
        <Box
          ref={drop}
          sx={{
            height: "100%",
            width: "100%",
            border: `1px solid ${
              error ? theme.palette.error.main : theme.palette.grey["400"]
            }`,
            overflow: "auto",
          }}
        >
          {renderList(fileInputRef)}
        </Box>
      ) : (
        <>
          <Box
            ref={drop}
            sx={{
              border: `1px solid ${
                error ? theme.palette.error.main : theme.palette.grey["400"]
              }`,
              borderRadius: 1,
              color: theme.palette.grey["600"],
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography>
              Solte seu{multiple && "s"} arquivo{multiple && "s"} aqui
              <br />
            </Typography>
            <Typography>ou</Typography>
            <Button
              variant="outlined"
              startIcon={<CloudUpload />}
              onClick={() => {
                fileInputRef.current.click();
              }}
            >
              Clique aqui
            </Button>
          </Box>
        </>
      )}
      <input
        multiple={multiple}
        ref={fileInputRef}
        onChange={handleButtonUpload}
        type="file"
        accept={accept}
        hidden
      />
    </>
  );
};
