import { useCallback, useState } from "react";

import { TargetBox } from "./TargetBox";
import { Box, Button, LinearProgress, Typography } from "@mui/material";
import { CloudUpload, List } from "@mui/icons-material";
import { FilesList } from "./FilesList";
function StatefulFileInput({ 
  multiple = false,
  loading = false,
  onSubmit = () => {},
  submitButtonRef = null,
}) {
  const [droppedFiles, setDroppedFiles] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);

  const handleFileDrop = useCallback(
    (item) => {
      if (item) {
        const files = item.files;
        setDroppedFiles((prev) => {
          if (multiple) {
            return [...prev, ...files];
          }
          return [files[0]];
        });
      }
    },
    [setDroppedFiles]
  );

  const handleDeleteFile = useCallback(
    (deletedItemIndex) => {
      setDroppedFiles((prev) => {
        const foo = prev.filter((item, index) => {
          return index != deletedItemIndex;
        });

        return foo;
      });
    },
    [setDroppedFiles]
  );
  return (
    <Box
      sx={{
        height: "250px",
        width: "100%",
        display: "flex",
        flexDirection: "row-reverse",
      }}
    >
      {loading ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Typography variant="h6"> Aguarde um momento...</Typography>
          <LinearProgress sx={{ width: "160px" }} />
        </Box>
      ) : (
        <>
          <Button
            sx={{ display: submitButtonRef == null ? "auto" : "none" }}
            disabled={!droppedFiles.length}
            onClick={() => {
              onSubmit(droppedFiles);
            }}
          >
            {" "}
            ENVIAR
          </Button>
          <Box
            sx={{
              aspectRatio: "16 / 9",
              width: "100%",
              height: "auto",
            }}
          >
            <TargetBox
              renderList={(fileInputRef) => {
                return (
                  <FilesList
                    fileInputRef={fileInputRef}
                    files={droppedFiles}
                    onDelete={handleDeleteFile}
                  />
                );
              }}
              files={droppedFiles}
              multiple={multiple}
              onDrop={handleFileDrop}
              onDelete={handleDeleteFile}
            />
          </Box>
        </>
      )}
    </Box>
  );
}

export default StatefulFileInput;
