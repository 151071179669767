import CookieDialogProvider from "./common/context";

import DialogModal from "./ui/partials/DialogModal";
import DialogContent from "./ui/partials/Content";

function CookieComplianceDialog({ open = true, ...props }) {
  return <></>
  return (
    <CookieDialogProvider open={open}>
      <DialogModal>
        <DialogContent />
      </DialogModal>
    </CookieDialogProvider>
  );
}

export default CookieComplianceDialog;
