import { axios } from "../../../shared/lib/axios";
import { useQuery } from "@tanstack/react-query";
import _cookies from "../../../shared/utils/_cookies";

const getTrackCourses = async ({ trackId }) => {
  const res = await axios.cadernoVirtualBase(
    "/certification-service/certification/listCoursesByTrack",
    {
      params: {
        trackId,
      },
    }
  );

  return res.object;
};

export const useTrackCourses = ({ config, trackId }) => {
  return useQuery({
    ...config,
    queryKey: ["trackCourses", { trackId }],
    queryFn: () => getTrackCourses({ trackId }),
  });
};
