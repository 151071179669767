import { TRACK_DADOS_TRILHA, COURSE_INSERE_MATRICULA } from "./actionCreators";

const initialState = { trackData: null, courses: null };

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case TRACK_DADOS_TRILHA:
      return action.track;
    case COURSE_INSERE_MATRICULA:
      return state;
    default:
      return state;
  }
}
