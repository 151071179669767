import { useState } from "react";
import { useQuery } from "../../../shared/hooks/useQuery";
import { certificate as certificateBackgroundImage } from "../../../shared/config";

import {
  Container,
  Button,
  List,
  ListItem,
  Box,
  Collapse,
  Checkbox,
  CircularProgress,
  Typography,
  ButtonGroup,
  LinearProgress,
  Grid,
} from "@mui/material";
import Loading from "../../components/Loading";
import {
  Certificate1sPart,
  Certificate2ndPart,
} from "../../components/CertificateParts";
import {
  Error,
  ExpandLess,
  ExpandMore,
  PhotoSizeSelectActual,
  PhotoLibrary,
} from "@mui/icons-material";
import { getCertificate } from "../../../features/certificates/getCertificate";
import { useMutation, useQueries } from "@tanstack/react-query";
import { useTheme } from "@emotion/react";
import { generatePDF } from "../../../shared/utils/pdfCreator";
import CertificatesListForm from "./CertificatesListForm";
import CertificatesListItem from "./CertificatesListItem";
export const FullCertificate = ({ certificate, index }) => {
  return !certificate ? (
    <Loading />
  ) : (
    <div>
      <Certificate1sPart
        id={`index${index}certificatePage1`}
        certificate={certificate}
      />
      <Certificate2ndPart
        id={`index${index}certificatePage2`}
        certificate={certificate}
      />
    </div>
  );
};

const MultipleCertificates = () => {
  const ids = useQuery()
    .get("matriculaids")
    .split(",")
    .filter((obj) => obj !== "");
  const [courseType, setCourseType] = useState(
    useQuery().get("certificateType") || "proprio"
  );
  const theme = useTheme();

  const [openItem, setOpenItem] = useState(null);
  const [isListExpanded, setIsListExpanded] = useState(false);
  const [progress, setProgress] = useState(0);
  const [state, setState] = useState(
    ids?.map((id) => {
      return { id, selected: false };
    }) || []
  );

  const queries = useQueries({
    queries: state.map((obj, index) => {
      return {
        queryKey: ["certificate", obj.id, courseType], // queryKey: ["certificate", matriculaid],
        queryFn: () => getCertificate({ matriculaid: obj.id, courseType }),
        placeholderData: [],
        onSuccess: () => handleClickItem(true, index),
      };
    }),
  });
  const generatePDFMutation = useMutation({
    mutationFn: async (type) => {
      switch (type) {
        case "INDIVIDUAL":
          await generatePDF({
            config: {
              orientation: "landscape",
              unit: "px",
              format: [900, 675],
            },
            pages: [].concat(
              state.flatMap((obj, index) => {
                if (obj.selected) {
                  return [
                    document.getElementById(`index${index}certificatePage1`),
                    document.getElementById(`index${index}certificatePage2`),
                  ];
                }
              })
            ),
            title: "certificados",
          });
          setProgress(0);
          break;
        case "SEPARATE":
          const length = state.filter((obj) => obj.selected).length;
          for await (const [index, obj] of state.entries()) {
            if (obj.selected) {
              await generatePDF({
                title: "certificado" + obj.id,
                config: {
                  orientation: "landscape",
                  unit: "px",
                  format: [900, 675],
                },
                pages: [
                  document.getElementById(`index${index}certificatePage1`),
                  document.getElementById(`index${index}certificatePage2`),
                ],
              });
              setProgress((index * 100) / length);
            }
          }
          setProgress(0);
          break;
        default:
          console.log("{error}");
      }
    },
  });

  const handleClickItem = (selected, index) => {
    setState((prev) => {
      prev[index] = { ...prev[index], selected };
      return [...prev];
    });
  };

  const handleExpandItemClick = (index) =>
    setOpenItem((prev) => (index !== prev ? index : null));

  const isSomeLoading = queries.some((obj) => obj.isLoading);
  const isSomeError = queries.some((obj) => obj.isError);
  const isAllSelected = state.every((obj) => obj.selected);
  const onlySomeSelected =
    state.some((obj) => obj.selected) && state.some((obj) => !obj.selected);
  const handleSelectAll = (checked) => {
    state.map((obj, index) => {
      if (onlySomeSelected) {
        handleClickItem(false, index);
      } else if (queries[index].isSuccess) {
        handleClickItem(checked, index);
      }
    });
  };

  return (
    <Container
      disableGutters
      maxWidth="false"
      sx={{
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        overflowY: "scroll",
        height: "100vh",
        padding: "24px",
      }}
    >
      <CertificatesListForm
        defaultValues={{
          ids: ids || "",
          courseType,
        }}
        onSubmit={(data) => {
          setIsListExpanded(true);
          setState(
            data.ids.map((id) => {
              return { id, selected: false };
            })
          );
          setCourseType(data.courseType);
        }}
      />
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        {generatePDFMutation.isLoading ? (
          <Box sx={{ position: "relative", display: "inline-flex" }}>
            <CircularProgress variant="determinate" value={progress} />
            <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="caption"
                component="div"
                color="text.secondary"
              >
                {`${Math.round(progress)}%`}
              </Typography>
            </Box>
          </Box>
        ) : (
          <ButtonGroup variant="text">
            <Button
              disabled={
                state.filter(({ selected }) => selected).length === 0 ||
                generatePDFMutation.isLoading
              }
              startIcon={<PhotoSizeSelectActual />}
              onClick={() => {
                setIsListExpanded(false);
                generatePDFMutation.mutate("INDIVIDUAL");
              }}
            >
              Imprimir em arquivo único
            </Button>
            <Button
              disabled={
                state.filter(({ selected }) => selected).length === 0 ||
                generatePDFMutation.isLoading
              }
              startIcon={<PhotoLibrary />}
              onClick={() => {
                setIsListExpanded(false);
                generatePDFMutation.mutate("SEPARATE");
              }}
            >
              Imprimir arquivos individuais
            </Button>
          </ButtonGroup>
        )}
      </Box>
      <Button
        disabled={generatePDFMutation.isLoading}
        onClick={() => {
          setIsListExpanded(!isListExpanded);
        }}
        sx={{ width: "100%" }}
        startIcon={isListExpanded ? <ExpandLess /> : <ExpandMore />}
      >
        {isListExpanded
          ? "Ver prévias dos certificados"
          : "Ver detalhes dos certificados"}
      </Button>
      <Collapse in={isListExpanded} collapsedSize={30}>
        <List
          dense
          sx={{
            padding: "0",
            borderTop: `1px solid ${
              isSomeError
                ? theme.palette.warning.main
                : isSomeLoading
                ? theme.palette.grey["300"]
                : theme.palette.success.main
            }`,
          }}
        >
          <ListItem
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Checkbox
              checked={isAllSelected}
              indeterminate={onlySomeSelected}
              sx={{ position: "absolute", left: "0" }}
              onClick={(event) => {
                handleSelectAll(event.target.checked);
              }}
            />

            <Typography>Selecionar todos</Typography>
          </ListItem>
          {state.map((obj, index) => {
            const expanded = openItem === index;

            return (
              <CertificatesListItem
                data={{ ...obj, ...queries[index]?.data }}
                isLoading={queries[index].isLoading}
                isError={queries[index].isError}
                onSelect={(event) => {
                  handleClickItem(event.target.checked, index);
                }}
                onExpand={() => handleExpandItemClick(index)}
                expanded={expanded}
                selected={obj.selected}
              />
            );
          })}
        </List>
      </Collapse>

      <Collapse in={!isListExpanded}>
        <List
          sx={{
            overflowY: "visible",
          }}
        >
          {state.map((obj, index) => {
            const certificate = queries[index].data || {};
            return (
              obj.selected && (
                <ListItem
                  sx={{
                    overflowY: "visible",
                    position: "relative",
                    // top: `${index * 1300}px`,
                    alignSelf: "center",
                    justifyContent: "center",
                    // height: "1950px",
                  }}
                >
                  <FullCertificate
                    key={index}
                    certificate={{
                      ...certificate,
                      certificateUrl: certificateBackgroundImage,
                    }}
                    index={index}
                  />
                </ListItem>
              )
            );
          })}
        </List>
      </Collapse>
    </Container>
  );
};

export default MultipleCertificates;
