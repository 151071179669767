import { api } from "../../../../../shared/api";

// actions
export const CHAPTER_SET_MODULE_LIST = "CHAPTER_SET_MODULE_LIST";
export const CHAPTER_GET_MODULE_CONTENT = "CHAPTER_GET_MODULE_CONTENT";
const CHAPTER_ACESSAR_CAPITULO = "CHAPTER_ACESSAR_CAPITULO";
const CHAPTER_DADOS_CAPITULO = "CHAPTER_DADOS_CAPITULO";
const CHAPTER_LISTAR_ANEXOS = "CHAPTER_LISTAR_ANEXOS";
const CHAPTER_FINALIZA_CAPITULO = "CHAPTER_FINALIZA_CAPITULO";
const CHAPTER_SET_CURRENT_CHAPTER = "CHAPTER_SET_CURRENT_CHAPTER";
const CHAPTER_SET_FINISH_BUTTON_VISIBILITY =
  "CHAPTER_SET_FINISH_BUTTON_VISIBILITY";
const CLEAN_CHAPTER = "CLEAN_CHAPTER";
const CHAPTER_CHANNEL_CAPITULO = "CHAPTER_CHANNEL_CAPITULO";

//Move to Social Store
export const CHAPTER_GET_POST_DATA = "CHAPTER_GET_POST_DATA";

// action creators
export const acessarCapitulo = (
  token,
  alunoid,
  { matriculaid, disciplinaid, capituloid }
) => {
  return async (dispatch) => {
    const body = new FormData();
    body.append(
      "dados",
      `[{"alunoid":${alunoid},"matriculaid":${matriculaid},"disciplinaid":${disciplinaid},"capituloid":${capituloid}}]`
    );
    try {
      // const res =
      await fetch(api.acessar_capitulo, {
        method: "post",
        headers: { token },
        body,
      });
      // const json = await res.json();
      dispatch({ type: CHAPTER_ACESSAR_CAPITULO, accessChapter: true });
    } catch (error) {
      dispatch({ type: CHAPTER_ACESSAR_CAPITULO, accessChapter: false });
      throw new Error(error);
    }
  };
};

export const dadosCapitulo = (
  token,
  { capituloid, disciplinaid, postagemid }
) => {
  return async (dispatch) => {
    const apiUrl =
      disciplinaid > 900000 ? api.dados_capitulo_convenio : api.dados_capitulo;
    try {
      const res =
        postagemid == null
          ? await fetch(`${apiUrl}?capituloid=${capituloid}`, {
              method: "get",
              headers: { token },
            })
          : await fetch(`${api.dados_postagem}?postagemid=${postagemid}`, {
              method: "get",
              headers: { token },
            });

      const json = await res.json();
      if (!json[0].data) throw new Error(res.statusText);
      const details = json[0].data[0];
      dispatch({ type: CHAPTER_DADOS_CAPITULO, details });
      return details;
    } catch (error) {
      dispatch({ type: CHAPTER_DADOS_CAPITULO, details: null });
      return null;
    }
  };
};

export const listarAnexos = (
  token,
  { capituloid, disciplinaid, postagemid }
) => {
  return async (dispatch) => {
    const apiUrl =
      disciplinaid > 900000 ? api.listar_anexos_convenio : api.listar_anexos;
    try {
      const res =
        postagemid == null
          ? await fetch(`${apiUrl}?capituloid=${capituloid}`, {
              method: "get",
              headers: { token },
            })
          : await fetch(
              `${api.listar_anexos_alunos}?postagemid=${postagemid}`,
              {
                method: "get",
                headers: { token },
              }
            );
      const json = await res.json();
      const attachments = json[0].data;

      dispatch({ type: CHAPTER_LISTAR_ANEXOS, attachments });
    } catch (error) {
      dispatch({ type: CHAPTER_LISTAR_ANEXOS, attachments: null });
      throw new Error(error);
    }
  };
};

export const finalizaCapitulo = ({
  alunoid,
  matriculaid,
  disciplinaid,
  capituloid,
  token,
}) => {
  return async (dispatch) => {
    const body = new FormData();
    body.append(
      "dados",
      `[{"alunoid":${alunoid},"matriculaid":${matriculaid},"disciplinaid":${disciplinaid},"capituloid":${capituloid}}]`
    );
    try {
      const res = await fetch(api.finaliza_capitulo, {
        method: "post",
        headers: { token },
        body,
      });
      if (res.status >= 200 && res.status < 300) {
        dispatch({ type: CHAPTER_FINALIZA_CAPITULO, action: true });
      }
    } catch (error) {
      dispatch({ type: CHAPTER_FINALIZA_CAPITULO, finishChapter: false });
      throw new Error(error);
    }
  };
};

export const canalCapitulo = ({ token, capituloid }) => {
  return async (dispatch) => {
    try {
      const res = await fetch(
        `${api.dados_capitulo}?capituloid=${capituloid}`,
        {
          method: "get",
          headers: { token },
        }
      );
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        const details = json[0].data[0];
        dispatch({ type: CHAPTER_CHANNEL_CAPITULO, details });
        return details;
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      throw new Error(error);
    }
  };
};

export const setCurrentChapter = (currentChapter) => {
  return { type: CHAPTER_SET_CURRENT_CHAPTER, currentChapter };
};

export const setFinishButtonVisibility = (isVisible) => {
  return { type: CHAPTER_SET_FINISH_BUTTON_VISIBILITY, isVisible };
};
export const clearChapter = () => {
  return {
    type: CLEAN_CHAPTER,
    details: null,
    attachments: null,
    currentChapter: null,
  };
};
// reducer
export default function reducer(state = {}, action) {
  switch (action.type) {
    case CHAPTER_ACESSAR_CAPITULO:
      return { ...state, accessChapter: action.accessChapter };

    case CHAPTER_DADOS_CAPITULO:
      return { ...state, details: action.details };

    case CHAPTER_LISTAR_ANEXOS:
      return { ...state, attachments: action.attachments };

    case CHAPTER_FINALIZA_CAPITULO:
      if (action) {
        return {
          ...state,
          currentChapter: { ...state.currentChapter, SITUACAO: "CONCLUSO" },
        };
      } else {
        return { ...state };
      }

    case CHAPTER_SET_CURRENT_CHAPTER:
      return { ...state, currentChapter: action.currentChapter };

    case CHAPTER_SET_FINISH_BUTTON_VISIBILITY:
      return { ...state, isFinishButtonVisible: action.isVisible };
    case CHAPTER_CHANNEL_CAPITULO:
      return { ...state, details: action.details };
    case CHAPTER_SET_MODULE_LIST:
      return { ...state, content: action.payload };
    case CHAPTER_GET_POST_DATA:
      return { ...state, details: action.details };
    case CLEAN_CHAPTER:
      return {
        ...state,
        details: action.details,
        attachments: action.attachments,
        currentChapter: action.currentChapter,
      };
    default:
      return state;
  }
}
