export const titles = {
  canais: "Canais",
  extras: "Cursos Extras",
  game: "Seus Games",
  ideias: "Ideias",
  lives: "Lives",
  postagens: "Postagens",
  trilhas: "Trilhas",
  tutoriais: "Biblioteca",
  catalogo: "Catálogo",
  //TODO verificar quando possível
  pesquisa: "Pesquisa",
};

export const keysNav = {
  CANAIS: "canais",
  EXTRAS: "extras",
  GAMES: "game",
  IDEIAS: "ideias",
  LIVES: "lives",
  POSTAGEM: "postagens",
  TRILHAS: "trilhas",
  TUTORIAIS: "tutoriais",
  BASE: "base",
  NOTICIAS: "noticias",
  Professor: "menu-educador",
  CATALOGO: "catalogo",
  Gestor: "prateleira",
  CERTIFICACOES: "certificacoes",
  //TODO verificar quando possível
  PESQUISA: "pesquisa",
};
