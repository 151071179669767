// actions
const SNACKBAR_SET = "SNACKBAR_SET";

// action creators
export const setSnackbar = (
  open = false,
  message = "",
  variant = "info",
  autoHideDuration = 6000
) => {
  return {
    type: SNACKBAR_SET,
    settings: { open, message, variant, autoHideDuration }
  };
};

// reducer
const initialState = {
  open: false,
  message: "",
  variant: "info",
  autoHideDuration: 6000
};
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SNACKBAR_SET:
      return action.settings;

    default:
      return state;
  }
}
