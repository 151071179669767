import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { ChoiceComponent } from "./MultipleChoice";

export default function TrueOrFalse({
  value,
  onChange,
  showAnswer = false,
  listOptions = [],
}) {
  return (
    <RadioGroup
      row
      sx={{
        margin: "1rem",
        gap: "5rem",
        "& .MuiRadio-root": {
          padding: "0",
        },
      }}
      value={value}
      onChange={onChange}
    >
      <ChoiceComponent
        sx={{
          outline:
            (value === "0" || value === 0) && showAnswer
              ? "5px solid blue"
              : "unset",
          padding: "0.25rem",
          borderRadius: "10px",
        }}
        value={"0"}
        label={listOptions?.[0]?.optionDescription}
      />
      <ChoiceComponent
        sx={{
          outline:
            (value === "1" || value === 1) && showAnswer
              ? "5px solid blue"
              : "unset",
          padding: "0.25rem",
          borderRadius: "10px",
        }}
        value={"1"}
        label={listOptions?.[1]?.optionDescription}
      />
    </RadioGroup>
  );
}
