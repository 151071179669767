import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import Loading from "../components/Loading";
import { logout } from "../../shared/store/auth";
import { isLocal } from "../../shared/config/urlConfig";

const Logout = ({ auth, ...props }) => {
  const { logout } = props;

  useEffect(() => {
    async function asyncEffect() {
      console.log('entrou no logout')
      await logout(auth.user.alunoid, auth.token);
      if (!isLocal) window.location.href = "/";
      else window.location.href = "/aluno";
    }
    asyncEffect();
  }, [auth.token, auth.user.alunoid, logout]);

  return <Loading />;
};

const mapStateToProps = (state) => {
  return { auth: state.auth };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ logout }, dispatch);
};

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} router={{ location, navigate, params }} />;
  }
  return ComponentWithRouterProp;
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Logout));
