import { api } from "../api";

// actions
const PROFILE_DADOS_ALUNO = "PROFILE_DADOS_ALUNO";
const PROFILE_ATUALIZA_PERFIL = "PROFILE_ATUALIZA_PERFIL";
const PROFILE_ALTERA_SENHA = "PROFILE_ALTERA_SENHA";
const PROFILE_ALTERA_FOTO = "PROFILE_ALTERA_FOTO";

// action creators
export const dadosAluno = (alunoid, token) => {
  return async dispatch => {
    try {
      const res = await fetch(`${api.dados_aluno}?alunoid=${alunoid}`, {
        method: "get",
        headers: { token }
      });
      if (res.status >= 200 && res.status < 300) {
        const json = await res?.json();
        const details = json[0]?.data[0];
        dispatch({ type: PROFILE_DADOS_ALUNO, details });
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      dispatch({ type: PROFILE_DADOS_ALUNO, details: null });
      throw new Error(error);
    }
  };
};

export const atualizaPerfil = (dados, token) => {
  return async dispatch => {
    const body = new FormData();
    body.append("dados", dados);

    try {
      const res = await fetch(api.atualiza_perfil, {
        method: "post",
        headers: { token },
        body
      });
      if (res.status >= 200 && res.status < 300) {
        dispatch({
          type: PROFILE_ATUALIZA_PERFIL,
          updateProfile: res.status
        });
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      dispatch({ type: PROFILE_ATUALIZA_PERFIL, updateProfile: null });
      throw new Error(error);
    }
  };
};
export const delay = ms => new Promise(
  resolve => setTimeout(resolve, ms)
);

export const alteraSenha = (dados, token) => {
  return async dispatch => {
    const body = new FormData();
    body.append("dados", dados);

    try {
      const res = await fetch(api.altera_senha, {
        method: "post",
        headers: { token },
        body
      });
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        console.log('resultado senha', json[0]);
        
        dispatch({
          type: PROFILE_ALTERA_SENHA,
          updatePassword: json
        });
        return json[0];
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      dispatch({ type: PROFILE_ALTERA_SENHA, updatePassword: null });
      throw new Error(error);
    }
  };
};

export const alteraFoto = (alunoid, file, token) => {
  return async dispatch => {
    const body = new FormData();
    body.append("dados", `[{"alunoid":${alunoid}}]`);
    body.append("file", file);

    try {
      const res = await fetch(api.altera_foto, {
        method: "post",
        headers: { token },
        body
      });
      if (res.status >= 200 && res.status < 300) {
        dispatch({
          type: PROFILE_ALTERA_FOTO,
          updateImage: res.status
        });
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      dispatch({ type: PROFILE_ALTERA_FOTO, updateImage: null });
      throw new Error(error);
    }
  };
};

// reducer
export default function reducer(state = {}, action) {
  switch (action.type) {
    case PROFILE_DADOS_ALUNO:
      return { ...state, details: action.details };

    case PROFILE_ATUALIZA_PERFIL:
      return { ...state, updateProfile: action.updateProfile };

    case PROFILE_ALTERA_SENHA:
      return { ...state, updatePassword: action.updatePassword };

    case PROFILE_ALTERA_FOTO:
      return { ...state, updateImage: action.updateImage };

    default:
      return state;
  }
}
