import { useQuery } from "@tanstack/react-query";
import { axios } from "../../../shared/lib/axios";

export const getSurvey = async ({ surveyId }) => {
  const res = await axios.cadernoVirtualBase("survey-service2/research", {
    params: {
      id: surveyId,
    },
  });
  return res.object[0];
};

export const useSurvey = ({ config, surveyId } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["survey", { surveyId }],
    queryFn: () => getSurvey({ surveyId }),
  });
};
